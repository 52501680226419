<template>
<div>
  <div>
   <div>
     Landlords
     <v-btn color="rgb(255, 64, 0)" dark @click="leadDialog=true" >Add new Lead</v-btn>
     <!-- <v-btn color="primary" @click="switchToZh">switch to ZH</v-btn> -->
     <v-btn color="primary" @click="messageDialog=true">Send Line Message</v-btn>
     <v-spacer></v-spacer>
     <v-layout wrap >

     <v-select
       :items="admins"
       v-model="selectedAdmin"
       placeholder="Admin"
       label="Selected Admin"
       @change="selectAdmin"
       item-text="userName"
       item-value="id"
       clearable
     ></v-select>
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-layout>
 </div>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="tUsers"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td :class="{doToday: props.item.doToday}">
         <v-menu
         :close-on-content-click="false"

         v-model="props.item.editNC"
         :nudge-right="40"
         lazy
         full-width
         class="table-menu px-1 "
         transition="scale-transition"
       >
         <v-text-field
           slot="activator"
           v-model="props.item.nextContact"
           label="next Contact"
           readonly
         ></v-text-field>
         <v-date-picker v-model="props.item.nextContact" @input="saveNextContact(props.item)"></v-date-picker>
       </v-menu>
     </td>
       <td> <span v-if="props.item.editUsername==true">
         <v-text-field
            v-model="props.item.tUsername"
            label="userName"
         ></v-text-field>
         <v-btn color="primary" :disabled="props.item.disable" @click="saveUsername(props.item)">save</v-btn>
       </span>
       <span v-else>
         <a :href="'/profile?id='+props.item['.key']" > {{ props.item.userName }} </a>
         <br>{{props.item.type}}
         <br>
         <a @click="editUsername(props.item)">Edit Username</a>
       </span>
       </td>
       <td class="">{{ props.item.email }}
         <span v-if="props.item.communication"><br>communication: {{props.item.communication}}</span>
         <span v-if="props.item.lineId"><br>line: {{props.item.lineId}}</span>
         <span v-if="props.item.facebook"><br><a :href="props.item.facebook" target="blank">fb link</a></span>
         <span v-if="props.item.phone"><br>Phone: {{props.item.phone}}</span>
         <span><br>Send Chinese: {{props.item.sendChinese}}</span>

       </td>
       <td>
         <v-layout wrap >
           <v-menu
             :close-on-content-click="false"

             v-model="props.item.editLC"
             :nudge-right="40"
             lazy
             full-width
             class="table-menu px-1 "
             transition="scale-transition"
           >
             <v-text-field
               slot="activator"
               v-model="props.item.lastContact"
               label="last Contact"
               readonly
               width="150px"
             ></v-text-field>
             <v-date-picker v-model="props.item.lastContact" @input="saveLastContact(props.item)"></v-date-picker>
           </v-menu>
         </v-layout>
         <v-layout wrap>
           <span v-if="props.item.editInfo==true">
             <v-textarea
              v-model="props.item.adminText"
              auto-grow
              rows="1"
             ></v-textarea>
             <v-btn color="primary" :disabled="props.item.disable" @click="saveAdminText(props.item)">save</v-btn>
           </span>
           <span v-else>Admin Note: {{props.item.adminText}} <a @click="editInfo(props.item)">edit</a>
           </span>
         </v-layout>
       </td>
       <td>
         <span v-if="props.item.tags!=undefined" v-for="i in props.item.tags" :key="i">{{i}}, </span>
         <v-autocomplete :search-input.sync="props.item.searching" :items="tags" v-model="props.item.tags" multiple hide-details clearable item-text="name" item-value="name" label="Tags?" v-if="user.roleValue>20">
           <template height="3em" slot="no-data" class="pa-2">
              <v-btn color="primary" @click="saveNew(props.item)">Add new Tag </v-btn>
           </template>

           <template slot="selection" slot-scope="{item, selected}">
                <!-- <span v-text="item.name"></span> -->
           </template>
           <template
             slot="item"
             slot-scope="{ item, tile }"
           >
             <v-list-tile-content>
               <v-list-tile-title v-text="item.name"></v-list-tile-title>
               <!-- <v-list-tile-sub-title v-text="item.name"></v-list-tile-sub-title> -->
             </v-list-tile-content>
           </template>
         </v-autocomplete>
         <v-btn color="primary"  text @click="saveTags(props.item)">Save Tags</v-btn>
       </td>
       <td class="">
         <div class="" v-if="props.item.verifyMe==true">
           Please check me out!!
         </div>
         <div class="" v-else>
           meh
         </div>
       </td>
       <td class="">{{ props.item.name }}</td>
       <td class="">
         <v-select
            v-if="props.item.type!='lead'"
           :items="certifications"
           placeholder="Not Certified"
           v-model="props.item.trustVal"
           @change="setTrust(props.item, $event,props.item.apartments, props.item.studios, props.item.wholeApts)"
         ></v-select>
       </td>
       <td class=""><router-link v-if="props.item.type!='lead'" :to="{ name: 'adminLandlordDashboard', params: {id:props.item['.key']} }">View Rooms</router-link></td>
       <td> <div class="" v-if="props.item.type!='lead'">

              <v-btn color="primary" @click="setSummer(props.item,true)">Set Summer</v-btn> <v-btn color="error" @click="setSummer(props.item,false)">Remove summer</v-btn>
              <br><v-btn color="#FF4000" dark v-if="props.item.margin!=false" @click="setLLPrice(props.item,false)">Set Price Method</v-btn>
              <v-btn color="#FF4000" v-else dark @click="setLLPrice(props.item,true)">Set Margin Method</v-btn>
            </div>
            <div class="" v-else>
              LEAD
            </div>
       </td>
       <td>
         {{props.item.admin}}
         <v-select
           :items="admins"
           placeholder="No admin selected"
           v-model="props.item.admin"
           @change="setAdmin(props.item)"
           item-text="userName"
           item-value="id"
         ></v-select>
       </td>
       <!-- <td>{{props.item.admin}}</td> -->
       <td class="">{{ props.item.dateAdded | dateLL}}</td>
       <td class="">{{ props.item.lastLogin | dateLL}}</td>
       <td class=""><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/users/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </div>
 <v-dialog
   v-model="leadDialog"
   scrollable
   persistent :overlay="false"
   max-width="500px"
   transition="dialog-transition"
 >
    <v-card>
      <v-card-title primary-title>
        New Lead
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="lead.userName"
          label="What this lead's username?"
          :error-messages="userNameErrorMessage"
          @blur="$v.lead.userName.$touch()"
        ></v-text-field>
        <v-select :items="communicationOptions" v-model="lead.communication" :label="$t('profile.preferredMethodHint')"></v-select>
        <v-text-field
          v-model="lead.lineId"
          label="Line ID"
        ></v-text-field>
        <v-text-field
        v-model="lead.phone"
        label="Phone number"
        ></v-text-field>
        <v-text-field
          v-model="lead.facebook"
          label="Facebook Link"
        ></v-text-field>
        <v-text-field
        v-model="lead.email"
        label="Email"
        ></v-text-field>
        <v-textarea
         v-model="lead.adminText"
         auto-grow
         label="Text"
         rows="1"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="leadDialog=false">cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="disabledLead" @click="saveLead">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar
    v-model="showSnackbar"
    color="primary"
    :timeout="3000"

  >
    {{snackbarMessage}}
    <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
  </v-snackbar>

  <v-dialog
    v-model="messageDialog"
    scrollable
    :overlay="true"
    max-width="500px"
    transition="dialog-transition"
  >
     <v-card>
       <v-card-title primary-title>
         New Message
       </v-card-title>
       <v-card-text>
         <v-select
           multiple
           :items="sendTags"
           v-model="toMessage"
           item-value="name"
           item-text="name"
           label="Which tag?"
         ></v-select>
         <v-textarea
          v-model="messageContent"
          auto-grow
          label="Which Message"
          rows="3"
         ></v-textarea>
       </v-card-text>
       <v-card-actions>
         <v-btn color="error"  @click="messageDialog=false">cancel</v-btn>
         <v-spacer></v-spacer>
         <v-btn color="primary" :disabled="disabledMessage" @click="sendMessage()">Send</v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import { required, email, minLength} from 'vuelidate/lib/validators'



export default {
  name: 'Lanldords',
  data: () => ({
    headers: [
      { text: 'Next Contact', value:'nextContact'},
      { text: 'Username', value: 'userName' },
      { text: 'Email', value: 'email' },
      { text: 'Admin Info', value: 'lastContact', width:"250px"},
      { text: 'TAGS', value: 'tags' },
      { text: 'Certification Requested', value: 'verifyMe' },
      { text: 'Real Name', value: 'name'},
      { text:'Trust value',sortable:true, value:'certified'},
      { text: 'Rooms' , sortable:false},
      { text: 'Set Summer', sortable:false},
      {text: 'Admin', value:'admin'},
      { text: 'Date joined', value: 'dateAdded' },
      { text: 'Last login', value: 'lastLogin' },
      { text: 'id' , value:".key"},
    ],
    // tags:[{name:'Taipei'},{name:'Taichung'}],
    certifications:[
      {value:0, text:"Not Certified"},
      {value:1, text:"Certified"},
      {value:3, text:"Exclusive"}
    ],
    pagination:{
      sortBy:'verifyMe',
    },
    snackbarMessage:"",
    showSnackbar:false,
    search: null,
    rooms:[],
    value:'',
    json:"",
    searched: [],
    selectedAdmin:"",
    leadDialog:false,
    disabledLead:false,
    lead:{
      userName:"",
      communication:'Line',
    },
    messageDialog:false,
    toMessage:[],
    messageContent:"",
    disabledMessage:false,
    tUsers:[],
    leadErrors:[]
  }),
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),
    sendTags(){
      let allTags=JSON.parse(JSON.stringify(this.tags))
      allTags.unshift({name:'All with Line Follow'})
      return allTags
    },
    communicationOptions(){
      let t=['Email', 'Phone', 'Line', 'WeChat', 'WhatsApp', 'Facebook']
      if (this!=undefined){
        t=[
          {
            text:this.$t('tenants.email'),
            value:'Email',
          },
          {
            text:this.$t('profile.lineApp'),
            value:'Line',
          },{
            text:this.$t('profile.phone'),
            value:'Phone',
          },{
            text:this.$t('profile.wechatApp'),
            value:'WeChat',
          },{
            text:this.$t('profile.whatsapp'),
            value:'WhatsApp',
          },{
            text:this.$t('profile.facebookApp'),
            value:'Facebook',
          },
        ]
      }
      return t
    },
    userNameErrorMessage(){
      let errors=[]
      if (!this.$v.lead.userName.$dirty) return errors
      !this.$v.lead.userName.required && errors.push('userName is a required field')
      return errors
    },


  },
  validations: {
    lead: {
      userName: {
        required
      }
    },
  },
  firebase() {
    return {
      tags:{
        source:firebase.database().ref('tags')
      },
      users: {
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(20),
        readyCallback:function(){
          console.log(this.users,'readyCallback')
          for (var i = 0; i < this.users.length; i++) {
            this.users[i].id=this.users[i]['.key']
            this.users[i].type="Landlord"
            this.users[i].dateAdded=moment(this.users[i].dateAdded).format()
            this.users[i].lastLogin=moment(this.users[i].lastLogin).format()
            if(this.users[i].trustVal==undefined){
              this.users[i].trustVal=0
            }
            if (this.users[i].verifyMe==undefined){
              this.users[i].verifyMe=false
            }
            if (this.users[i].nextContact==undefined){
              this.users[i].nextContact="2020-12-01"
            }
            if (moment(this.users[i].nextContact).isBefore(moment().add(1,"Day"))){
              this.users[i].doToday=true
            }
            if(this.users[i].apartments==undefined){
              this.users[i].apartments={}
            }
            if(this.users[i].studios==undefined){
              this.users[i].studios={}
            }
          }
          this.tUsers=JSON.parse(JSON.stringify(this.users)).concat(JSON.parse(JSON.stringify(this.leads)))
          this.pagination.sortBy='nextContact'

          this.pagination.sortBy='verifyMe'
          this.pagination.descending=true
        }
      },
      leads:{
        source:firebase.database().ref('leads'),
        readyCallback:function(){
          for (var i = 0; i < this.leads.length; i++) {
            this.leads[i].type='lead'
          }
          this.tUsers=JSON.parse(JSON.stringify(this.users)).concat(JSON.parse(JSON.stringify(this.leads)))
          this.pagination.sortBy='nextContact'
          this.pagination.sortBy='verifyMe'
          this.pagination.descending=true
        }
      },
      admins:{
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(80),
        readyCallback:function(){
          console.log(this.admins);
          this.admins.forEach((item, i) => {
            item.id=item['.key']
          });
        }
      },
      roomsDB:{
        source:firebase.database().ref('rooms'),
        readyCallback:function(){
          this.roomsDownloaded=true
          for (var i = 0; i < this.roomsDB.length; i++) {
            this.rooms.push({'id':this.roomsDB[i]['.key']})
          }
          console.log("the rooms:",this.rooms)

        }

      },
      lineFollows:{
        source:firebase.database().ref('lineFollow').orderByChild("ll").startAt(false),
      },
      apts:{
        source:firebase.database().ref('apartments'),
        asObject:true,
      },
      studios:{
        source:firebase.database().ref('studios')
      },
      wholeApts:{
        source:firebase.database().ref('wholeApts')
      }

    }

  },
  methods: {
    sendMessage(){
      this.disabledMessage=true
      for (var i = 0; i < this.lineFollows.length; i++) {
        let follow=this.lineFollows[i]
        if (follow.hasOwnProperty('ll') && follow.hasOwnProperty('userId')){
          let user=this.users.find(user=>user['.key']==follow.ll)
          // console.log(user.userName);
          if (this.toMessage.includes('All with Line Follow')){
            console.log('send to ALL',user.userName);
            try{

              let info={to: follow.userId,messages:[{"type": "text","text": this.messageContent}]}
              axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', info, {
                headers: {
                  'Content-Type': 'text/plain'
                }
              })
              .then(res=>{
                console.log(res);
              })
              .catch(err=>{
                console.log(err);
                throw (err)
              })
            }
            catch(err){
              console.log(err);
              alert('Tell Vincent There is an error:', err)
            }
          } else if (user.hasOwnProperty('tags') && this.toMessage.some(r=> user.tags.includes(r))) {
            console.log('send to ',user.userName);
            try{

              let info={to: follow.userId,messages:[{"type": "text","text": this.messageContent}]}
              axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', info, {
                headers: {
                  'Content-Type': 'text/plain'
                }
              })
              .then(res=>{
                console.log(res);
              })
              .catch(err=>{
                console.log(err);
                throw (err)
              })
            }
            catch(err){
              console.log(err);
              alert('Tell Vincent There is an error:', err)
            }
          }
        }
      }
      this.messageDialog=false
      console.log('Message: ',this.messageContent);
      this.disabledMessage=false
    },
    saveTags(item){
      console.log(item.tags, item['.key'])
      axios.patch('users/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{tags:item.tags})
    },
    saveNew(item){
      console.log(item.searching, moment().format('X'));
      axios.post('tags.json?auth='+this.$store.state.auth.idToken,{ts:moment().format('X'), name:item.searching})
    },
    switchToZh(){
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].admin==this.selectedAdmin && this.users[i].sendChinese==undefined){
          this.users[i]['.key']
          axios.patch('users/'+this.users[i]['.key']+'.json?auth='+this.$store.state.auth.idToken,{sendChinese:true})
          .then(res=>{
            console.log('check ', res);
          })
          .catch(err=>{
            console.log(err, ' error patching');
          })

        }
      }
    },
    editUsername(item){
      item.editUsername=true
      item.tUsername=item.userName
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    editInfo(item){
      item.editInfo=true
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    saveLastContact(item){
      item.editLC=false
      this.saveToDb(item, {lastContact:item.lastContact})
      axios.patch('users/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{lastContact:item.lastContact})
      .then(res=>{
        this.pagination.descending=!this.pagination.descending
        this.pagination.descending=!this.pagination.descending
      })
      .catch(err=>{
        console.log('error saving Admin info:',err);
        alert('Something happened, Tell Vincent - DO NO REFRESH')
      })

    },
    saveToDb(user,item, button=''){
      if (user.type=="lead"){
        axios.patch('leads/'+user['.key']+'.json?auth='+this.$store.state.auth.idToken,item)
        .then(res=>{
          user[button]=false
          this.snackbarMessage="Lead Updated"
          this.showSnackbar=true
          this.pagination.descending=!this.pagination.descending
          this.pagination.descending=!this.pagination.descending

        })
        .catch(err=>{
          alert('Something happened, try again or show Vincent without refreshing')
          console.log(err, "Something happened");
        })
      } else {
        axios.patch('users/'+user['.key']+'.json?auth='+this.$store.state.auth.idToken,item)
        .then(res=>{
          user[button]=false
          this.snackbarMessage="Landlord Updated"
          this.showSnackbar=true
          this.pagination.descending=!this.pagination.descending
          this.pagination.descending=!this.pagination.descending
        })
        .catch(err=>{
          alert('Something happened, try again or show Vincent without refreshing')
          console.log(err, "Something happened");
        })

      }
    },
    saveLead(){
      this.disabledLead=true
      this.$v.lead.$touch()
      if (this.$v.$anyError){
        this.disabledLead=false
        return
      } else {
        this.lead.lastContact=moment().format("YYYY-MM-DD")
        this.lead.admin=this.user.id
        this.lead.nextContact=moment().add(2,'Days').format("YYYY-MM-DD")
        axios.post('leads.json?auth='+this.$store.state.auth.idToken,this.lead)
        .then(res=>{
          this.snackbarMessage="Lead added"
          this.showSnackbar=true
          this.disabledLead=false
          this.leadDialog=false
          this.lead.type="lead"
          this.tUsers.append(this.lead)
          this.pagination.descending=!this.pagination.descending
          this.pagination.descending=!this.pagination.descending
          this.lead={}

        })
        .catch(err=>{
          this.disabledLead=false
          this.leadDialog=false
        })
      }
    },
    saveNextContact(item){
      item.editNC=false
      this.saveToDb()
    },
    saveAdminText(item){
      item.disable=true
      item.editInfo=false
      this.saveToDb(item, {adminText:item.adminText}, 'disable')
    },
    saveUsername(item){
      item.disable=true
      item.editUsername=false
      item.userName=item.tUsername
      this.saveToDb(item, {userName:item.tUsername}, 'disable')
    },
    selectAdmin(){
      let tempUsers= JSON.parse(JSON.stringify(this.users)).concat(JSON.parse(JSON.stringify(this.leads)))
      if (this.selectedAdmin!=undefined && this.selectedAdmin!=""){
        this.tUsers=tempUsers.filter(item=>this.checkAdmin(item,this.selectedAdmin))
      } else {
        this.tUsers=tempUsers
        console.log('Reset users');
      }
    },
    checkAdmin(item, selectedAdmin){
      if (item.admin==selectedAdmin){
        return true
      } else {
        return false
      }
    },

    setAdmin(item){
      console.log(item.admin,' the new admin');
      this.saveToDb(item, {admin:item.admin})

    },
    setLLPrice(item, val){
      this.saveToDb(item, {margin:val})
    },
    setTrust(ll, payload, aptIds, studios, wholeApts){
      let id=ll['.key']
      ll.verifyMe=false
      console.log('trying to set trust', payload)
      this.saveToDb(ll, {trustVal:payload, verifyMe:false})
      let sId=""
      if (studios!=undefined && Object.keys(studios).length>0){
        for (sId in studios) {
          if (studios.hasOwnProperty(sId)) {
            console.log('studios:'+studios[sId].studioId)
            axios.patch('studios/'+studios[sId].studioId+'.json?auth='+this.$store.state.auth.idToken,{trustVal:payload})
            .then(()=>{
              console.log('done studio');
            })
            .catch(err=>{
              console.log('error: ',err);
            })
          }
        }
      }
      if (wholeApts!=undefined && Object.keys(wholeApts).length>0){
        for (sId in wholeApts) {
          if (wholeApts.hasOwnProperty(sId )) {
            console.log('wholeApts:'+wholeApts[sId].apartmentId);
            axios.patch('wholeApts/'+wholeApts[sId].apartmentId+'.json?auth='+this.$store.state.auth.idToken,{trustVal:payload})
            .then(()=>{
              console.log('done wholeApts');
            })
            .catch(err=>{
              console.log('error: ',err);
            })
          }
        }
      }
      if(aptIds!=undefined && Object.keys(aptIds).length>0){
        console.log('still trying haha')
        console.log(aptIds)
        for (sId in aptIds) {
          if (aptIds.hasOwnProperty(sId)) {
            axios.patch('apartments/'+sId+'.json?auth='+this.$store.state.auth.idToken,{trustVal:payload})
            for (var roomId in this.apts[aptIds[sId].apartmentId].rooms) {
              if (this.apts[aptIds[sId].apartmentId].rooms.hasOwnProperty(roomId)) {
                console.log('room: ',roomId);
                axios.patch('rooms/'+roomId+'.json?auth='+this.$store.state.auth.idToken,{trustVal:payload})
                .then(()=>{ console.log('done room');})
                .catch(err=>{
                  console.log('error: ',err);
                })
              }
            }
          }
        }
      } else {
        console.log('not doing the apts');
      }
    },
    writeRooms(){
      console.log("write the rooms")
      let obj={rooms:this.rooms}
      this.json=JSON.stringify(obj)
      console.log('json sting:', this.json)
    },
    checkLandlord(item, ll){
      if (item.general!=undefined && item.general.landlordId==ll['.key']){
        return true
      } else {
        return false
      }
    },
    setSummer(ll,payload){
      let tStudios=this.studios.filter(item=>this.checkLandlord(item,ll))
      let tApts=this.wholeApts.filter(item=>this.checkLandlord(item,ll))
      // console.log('users/'+ll['.key'], {acceptSummer:payload,summerTS:moment().format("l")});
      axios.patch('users/'+ll['.key']+'.json?auth='+this.$store.state.auth.idToken,{acceptSummer:payload,summerTS:moment().format('l')})
      for (let sId in ll.apartments) {
        if (ll.apartments.hasOwnProperty(sId)) {
          // console.log('apartments/'+sId + payload);
          axios.patch('apartments/'+sId+'.json?auth='+this.$store.state.auth.idToken,{acceptSummer:payload})
          if (this.apts[ll.apartments[sId].apartmentId]!=undefined){

            for (var roomId in this.apts[ll.apartments[sId].apartmentId].rooms) {
              if (this.apts[ll.apartments[sId].apartmentId].rooms.hasOwnProperty(roomId)) {
                // console.log('room: ',roomId);
                axios.patch('rooms/'+roomId+'.json?auth='+this.$store.state.auth.idToken,{acceptSummer:payload})
                .then(()=>{ console.log('done room');})
                .catch(err=>{
                  console.log('error: ',err);
                })
              }
            }
          }
        }
      }
      for (var i = 0; i < tApts.length; i++) {
        // console.log( ' axios.patch(wholeApts/+', tApts[i]['.key']);
        axios.patch('wholeApts/'+tApts[i]['.key']+'.json?auth='+this.$store.state.auth.idToken,{acceptSummer:payload})
      }
      for (var j = 0; j < tStudios.length; j++) {
        // console.log( ' axios.patch(studios/+', tStudios[j]['.key']);
        axios.patch('studios/'+tStudios[j]['.key']+'.json?auth='+this.$store.state.auth.idToken,{acceptSummer:payload})
      }


    }
  },
  created() {
  },
  filters:{
    dateLL(t){
      return moment(t).format("LL")
    }
  },
  metaInfo() {
      return {
        title:'Landlords - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.doToday{
  background-color: rgb(255, 64, 0);
color: white!important;
}
.table-menu{
  width:130px;
}
.md-field {
    max-width: 300px;
}
</style>
