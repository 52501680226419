<template>
<div>
  <v-card >
   <v-card-title>
     Orders
     <!-- <v-btn color="primary" @click="create">Create user Bookings pay in</v-btn> -->
     <v-btn color="primary" @click="addBR=true">Manually insert a new booking</v-btn>
     <!-- <v-btn color="primary" @click="payments">Add a online payment from Kiki</v-btn> -->
     <!-- <v-btn color="primary" @click="makeFapiou">Make Fapious</v-btn> -->
     <!-- <v-btn color="primary" @click="sendSorry">Sorry</v-btn> -->
     <!-- <v-btn color="primary" @click="sendAllRentEmails">send All Rents</v-btn> -->
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>

   </v-card-title>
   <v-layout wrap>
     <v-flex xs12 sm4 md3>
     <v-select
     :items="types"
     v-model="type"
     label="which type to count?"
     ></v-select>
    </v-flex>
     <v-btn color="primary" @click="counting()">count</v-btn>
     {{myCount}}
     <v-btn color="primary" @click="calcRev">Rev</v-btn>
     comm: {{revenue.comm}}, fee: {{revenue.fee}} , total: {{revenue.comm+revenue.fee}}

     <!-- order length: {{Object.keys(orders).length}} -->
     <v-flex xs12 v-if="initialOrders" class=" text-xs-right">

       <small >(only 250 most recent are loaded) <a @click="loadAll()">(load all)</a></small>
     </v-flex>

   </v-layout>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="current"
     item-key="key"
     :loading="loading"
     loading-text="Please be patient, i'm working!"
     :pagination.sync="pagination"
     :search="search"
   >
     <template slot="items" slot-scope="props">
       <tr @click="props.expanded = !props.expanded">

         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}"> {{ props.item.confirmTimestamp | dateLL}}</td>
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}"><a :href="'/profile?id='+props.item.tenantId" v-if="users[props.item.tenantId]!=undefined">{{users[props.item.tenantId].name}}</a></td>
         <td> {{ props.item.tenant.email }}
           <span v-if="props.item.tenant.communication"><br>communication: <span class="bold">{{props.item.tenant.communication}}</span></span>
           <span v-if="props.item.tenant.appId"><br> App: {{props.item.tenant.appId}}</span>
           <a v-if="props.item.tenant.communication=='WhatsApp' && props.item.tenant.appId!=undefined" :href="'https://api.whatsapp.com/send?phone='+props.item.tenant.appId" target="blank"> <br>open whatsapp</a>
           <a v-else-if="props.item.tenant.communication=='WhatsApp' && props.item.tenant.phone!=undefined" :href="'https://api.whatsapp.com/send?phone='+props.item.tenant.phone" target="blank"> <br>open whatsapp</a>           <span v-if="props.item.phone"><br>Phone: {{props.item.phone}}</span>
         </td>
         <!-- <td><v-btn color="primary" @click="checkCoupon(props.item)">Check Coupon ({{props.item.coupon}}- {{props.item.discount}})</v-btn></td> -->
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}"><a :href="props.item.roomLink">{{ props.item.fullRoomNumber }}</a>
           <span v-if="props.item.oldRoomName!=undefined && props.item.changeDate!=undefined"> <br>Changed From {{props.item.oldRoomName}} ({{props.item.changeDate | dateFormat }})</span>
         </td>
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}">
           <span :class="{'font-weight-bold':checkCurrency(props.item.currency)}">TWD {{props.item.total}}</span> = {{ props.item.price }} + {{props.item.commission}}
           <span v-if="props.item.utilities>0">+ {{props.item.utilities}}</span>
           <span v-if="props.item.discount>0">- {{props.item.discount}}</span>
           <span v-if="props.item.currency!='TWD'" class="font-weight-bold"><br> {{props.item.currency}} {{props.item.fxTotal}}</span> </td>
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}"> <span v-if="props.item.payIn">
                                        <v-text-field v-model="props.item.cancelPayment" label="TYPE CANCEL TO REMOVE" v-if="props.item.cancelPayment!='CANCEL'"></v-text-field><v-btn color="error" v-else @click="cancelPayment(props.item)" flat>Cancel</v-btn>
                                    </span><span v-else> <b v-if="props.item.status=='Failed Payment'">{{props.item.status}}<br> </b>  <v-btn color="primary" @click="pay(props.item)">Set Paid</v-btn> <br> <v-btn @click="sendFailedPaymentEmail(props.item)" color="red" flat >Email</v-btn> </span></td>
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}"><v-btn color="primary" @click="moneyIn(props.item)" :disabled="props.item.moneyInDisabled" v-if="props.item.moneyIn!=true">Money in</v-btn><span v-else>Money in the bank</span></td>
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}"> <a :href="'/profile?id='+props.item.landlordId">{{props.item.llName}}</a></td>
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}" >
           <div class="" v-if="props.item.changeDates==true">
             <v-menu
               ref="dateMenu"
               :close-on-content-click="false"
               v-model="dateMenu"
               :nudge-right="40"
               lazy
               transition="scale-transition"
               offset-y
               v-if="props.item.hasOwnProperty('dates')"
               full-width
               min-width="290px"
                     >
                 <v-text-field
                   slot="activator"
                   label="Move In Date"
                   v-model="props.item.dates.mid"
                   prepend-icon="event"
                   readonly
                 ></v-text-field>
                   <v-date-picker :locale="$vuetify.lang.current" v-model="props.item.dates.mid" no-title  @input="$refs.dateMenu.save(props.item.dates.mid)">
                   </v-date-picker>
               </v-menu>
               <v-menu
                 ref="dateMenu2"
                 :close-on-content-click="false"
                 v-model="dateMenu2"
                 :nudge-right="40"
                 lazy
                 transition="scale-transition"
                 offset-y
                 full-width
                 min-width="290px"
                       >
                   <v-text-field
                     slot="activator"
                     label="Move Out Date"
                     v-model="props.item.dates.mod"
                     prepend-icon="event"
                     readonly
                   ></v-text-field>
                     <v-date-picker :locale="$vuetify.lang.current" v-model="props.item.dates.mod" no-title  @input="$refs.dateMenu2.save(props.item.dates.mod)">
                     </v-date-picker>
                 </v-menu>
                 <v-btn color="primary" flat @click="saveDates(props.item)">save</v-btn>
           </div>
           <div class="" v-else >
             <div class="" v-if="props.item.hasOwnProperty('dates')">

               {{ props.item.dates.mid | dateFormat}} => {{props.item.dates.mod | dateFormat}} <br>
             </div>
             <div class="" v-else>{}
               THIS BOOKING HAS NO DATES!!!!
             </div>
             <v-btn color="primary" @click="props.item.changeDates=true">Change Dates</v-btn>
           </div>
           <v-btn color="error" flat :disabled="props.item.disableMakeRent" @click="makeRent(props.item)">Make Rent</v-btn>
         </td>
         <td class="" :class="{'pay-fail' : props.item.status=='Failed Payment'}">
           <div class="" v-if="props.item.hasOwnProperty('realDates')">
             {{ props.item.realDates.mid | dateLL}} <span v-if="users[props.item.tenantId]">{{users[props.item.tenantId].school}}</span>

           </div>
           <div class="" v-else>
             THIS BOOKING HAS NO REALDATES!!!
           </div>
         </td>
         <td class=""> <v-text-field
           placeholder="3"
           v-model="props.item.perc"
           append-icon="%"
           ></v-text-field> <v-btn color="primary" :disabled="props.item.disablePerc==true" @click="savePerc(props.item)">save</v-btn></td>
         <td>
           <span v-if="props.item.fDueDate!=undefined">due date: {{props.item.fDueDate}}</span>
           <v-text-field
              v-if="props.item.setDueDate==true"
              label="Due Date"
              v-model="props.item.fDueDate"
              type="number"
           ></v-text-field>
           <v-btn v-if="props.item.setDueDate==true" color="primary" @click="saveDueDate(props.item)">Save Due Date</v-btn>
           <v-btn color="primary" flat v-else @click="props.item.setDueDate=true">Set a Due Date</v-btn>
           <span v-if="props.item.askDay!=undefined">Ask day: {{props.item.askDay}}</span>
           <v-text-field
              v-if="props.item.setAskDay==true"
              label="Ask Day"
              v-model="props.item.askDay"
              type="number"
             ></v-text-field>
             <v-btn v-if="props.item.setAskDay==true" color="primary" @click="saveAskDay(props.item)">Save Ask Day</v-btn>
             <v-btn color="primary" flat v-else @click="props.item.setAskDay=true">Set a Ask Day</v-btn>
         </td>
         <td class=""> <v-btn color="primary" :href="'/payment/'+props.item['.key']" target="_blank">Invoice</v-btn></td>
         <td class=""><v-text-field v-model="props.item.cancel" label="TYPE CANCEL TO REMOVE" v-if="props.item.cancel!='CANCEL'"></v-text-field>
           <div v-else>
             <v-textarea
              v-model="props.item.cancelReason"
              auto-grow
              :error-messages="props.item.reasonError"
              label="Why do they cancel?"
              rows="1"
             ></v-textarea>
             <v-btn color="error" @click="cancelBook(props.item)">CANCEL Booking</v-btn>
           </div>
         </td>
         <td>
           <v-select :items="currencyOptions" @change="setCurrency(props.item)" v-model="props.item.currency" ></v-select>
         </td>
         <td><span v-if="users.hasOwnProperty(props.item.tenantId)">{{users[props.item.tenantId].school}}</span></td>
         <td class="">
           <v-text-field v-model="props.item.llPrice" prepend-icon="attach_money"></v-text-field>
          <v-btn color="primary" :disabled="props.item.disableLLPrice==true" @click="saveLLPrice(props.item)">save</v-btn>
         </td>
         <td>Recurring Rent: {{props.item.reccRent}}
         </td>
         <td><v-btn color="info" :to="'/mybooking?id='+props.item.tenantId">See My Booking Page</v-btn></td>
         <td><v-btn color="info" :disabled="props.item.moneyIn!=true" :to="'/admin/changeroom/'+props.item['.key']">Change Booking</v-btn></td>
         <td><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/completedOrders/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>
       </tr>
     </template>
     <template slot="expand" slot-scope="props">
       <v-card flat v-if="props.item.status=='Failed Payment'">
         <v-card-text>
            <v-layout row wrap>
              <v-flex xs6>
                Bank Message: {{props.item.data.bank_result_msg}}
                Bank code: {{props.item.data.bank_result_code}}
                Trade ID: {{props.item.data.rec_trade_id}}
              </v-flex>
              <v-flex xs6>
                Message: {{props.item.data.msg}}
                status:{{props.item.data.status}}
              </v-flex>
            </v-layout>
         </v-card-text>
       </v-card>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
  <v-dialog
    v-model="addBR"
    max-width="500px"
  >
  <AddRequest @close="addBR=false"></AddRequest>
  </v-dialog>
  <v-snackbar
    v-model="snackbar"
    :color="snackbarColor"
    :timeout="6000"
  >
    {{snackbarText}}
    <v-btn flat color="primary" @click.native="snackbar = false">Close</v-btn>
  </v-snackbar>

</div>
</template>

<script>
import Countdown from 'vuejs-countdown'
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import AWSSES from '../../aws-ses'
import AddRequest from './AddRequest.vue'


export default {
  name: 'Orders',
  data: () => ({
    start:"2019-06-01",
    end:"2019-09-30",
    addBR:false,
    myCount:0,
    current:[],
    orders:[],
    initialOrders:true,
    orderLoading:true,
    usreLoading:true,
    roomsLoading:true,
    loading:true,
    dateMenu:false,
    dateMenu2:false,
    type:"studio",
    types:[
      'room',
      'studio',
      'apartment'
    ],
    revenue:{
      comm:0,
      fee:0,
    },
    pagination:{
      sortBy: 'timeVal',
      descending:true
    },
    headers: [
      { text: 'Date confirmed', value: 'timeVal'},
      { text: 'Tenant name', value:'tName'},
      { text: 'Tenant info', value:'tName'},
      // {text:' Coupon', value:"coupon"},
      { text: 'Room name', value: 'fullRoomNumber' },
      { text: 'Room price', value: 'price' },
      { text: 'Payment', value:'payIn'},
      { text: 'Payment Received', value:'moneyIn'},
      { text: 'Landlord name', value:'llName'},
      { text: 'Contract Dates', value: 'dates.mid'},
      { text: 'Arrival', value:'arrival'},
      { text: 'Percentage', value:'perc'},
      { text: 'Due Date & ask day', value:'fDueDate'},
      { text: 'Link to invoice', sortable:false},
      { text: 'type CANCEL', sortable:false},
      { text: 'Select Currency', sortable:false},
      { text: 'School', value:'school'},
      { text: 'Landlord Price', value:'llPrice'},
      { text: 'Recurring rent', value:'reccRent'},
      { text: 'My Booking Page', value:'.key'},
      { text: 'Change Room', value:'.key'},
      { text: 'DB', value:'.key'},

    ],
    serviceFee:.2,

    transactionCost:.028,
    search: null,
    searched: [],
    validOrders:[],
    canceled:[],
    currencyOptions: [{
        text: "New Taiwan Dollar (TWD)",
        value: "TWD"
      },
      {
        text: "Euro (EUR)",
        value: "EUR"
      },
      {
        text: "US Dollar (from any USD denominated bank accounts) (USD)",
        value: "USD"
      },
      {
        text: "GB Pound (from any bank account in the UK) (GBP)",
        value: 'GBP'
      },
      {
        text: "Australian Dollar (from any bank account in Australia) (AUD)",
        value: "AUD"
      },
      {
        text: "Chinese Yuan (CNY)",
        value: "CNY",
      },
      {
        text: "Honk Kong Dollar (HKD)",
        value: "HKD"
      },
      {
        text: "Japanese Yen (JPY)",
        value: "JPY",
      },
      {
        text: "Canadian Dollar (CAD)",
        value: "CAD",
      },
      {
        text: "South African Rand (ZAR)",
        value: "ZAR",
      },
      {
        text: "New Zealand Dollar (NZD)",
        value: "NZD"
      },
      {
        text: "Swiss Franc (CHF)",
        value: "CHF"
      },
      {
        text: "Swedish Kron (SEK)",
        value: "SEK"
      },
      {
        text: "Singapore Dollar (SGD)",
        value: "SGD",
      },
      {
        text: "Mexican Peso (MXN)",
        value: "MXN",
      },
      {
        text: "Thai Baht (THB)",
        value: "THB"
      }
    ],
    snackbar:false,
    snackbarColor:"success",
    snackbarText:"Update Success"

  }),
  firebase() {
    return {
      initialOrders: {
        source:firebase.database().ref('completedOrders').orderByChild('confirmTimestamp').limitToLast(250),
        asObject:true,
        readyCallback:function(){
          // delete this.orders['.key']
          this.orderLoading=false
          this.orders=this.initialOrders
          // console.log(this.orders,'readyCallback')
          this.processOrders()
        }
      },
      // coupons:{
      //   source:firebase.database().ref('coupons'),
      //   asObject:true,
      // },
      users:{
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){
          this.usersLoading=false
          this.matchOrdersUsers()
        }
      },
      rooms:{
        source:firebase.database().ref('rooms'),
        asObject:true,
        readyCallback:function(){
          this.roomsLoading=false
          this.matchOrdersListings()
        }
      },
      studios:{
        source:firebase.database().ref('studios'),
        asObject:true,
        readyCallback:function(){
          this.matchOrdersListings()
        }
      },
    }

  },
  methods: {
    testNewFunction(item){
      item.dates.mid="2019-01-01"
      item.dates.mod="2020-01-01"
      axios.get('completedOrders/'+item['.key']+'.json')
      .then(res=>{
        let oldCR=res.data
        try {
          let before="Room "+item.fullRoomNumber+ " ("+item['.key']+") was from "+moment(oldCR.dates.mid).format("LL")+ " till "+moment(oldCR.dates.mod).format('LL')
          let after="Room "+item.fullRoomNumber+ " ("+item['.key']+") is from "+moment(item.dates.mid).format("LL")+ " till "+moment(item.dates.mod).format('LL')
          let ftn="Change Dates"
          this.adminNotif( before, after, ftn, item)
        } catch (e) {
          console.log(e);
          alert('something happend with notifications, alert Vincent')
        }
      })

    },
    loadAll(){
      this.loading=true
      this.initialOrders=false
      this.current=[]
      axios.get('completedOrders.json')
      .then(res=>{
        this.orders=res.data
        this.processOrders()
      })
    },
    processOrders(){
      for (var id in this.orders) {
        if (this.orders.hasOwnProperty(id) && this.orders[id] instanceof Object) {
          // console.log(this.orders[id], id );
          if (this.orders[id].discount==undefined){
            this.orders[id].discount=0
          }
          if (this.orders[id].fx==undefined){
            this.orders[id].fx=1
          }
          if (this.orders[id].realDates!=undefined && this.orders[id].realDates.mid!=undefined ){
            this.orders[id].arrival=this.orders[id].realDates.mid
          } else if (this.orders[id].dates!=undefined) {
            this.orders[id].arrival=this.orders[id].dates.mid
          } else {
            console.log(this.orders[id], id , 'was bugging');
          } {
          }
          if (this.orders[id].currency==undefined){
            this.orders[id].currency="TWD"
          }
          if (this.orders[id].status=='Canceled'){
            this.canceled.push(this.orders[id])
            delete this.orders[id]
          } else {
            this.orders[id]['.key']=id
            this.orders[id].timeVal=new Date(moment(this.orders[id].confirmTimestamp)).getTime()
            let roomPrice = new Number(this.orders[id].price)
            // console.log(this.fxPrice)
            let tc=this.transactionCost
            let utilities=0
            if (this.orders[id].utilities && this.orders[id].utilities>0){
              utilities=this.orders[id].utilities
            }
            let sf=this.serviceFee
            this.orders[id].utilities=utilities
            this.orders[id].commission = Math.ceil((((roomPrice+utilities) * sf) + roomPrice + utilities-this.orders[id].discount) * tc + ((roomPrice+utilities) * sf))

            this.orders[id].total = roomPrice + this.orders[id].commission - this.orders[id].discount + utilities
            // console.log(this.total)
            this.orders[id].fxTotal = Math.ceil(this.orders[id].total * this.orders[id].fx)

          }
          // this.orders[id].id=this.orders[id]['.key']
          // console.log('order:',this.orders[id])
          // this.orders[id].confirmTimestamp=moment(this.orders[id].confirmTimestamp).format('ll')
          // this.orders[id].dateRequested=new Date(moment(this.orders[id].dateRequested).format('lll')).getTime()
          // this.orders[id].end=moment(this.orders[id].dateRequested).add(2,'d').format('lll')
          this.loading=false
        }
      }
      this.matchOrdersUsers()
      this.matchOrdersListings()
    },
    findAndSaveRents(crId,payload){
      axios.get('rents.json?orderBy="crId"&equalTo="'+crId+'"')
      .then(res=>{
        console.log('rent',res.data);

        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            axios.patch('rents/'+Object.keys(res.data)[i]+'.json?auth='+this.$store.state.auth.idToken,payload)
            // console.log('rents/'+Object.keys(res.data)[i]+'.json?auth=',payload);
          }
        }
      })
      axios.get('transfTBM.json?orderBy="crId"&equalTo="'+crId+'"')
      .then(res=>{
        console.log('TRansf',res.data);
        if (Object.keys(res.data).length>0){
          // console.log();
          for (var j = 0; j < Object.keys(res.data).length; j++) {
            // console.log('transfTBM/'+Object.keys(res.data)[j]+'.json?auth=',payload);
            axios.patch('transfTBM/'+Object.keys(res.data)[j]+'.json?auth='+this.$store.state.auth.idToken,payload)
          }
        }
      })
    },
    saveDueDate(item){
      let payload={
        fDueDate:item.fDueDate,
      }
      this.findAndSaveRents(item['.key'],payload)
      axios.patch('completedOrders/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,payload)
      // console.log('completedOrders/'+item['.key']+'.json?auth=', payload);
      item.setDueDate=false
    },
    saveAskDay(item){
      let payload={
        askDay:item.askDay,
        noDateChange:true
      }
      this.findAndSaveRents(item['.key'],payload)
      // console.log('completedOrders/'+item['.key']+'.json?auth=', payload);
      axios.patch('completedOrders/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,payload)
      item.setAskDay=false
    },
    sendFailedPaymentEmail(item){
      console.log('send, ',item.tenant);
      AWSSES.emailing(item.tenant, 'TPaymentFailed')
    },
    counting(){
      this.myCount=0
      for (var id in this.orders) {
        if (this.orders.hasOwnProperty(id)) {
          // console.log(id, this.orders);
          // console.log(this.orders[id].type, this.type);
          if (this.orders[id].type==this.type){
            console.log('add');
            this.myCount=this.myCount+1
          }
        }
      }
    },
    adminNotif(before,after,ftn,order){

      let emailToSend={
        admin:this.$store.state.auth.user.userName,
        function:ftn,
        date: moment().format("LLLL"),
        crId:order['.key'],
        before:before,
        after:after,
        room:order.fullRoomNumber,
        tenant:order.tenant.name,
      }
      console.log(emailToSend);
      let admin={
        name:'Sandrine',
        email:"sandrine@myroomabroad.com"
      }
      AWSSES.adminNotif(admin,emailToSend, 'ACREdited')
    },
    saveDates(item){
      // console.log(item.dates);
      item.changeDates=false
      axios.get('completedOrders/'+item['.key']+'.json')
      .then(res=>{
        let oldCR=res.data
        try {
          let before="MOVE IN: "+moment(oldCR.dates.mid).format("YYYY-MM-DD")+" ---  MOVE OUT: "+moment(oldCR.dates.mod).format('LL')
          let after="MOVE IN: "+moment(item.dates.mid).format("YYYY-MM-DD")+"  ---  MOVE OUT: "+moment(item.dates.mod).format('LL')
          let ftn="Change Dates"
          this.adminNotif( before, after, ftn, item)
        } catch (e) {
          console.log(e);
          alert('something happend with notifications, alert Vincent')
        }

        axios.patch('completedOrders/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{dates:item.dates})
        this.changeDate(item, item.dates.mod)
      })
      axios.get('rents.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('rent',res.data);

        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            axios.patch('rents/'+Object.keys(res.data)[i]+'.json?auth='+this.$store.state.auth.idToken,{dates:item.dates})
            // console.log('rents/'+Object.keys(res.data)[i]+'.json?auth=',{dates:item.dates});
          }
        }
      })
      axios.get('transfTBM.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('TRansf',res.data);
        if (Object.keys(res.data).length>0){
          // console.log();
          for (var j = 0; j < Object.keys(res.data).length; j++) {
            axios.patch('transfTBM/'+Object.keys(res.data)[j]+'.json?auth='+this.$store.state.auth.idToken,{dates:item.dates})
            // console.log('transfTBM/'+Object.keys(res.data)[j]+'.json?auth=',{dates:item.dates});
          }
        }
      })
    },
    saveLLPrice(item){
      item.disableLLPrice=true
      axios.patch('completedOrders/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{llPrice:item.llPrice})
      // console.log(item.perc, 'rents.json?orderBy="crId"&equalTo="'+item['.key']+'"');
      axios.get('rents.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('rent',res.data);

        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            axios.patch('rents/'+Object.keys(res.data)[i]+'.json?auth='+this.$store.state.auth.idToken,{llPrice:item.llPrice})
            console.log('rents/'+Object.keys(res.data)[i]+'.json?auth=',{llPrice:item.llPrice});
          }
        }
      })
      axios.get('transfTBM.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('TRansf',res.data);
        if (Object.keys(res.data).length>0){
          // console.log();
          for (var j = 0; j < Object.keys(res.data).length; j++) {
            // console.log('transfTBM/'+Object.keys(res.data)[j]+'.json?auth=',{perc:item.perc});
            axios.patch('transfTBM/'+Object.keys(res.data)[j]+'.json?auth='+this.$store.state.auth.idToken,{llPrice:item.llPrice})
          }
        }
      })

    },
    savePerc(item){
      item.disablePerc=true
      axios.patch('completedOrders/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{perc:item.perc})
      console.log(item.perc, 'rents.json?orderBy="crId"&equalTo="'+item['.key']+'"');
      axios.get('rents.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('rent',res.data);

        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            axios.patch('rents/'+Object.keys(res.data)[i]+'.json?auth='+this.$store.state.auth.idToken,{perc:item.perc})
            console.log('rents/'+Object.keys(res.data)[i]+'.json?auth=',{perc:item.perc});
          }
        }
      })
      axios.get('transfTBM.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('TRansf',res.data);
        if (Object.keys(res.data).length>0){
          // console.log();
          for (var j = 0; j < Object.keys(res.data).length; j++) {
            // console.log('transfTBM/'+Object.keys(res.data)[j]+'.json?auth=',{perc:item.perc});
            axios.patch('transfTBM/'+Object.keys(res.data)[j]+'.json?auth='+this.$store.state.auth.idToken,{perc:item.perc})
          }
        }
      })

    },
    switchCurrent(){
      if (this.current==this.validOrders){
        this.current=this.canceled
      } else {
        this.current == this.validOrders
      }
    },
    setCurrency(order){
      axios.get('https://free.currencyconverterapi.com/api/v6/convert?q=TWD_' + order.currency + '&compact=ultra&apiKey=2d6cf65af2f8557d2378')
      .then(res => {
        let fromToCurrency = "TWD_" + order.currency
        // console.log(res.data[fromToCurrency])
        if (order.currency!="TWD"&&order.currency!="NTD"){
          order.fx = res.data[fromToCurrency]*1.04
        } else {
          order.fx = res.data[fromToCurrency]
        }
        axios.patch('completedOrders/'+order['.key']+'.json?auth='+this.$store.state.auth.idToken,{currency:order.currency, fx:order.fx})
      })
      .catch(err=>{
        console.log(err);
        order.fx=1
        order.currency="TWD"
      })

    },
    checkCurrency(curr){
      if (curr=="TWD"){
        return true
      } else {
        return false
      }
    },
    create(){

      console.log('create');
      for (var i = 0; i < this.validOrders.length; i++) {
        console.log(this.validOrders[i].payIn);
        if (this.validOrders[i].payIn==true){
          console.log('setfor user,',this.validOrders[i].tenantId, this.validOrders[i].tenant.name);
          axios.patch('users/'+this.validOrders[i].tenantId+'.json?auth='+this.$store.state.auth.idToken,{orderPaid:true})
        }
      }
    },
    makeRent(order){
      order.disableMakeRent=true
      let t={
        crId:order['.key'],
        type:order.type,
        tenantId:order.tenantId,
        realDates:order.realDates,
        llPrice:order.llPrice,
        price:order.price,
        perc:order.perc,
        listingId:order.listingId,
        landlordId:order.landlordId,
        brId:order.id,
        reccRent: order.reccRent,
        dates:order.dates,
        roomNumber:order.fullRoomNumber,
        listUtil:order.listUtil,
        utilities:order.utilities,
        lastRent:moment().date(moment(order.dates.mod).date()).subtract(1, 'Month').format('YYYYMMDD'),
      }
      console.log(t);
      axios.post('rents.json?auth='+this.$store.state.auth.idToken,t)
      .then(()=>{
        this.snackbar=true
        this.snackbarColor="success"
        this.snackbarText="Added the rent. Please check the comment if it's on the right date"
      })
      .catch(e=>{
        console.error(e);
        this.snackbar=true
        this.snackbarColor="error"
        this.snackbarText="FAILED to add the rent, try again, or ask Vincent"
      })

    },
    changeDate(order, date){
      let db=""
      switch (order.type) {
        case 'room':
          db='rooms/'
          break;
        case 'studio':
          db='studios/'
          break
        case 'apartment':
          db='wholeApts/'
          break
        default:
          db=''
      }
      if (db!=""){
        // console.log('patch : '+ db+order.listingId+ "with old date: "+order.oldNextAv);
        axios.patch(db+order.listingId+'/general.json?auth='+this.$store.state.auth.idToken,{nextAvailability:date})
      } else {
        console.log('db does not exist');
      }
    },
    cancelBook(order){
      // console.log('cancel booking', order)
      if (order.cancelReason==undefined || order.cancelReason==""){
        order.reasonError="WHY WAS THIS BOOKING CANCELED!!!"
        return
      }
      alert('you just canceled a booking :O')
      // console.log('patch completedOrders: '+order['.key']+"STATUS CANCELED");
      // console.log('patch bookingRequests/'+order['id']);
      try {

        let before="There was a booking for room "+order.fullRoomNumber+"("+order['.key']+")"
        let after="It has been canceled on " + moment().format('YYYY/MM/DD') +" --- for this reason : "+order.cancelReason
        let ftn="Cancel Booking"
        this.adminNotif( before, after, ftn, order)
      } catch (e) {
        console.log(e);
        alert('something happend with notifications, alert Vincent')
      }
      axios.get('rents.json?orderBy="crId"&equalTo="'+order['.key']+'"')
      .then(res=>{
        console.log('rent',res.data);

        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            axios.delete('rents/'+Object.keys(res.data)[i]+'.json?auth='+this.$store.state.auth.idToken)
            // console.log('rents/'+Object.keys(res.data)[i]+'.json?auth=',payload);
          }
        }
      })
      axios.get('transfTBM.json?orderBy="crId"&equalTo="'+order['.key ']+'"')
      .then(res=>{
        console.log('TRansf',res.data);
        if (Object.keys(res.data).length>0){
          // console.log();
          for (var j = 0; j < Object.keys(res.data).length; j++) {
            // console.log('transfTBM/'+Object.keys(res.data)[j]+'.json?auth=',payload);
            axios.delete('transfTBM/'+Object.keys(res.data)[j]+'.json?auth='+this.$store.state.auth.idToken)
          }
        }
      })

      axios.patch('completedOrders/'+ order['.key']+'.json?auth='+this.$store.state.auth.idToken,{status:"Canceled", cancelReason:order.cancelReason, cancelTS:moment().format("L")})
      axios.patch('users/'+order.tenantId+'.json?auth='+this.$store.state.auth.idToken,{orderPaid:false})
      axios.patch('bookingRequests/'+order['id']+'.json?auth='+this.$store.state.auth.idToken,{status:"Canceled"})
      // console.log('check type: '+order.type);
      let db=""
      switch (order.type) {
        case 'room':
          db='rooms/'
          break;
        case 'studio':
          db='studios/'
          break
        case 'apartment':
          db='wholeApts/'
          break
        default:
          db=''
      }
      if (db!=""){
        // console.log('patch : '+ db+order.listingId+ "with old date: "+order.oldNextAv);
        axios.patch(db+order.listingId+'/general.json?auth='+this.$store.state.auth.idToken,{nextAvailability:order.oldNextAv})
      } else {
        // console.log('db does not exist');
      }
      for (var i = 0; i < this.validOrders.length; i++) {
        if (this.validOrders[i]['.key']==order['.key']){
          this.validOrders.splice(i,1)
        }
      }

    },
    sendSorry(){
      let t=[]
      // for (var i = 0; i < this.current.length; i++) {
      //   if (moment(this.current[i].confirmTimestamp).isAfter('2019 December 31')){
      //     t.push(this.current[i])
      //   }
      // }
      // for (var j = 0; j < t.length; j++) {

        // console.log(t[j].tenant.email);
        let admin={

          email:'vincent@myroomabroad.com',
          name:"User"
        }
        let text="Dear, <br><br>You may have received an email on our behalf on June 24th 2020 stating you have received an invoice for our service fee.<br>This email has been sent by error and rest assured you are <b>not charged</b> any service fee, nor is any fee due.<br>On behalf of everyone at My Room Abroad, I want to apologize for any inconvenience and doubt caused by this mistake.<br><br>We wish you a wonderful weekend, <br>Sincerely, <br><br>Your My Room Abroad team"
        let info2={
          text:text,
          subject:"We are sorry for the Invoice Email on June 24"
        }
        AWSSES.sendPlainText(admin, info2)
      // }

    },
    makeFapiou(){
      let t=[]
      // console.log(t);
      for (var i = 0; i < this.current.length; i++) {
        if (moment(this.current[i].confirmTimestamp).isAfter('2019 December 31')){
          t.push(this.current[i])
        }
      }
      // console.log(t);
      for (var j = 0; j < t.length; j++) {
        // let fapiouInfo={
        //   relateNumber:'MRA'+Math.round(moment(t[j].confirmTimestamp).format('X')).toString()+t[j].tenant.userName.substring(0,3).toUpperCase(),
        //   amount: t[j].commission,
        // }
        // console.log(t[j],fapiouInfo, t[j].tenant)
        // t[j].tenant.id=t[j].tenantId
        // this.createFapiou(fapiouInfo,t[j].tenant)
        console.log(i);

      }

    },
    createFapiou(invoice,user){
      let data={
          relateNumber:invoice.relateNumber+Math.round(moment().format('X')).toString(),
          businessId:'',
          name:user.userName,
          email:user.email,
          userId:user.id,
          amount:invoice.amount,
          itemName:"My Room Abroad Service fee",
          address:"",
          type:"B2C"
      }
      if (user.hasOwnProperty('companyId') && user.companyId.length==8){
        data.businessId=user.companyId
        data.type="B2B"
      }
      console.info(data);
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/fapiou2', data, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then(res => {
        // console.log('Response data: ', res.data);
        console.log('SUCCESS: ',res.data);
        let fapiouNumber=this.getQueryVariable('InvoiceNumber', res.data)
        if (fapiouNumber!=undefined && fapiouNumber!=""){
          axios.post('fapiou.json?auth='+this.$store.state.auth.idToken,{number:fapiouNumber, ts:Math.round(moment().format('X')).toString(), user:user.id})
        } else {
          console.log('no fapiou created for ');
          this.failedFapiou(data,user)
        }

      })
      .catch(err=>{
        console.log('ERROR', err);
        this.failedFapiou(data,user)
      })
    },
    failedFapiou(data,user){
      axios.post('failedFapiou.json?auth='+this.$store.state.auth.idToken,data)


    },

    getQueryVariable(variable, query) {
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    },
    matchOrdersUsers(){
      // delete this.orders['.key']
      this.validOrders=[]
      // console.log('match the users');
      // console.log('users:', this.users, 'orders:', this.orders);
      if (this.usersLoading==false && this.orderLoading==false){
        for (var id in this.orders) {
          if (this.orders.hasOwnProperty(id) && this.orders[id] instanceof Object ) {
            // console.log(this.orders[id])

            this.orders[id].tenant=this.users[this.orders[id].tenantId]
            if (this.orders[id].tenant && this.users[this.orders[id].tenantId]){
              this.orders[id].tenant.id=this.orders[id].tenantId
              this.orders[id].school=this.users[this.orders[id].tenantId].school
            } else {
              console.log(this.orders[id]);
            }
            this.orders[id].landlord=this.users[this.orders[id].landlordId]
            if (this.orders[id].landlord){
              this.orders[id].llLastLogin=moment(this.orders[id].landlord.lastLogin).format('ll')
              this.orders[id].llName=this.orders[id].landlord.userName
              if (this.orders[id].landlord.hasOwnProperty('nickname')){
                this.orders[id].llName=this.orders[id].llName+" ("+this.orders[id].landlord.nickname+")"
              }
              this.orders[id].llEmail=this.orders[id].landlord.email
              // this.pagination.descending = !this.pagination.descending
            }
            if (this.orders[id].tenant){
              this.orders[id].tLastLogin=moment(this.orders[id].tenant.lastLogin).format('ll')
              this.orders[id].tName=this.orders[id].tenant.name
              this.orders[id].tEmail=this.orders[id].tenant.email
              // this.pagination.descending = !this.pagination.descending
            }
            // console.log(this.orders[id].fullRoomNumber, 'room number before the end of match use listings')
            if(this.orders[id].fullRoomNumber!=undefined){
              this.validOrders.push(this.orders[id])

            }

          }
        }

      }
      if (this.orderLoading==false && this.usersLoading==false && this.roomsLoading==false){
        this.loading=false
      }
      this.current=this.validOrders
    },
    matchOrdersListings(){
      this.validOrders=[]
      // console.log('Mathc the listings');
      // console.log('orders:',);
      if (this.orders!=undefined && this.studios!=undefined && this.rooms!=undefined ){
        for (var id in this.orders) {
          // console.log(this.orders[id],' is of type Object: ',this.orders[id] instanceof Object)
          if (this.orders.hasOwnProperty(id) && this.orders[id] instanceof Object ) {
            if (this.orders[id].type=='studio'){
              this.orders[id].roomLink='/studioDetails/'+this.orders[id].listingId
              this.orders[id].listing=this.studios[this.orders[id].listingId]
            } else if(this.orders[id].type=='room'){
              this.orders[id].roomLink='/details/'+this.orders[id].listingId
              this.orders[id].listing=this.rooms[this.orders[id].listingId]
            } else {
              this.orders[id].roomLink='/apartmentDetails/'+this.orders[id].listingId+'/apartment'
              this.orders[id]['.key']=id
              // this.pagination.descending = !this.pagination.descending
            }
            this.orders[id].key=id
            if (this.orders[id].listing) {
              this.orders[id].fullRoomNumber=this.orders[id].listing.general.fullRoomNumber
            }
            // console.log(this.orders[id].tenant,'after mathc listings show tenant');
            if(this.orders[id].tenant!=undefined){
              this.validOrders.push(this.orders[id])
            }
          }
        }
      }
      if (this.orderLoading==false && this.usersLoading==false && this.roomsLoading==false){
        this.loading=false
      }
      this.current=this.validOrders
    },
    pay(order){
      // console.log('process payment',order['.key']);
      order.payIn=true
      order.status="Paid"
      // console.log('pay');
      axios.patch('users/'+order.tenantId+'.json?auth='+this.$store.state.auth.idToken,{orderPaid:true})
      axios.patch('completedOrders/'+order['.key']+'.json?auth='+this.$store.state.auth.idToken,{payIn:true,status:'Paid'})
      .then(()=>{
        if (order.landlord.sendChinese){
          // console.log('send email in zh to ll, ');
          AWSSES.emailing(order.landlord, 'ZHLLPaymentReceived')
          AWSSES.emailing(order.tenant, 'TPaymentReceivedZHLL')
        } else {
          // console.log('send email in english to ll');
          AWSSES.emailing(order.landlord, 'LLPaymentReceived')
          AWSSES.emailing(order.tenant, 'TPaymentReceivedENLL')
        }
        // console.log('Send email to tenant');
        // console.log('patched', order.landlord);
        // this.matchOrdersListings()
        // this.matchOrdersUsers()
        this.pagination.descending=!this.pagination.descending
        this.pagination.descending=!this.pagination.descending

      })
      .catch(err=>{
        console.log('error: ',err);
      })
    },
    checkCoupon(order){
      let affRev=0
      if (order.discount!=undefined && order.discount>0 && order.coupon!=undefined){
        axios.get('/rCoupons.json?orderBy="name"&equalTo="' + order.coupon + '"')
        .then(res=>{
          let coupon=res.data[Object.keys(res.data)[0]]
          console.log("There is a coupon",coupon, ' coupon.aff: ',coupon.aff)
          if (coupon.aff==true){
            console.log('absVal?: ',coupon.affAbsVal);
            if (coupon.affAbsVal==true){
              affRev=parseInt(coupon.affVal)
            } else {
              affRev=(parseInt(coupon.affVal)/100)*parseInt(order.price)
            }
            console.log(affRev,' is what ', coupon.owner);
            let t={
              brId:order.brId,
              comment:'Affiliate for '+order.fullRoomNumber,
              crId:order.crId,
              landlordId:coupon.owner,
              listingId:order.listingId,
              moneyInTS:new Date(),
              price:affRev,
              roomNumber:order.roomNumber,
              tenantId:order.tenantId,
              deposit:true
            }
            axios.post('transfTBM.json?auth='+this.$store.state.auth.idToken,t)
            .then(()=>{
              console.log('posted');
            })
          } else {
            console.log("there's no affiliate info!");
          }
        })


      } else {
        console.log('No Coupon');
      }
    },
    moneyIn(order){
      order.moneyInDisabled=true
      order.moneyIn=true
      order.status="Money In"
      this.pagination.descending=!this.pagination.descending
      console.log(this.pagination.descending)
      this.pagination.descending=!this.pagination.descending
      console.log(this.pagination.descending)
      console.log('Money IN', order)
      let t={
        crId:order['.key'],
        type:order.type,
        tenantId:order.tenantId,
        status:"Money Received",
        rents:order.rents,
        realDates:order.realDates,
        llPrice:order.llPrice,
        price:order.price,
        perc:order.perc,
        listingId:order.listingId,
        landlordId:order.landlordId,
        brId:order.id,
        discount:order.discount,
        reccRent: order.reccRent,
        dates:order.dates,
        currency:order.currency,
        roomNumber:order.fullRoomNumber,
        comment:order.fullRoomNumber + " - First rent Payment",
        moneyInTS:new Date(),
        listUtil:order.listUtil,
        utilities:order.utilities
      }
      this.checkCoupon(order)
      this.sendRentEmail(order)
      axios.patch('completedOrders/'+ order['.key']+'.json?auth='+this.$store.state.auth.idToken,{moneyIn:true, status:'Money In'})
      .then(()=>{
        // console.log('patched');

        // AWSSES.emailing(order.landlord, 'TPaymentReceived')
        // AWSSES.emailing(order.tenant, 'TPaymentReceived')
      })
      .catch(err=>{
        console.log('error: ',err);
      })
      axios.post('transfTBM.json?auth='+this.$store.state.auth.idToken,t)
      console.log('confirmTimestamp:', order.confirmTimestamp);
      let details={
        currency: order.currency,
        fx:order.fx,
        total:order.total,
        lastRent:moment().format("MMYYY"),
        ref:moment(order.confirmTimestamp).format('YYMMDD')+order.tenant.name.charAt(0)+order.tenant.name.split(" ").pop().charAt(0),
        listingId:order.listingId,
        tenantId:order.tenantId,
        comment:t.comment,
        ts:moment().format('YYMMDD')
      }
      axios.post('payIn.json?auth='+this.$store.state.auth.idToken,details)
    },
    sendAllRentEmails(){
      let count=0
      for (var i = 0; i < this.validOrders.length; i++) {
        if (this.validOrders[i].moneyIn==true && moment(this.validOrders[i].dates.mid).isAfter(moment())){
          console.log('send for this order: ', this.validOrders[i]);
          this.sendRentEmail(this.validOrders[i])
        }
      }
      console.log(count, "Number of emails to be sent");
    },
    sendRentEmail(order){
      let mid=order.dates.mid
      let mod=order.dates.mod
      console.log(order.tenant);
      //check if full months
      if (moment(mid).format('D')!= moment(mod).add(1,"d").format('D')){
        // This means it's not complete months.
        if (parseInt(moment(mid).format('D'))<=10){
          console.log('send email 3 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo3')
        } else if (parseInt(moment(mid).format('D'))<=25){
          console.log('send email 5 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo5')
        } else {
          console.log('send email 2 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo2')
        }
      } else {
        if (10 < parseInt(moment(mid).format('D')) && parseInt(moment(mid).format('D')) <= 25) {
          console.log('send email 4 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo4')
        } else {
          console.log('send email 1 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo1')
        }
      }

    },
    cancelPayment(order){
      // console.log('cancel Payment',order['.key']);
      order.payIn=false
      order.status="Accepted"
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
      axios.patch('completedOrders/'+order['.key']+'.json?auth='+this.$store.state.auth.idToken,{payIn:false,status:'Accepted'})
      .then(()=>{
        // console.log('patched')
        // this.matchOrdersListings()
        // this.matchOrdersUsers()
      })
      .catch(err=>{
        console.log('error: ',err);
      })
    },
    printEmailList(){
      let t=['name','email']
      for (var i = 0; i < this.current.length; i++) {
        if (moment(this.current[i].confirmTimestamp).isAfter(moment('2019/05/01'))) {
          t.push(this.current[i].tName, this.current[i].tEmail)

        }
      }
      // console.log('print t: ',JSON.stringify(t));

      return t
    },
    calcRev(){
      let t={
        fee:0,
        comm:0,
      }
      console.log(this.current.length);
      if(moment(this.start).isValid() &&  moment(this.end).isValid()){
        for (var i = 0; i < this.current.length; i++) {
          console.log(this.oders);
          if (moment(this.current[i].confirmTimestamp).isBetween(moment(this.start), moment(this.end))){
            t.fee=t.fee+Math.ceil(parseInt(this.current[i].price)*.2)
            t.comm = t.comm + Math.ceil(moment(this.current[i].dates.mod).diff(this.current[i].dates.mid,'months',true)*parseInt(this.current[i].price)*.03)
          }
        }
      }
      this.revenue=t
    },



  },
  computed: {

  },
  filters: {
    dateFormat: function(t) {
      return moment(t).format('ll')
    },
    dateLL: function(t) {
      return moment(t).format('L')
    }
  },
  created() {
  },
  components:{
    Countdown,
    AddRequest,
  },
  metaInfo() {
      return {
        title:'CR - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.pay-fail {
  background-color: red;
  color:#ffffff;
}
</style>
