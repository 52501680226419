<template>
<div>
  <v-card>
   <v-card-title>
     {{title}}
     <v-spacer></v-spacer>
     <v-btn color="primary" flat @click="write('rooms',rooms)">show rooms</v-btn>
     <v-btn color="primary" flat @click="write('studios',studios)">Show studios</v-btn>
     <v-btn color="primary" flat @click="write('apartments',apts)">Show apts</v-btn>
     <v-btn color="primary" flat @click="write('wholeApts',wholeApts)">Show wholeApts</v-btn>
     <v-btn color="primary" flat @click="write('locations',locs)">Show Locations</v-btn>
     <v-btn color="primary" flat @click="writeCustom()">Show custom</v-btn>
   </v-card-title>
   <!-- <v-card-text v-if="json=='custom'">
      <div class="">
      <div class="" v-for="element in custom" :key="element.id">
        <span>{{element.address}}</span>, <span> {{element.availability}}</span>, <span> {{element.id}}</span>, <span> {{element.image}}</span>, <span> {{element.lat}}</span>, <span> {{element.lng}}</span>, <span> {{element.name}}</span>, <span> {{element.neighborhood}}</span>, <span> {{element.price}}</span>, <span> {{element.url}}</span>
      </div>

   </v-card-text> -->
   <v-card-text>
     {{json}}
   </v-card-text>

 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
// import axios from 'axios'

export default {
  name: 'SEO',
  data: () => ({
    search: '',
    rooms:[],
    studios:[],
    apts:[],
    locs:[],
    wholeApts:[],
    title:'Select what you want to see',
    json:"",
    searched: [],
    custom:{},
    users: []
  }),

  firebase() {
    return {
      roomsDB:{
        source:firebase.database().ref('rooms'),
        readyCallback:function(){
          for (var i = 0; i < this.roomsDB.length; i++) {
            console.log(moment(this.roomsDB[i].dateAdded).isAfter(moment().subtract(6,"M")), moment().subtract(6,"M").format())
            if (!this.roomsDB[i].draft){
              this.rooms.push({'id':this.roomsDB[i]['.key']})
            }
          }
          console.log("the rooms:",this.rooms)
        }
      },
      studiosDB:{
        source:firebase.database().ref('studios'),
        readyCallback:function(){
          for (var i = 0; i < this.studiosDB.length; i++) {
            if(!this.studiosDB[i].draft && this.studiosDB[i].general!=undefined){
              this.studios.push({'id':this.studiosDB[i]['.key']})
            }
          }
          console.log("the rooms:",this.studios)
        }
      },
      aptDB:{
        source:firebase.database().ref('apartments'),
        readyCallback:function(){
          for (var i = 0; i < this.aptDB.length; i++) {
            if(!this.aptDB[i].draft){
              this.apts.push({'id':this.aptDB[i]['.key']})
            }
          }
          console.log("the rooms:",this.apts)
        }
      },
      wholeAptDB:{
        source:firebase.database().ref('wholeApts'),
        readyCallback:function(){
          for (var i = 0; i < this.wholeAptDB.length; i++) {
            if(!this.wholeAptDB[i].draft){
              this.wholeApts.push({'id':this.wholeAptDB[i]['.key']})
            }
          }
          console.log("the rooms:",this.wholeApts)
        }
      },
      locations:{
        source:firebase.database().ref('locations'),
        readyCallback:function(){
          for (var i = 0; i < this.locations.length; i++) {
            this.locations[i].tName=this.locations[i].name.replace(/ /g,"%20")
            this.locs.push({'name':this.locations[i].tName, lat:this.locations[i].lat, lng:this.locations[i].lng},)
          }
          console.log("the rooms:",this.wholeApts)
        }
      }

    }

  },
  methods: {

    write(key, value){
      console.log("write the rooms")
      let obj={}
      obj[key]=value
      this.title=key.toUpperCase()
      this.json=JSON.stringify(obj)
      console.log('json sting:', this.json)
    },
    writeRooms(){
      console.log("write the rooms")
      let obj={rooms:this.rooms}
      this.title='ROOMS'
      this.json=JSON.stringify(obj)
      console.log('json sting:', this.json)
    },
    writeStudios(){
      console.log("write the studios")
      let obj={studios:this.studios}
      this.title="STUDIOS"
      this.json=JSON.stringify(obj)
      console.log('json sting:', this.json)
    },
    writeCustom(){
      let t=[]
      this.json="custom"
      this.custom=this.locations
      // let obj={studios:this.studios}
      this.title="CUSTOM"

    }
  },
  created() {
    // this.writeCustom()
  },
  metaInfo() {
      return {
        title:'SEO - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
