<template>
<div>
  <v-card>
   <v-card-title>
     Booking Requests
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>

   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="reqs"
     :pagination.sync="pagination"
     :search="search"
   >
     <template slot="items" slot-scope="props">
       <tr @click="props.expanded = !props.expanded">

         <td class="">
           <!-- <v-btn color="primary" @click="testDecline(props.item)">test Decline email</v-btn> -->
           <Countdown class="caption" :end="props.item.end" v-if="props.item.status=='Requested' && props.item.end"></Countdown> <div class="" v-else-if="props.item.confirmTimestamp!=undefined">
            {{props.item.confirmTimestamp | dateFormat}}
         </div>
         <div class="" v-else="">
           undefined confirm, This is request date:{{props.item.dateRequested | dateFormat}}
         </div>
         <v-btn color="primary" flat @click="onlyOneDay(props.item)">remove a day to confirm</v-btn>
        </td>

         <td class="">{{ props.item.status }} <span v-if="props.item.reason">({{props.item.reason}}<a :href="'/'+props.item.suggest" v-if="props.item.suggest"> suggest</a>)</span>
           <br>
           <v-btn color="red" v-if="props.item.status=='Requested'" dark @click="startDecline(props.item)">Decline</v-btn>
           <br>
           <span color="primary" v-if="users.hasOwnProperty(props.item.landlordId) && users[props.item.landlordId].hasOwnProperty('lineFollowId') && props.item.status=='Requested'">
             <a  @click="sendReminder(props.item)">Send Reminder </a>
             <span v-if="props.item.hasOwnProperty('lastReminder')">, last reminder was on {{props.item.lastReminder | dateFormat}}</span>
           </span>
           <span v-else-if="props.item.status=='Requested' && users.hasOwnProperty(props.item.landlordId) && !users[props.item.landlordId].hasOwnProperty('lineFollowId')"> User has no lineFollowId</span>
           <!-- <span v-else-if="props.item.status!='Requested'"></span> -->

         </td>
         <td class=""><a :href="'/profile?id='+props.item.tenantId">{{ props.item.tName }}</a></td>
         <td> <span v-if="props.item.hasOwnProperty('tenant')">{{ props.item.tenant.email }}
               <span v-if="props.item.tenant.communication"><br>communication: <span class="bold">{{props.item.tenant.communication}}</span></span>
               <span v-if="props.item.tenant.appId"><br> App: {{props.item.tenant.appId}}</span>
               <a v-if="props.item.tenant.communication=='WhatsApp'" :href="'https://wa.me/'+props.item.tenant.appId" target="blank"> <br>open whatsapp</a>
               <span v-if="props.item.tenant.phone"><br>Phone: {{props.item.phone}}</span>
             </span>
         </td>
         <td class=""><a :href="props.item.roomLink">{{ props.item.fullRoomNumber }}</a></td>
         <td class=""><span v-if="props.item.dates!=undefined">{{ props.item.dates.mid | dateFormat}} => {{props.item.dates.mod | dateFormat}}</span></td>
         <td class=""><a :href="'/profile?id='+props.item.landlordId">{{ props.item.llName }}</a>
           <span v-if="users && users[props.item.landlordId] && users[props.item.landlordId].admin"> -
             <span v-if="users[users[props.item.landlordId].admin]">
               ({{users[users[props.item.landlordId].admin].userName}})
             </span>
             <span v-else>
               {{users[props.item.landlordId].admin}} ERROR
             </span>
           </span>
         </td>
         <td class="">{{ props.item.discount}} <span v-if="props.item.coupon!=undefined && props.item.coupon!=''">({{props.item.coupon}})</span></td>
         <td>Next Av: <span v-if="props.item.hasOwnProperty('listing') && props.item.listing.hasOwnProperty('general')">{{props.item.listing.general.nextAvailability | dateFormat}}</span></td>
         <td class="">{{ props.item.llLastLogin}}</td>
         <td><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/bookingRequests/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>
       </tr>


     </template>
     <template slot="expand" slot-scope="props">
       <v-card flat>
         <v-card-text>
           <v-flex xs12>
             {{props.item.realDates.mid | dateFormat}} => {{props.item.realDates.mod | dateFormat}}
           </v-flex>
           <v-layout row wrap v-if="props.item.edit==true">
            <v-flex xs10>

             <v-textarea
               v-model="users[props.item.tenantId].about"
               label="About User"

             ></v-textarea>
           </v-flex>
           <v-flex xs2>
             <v-btn color="primary" @click="saveAbout(props.item, users[props.item.tenantId].about)">SAVE</v-btn>
           </v-flex>
           </v-layout>
           <v-layout v-else row wrap>
             <v-flex xs10>
               {{users[props.item.tenantId].about}}

             </v-flex>
             <v-flex xs2>
               <v-btn color="primary" @click="editEdit(props.item)">edit</v-btn>
             </v-flex>
           </v-layout>
            <div class="" >
            </div>
         </v-card-text>
       </v-card>
     </template>

     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
 <DeclineDialog :declineDialog='declineDialog' @changeDialog="declineDialog=$event" @decline="declineRequest" :allRooms="allListings"></DeclineDialog>
 <v-snackbar
   v-model="showSnackbar"
   color="success"
   :timeout="6000"

 >
   {{snackbarMessage}}
   <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
 </v-snackbar>

</div>
</template>

<script>
import Countdown from 'vuejs-countdown'
import firebase from '../../FirebaseApp'
import moment from 'moment'
import AddRequest from './AddRequest.vue'
import axios from 'axios'
import DeclineDialog from '../requestsPage/DeclineDialog.vue'
import AWSSES from '../../aws-ses'

export default {
  name: 'bookingRequests',
  data: () => ({
    admin:{
      userName:"admin",
      email:"hello@myroomabroad.com"
    },
    snackbarMessage:"",
    showSnackbar:false,
    usersLoaded:false,
    lineLoaded:false,
    pagination:{
      descending: true
    },
    headers: [
      { text: 'Countdown', value: 'dateRequested' },
      { text: 'Status', value: 'status' },
      { text: 'Tenant', value:'tName'},
      { text: 'User info', value:"communication"},
      { text: 'Room name', value: 'fullRoomNumber' },
      { text: 'Dates', value: 'dates.mid' },
      { text: 'Landlord name', value:'llName'},
      { text: 'Coupon', value:'discount'},
      { text: 'Next Availability', sortable:false},
      { text: 'Last Login', value: 'llLastLogin'},
      { text: "id", value:".key"},
    ],
    declineDialog:false,
    declining:{},
    search: null,
    searched: [],


  }),
  firebase() {
    return {
      reqs: {
        source:firebase.database().ref('bookingRequests').limitToLast(200),
        readyCallback:function(){
          console.log(this.reqs,'readyCallback')
          // var offset = moment.zone.utcoffset("Taipei");
          console.log(moment.tz.guess(),'THE LOCAL TZ');
          // var HtoAdd=(LOCAL_tz_offset-UTC_offset)/60
          for (var i = 0; i < this.reqs.length; i++) {
            this.reqs[i].edit=false
            this.reqs[i].dateRequestedS=moment(this.reqs[i].dateRequested).valueOf()
            this.reqs[i].end=moment.tz(this.reqs[i].dateRequested,moment.tz.guess()).add(2,'d').locale('en').format('lll')
          }
          this.matchReqsUsers()
          this.matchReqsListings()
        }
      },
      users:{
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){
          this.usersLoaded=true
          this.matchReqsUsers()
          this.matchLineUsers()
        }
      },
      rooms:{
        source:firebase.database().ref('rooms'),
        asObject:true,
        readyCallback:function(){
          this.matchReqsListings()
        }
      },
      studios:{
        source:firebase.database().ref('studios'),
        asObject:true,
        readyCallback:function(){
          this.matchReqsListings()
        }
      },
      apts:{
        source:firebase.database().ref('wholeApts'),
        asObject:true,
        readyCallback:function(){
          this.matchReqsListings()
        }
      },
      line:{
        source:firebase.database().ref('lineFollow'),
        readyCallback:function(){
          this.lineLoaded=true
          this.matchLineUsers()
        }
      }
    }

  },
  methods: {
    testDecline(item){
      // let json=JSON.stringify(item)
      // console.log(json);
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendDeclineEmail',item)
    },
    onlyOneDay(item){
      let newTS=moment(item.dateRequested).subtract(1,"Days")
      console.log(item['.key'], newTS);
      axios.patch('bookingRequests/' + item['.key'] + '.json' + '?auth=' + this.$store.state.auth.idToken, {dateRequested:newTS})
    },
    startDecline(prop){
      this.declining=prop
      this.declineDialog=true
    },
    matchLineUsers(){
      console.log('Do Match');
      if (this.usersLoaded && this.lineLoaded){
        console.log('really start now');
        for (var i = 0; i < this.line.length; i++) {
          if (this.line[i].hasOwnProperty('ll')&& this.users.hasOwnProperty(this.line[i].ll)){
            console.log('got a Match');
            if (this.users[this.line[i].ll].hasOwnProperty('lineFollowId')){
              this.users[this.line[i].ll].lineFollowId.push(this.line[i].userId)
            } else {
              this.users[this.line[i].ll].lineFollowId=[this.line[i].userId]
            }
          } else {
            console.log('NO MATCH', this.line[i],this.users[this.line[i].ll]);
          }
        }
      }
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    declineRequest(payload){
      // console.log('YOU HAVE DECLINED')
      this.declining.status = "Declined"
      this.declining.confirmTimestamp = new Date()
      this.declining.reason=payload.reason
      this.declining.suggest=payload.suggest
      if (payload.reason=='dates'){
        if (payload.mid==undefined||payload.mid==""){
          payload.mid=this.declining.dates.mid
        }
        if (payload.mod==undefined||payload.mod==""){
          payload.mod=this.declining.dates.mod
        }
        this.declining.sMid=payload.mid
        this.declining.sMod=payload.mod
      }
      let db=""
      if (this.declining.type.toLowerCase() == 'studio') {
        db = '/studios/'
      } else if (this.declining.type.toLowerCase() == 'room') {
        db = '/rooms/'
      } else if (this.declining.type.toLowerCase()== 'apartment'){
        db = '/wholeApts/'
      }

      if (payload.nextAv){
        axios.patch(db + this.declining.listingId + '/general.json' + '?auth=' + this.$store.state.auth.idToken, {
          nextAvailability: payload.nextAv
        })
        .catch(err=>{
          console.log('error: ',err);
        })
      }
      let obj=JSON.parse(JSON.stringify(this.declining))
      delete obj['.key']
      console.log('patch this: ', '/bookingRequests/' + this.declining.id, obj);
      axios.patch('bookingRequests/' + this.declining['.key'] + '.json' + '?auth=' + this.$store.state.auth.idToken, obj)
        .then(() => {
          axios.get('users/' + this.declining.tenantId + '.json')
          .then(res => {
            let info={
              tenant: res.data.name,
              landlord: "The landlord",
              mid: moment(payload.mid).format("YYYY-MM-DD"),
              mod: moment(payload.mod).format("YYYY-MM-DD"),
              amount:null,
              comment:null,
              room:null,
            }
            // console.log(info);

            // AWSSES.emailingInfo(this.admin, info, 'ABookingRequestDeclined')
            if (payload.suggest!=null && payload.suggest!="" && payload.suggest.includes('/')){
              console.log("There is  a suggestion");
              AWSSES.emailingInfo(res.data, info, 'TBookingRequestDeclinedSuggest')
            } else if (payload.mid != null && payload.mid!=""){
              console.log("There is  a date suggestion");
              AWSSES.emailingInfo(res.data, info, 'TBookingRequestDeclinedDates')
            } else {
              console.log('there is no suggestion');
              try {
                axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendDeclineEmail',this.declining)
              } catch (e) {
                console.log(e);
                AWSSES.emailingInfo(res.data, info, 'TBookingRequestDeclined')
              }
            }
          })
          .catch(err=>{
            console.log('error: ',err);
          })

      })
      .catch(err=>{
        console.log('error: ',err);
      })

    },
    sendReminder(prop){
      let textMsg="Hi！\n恭喜您有一個新的訂房在My Room Abroad！\n, 你可以在此連結看到你的訂房請求：https://myroomabroad.com/requestsInbox"
      let messageData={
        to:"",
        messages:[
          {"type": "text",
          "text": textMsg
        }]

      }
      this.snackbarMessage="Reminder sent to: "+this.users[prop.landlordId].userName
      this.showSnackbar=true
      for (var i = 0; i < this.users[prop.landlordId].lineFollowId.length; i++) {
        messageData.to=this.users[prop.landlordId].lineFollowId[i]
        axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', messageData, {
          headers: {
            'Content-Type': 'text/plain'
          }
        })
        // console.log();
        console.log('Send to ', this.users[prop.landlordId].lineFollowId[i]);
      }
      axios.patch('bookingRequests/'+prop['.key']+'.json?auth='+this.$store.state.auth.idToken,{lastReminder:moment().format()})
      prop.lastReminder=moment().format()
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    editEdit(prop){
      prop.edit=true
    },
    saveAbout(prop, about){
      prop.edit=false
      axios.patch('users/'+prop.tenantId+'.json?auth='+this.$store.state.auth.idToken,{about:about})
      .then(()=>{
        console.log('Successfulle saved');
      })
      .catch(err=>{
        console.log(err);
      })
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    matchReqsUsers(){
      if (this.reqs.length>0 && this.users){
        for (var i = 0; i < this.reqs.length; i++) {
          this.reqs[i].tenant=this.users[this.reqs[i].tenantId]
          if (this.reqs[i].tenant){
            this.reqs[i].tName=this.reqs[i].tenant.name
            this.reqs[i].tAbout=this.reqs[i].tenant.about
          }
          this.reqs[i].landlord=this.users[this.reqs[i].landlordId]
          if (this.reqs[i].landlord){
            this.reqs[i].llLastLogin=moment(this.reqs[i].landlord.lastLogin).format('ll')
            this.reqs[i].llName=this.reqs[i].landlord.userName
            if (this.reqs[i].landlord.hasOwnProperty('nickname')){
              this.reqs[i].llName=this.reqs[i].llName+" ("+this.reqs[i].landlord.nickname+")"
            }
            this.reqs[i].llEmail=this.reqs[i].landlord.email
          }
          this.pagination.descending=!this.pagination.descending
          this.pagination.descending=!this.pagination.descending

        }


      }
    },
    matchReqsListings(){
      console.log('Mathc the listings');

      if (this.reqs.length >0 && this.studios!={} && this.rooms!={} && this.apts!={}){

        for (var i = 0; i < this.reqs.length; i++) {
          if (this.reqs[i].type=='studio'){
            this.reqs[i].listing=this.studios[this.reqs[i].listingId]
            this.reqs[i].roomLink='/studioDetails/'+this.reqs[i].listingId
          } else if(this.reqs[i].type=='room'){
            this.reqs[i].listing=this.rooms[this.reqs[i].listingId]
            this.reqs[i].roomLink='/details/'+this.reqs[i].listingId
          } else if (this.reqs[i].type=='apartment'){
            this.reqs[i].listing=this.apts[this.reqs[i].listingId]
            this.reqs[i].roomLink='/apartmentDetails/'+this.reqs[i].listingId+'/apartment'
          }
          if(this.reqs[i].listing){
              this.reqs[i].fullRoomNumber=this.reqs[i].listing.general.fullRoomNumber
          }
          this.pagination.descending=!this.pagination.descending
          this.pagination.descending=!this.pagination.descending
        }

      }

    }

  },
  computed:{
    now(){
      return moment.tz("UTC")
    },
    allListings(){
      let t=[]
      if (this.rooms!=undefined && this.studios!=undefined && this.apts!=undefined){
        for (var list in this.rooms) {
          if (this.rooms.hasOwnProperty(list)) {
            if (this.rooms[list].draft==false){
              this.rooms[list].type="room"
              this.rooms[list].id=list
              t.push(this.rooms[list])
            }
          }
        }
        for (var list in this.studios) {
          if (this.studios.hasOwnProperty(list) ){
            if (this.studios[list].draft==false){
              this.studios[list].type="studio"
              this.studios[list].id=list
              t.push(this.studios[list])
            }
          }
        }
        for (var list in this.apts) {
          if (this.apts.hasOwnProperty(list)) {
            if (this.apts[list].draft==false){
              this.apts[list].id=list
              t.push(this.apts[list])
            }
          }
        }
      }
      return t
    }
  },
  filters: {
    dateFormat: function(t) {
      return moment(t).format('ll')
    }
  },
  created() {
  },
  components:{
    Countdown,
    AddRequest,
    DeclineDialog,
  },
  metaInfo() {
      return {
        title:'BR - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.bold{
  font-weight: bold;
}
.md-field {
    max-width: 300px;
}
</style>
