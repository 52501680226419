<template>
<div>
  <v-card>
   <v-card-title>
     Listings
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="allLists"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td> <span v-if="props.item.hasOwnProperty('general')">{{props.item.title}}</span> </td>
       <td> <span v-if="props.item.hasOwnProperty('general')"> {{props.item.roomNumber}} </span> </td>
       <td> <span v-if="props.item.hasOwnProperty('general')">{{props.item.type}}</span> </td>
       <td> <span v-if="props.item.hasOwnProperty('ll') && users.hasOwnProperty(props.item.ll)">{{users[props.item.ll].userName}}</span> </td>
       <td> <span v-if="props.item.hasOwnProperty('general')">{{props.item.nextAv | dateFormat}}</span> </td>
       <td> <v-btn color="primary" :href="props.item.url" target="_blank">See Listing</v-btn></td>
       <td> <a :href="props.item.db" target="_blank"> DB</a> </td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>

 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Users',
  data: () => ({
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Room Number', value: 'roomNumber'},
      { text: 'Type', value: 'type' },
      { text: 'LL', value: 'll' },
      { text: 'next Av', value:'nextAv'},
      { text: 'Link', value: '.key'}
    ],
    search:"",
    pagination: {
      descending: true
    },
    allLists:[]
  }),
  firebase() {
    return {
      rooms: {
        source:firebase.database().ref('rooms').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          for (var i = 0; i < this.rooms.length; i++) {
            this.rooms[i].type="Private Room"
            this.rooms[i].url="/details/"+this.rooms[i]['.key']
            if (this.rooms[i].hasOwnProperty('general'))
            this.rooms[i].title=this.rooms[i].general.title
            this.rooms[i].roomNumber=this.rooms[i].general.fullRoomNumber
            this.rooms[i].nextAv=this.rooms[i].general.nextAvailability
            this.rooms[i].db='https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/rooms/'+this.rooms[i]['.key']
          }
          this.allLists=this.allLists.concat(JSON.parse(JSON.stringify(this.rooms)))
        }
      },
      apts: {
        source:firebase.database().ref('apartments').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          for (var i = 0; i < this.apts.length; i++) {
            this.apts[i].type="Apt"
            this.apts[i].url="/apartmentDetails/"+this.apts[i]['.key']+'/rooms'
            if (this.apts[i].hasOwnProperty('general')){
              this.apts[i].title=this.apts[i].general.title
              this.apts[i].roomNumber=this.apts[i].general.name
              this.apts[i].ll=this.apts[i].general.landlordId
              this.apts[i].db='https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/apartments/'+this.apts[i]['.key']
            }
          }
          this.allLists=this.allLists.concat(JSON.parse(JSON.stringify(this.apts)))
        }
      },
      studios: {
        source:firebase.database().ref('studios').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          for (var i = 0; i < this.studios.length; i++) {
            this.studios[i].type="Studio"
            this.studios[i].url="/studioDetails/"+this.studios[i]['.key']
            if( this.studios[i].hasOwnProperty('general')){
              this.studios[i].title=this.studios[i].general.title
              this.studios[i].roomNumber=this.studios[i].general.fullRoomNumber
              this.studios[i].nextAv=this.studios[i].general.nextAvailability
              this.studios[i].ll=this.studios[i].general.landlordId
              this.studios[i].db='https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/studios/'+this.studios[i]['.key']
            }
          }
          this.allLists=this.allLists.concat(JSON.parse(JSON.stringify(this.studios)))
        }
      },
      wholeApts: {
        source:firebase.database().ref('wholeApts').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          for (var i = 0; i < this.wholeApts.length; i++) {
            this.wholeApts[i].type="Whole Apt"
            this.wholeApts[i].url="/apartmentDetails/"+this.wholeApts[i]['.key']+'/apartment'
            if( this.wholeApts[i].hasOwnProperty('general')){
              this.wholeApts[i].title=this.wholeApts[i].general.title
              this.wholeApts[i].roomNumber=this.wholeApts[i].general.fullRoomNumber
              this.wholeApts[i].nextAv=this.wholeApts[i].general.nextAvailability
              this.wholeApts[i].ll=this.wholeApts[i].general.landlordId
              this.wholeApts[i].db='https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/wholeApts/'+this.wholeApts[i]['.key']
            }
          }
          this.allLists=this.allLists.concat(JSON.parse(JSON.stringify(this.wholeApts)))
        }
      },
      users: {
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){
        }
      },
    }
  },
  methods: {
  },
  filters: {
    dateFormat: function(t) {
      if (t!=undefined){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    },
    dateTime: function(t) {
      if (t!=undefined){
        return moment.tz(t,"Asia/Taipei").format('lll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
  },
  metaInfo() {
      return {
        title:'Listings - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
