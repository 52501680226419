<template>
<div>
  <v-card>
    <v-card-title>
      Rents
      <v-btn color="primary" @click="checkRentPrices">checkprices</v-btn>
      <!-- <v-btn color="primary" @click="depositDialog=true">Add a deposit payment</v-btn> -->
      <v-spacer></v-spacer>
      <v-checkbox label="Name Filter" hide-details v-model="nameFilter" class="pr-2" ></v-checkbox>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]' :headers="headers" :items="rentsDB" :search="search" :pagination.sync="pagination" :custom-filter="customFilter">
      <template slot="items" slot-scope="props">
        <td> <v-text-field
          v-model="props.item.askDay"
          type="number"
        ></v-text-field>
        <span v-if="props.item.fDueDate!=undefined">Due: {{props.item.fDueDate | dueDateFilter}}</span>
        <span v-if="props.item.askDayPrev!=undefined">before: {{props.item.askDayPrev}}</span>
      </td>
        <td>
          <div class="">
              <v-text-field label="Utilities" v-model="props.item.utilities" suffix="$" type="number"></v-text-field>
          </div>
        </td>
        <td>
          <div class="" v-if="users[props.item.tenantId]!=undefined">
            <a :href="'/profile?id='+props.item.tenantId" v-if="users[props.item.tenantId].name">{{users[props.item.tenantId].name}}</a>
            <a :href="'/profile?id='+props.item.tenantId" v-else>{{users[props.item.tenantId].userName}}</a>
            <span class="light-grey"> @ <a class="light-grey" :href="'/landlordDashboard/'+props.item.landlordId" v-if="users[props.item.landlordId]!=undefined">{{users[props.item.landlordId].userName}}'s</a><span> - </span> <a :href="roomLink(props.item)">{{props.item.roomNumber}}</a></span>
          </div>
        </td>
        <td>
          <div class="" v-if="props.item.listUtil!=undefined">

            <span v-if="props.item.fixedUtil">Fixed utilities</span>
            <div class="" v-else>

              <span class="font-weight-bold" >{{props.item.listUtil.name}}</span>: {{props.item.listUtil.comment}}
            </div>
          </div>
          <!-- <div class="" v-for="transf in props.item.transfers" :key="transf['.key']">
         {{transf.roomNumber}} ({{transf.price}}<span v-if="transf.utilities"> + {{transf.utilities}}</span> - {{transf.price*.03}})
         <br>
         {{transf.comment}}
       </div> -->
        </td>
        <td>
          <span class="font-weight-bold" v-if='props.item.nextRent!=undefined'>
            {{ props.item.nextRent}} TWD
          </span>
          <span class="font-weight-bold" v-else-if="props.item.deposit==true" >{{props.item.price}} TW$</span>
          <br>
          <span>
            {{props.item.invoiceNumber}}
          </span>
          <br>
          <span v-if="props.item.comment">Comment: {{props.item.comment}}</span>
          <span v-else>InvComment: {{props.item.invoiceComment}}</span>

        </td>
        <td><div v-if="props.item.dates!=undefined" class="">
          {{ props.item.dates.mid | dateFormat}}

          <br>
          {{props.item.dates.mod | dateFormat}}
          <br>
          <!-- {{props.item.lastRent}} -->
        </div>
        </td>
        <td>
          <!-- <v-btn slot="activator" color="rgb(255,192,0)" dark :disabled="props.item.moneyReceived" @click="setPaid(props.item, false)">Cash received</v-btn> -->

          <div class="">
            <v-btn color="error" @click="payAllToLL(props.item)">pay All To LL</v-btn>
          </div>
          <div class="" v-if="props.item.reccRent==true && props.item.fPay!=true" :disabled="props.item.processing">
            <v-tooltip top>
              <v-btn  slot="activator" color="light-green" dark @click="grabCash(props.item)">Grab his cash! :P</v-btn>
              <span>Last Rent: {{props.item.temp}}</span>
            </v-tooltip>

          </div>
          <div class="" v-else>

            <div class="" v-if="props.item.invoiceSent">
              <v-tooltip top>
                <v-btn slot="activator" color="rgb(255,192,0)" dark :disabled="props.item.moneyReceived" @click="setPaid(props.item, false)">Cash received</v-btn>
                <span>Last Rent: {{props.item.month}}</span>
              </v-tooltip>

              <br>
              <a @click="sendReminder(props.item)" class="pl-3 reminder">send reminder</a><span v-if="props.item.fDueDate!=undefined">(Due: {{props.item.fDueDate|dueDateFilter}})</span>
            </div>
            <v-tooltip top v-else>
              <v-btn slot="activator" color="primary":disabled="props.item.invoiceSent" @click="startSendInvoice(props.item)" >SEND INVOICE</v-btn>
              <span>Last Rent: {{props.item.temp}}</span>
            </v-tooltip>
          </div>
          <v-btn color="primary" @click="saveDate(props.item)">save Date</v-btn>
          <v-btn color="primary" v-if="props.item.noLateFees!=true" @click="setNoLateFees(props.item, true)">Set No late Fees</v-btn>
          <v-btn color="primary" v-else @click="setNoLateFees(props.item, false)">Set Auto late Fees</v-btn>
        </td>
        <td>
          <v-btn color="primary" @click="changeUtilities(props.item)">Save Utilities</v-btn>
          <v-btn color="#FF4000" dark  :disabled="props.item.moneyReceived" @click="setPaidToLL(props.item)">Paid to LL</v-btn>
            <v-btn color="error" :href="'/admin/changeroom/'+props.item['crId']">Change Room</v-btn></td>
        <td>{{props.item.llName}}
          <br>
          <v-btn color="#FF4000" dark v-if="props.item.noDateChange!=true" @click="keepOriginalDate(props.item, true)">Set Keep original Ask Day</v-btn>
          <v-btn color="primary" v-else @click="keepOriginalDate(props.item, false)">Set Auto Ask Day </v-btn>
        </td>
        <td><span v-if="props.item.hasOwnProperty('invoiceNumber')">{{props.item.invoiceNumber}}</span> </td>
        <td><v-text-field
          v-model="props.item.deleteText"
          placeholder="DELETE"
          v-if="props.item.deleteText!='DELETE'"
        ></v-text-field> <v-btn color="error" :disabled="props.item.deleting" v-else @click="deleteRentList(props.item)">DELETE</v-btn></td>
        <td><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/rents/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </v-card>
  <v-layout row justify-center>
    <v-dialog v-model="depositDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">New Deposit</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="depositRent"
            :items="depositOpts"
            label="Whose booking are we talking about?"
            prepend-icon="person"
            :filter="tFilter"
            >
            <template
                   slot="selection"
                   slot-scope="{ item, selected }"
            >
                 <span v-if="users[item.tenantId]!=undefined" v-text="users[item.tenantId].name+' ('+item.roomNumber+')'"></span>
           </template>
           <template
             slot="item"
             slot-scope="{ item, tile }"
           >
             <v-list-tile-avatar
               color="primary"
               v-if="users[item.tenantId]!=undefined && users[item.tenantId].name"
             >
               {{ users[item.tenantId].name.charAt(0) }}
             </v-list-tile-avatar>
             <v-list-tile-content>
               <v-list-tile-title v-if="users[item.tenantId]!=undefined" v-text="users[item.tenantId].name"></v-list-tile-title>
               <v-list-tile-sub-title v-if="users[item.landlordId]!=undefined" v-text="item.roomNumber + ' ('+users[item.landlordId].userName+')'"></v-list-tile-sub-title>
             </v-list-tile-content>
           </template>
          </v-autocomplete>
          <v-text-field
            type="number"
            label="Amount"
            suffix="NT$"
            v-model="depositAmount"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat :disabled="disableDeposit" @click.native="depositDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" flat :disabled="disableDeposit" @click.native="addDeposit">Add deposit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
  <v-layout row wrap>
    <PastRents :users="users" @deleteRent="deleteRent" :rents="past" :show="showPast" @changeShow="showPast=!showPast"></PastRents>
  </v-layout>
</div>
</template>

<script>
import PastRents from './PastRents'
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import AWSSES from '../../aws-ses'

export default {
  name: 'Rents',
  data: () => ({

    pagination: {
      descending: true
    },
    headers: [
      {
        text: 'Day to ask',
        value: 'askDay'
      },
      {
        text: 'Utilities',
        value: 'utilities'
      },
      {
        text: 'Tenant',
        value: 'tName'
      },
      {
        text: 'Comment',
        sortable: false
      },
      {
        text: 'Rent (TWD)',
        value: 'nextRent'
      },
      {
        text: 'Move In Date',
        value: 'mid'
      },
      {
        text: 'Send invoice',
        value: 'invoiceSent'
      },
      {
        text:'Change Room',
        sortable:false,
      },
      {
        text:'Landlord',
        value:'llName'
      },
      {
        text:'invoiceNumber',
        value:'invoiceNumber'
      },
      {
        text:'Write: "DELETE"',
        sortable: false,
      }
    ],
    nameFilter:true,
    search: null,
    depositRent: null,
    depositAmount: 0,
    depositDialog: false,
    disableDeposit:false,
    searched: [],
    allRents:[],
    matches: [],
    showPast:false,
    past:[],
    admin: {
      userName: "Admin",
      email: "vincent@myroomabroad.com"
    }
  }),
  firebase() {
    return {
      rentsDB: {
        source: firebase.database().ref('rents').orderByChild('deposit').endAt(false),
        readyCallback: function() {
          // console.log(moment(rent.lastRent,/'MMYYYY').format(), moment());
          // console.log('checkHere:', moment('052019','MMYYYY').endOf('Month').isAfter(moment('2019-06-21T23:59:59.999Z').startOf('Month')));
          this.past=this.rentsDB.filter(rent => this.addToPastCheck(rent) && rent.invoiceSent!=true);
          this.past.sort(function(a,b) {
            if(a.landlordId>b.landlordId){
              return 1
            } else if (a.landlordId<b.landlordId){
              return -1
            }
          })
          if (this.past.length>0){
            this.showPast=true
          }
          this.matchRentUser()
        }
      },
      transfsTBM:{
        source: firebase.database().ref('transfTBM'),
        readyCallback: function(){
          this.matchRentUser("transfs")
        }
      },
      users: {
        source: firebase.database().ref('users'),
        asObject: true,
        readyCallback: function() {
          this.matchRentUser()
        }

      },
      cr:{
        source: firebase.database().ref('completedOrders'),
        asObject:true,

      }
    }
  },
  methods: {
    addToPastCheck(rent){
      if (rent.hasOwnProperty('dates') && moment(rent.dates.mid).add(1,"month").isAfter(moment(rent.dates.mod))){
        console.log('Only 1 month');
        return true
      }
      if (rent.hasOwnProperty('dates') && this.getLastRentMoment(rent.lastRent).endOf('Month').isAfter(moment(rent.dates.mod).startOf('Month'))){
        return true
      } else if (rent.hasOwnProperty('dates') && this.isOddDates(rent)==false && parseInt(moment(rent.dates.mod).format("D"))<25 && this.getLastRentMoment(rent.lastRent).add(1,"M").endOf('Month').isAfter(moment(rent.dates.mod).startOf('Month'))) {
        // console.log("its a whole month with early contract end (before 11)");
        return true
      } else {
        return false
      }
    },

    customFilter(value, search, item){
      console.log(value, search);
      let t=[]
      // item(value)
      if (search!=null && search!=""){

        for (var i = 0; i < value.length; i++) {
          // console.log(value[i].toString());
          if (this.nameFilter!=true){
            // console.log('Test');
            for (var el in value[i]){
              // console.log(el)
              if (value[i].hasOwnProperty(el) && value[i][el]!=undefined) {
                // console.log(el,value[i][el],typeof(value[i][el])=="object" );
                if (typeof(value[i][el])=="object") {
                  // console.log('skip ',el);
                  // for (var el2 in value[i][el]) {
                  //   console.log(value[i][el][el2]);
                  //   if (value[i][el].hasOwnProperty(el2) && typeof(value[i][el][el2])!="object") {
                  //     if ( value[i][el][el2].toString().toLowerCase().includes(search.toLowerCase() ) ){
                  //       t.push(value[i])
                  //     }
                  //   }
                  // }
                } else {
                  // console.log(value[i].llName)
                  if (value[i][el].toString().toLowerCase().includes(search.toLowerCase() ) ){
                    t.push(value[i])
                    break
                  }
                }
              }
            }
          } else {
            // console.log(value[i].tName );
            if ( (value[i].tName!=undefined && value[i].tName.toLowerCase().startsWith(search.toLowerCase()) )|| (value[i].llName!=undefined && value[i].llName.toLowerCase().startsWith(search.toLowerCase()))){
              t.push(value[i])
            }
          }
        }
      } else {
        t=value
      }
      return t

      // return item.toString().indexOf(search)!=-1
    },
    deleteRentList(rent){
      console.log('rents/'+rent['.key']+'.json DELETED');
      axios.delete('rents/'+rent['.key']+'.json?auth='+this.$store.state.auth.idToken)
    },
    deleteRent(rent){
      // console.log(rent)
      let index = this.past.findIndex(x => x['.key']==rent['.key'])
      // console.log(index);
      this.past.splice(index,1)
    },
    calcRentAmount(rent){
      if (isNaN(parseInt(rent.utilities))==false && isNaN(parseInt(rent.nextRent))==false){
        return Math.ceil((parseInt(rent.utilities)+parseInt(rent.nextRent))*1.028)
      }
    },
    grabCash(rent){
      rent.processing=true
      if (rent.utilities == undefined) {
        // console.log('return');
        alert('enter the utilities first!')
        return
      } else {
        let nextRentMonth = moment(this.setNextRentMonth(rent), 'DD MMM YY').format("YYYYMMDD")
        let invoiceNumber = 'R' + moment().format('YYMMDD') + rent.tName.substring(0,3) + rent.tName.split(" ").pop().substring(0,3)
        let due = this.setDueDate(rent)
        axios.patch('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {
          utilities: rent.utilities,
          month: nextRentMonth,
          askDay:rent.askDay,
          noDateChange:rent.noDateChange,
          fDueDate:rent.fDueDate,
          dueDate: due,
          invoiceSent: true,
          nextRent:rent.nextRent,
          invoiceNumber: invoiceNumber,
          comment: rent.invoiceComment,
          setCheckDates:rent.setCheckDates
        })
        rent.invoiceSent = true
        this.pagination.descending = !this.pagination.descending
        this.pagination.descending = !this.pagination.descending
        let tenant=this.users[rent.tenantId]
        if (tenant.card!=undefined){
          let info={
            "card_key": tenant.card.card_key,
            "card_token": tenant.card.card_token,
            "comment": rent.invoiceComment,
            "amount": this.calcRentAmount(rent)
          }
          let vm=this
          // console.log(tenant.card);
          axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/payByToken', info, {
            headers: {
              'Content-Type': 'text/plain'
            }
          })
          .then(res=>{
            if (res.data.status==0 ){
              vm.successfulPayment(rent)
              rent.processing=false
            } else {
              vm.failedPayment(rent)
              rent.processing=false
            }
          })
        } else {
          rent.processing=false
          this.failedPayment(rent)
        }
      }
    },
    failedPayment(rent){
      rent.fPay=true
      axios.patch('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {fPay:true})
      axios.patch('users/' + rent.tenantId + '.json?auth=' + this.$store.state.auth.idToken, {
        unpaidInvoice: true
      })
      .then(() => {
        let tenant = {
          userName: this.users[rent.tenantId].userName,
          email: this.users[rent.tenantId].email
        }
        AWSSES.emailing(this.users[rent.tenantId], 'TFailedAutoPaymentRent')
      })
      alert('Payment has failed. Invoice has been sent.')
    },
    successfulPayment(rent){
      this.setPaid(rent, true)
      AWSSES.emailing(this.users[rent.tenantId], 'TAutoPayRentSuccess')
    },
    setNoLateFees(item, val){
      axios.patch('rents/' + item['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {noLateFees:val})
    },
    keepOriginalDate(item, val){
        axios.patch('rents/' + item['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {noDateChange:val})
    },
    changeUtilities(rent){
      axios.patch('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {
        utilities:rent.utilities
      })
      .then(()=>{
        alert("Updated")
      })
      .catch(err=>{
        console.log('error: ',err);
        alert("Failed")
      })
    },
    checkRentPrices(){
      for (var i = 0; i < this.rentsDB.length; i++) {
         if (this.rentsDB[i].price != this.cr[this.rentsDB[i].crId].price){
           // console.log('room ',this.rentsDB[i].roomNumber,"is different in its price");
           // axios.patch('rents/' + this.rentsDB[i]['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {
           //   price:this.cr[this.rentsDB[i].crId].price
           // })
           // .then(()=>{
           //   console.log('patch success');
           // })
         }
      }
    },
    tFilter (item, queryText) {
      // console.log(this.users[item.tenantId], item.tenantId, 'the search based on this:', item.roomNumber);
      let textOne=""
      let textTwo=""
      let textThree=""
      if (this.users[item.tenantId]!=undefined && this.users[item.tenantId].name!=undefined){
        textOne = this.users[item.tenantId].name.toLowerCase()
      }
      if (this.users[item.tenantId]!=undefined && this.users[item.landlordId].userName!=undefined){
        textTwo = this.users[item.landlordId].userName.toLowerCase()
      }
      if (item.roomNumber!=undefined){
        textThree = item.roomNumber.toLowerCase()
      }
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textThree.indexOf(searchText)>-1
    },
    saveDate(rent){
      axios.patch('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {
        askDay:rent.askDay,
        noDateChange:true,
      })
    },
    addDeposit() {
      this.disableDeposit=true
      let deposit = this.depositRent
      delete deposit['.key']
      deposit.comment = this.depositRent.roomNumber + " - Deposit Payment"
      deposit.invoiceComment = this.depositRent.roomNumber + " - Deposit Payment"
      deposit.invoiceSent=true
      deposit.price = this.depositAmount
      deposit.utilities=0
      deposit.deposit=true
      // console.log('add this deposit:' , deposit);
      axios.post('rents.json?auth=' + this.$store.state.auth.idToken, deposit)
      .then(()=>{
        this.depositDialog=false
        this.disableDeposit=false
        this.depositRent=null
      })
      .catch(err=>{
        console.log(err);
        this.disableDeposit=false
        alert('Something bad happened... SCREEAAAM')
      })
      axios.patch('/users/'+this.depositRent.tenantId+'.json?auth=' + this.$store.state.auth.idToken, {deposit:true})
      .then(()=>{
        // console.log('Yaay, check ur mails');
      })
      .catch(err=>{
        console.log(err);
        this.disableDeposit=false
        alert('Something bad happened... SCREEAAAM')
      })
    },
    roomLink(rent) {
      switch (rent.type) {
        case 'room':
          return '/details/' + rent.listingId
        case 'studio':
          return '/studioDetails/' + rent.listingId
        case 'apartment':
          return '/apartmentDetails/' + rent.listingId
        default:
          return ''
      }
    },
    startSendInvoice(rent) {
      if (rent.utilities == undefined) {
        // console.log('return');
        alert('enter the utilities first!')
        return
      } else {
        if (rent.currency != "TWD") {
          axios.get('https://free.currencyconverterapi.com/api/v6/convert?q=TWD_' + this.user.currency + '&compact=ultra&apiKey=2d6cf65af2f8557d2378')
            .then(res => {
              let fromToCurrency = "TWD_" + this.user.currency
              rent.fx = res.data[fromToCurrency] * 1.04
              this.sendInvoice(rent)
            })
            .catch(err=>{
              console.log('API err: ',err);
              rent.fx=1
              rent.currency='TWD'
              this.sendInvoice(rent)
            })
        } else {
          rent.fx = 1
          this.sendInvoice(rent)
        }
      }
    },
    sendInvoice(rent) {
      //mail you have an invoice
      // console.log(rent.tenant,rent, "This is the tenant and the rent:");
      let nextRentMonth = moment(this.setNextRentMonth(rent), 'DD MMM YY').format("YYYYMMDD")
      // console.log(nextRentMonth);
      let invoiceNumber = 'R' + moment().format('YYMMDD') + rent.tName.substring(0,3) + rent.tName.split(" ").pop().substring(0,3)
      let due = this.setDueDate(rent)
      // console.log(due);
      axios.patch('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {
        utilities: rent.utilities,
        month: nextRentMonth,
        fx: rent.fx,
        askDay:rent.askDay,
        dueDate: due,
        invoiceSent: true,
        nextRent:rent.nextRent,
        invoiceNumber: invoiceNumber,
        comment: rent.invoiceComment,
        setCheckDates:rent.setCheckDates
      })
      rent.invoiceSent = true
      this.pagination.descending = !this.pagination.descending
      this.pagination.descending = !this.pagination.descending
      // console.log('patch -- ' + 'users/' + rent.tenantId + '.json?auth=')
      axios.patch('users/' + rent.tenantId + '.json?auth=' + this.$store.state.auth.idToken, {
          unpaidInvoice: true
        })
        .then(() => {
          let tenant = {
            userName: this.users[rent.tenantId].userName,
            email: this.users[rent.tenantId].email
          }
          let info={
            table:this.getEmailTable(rent),
            due:due.format("YYYY-MM-DD")
          }
          AWSSES.sendRent(tenant, info, 'TPayRent')
        })

    },
    getEmailTable(rent){
      let t="<table  align='center' style='border-collapse:collapse; border: thin solid black; width:100%;'><tr style='border-bottom: 1px solid black;  height: 3em; vertical-align:center'><th>Item</th><th style='text-align:right; vertical-algin:center; padding-right:1em;'>Amount</th></tr><tr style='height:2em'><td style='padding-left:1em'>"+rent.invoiceComment+"</td><td style='text-align:right;padding-right:1em;'>"+rent.nextRent+" TW$</td></tr>"
      let total=rent.nextRent
      if (rent.utilities!=undefined && rent.utilities>0) {
        t=t+"<tr style='height:2em'><td style='padding-left:1em'>Utilities</td><td style='text-align:right; padding-right:1em;'>"+rent.utilities+" TW$</td></tr>"
        total=total+rent.utilities
      }
      t=t+"<tr style='height:2.3em'><td style='font-weight:bold; padding-left:1em'>TOTAL </td><td style='text-align:right;font-weight:bold; border-top:2px solid black; padding-right:1em;'>"+total+" TW$</td></tr></table>"
      // console.log(t);
      // let due=this.setDueDate()
      // console.log(due.format('YYYY-MM-DD'));
      // info={
      // }
      // AWSSES.emailingInfo(tenant, 'TPayRent')
      return t
    },
    setDueDate(rent) {
      if (rent.fDueDate!=undefined){
        if (parseInt(rent.fDueDate)<=10 && moment().date() > 10){
          return moment().add(1,'M').date(parseInt(rent.fDueDate))
        } else {
          return moment().date(parseInt(rent.fDueDate))
        }

      } else if (moment().date() <= 10) {
        return moment().date(10)
      } else {
        return moment().date(25)
      }
    },
    sendReminder(rent) {
      //MAIL REMINDER to
      let tenant={
        userName: this.users[rent.tenantId].userName,
        email: this.users[rent.tenantId].email
      }
      AWSSES.emailing(tenant, 'TRentReminder')
      alert('Reminder Sent')
      console.log('send reminder to:', rent.tenant.email);
    },
    setPaid(rent, acc = false) {
      //add to transfersTBM
      let comment=""
      if (rent.comment){
        comment=rent.comment
      } else {
        comment=rent.invoiceComment
      }
      rent.moneyReceived=true
      let t = {
        brId: rent.brId,
        comment: comment,
        crId: rent.crId,
        currency: rent.currency,
        dates: rent.dates,
        landlordId: rent.landlordId,
        listUtil: rent.listUtil,
        listingId: rent.listingId,
        reccRent:rent.reccRent,
        moneyInTS: new Date(),
        nextRent:rent.nextRent,
        price: rent.price,
        llPrice: rent.llPrice,
        perc: rent.perc,
        realDates: rent.realDates,
        roomNumber: rent.roomNumber,
        tenantId: rent.tenantId,
        type: rent.type,
        askDay:rent.askDay,
        noDateChange:rent.noDateChange,
        fDueDate:rent.fDueDate,
        utilities: rent.utilities,
        lastRent: moment(this.setNextRentMonth(rent), 'DD MMM YY').format("YYYYMMDD")
      }
      // console.log('POST -- transfersTBM.json?auth=', t);
      axios.post('transfTBM.json?auth=' + this.$store.state.auth.idToken, t)
      //remove from list
      // console.log('DELETE -- rents/' + rent['.key'] + '.json?auth=');
      axios.delete('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken)
      let index = this.rentsDB.findIndex(item => item['.key'] == rent['.key']);
      // this.rentsDB.splice(index, 1)
      // console.log('PATCH -- users/' + rent.tenantId + '.json?auth=', {
        // unpaidInvoice: false
      // });
      axios.patch('users/' + rent.tenantId + '.json?auth=' + this.$store.state.auth.idToken, {
          unpaidInvoice: false
        })
        .then(() => {
          let landlord = {
            userName: this.users[rent.landlordId].userName,
            email: this.users[rent.landlordId].email
          }
          AWSSES.emailing(landlord, 'LLRentReceived')

        })
      // create a Received payment.
      let total=0
      if (rent.nextRent!=undefined){
        total=parseInt(rent.nextRent)+parseInt(rent.utilities)
      } else {
        total=parseInt(rent.price)+parseInt(rent.utilities)
      }
      let ref =""
      if (rent.tenant.name!=undefined){
        // console.log('use Name');
        ref=moment(rent.confirmTimestamp).format('YYMMDD') + rent.tenant.name.charAt(0) + rent.tenant.name.split(" ").pop().charAt(0)
      } else {
        // console.log('use userName');
        ref=moment(rent.confirmTimestamp).format('YYMMDD') + rent.tenant.userName.charAt(0) + rent.tenant.userName.split(" ").pop().charAt(0)
      }
      let details = {
        currency: rent.currency,
        fx: rent.fx,
        total: total,
        ref: moment(rent.confirmTimestamp).format('YYMMDD') + rent.tenant.name.charAt(0) + rent.tenant.name.split(" ").pop().charAt(0),
        listingId: rent.listingId,
        tenantId: rent.tenantId,
        comment: t.comment,
        ts: moment().format('YYMMDD')
      }
      if (acc==true){
        details.type="ACC"
      }
      axios.post('payIn.json?auth=' + this.$store.state.auth.idToken, details)
    },
    calcComm(rent){
      let t=0
      if (rent.perc!=undefined){
        t=parseInt(rent.nextRent)*-parseInt(rent.perc)/100
      } else if (rent.llPrice!=undefined) {
        t=parseInt(rent.llPrice)-parseInt(rent.price)
      } else {
        t=parseInt(rent.nextRent)*-0.03
      }
      return t
    },
    getLastRentMoment(date){
      if (moment(date,"MMYYYY").isValid()){
        return moment(date,"MMYYYY")
      } else if (moment(date,"YYYYMMDD").isValid()) {
        return moment(date,"YYYYMMDD")
      } else {
        return moment(date)
      }
    },
    calculateTotalComm(rent){
      let tComm=0
      if(!rent.hasOwnProperty('nextRent')){
        rent.nextRent=this.setRent(rent)
      }
      let mod=moment(rent.dates.mod)
      if (mod.date()<=10 && this.isOddDates(rent)==false){
        mod=mod.subtract(1,"Month")
      }
      mod=mod.subtract(1,'Month')
      console.log('Last Rent: ',rent.lastRent, '  nextRent: ',rent.nextRent, ' MOD: ',rent.dates.mod,);
      tComm=this.calcComm(rent)
      rent.lastRent=moment(this.setNextRentMonth(rent),'DD MMM YY').format("YYYYMMDD")
      rent.comm1=this.setComment(rent,rent.lastRent)
      // Check if next Rent Month is last Month
      console.log(tComm);
      // console.log(parseInt(moment(rent.lastRent,"YYYYMM").month()) < parseInt(moment(rent.dates.mod).month()));
      console.log(rent.lastRent,moment(rent.dates.mod).format('ll'),mod.format('ll'));
      while (this.getLastRentMoment(rent.lastRent).isBefore(mod,"Month")){
        rent.nextRent=this.setRent(rent)
        tComm=tComm+this.calcComm(rent)
        console.log(tComm);
        // console.log(rent.lastRent)
        rent.lastRent=moment(this.setNextRentMonth(rent),'DD MMM YY').format("YYYYMMDD")
        console.log(rent.lastRent, rent.dates.mod, this.getLastRentMoment(rent.lastRent).isBefore(mod,"Month"));
      }
      if (this.isOddDates(rent)){
        rent.nextRent=this.calcAmount(rent)
      } else {
        rent.nextRent=this.setRent(rent)
      }
      tComm=tComm+this.calcComm(rent)
      console.log(rent.comm1, " and  until ", moment(rent.dates.mod).format('LL'), ' TOTAL COMM : ', tComm);
      return tComm
    },
    payAllToLL(rent){
      rent.moneyReceived=true
      rent.invoiceSent=false
      rent.totalComm=this.calculateTotalComm(rent)
      // console.log(commission);
      let t = {
        comment: "Pay All To Landlord. Commission for remaining periods",
        landlordId: rent.landlordId,
        listUtil:0,
        listingId: rent.listingId,
        moneyInTS: new Date(),
        deposit:true,
        price: rent.totalComm,
        margin:0,
        roomNumber: rent.roomNumber,
        tenantId: rent.tenantId,
        type: rent.type,
      }
      console.log(t);
      console.log('POST -- transfersTBM.json?auth=', t);
      axios.post('transfTBM.json?auth=' + this.$store.state.auth.idToken, t)
      .then(res=>{
        axios.delete('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken)

      })// //remove from list
      axios.patch('users/' + rent.tenantId + '.json?auth=' + this.$store.state.auth.idToken, {
        unpaidInvoice: false
      })
      // // console.log('DELETE -- rents/' + rent['.key'] + '.json?auth=');
      let si = this.rentsDB.findIndex(item => item['.key']==rent['.key']);
      // console.log(this.rentsDB[si],'remove this one at position:', si);
      this.rentsDB.splice(si,1)
      this.pagination.descending = !this.pagination.descending
      this.pagination.descending = !this.pagination.descending

    },
    setPaidToLL(rent) {
      //add to transfersTBM
      if (rent.deposit ==true){
        return
      } else {
        rent.moneyReceived=true
        rent.invoiceSent=false
        rent.lastRent=moment(this.setNextRentMonth(rent), 'DD MMM YY').format("YYYYMMDD")
        let tRent=rent.price
        if(rent.hasOwnProperty('nextRent')){
            tRent=rent.nextRent
        }
        let commission=parseInt(tRent)*-0.03

        if (rent.perc!=undefined){
          commission=parseInt(tRent)*-parseInt(rent.perc)/100
        }
        let t = {
          comment: "Paid to landlord. Commission for this period",
          landlordId: rent.landlordId,
          listUtil:0,
          listingId: rent.listingId,
          moneyInTS: new Date(),
          deposit:true,
          price: commission,
          margin:0,
          roomNumber: rent.roomNumber,
          tenantId: rent.tenantId,
          type: rent.type,
        }
        // console.log('POST -- transfersTBM.json?auth=', t);
        axios.post('transfTBM.json?auth=' + this.$store.state.auth.idToken, t)
        //remove from list
        // console.log('DELETE -- rents/' + rent['.key'] + '.json?auth=');
        axios.patch('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {lastRent:moment(this.setNextRentMonth(rent), 'DD MMM YY').format("YYYYMMDD"), invoiceSent:false})
        axios.patch('users/' + rent.tenantId + '.json?auth=' + this.$store.state.auth.idToken, {
            unpaidInvoice: false
        })
        let si = this.rentsDB.findIndex(item => item['.key']==rent['.key']);
        // console.log(this.rentsDB[si],'remove this one at position:', si);
        this.rentsDB.splice(si,1)
        this.pagination.descending = !this.pagination.descending
        this.pagination.descending = !this.pagination.descending
        }
    },
    checkRent() {
      let indexes = []
      for (var i = 0; i < this.rentsDB.length; i++) {
        if (this.rentsDB[i].dates!=undefined && this.rentsDB[i].deposit!=true){

          let nextRentMonth = this.setNextRentMonth(this.rentsDB[i])
          // console.log(moment(nextRentMonth, 'MMM YY').subtract(1, 'month'), 'next renth month', moment());
          // console.log('checkinvoice');
          if (this.rentsDB[i].invoiceSent==true){
            // console.log('waiting for payment');
            continue
          }
          // console.log('still here');
           // LOGIC FOR FILTERING OUT THE RIGHT DATES
          // if (moment().date()>5&&moment().date()<21){
          //   if (this.rentsDB[i].askDay<6||this.rentsDB[i]>20){
          //     indexes.push(i)
          //   }
          // } else {
          //   if (this.rentsDB[i].askDay>5&&this.rentsDB<21){
          //     indexes.push(i)
          //   }
          // }
          // console.log(moment().isBefore(moment(this.rentsDB[i].dates.mid)), parseInt(moment(this.rentsDB[i].dates.mid).format("D"))>25), "checkbox rent", moment(this.rentsDB[i].dates.mid).format("DD MM YYYY");
          if (moment().isBefore(moment(this.rentsDB[i].dates.mid)) && parseInt(moment(this.rentsDB[i].dates.mid).format("D"))>25){
            // indexes.push(i)

            // console.log('remove this one ', this.rentsDB[i]);
          } else if (moment().isBefore(moment(nextRentMonth, 'DD MMM YYYY').subtract(1, 'month').subtract(10,'days'))) {
            // console.log(this.rentsDB[i], 'splice this element coz:', nextRentMonth);
            // indexes.push(i)
          } else {
            // console.log('keep it coz', nextRentMonth);
          }
          this.rentsDB[i].invoiceComment = this.setComment(this.rentsDB[i],nextRentMonth)
          this.rentsDB[i].nextRent = this.setRent(this.rentsDB[i])
          this.rentsDB[i].utilities = this.setUtilities(this.rentsDB[i])


        } else {
          console.log(this.rentsDB[i]);
        }
      }

      for (var j = indexes.length - 1; j >= 0; j--) {
        this.rentsDB.splice(indexes[j], 1);
      }


    },
    setComment(rent,nextRentMonth){
      let t=""
      let endOfMonth= moment(nextRentMonth,'DD MMM YY').endOf('Month').format('DD')
      let month=moment(nextRentMonth,'DD MMM YY').format('MMM YYYY')
      if (this.isOddDates(rent)){
        let nFM=this.notFirstMonth(rent)
        let lM=this.isLastMonth(rent)
        if (nFM==true && lM==false){
          t=rent.roomNumber+' - Rent for 1 - '+ endOfMonth + ' '+month
          // console.log('odd dates middle Comment', t);
        } else if (nFM==false && lM==true){
          t=rent.roomNumber+' - Rent for '+moment(rent.dates.mid).format("DD MMM YYYY")+' - ' +moment(rent.dates.mod).format('DD')+' '+ month
          // console.log('lastMonth Comment', t);
        } else if (lM==true){
          t=rent.roomNumber+' - Rent for 1 '+month+' - ' +moment(rent.dates.mod).format('DD')+' '+ month
          // console.log('lastMonth Comment', t);
        } else if (nFM==false){
          t=rent.roomNumber+' - Rent for '+moment(rent.dates.mid).add(1,'Month').format('DD')+' '+ month + ' - ' + endOfMonth + ' ' + month
          // console.log('firstMonth odd comment', t)
        }

      } else {
        if (parseInt(moment(rent.dates.mid).format('DD'))==1){
          t= rent.roomNumber+' - Rent for '+ '1 - '+endOfMonth+' '+ month
          // console.log('1-end COmment - ',t );
        } else {
          t=rent.roomNumber+' - Rent for '+moment(rent.dates.mid).format('DD')+ ' '+ month +' - '+ moment(rent.dates.mod).subtract(1,"D").format("DD")+ ' '+ moment(nextRentMonth,"DD MMM YY").add(1,"M").format('MMM YY')
          // console.log('Normal dates - not first - ',t);
        }
      }
      // console.log(t);
      return t
    },
    setNextRentMonth(rent) {
      let nextRent = ""
      if (rent.lastRent != undefined ) {
        if (moment(rent.lastRent, 'MMYYYY').isValid()){
          if (rent.dates!=undefined){
            if (moment(rent.dates.mid).format("D")==1 || this.isOddDates(rent)){
              nextRent = moment(rent.lastRent, 'MMYYYY').add(1, 'month').format('DD MMM YY')
            } else {
              nextRent = moment(rent.lastRent, 'MMYYYY').add(1, 'month').date(moment(rent.dates.mod).date())
            }

          } else {
            nextRent="ERROR WITH DATES!"
          }
          // console.log('useOldformat');
        } else if (moment(rent.lastRent, 'YYYYMMDD').isValid()) {
          nextRent = moment(rent.lastRent, 'YYYYMMDD').add(1, 'month').format('DD MMM YY')
          // console.log('useNewFormat');
        }
      } else {
        if (rent.dates!=undefined){
          if (moment(rent.dates.mid).format("D")==1 || this.isOddDates(rent)){
            nextRent = moment(rent.dates.mid).add(1, 'month').endOf('Month').format('DD MMM YY')
          } else {
            nextRent = moment(rent.dates.mid).add(1, 'month').date(moment(rent.dates.mod).date())
          }
        } else {
          nextRent="ERROR WITH DATES!"
        }
      }
      rent.temp=nextRent
      return nextRent
    },
    setUtilities(contract) {
      if (contract.listUtil != undefined && contract.listUtil.name) {
        if (contract.listUtil.name.toLowerCase().includes('fixed') || contract.listUtil.name.toLowerCase().includes('included')) {
          contract.fixedUtil = true
          if (contract.nextRent==contract.price){
            return contract.listUtil.amount
          } else {
            let m=contract.nextRent/contract.price
            return Math.ceil(contract.listUtil.amount*m)
          }
        }
      } else if (contract.listUtil != undefined && !contract.listUtil.name){
        console.error('NO listutil name',contract);
        contract.fixedUtil = false
        contract.listUtil = {
          name: 'Other or to be split'
        }
        return
      } else {
        contract.fixedUtil = false
        contract.listUtil = {
          name: 'Other or to be split'
        }
        return
      }
    },
    isOddDates(contract){
      // console.log(contract.dates.mod,moment(contract.dates.mod).add(1,"d").format('D'));
      if (moment(contract.dates.mid).format('D')!= moment(contract.dates.mod).add(1,"d").format('D')){
        return true
      } else {
        return false
      }
    },
    isLastMonth(contract){
      let askDay=contract.askDay
      if (contract.askDayPrev){
        askDay=contract.askDayPrev
      }
      if (askDay!=5 && moment().add(1,'M').format('M YYYY')== moment(contract.dates.mod).format('M YYYY')) {
        return true
      } else if (askDay==5 && moment().format('M YYYY')== moment(contract.dates.mod).format('M YYYY')) {
        return true
      } else {
        return false
      }
    },
    notFirstMonth(contract){
      // console.log(contract.roomNumber,moment(contract.dates.mid).add(1,'M').format('M'), moment(this.setNextRentMonth(contract), 'MMM YY').format('M'));
      if (moment(contract.dates.mid).add(1,'M').isSame(moment(this.setNextRentMonth(contract), 'DD MMM YY'),'Month')) {
        // console.log('Its the first month');
        return false
      } else {
        // console.log("It's not the first month");
        return true
      }
    },
    calcLastRentAmount(contract){
      let totalDays=moment(contract.dates.mod).daysInMonth()
      let days=moment(contract.dates.mod).date()
      if (!contract.lastRent){
        console.log(contract.lastRent);
        days=days-moment(contract.dates.mid).date()
      }
      let ratio=days/totalDays
      // console.log(ratio, days, totalDays);
      // console.log('last rent:', contract,Math.ceil( contract.price*ratio));
      return Math.ceil(contract.price*ratio)
    },
    setRent(contract) {
      // console.log('mid:',contract.dates.mid, ', mod: ', moment(contract.dates.mod).add(1,"d").format('D'));
      // console.log('Days difference: ', moment(contract.dates.mid).format('D'), moment(contract.dates.mod).add(1,"d").format('D'));
      // console.log();
      if (contract.nextRent!=undefined){
        return contract.nextRent
      }
      if (this.isOddDates(contract)==true && this.notFirstMonth(contract)==false && contract.nextRent==undefined){
        console.log('FIRST MONTh' ,contract.roomNumber);
        // console.log('calc first rent');
        let start= moment(contract.dates.mid).add(1,'M')
        let end = moment(contract.dates.mid).add(1,'M').endOf('month')
        if (this.isLastMonth(contract)){
          console.log('also last', contract.roomNumber);
          end = moment(contract.dates.mod)
        }
        // console.log('START:', start.format('LLL'), ' END: ', end.format('LLL'));
        console.log('DAYS TILL END OF MONTH:',end.diff(start,'days'),' days');

        let days=end.diff(start,'days')+1
        let monthDays=start.daysInMonth()
        // console.log(days/monthDays);
        contract.invoiceComment=contract.invoiceComment + ' ('+days+' Days)';
        contract.setCheckDates=true
        return Math.ceil(contract.price*(days/monthDays))
      } else if (this.isOddDates(contract)==true && this.isLastMonth(contract)==true) {
        // console.log('calc Last Rent');
        // console.log(contract.roomNumber, this.users[contract.tenantId].userName);
        return this.calcLastRentAmount(contract)
      } else {
        // console.log('regular month');
        return contract.price
      }
    },
    setAskDay(rent){
      let midDate=moment(rent.dates.mid).date()
      if (midDate>25|| midDate<=10){
        if (moment(rent.dates.mid).format('D')!= moment(rent.dates.mod).add(1,"d").format('D') && midDate>25){
          return '5'
        }
          return '20'
      } else {
        return '5'
      }
    },
    matchRentUser(payload) {
      let items=this.rentsDB.concat(this.transfsTBM)
      // console.log('match the Users', this.users, items);
      if (this.users != undefined && items.length > 0) {
        for (var i in items) {
          items[i].id=items[i]['.key']
          // console.log('set ll');
          // console.log(items[i], i);
          if (items[i].dates!=undefined){
              items[i].mid=moment(items[i].dates.mid).valueOf()
            if (items[i].invoiceSent==undefined){
              items[i].invoiceSent=false
            }
            if(items[i].askDay==undefined){
              // console.log('date:', moment(items[i].date.mid).date() );
              // items[i].askDay=moment(items[i].dates.mid).date()
              items[i].askDay=this.setAskDay(items[i])
            }
            //check if it is not a contract where you have to ask at the end of the month
            if (items[i].askDay==5 && this.notFirstMonth(items[i]) && items[i].noDateChange!=true && moment(items[i].dates.mid).format('D')!= moment(items[i].dates.mod).add(1,"d").format('D')){
              items[i].askDayPrev=items[i].askDay
              items[i].askDay=20
              // console.log(items[i],'this is  a exceptional case!');
            }
          }
          if (this.users instanceof Object && this.users != {}) {
            items[i].landlord = this.users[items[i].landlordId]
            if (this.users[items[i].landlordId] != undefined) {
              items[i].llName = this.users[items[i].landlordId].userName
            }
            // console.log("landlordID:" , items[i].landlordId, items[i].llName);
            items[i].tenant = this.users[items[i].tenantId]
            if (this.users[items[i].tenantId] != undefined && this.users[items[i].tenantId].name != undefined) {
              items[i].tName = this.users[items[i].tenantId].name
            } else if (this.users[items[i].tenantId] != undefined && this.users[items[i].tenantId].userName != undefined){
              items[i].tName = this.users[items[i].tenantId].userName
            } else {
              items[i].tName = 'Unknown Tenant'
            }
          }
        }
        if (this.allRents.length==0){
          this.allRents=JSON.parse(JSON.stringify(this.rentsDB))
        }
        if (payload!= 'transfs'){
          this.checkRent()
        }
        this.pagination.descending = !this.pagination.descending
        this.pagination.descending = !this.pagination.descending
      }
    },
  },
  filters: {
    dateFormat: function(t) {
      if (t) {
        // return t
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    },
    dueDateFilter: function(t){
      return t.toString()+' th of the month'
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    // pastContracts(){
    //   console.log('do Past');
    //   return this.rentsDB.filter(rent => moment(rent.dates.mod).isAfter(moment()));
    // },
    // showPast(){
    //   if (this.pastContracts.length>0){
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    depositOpts(){
      return this.allRents.concat(this.transfsTBM)
    }
  },
  created() {
    // moment.tz.setGlobal('UTC')
  },
  components: {
    PastRents,
  },
  metaInfo() {
      return {
        title:'Rents - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.light-grey {
    color: rgba(0,0,0,.50) !important;
}
.reminder{
    color:red;
}
</style>
