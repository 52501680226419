<template>
<div>
  <v-card>
   <v-card-title>
     Line groups (Add My Room Abroad (Official channel) to a group for adding it here ;) )
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>

   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="groups"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td>{{props.item | date}}</td>
       <td>{{props.item.groupId}}</td>
       <td>
         <v-autocomplete
           :items="users"
           placeholder="No admin selected"
           v-model="props.item.ll"
           item-text="userName"
           @change="testChange(props.item)"
           item-value="id"
         ></v-autocomplete>
         <v-btn color="primary" @click="setLLGroup(props.item, props.item.ll)">Save</v-btn>
       </td>

       <!-- <td>{{props.item.admin}}</td> -->
       <td class=""><div class="" v-if="props.item.members!=undefined">
         {{props.item.members}}
       </div> <v-btn color="primary" v-else @click="getMembers(props.item)">Get Members</v-btn></td>
       <td><v-btn color="primary" :disabled="props.item.messageSent==true" @click="sendTestMessage(props.item)">Send Test Message</v-btn></td>
       <td class=""><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/lineGroups/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>

     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'LineGroups',
  data: () => ({
    headers:[
      {text:"Date Created" ,value:"ts"},
      {text:'Group ID', value:"groupId"},
      { text:"Landlord", value:"ll"},
      { text:"members", value:"members"},
      {text: "Test Message", value:""},
      {text: "Database Id", value:".key"}
    ],
    search:"",
    pagination:{
      descending:false,
    },
  }),
  firebase() {
    return {
      users: {
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(20),
        readyCallback:function(){
          for (var i = 0; i < this.users.length; i++) {
            this.users[i].id=this.users[i]['.key']
          }
        }
      },
      groups:{
        source:firebase.database().ref('lineGroups'),
      }


    }

  },
  methods: {
    sendTestMessage(group){
      group.messageSent=true
      let info={to: group.groupId,messages:[{"type": "text","text": "Hi, This a messages ment to identify this conversation for sending notifications. Please just ignore the message :). The conversation ID is: "+group.groupId+". We will add it to your profile, you don't need to do anything :)"}]}
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', info, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      axios.patch('lineGroups/'+group['.key']+".json",{messageSent:true})
      .then(res=>{
        console.log('patched the line group');
      })

    },
    getMembers(group){
      axios.get('https://api.line.me/v2/bot/group/'+group.groupId+'/members/ids',{headers:{Authentication:""}})
      .then(res=>{
        console.log(res.data);
        group.members=res.data
        // axios.patch('lineGroups/'+group['.key']+'.json',{members:res.data})
      })
      .catch(err=>{
        alert('Currently unavailable... Try again when Vincent announces it becomes available')
        console.log(err);
      })
    },
    setLLGroup(group, ll){
      console.log(ll);
      axios.patch('users/'+ll+'.json?auth='+this.$store.state.auth.idToken,{lineGroup:group.groupId})
      .then(res=>{console.log('llSET');})
      axios.patch('lineGroups/'+group['.key']+".json",{ll:ll, messageSent:true})
      .then(res=>{
        console.log('patched the line group');
      })
      let landlord=this.users.find(item=>{
        return item['.key']==ll
      })
      console.log(landlord);
      let info={to: group.groupId,messages:[{"type": "text","text": "Hi "+landlord.userName+"! We have added this channel to your account on My Room Abroad. You will receive a message on this group every time you receive a new booking request. Cheers!"}]}
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', info, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })

    },
    testChange(item){
      console.log(item);
    }
  },
  created() {
  },
  filters:{
    date(t){
      if (t!=undefined){
        return moment(t).format("LL")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.doToday{
  background-color: rgb(255, 64, 0);
color: white!important;
}
.table-menu{
  width:130px;
}
.md-field {
    max-width: 300px;
}
</style>
