<template>
<div>
  <v-card>
   <v-card-title>
     <v-layout wrap>

         Payments
         <v-spacer></v-spacer>

         <v-text-field
           v-model="search"
           append-icon="search"
           label="Search"
           single-line
           hide-details
         ></v-text-field>
         <v-flex xs12>
           <v-layout wrap align-center>

             <v-flex xs12 sm4>
               <v-menu
                 :close-on-content-click="false"
                 v-model="dateMenu"
                 :nudge-right="40"
                 lazy
                 transition="scale-transition"
                 offset-y
                 full-width
                 min-width="290px"
               >
                 <v-text-field
                   slot="activator"
                   v-model="checkDate"
                   label="Select a date in the month u wanna check"
                   prepend-icon="event"
                   readonly
                 ></v-text-field>
                 <v-date-picker v-model="checkDate" @input="dateMenu = false"></v-date-picker>
               </v-menu>
             </v-flex>
             <v-flex xs12 sm4>
               <v-select
               :items="admins"
               v-model="admin"
               item-text="userName"
               label="Select your admin"
               @change="calcComm"
               return-object
               ></v-select>

             </v-flex>
           </v-layout>
           <v-flex xs12 sm12>
             Admin Bonus for this month: {{adminBonus}}+{{adminBonusComm}}={{adminBonus+adminBonusComm}}
             <br>Tenants: <span v-for="(tenant,i) in this.logTenants" :key="'tenant- '+i">{{tenant}}, <br> </span>
             <br>Landlords: <span v-for="(ll,i) in this.logRooms" :key="'landlord- '+i">{{ll}}, <br> </span>
           </v-flex>
         </v-flex>
     </v-layout>
     <br>

   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="payments"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <tr @click="props.expanded = !props.expanded">
         <td>{{props.item.dateSent | dateFormat}}</td>
         <td>{{ props.item.ref}}</td>
         <td>{{ props.item.llName}} ({{landlords[props.item.landlordId].companyId}})</td>
         <td>{{ props.item.total}} TWD</td>
         <td>
           <div class="" v-for="(item, index) in props.item.items" :key="props.item.ref+item.roomNumber+ '  '+index">
             {{item.comment}} <span v-if="item.amount==undefined">({{item.price*.97}}</span><span v-else>({{item.amount}})</span> TW$)
           </div>
         </td>
         <td><v-btn color="primary" :disabled="props.item.disableFap" @click="makeInvoice(props.item)">make invoice</v-btn></td>
         <td class=""><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/payments/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>
       </tr>

     </template>
     <template slot="expand" slot-scope="props">

       <v-data-table
         :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
         :items="props.item.items"
         :headers="headers2"
       >
         <template slot="items" slot-scope="props">

           <td class="">{{props.item.roomNumber}}</td>
           <td class="">{{props.item.price }} - {{props.item.margin}}</td>
           <td class="">{{props.item.comment}}</td>
         </template>
       </v-data-table>
       </template>

     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import axios from 'axios'
import AWSSES from '../../aws-ses'


export default {
  name: 'Payments',
  data: () => ({
    dateMenu:false,
    pagination:{descending:true},
    headers: [
      { text: 'Date', value:'dateSent'},
      { text:'Reference', value: 'ref'},
      { text: 'Landlord', value: 'llName' },
      { text: 'Total Amount', value: 'total' },
      { text: 'Comments', value: 'name'},
      { text: 'Make Invoice', value: 'ref'},
      { text: 'Key', value: '.key'},
    ],
    headers2: [
      { text: 'Room', value: 'roomNumber' },
      { text: 'price', value: 'price' },
      { text: 'comment', value: 'comment' },
    ],
    checkDate:null,
    search: null,
    tMargin:0,
    adminBonus:0,
    adminBonusComm:0,
    cLLIds:[],
    cPayments:[],
    userCR:[],
    comm:[],
    logTenants:[],
    logRooms:[],
    searched: [],
    admin:{},
  }),
  firebase() {
    return {
      payments: {
        source:firebase.database().ref('payments'),
        readyCallback:function(){
          this.matchTransfsLL()
          // this.checkTransfers()
        }
      },
      cr: {
        source:firebase.database().ref('completedOrders').orderByChild("confirmTimestamp").startAt(moment().subtract(2, 'months').startOf('month').format()),
      },
      admins:{
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(80),
      },
      landlords:{
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(20),
        asObject:true,
        readyCallback:function(){

          // this.checkTransfers()
          this.matchTransfsLL()
        }

      }
    }
  },
  methods: {
    matchTransfsLL(){
      console.log('match the landlords');
      for (var i in this.payments) {
        this.payments[i].id=this.payments[i]['.key']
        // console.log('set ll', this.payments[i].id);
        if (this.landlords instanceof Object && this.landlords.hasOwnProperty(this.payments[i].landlordId)){
          this.payments[i].llName=this.landlords[this.payments[i].landlordId].userName
        }
      }
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    calcComm(payload){
      this.tMargin=0
      this.cLLIds=[]
      this.cPayments=[]
      this.userCR=[]
      this.comm=0
      this.logRooms=[]
      this.adminBonus=0
      this.adminBonusComm=0
      // console.log(payload);
      for (var id in this.landlords) {
        if (this.landlords.hasOwnProperty(id)) {
          if (this.landlords[id].admin==payload['.key']){
            this.cLLIds.push(id)
          }
        }
      }
      for (var i = 0; i < this.payments.length; i++) {
        // console.log(this.cLLIds.includes(this.payments[i].landlordId) ,'   -   ',moment(this.payments[i].dateSent).format('MM')==moment(this.checkDate).format('MM'));
        if (this.cLLIds.includes(this.payments[i].landlordId) && moment(this.payments[i].dateSent).format('MM')==moment(this.checkDate).format('MM') ) {
          this.cPayments.push(this.payments[i])
          for (var j = 0; j < this.payments[i].items.length; j++) {
            if (this.payments[i].items[j].hasOwnProperty('margin')){
              this.logRooms.push(this.payments[i].items[j].comment  +' - ('+ this.payments[i].items[j].margin + ' - '+ this.landlords[this.payments[i].landlordId].userName+')')
              this.tMargin=this.tMargin+parseInt(this.payments[i].items[j].margin)
              // console.log(this.payments[i].items[j].margin);
            } else {
              console.log(this.payments[i].items[j]);
            }
          }
        }
      }
      this.adminBonus=Math.round(this.tMargin*.05)
      axios.get('users.json?orderBy="admin"&equalTo="'+payload['.key']+'"')
      .then(res=>{
        // let adminUsers=res.data
        for (var i = 0; i < this.cr.length; i++) {
          if (moment(this.cr[i].status!="Canceled" && this.cr[i].confirmTimestamp).format('MM')==moment(this.checkDate).format('MM')){
            for (var id in res.data) {
                if (id==this.cr[i].tenantId){
                    this.userCR.push(this.cr[i])
                    this.logTenants.push(this.cr[i].fullRoomNumber)
                    let tComm=parseInt(this.cr[i].price)*.2
                    if (this.cr[i].discount!=undefined){
                      tComm=tComm-this.cr[i].discount
                    }
                    this.comm=this.comm+tComm
                }
            }
          }
        }
        this.adminBonusComm=Math.round(this.comm*.05)
      })
    },
    makeInvoice(item){
      item.disableFap=true
      let margin=0
      for (var i = 0; i < item.items.length; i++) {
        margin=margin+item.items[i].margin
      }
      let user=this.landlords[item.landlordId]
      user.id=item.landlordId
      let fapiouInfo={
        invoiceNumber:'MRA'+Math.round(moment().format('X')).toString().substr(-5)+user.userName.substring(0,3).toUpperCase(),
        amount: margin,
      }
      console.log(margin, user);
      // console.log(user.id.replace(/[\W_]+/g," "));
      this.createFapiou(fapiouInfo,user)
    },
    createFapiou(invoice,user){
      let data={
          relateNumber:invoice.invoiceNumber,
          businessId:'',
          name:user.userName,
          email:user.email,
          userId:user.id,
          amount:invoice.amount,
          itemName:"My Room Abroad Service fee",
          address:"",
          type:"B2C"
      }
      if (user.hasOwnProperty('companyId') && user.companyId.length==8){
        data.businessId=user.companyId
        data.type="B2B"
      }
      console.info(data);
      // this.failedFapiou(data,user)
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/fapiou2', data, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then(res => {
        // console.log('Response data: ', res.data);
        console.log('SUCCESS: ',res.data);
        let fapiouNumber=this.getQueryVariable('InvoiceNumber', res.data)
        if (fapiouNumber!=undefined && fapiouNumber!=""){
          axios.post('fapiou.json?auth='+this.$store.state.auth.idToken,{number:fapiouNumber, ts:Math.round(moment().format('X')).toString(), user:user.id})
        } else {
          console.log('no fapiou created for ');
          this.failedFapiou(data, user)
        }

      })
      .catch(err=>{
        console.log('ERROR', err);
        this.failedFapiou(data, user)
      })
    },
    failedFapiou(data,user){
      axios.post('failedFapiou.json?auth='+this.$store.state.auth.idToken,data)
      .then(res=>{
        console.log('success Failed Fapiou post');
      })
      .catch(err=>{
        console.log('error: ',err);
      })
    },

    getQueryVariable(variable, query) {
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    },

  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
    this.checkDate=moment().format('YYYY-MM-DD')
  },
  metaInfo() {
      return {
        title:'Payments - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
