<template>
<div>
  <v-card>
   <v-card-title>
     Issues
     <v-spacer></v-spacer>
     <v-btn color="primary" @click="run()">run</v-btn>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="issues"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <tr @click="props.expanded = !props.expanded">

         <td>{{ props.item.ts | date}}</td>
         <td><v-btn flat small color="primary" :disabled="props.item.solved==true" @click="solve(props.item)">Mark Resolved</v-btn></td>
         <td>{{props.item.title}}</td>
         <td><a :href="'/profile/'+props.item.tenant" target="blank" v-if="users.hasOwnProperty(props.item.tenant)">{{users[props.item.tenant].userName}}</a> </td>
         <td><a v-if="users.hasOwnProperty(props.item.tenant)" :href="'mailto:'+users[props.item.tenant].email+'?subject=Your issue on My Room Abroad'">{{users[props.item.tenant].email}}</a> </td>
         <td><a :href="crs[props.item.crID].link" target="blank" v-if="crs.hasOwnProperty(props.item.crID)">{{crs[props.item.crID].fullRoomNumber}}</a></td>
         <td><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/issues/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>
       </tr>
     </template>
     <template slot="expand" slot-scope="props">
       <v-card flat>
         <v-card-text>
          <v-layout wrap>
            {{props.item.desc}}
          </v-layout>
          <v-layout v-if="props.item.hasOwnProperty('photos')" wrap>
            <v-flex xs6 sm3 v-for="photo in props.item.photos" :key="photo.key">
              <img :src="photo.url" alt="" width="80%">
            </v-flex>
          </v-layout>

         </v-card-text>

       </v-card>
     </template>

     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
import axios from 'axios'
import * as types from '../../store/types'
const md5=require('md5')

export default {
  name: 'issues',
  data: () => ({
    pagination:{descending:true},
    headers: [
      { text:'Date', value: 'ts'},
      { text: 'Mark solved', value: 'solved' },
      { text: 'Type', value: 'title' },
      { text: 'Tenant', value: 'tName' },
      { text: 'email', value: 'tenant' },
      { text: 'Room', value: 'crID'},
      { text: 'id', value:'.key'}
    ],
    search: null,
    searched: [],

  }),
  firebase() {
    return {
      issues: {
        source:firebase.database().ref('issues').orderByChild('solved').endAt(true),
        readyCallback:function(){
          this.matchTransfsUsers()
        }
      },
      crs:{
        source:firebase.database().ref('completedOrders'),
        asObject:true,
        readyCallback:function(){
          for (var id in this.crs) {
            if (this.crs.hasOwnProperty(id) && this.crs[id] instanceof Object) {
              // console.log(id);
              // console.log(this.crs[id]);
              this.crs[id].link=this.getLink(this.crs[id])
            }
          }
        }
      },
      users:{
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){
          this.matchTransfsUsers()
        }

      }
    }
  },
  methods: {
    run(){
      let data={
      	  relateNumber:"asr234589hgfchqergjk4567htb",
          businessId:"50895192",
          name:"MyRoomAbroad",
          email:"Hello@myroomabroad.com",
  		    amount:2345678,
          name:"Serviuce",
          type:"B2B",
          address:"新北市中和區秀朗路三段101號5F"
      }
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/fapiou2', data, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then(res => {
        // console.log('Response data: ', res.data);
        console.log('SUCCESS: ',res.data);
      })
      .catch(err=>{
        console.log('ERROR', err);
      })
    },
    getLink(cr){
      switch (cr.type) {
        case 'room':
          return '/details/'+cr.listingId
          break
        case 'studio':
          return '/studioDetails/'+cr.listingId
        default:
          return '/apartmentDetails/'+cr.listingId+'/apartment'
      }
    },
    matchTransfsUsers(){
      console.log('match the landlords')
      for (var i in this.issues) {
        console.log('set ll');
        this.issues[i].title= this.setTitle(this.issues[i].s1,this.issues[i].s2)
        this.issues[i].id=this.issues[i]['.key']
        // let date =new Date(moment(this.issues[i].ts,"YYMMDD"))
        // this.issues[i].time=date.getTime()
        if (this.users instanceof Object && this.users[this.issues[i].tenant]!=undefined){
          this.issues[i].tName=this.users[this.issues[i].tenant].userName
          this.issues[i].tenantProfile=this.users[this.issues[i].tenant]
        }
        if (this.users instanceof Object && this.users[this.issues[i].ll]!=undefined){
          this.issues[i].llName=this.users[this.issues[i].ll].userName
          this.issues[i].llProfile=this.users[this.issues[i].ll]
        }
        this.pagination.descending=!this.pagination.descending
        this.pagination.descending=!this.pagination.descending
      }
    },
    setTitle(s1,s2){
      switch (s1) {
        case 1:
          switch(s2){
            case 1:
              return "Not as described"
            case 2:
              return "Dirty on Arrival"
            case 3:
              return "Pay Deposit in cash"
            case 4:
              return "Issue with contract"
            default:
              return "Check in"
          }
        case 2:
          return "Issue with payment"
        case 3:
          return "Issue with Landlord"
        case 4:
          switch(s2){
            case 1:
              return "Clogged"
            case 2:
              return "Leakage"
            case 3:
              return "Gas Bottle"
            case 4:
              return "Lost key"
            default:
              return "Other Infrastructure"
          }
        default:
          return "Other type"
      }
    },
    solve(item){
      item.solved=true
      axios.patch('issues/'+item['.key']+'.json?auth='+ this.$store.state.auth.idToken, {solved:true})
      console.log(item);
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending

    }

  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
  },
  filters:{
    date(t){
      return moment(t).format('LL')
    }
  },
  metaInfo() {
      return {
        title:'Issues - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
