<template>
<div>
  <v-card>
   <v-card-title>
     Analysis
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-card-text>
     <v-layout wrap>
       <v-flex xs4 sm3 md2>
         <v-select
         label="Date Filter Item"
         :items="dateFilterOpts"
         v-model="dateFilterVal"
         ></v-select>
       </v-flex>
       <v-flex xs6 sm3 md2>
         <v-menu
         v-model="menu1"
         :close-on-content-click="false"
         :nudge-right="40"
         transition="scale-transition"
         offset-y
         full-width
         min-width="290px"
         >
            <v-text-field
            slot="activator"
             v-model="start"
             label="Start"
             prepend-icon="event"
             readonly
           ></v-text-field>
           <v-date-picker v-model="start" @input="menu1 = false"></v-date-picker>
         </v-menu>

       </v-flex>
       <v-flex xs6 sm3 md2>
         <v-menu
         v-model="menu2"
         :close-on-content-click="false"
         :nudge-right="40"
         transition="scale-transition"
         offset-y
         full-width
         min-width="290px"
         >
        <v-text-field
         slot="activator"
         v-model="end"
         label="End"
         prepend-icon="event"
         readonly
         ></v-text-field>
         <v-date-picker v-model="end" @input="menu2 = false"></v-date-picker>
       </v-menu>
      </v-flex>
      <v-flex xs4 sm3 md2>
        <v-select
          label="Filter"
          :items="filterOpts"
          v-model="filterVal"
          @change="count"
        ></v-select>
      </v-flex>
      <v-flex xs4 sm3 md2>
        <v-select
          label="Not This filter"
          clearable
          :items="filterOpts"
          v-model="filterValNot"
          @change="count"
        ></v-select>
      </v-flex>

      <v-btn color="primary" @click="count">count</v-btn>
      <v-flex xs12>
        number of users: {{counter}},  <br> Average number of BR: {{averageBR}}
      </v-flex>
     </v-layout>
     <v-layout wrap>
       number of BR's made: one: {{one}}, two: {{two}}, Three: {{three}}, four: {{four}}, five:{{five}}, six:{{six}}, seven:{{seven}}, more:{{more}}
     </v-layout>
   </v-card-text>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="tUsers"
     :search="search"
     calculate-widths
   >
     <template slot="items" slot-scope="props" >
       <td :class="{hasBR:props.item.hasBR}"><a :href="'/profile?id='+props.item['.key']">{{ props.item.userName }}</a></td>
       <td> {{ props.item.email }}
         <span v-if="props.item.school"> <br>{{props.item.school}}</span>
         <span v-if="props.item.communication"><br>communication: <span class="bold">{{props.item.communication}}</span></span>
         <span v-if="props.item.appId"><br> App: {{props.item.appId}}</span>
         <a v-if="props.item.communication=='WhatsApp'" :href="'https://wa.me/'+props.item.phone" target="blank"> <br>open whatsapp</a>
         <span v-if="props.item.phone"><br>Phone: {{props.item.phone}}</span>
       </td>       <td :class="{hasBR:props.item.hasBR}" class="" >{{ props.item.lastLogin |dateFormat }}</td>
       <td :class="{hasBR:props.item.hasBR}" class="" >{{ props.item.dateAdded |dateFormat }}</td>
       <td :class="{hasBR:props.item.hasBR}" class="" ><span v-if="props.item.query!=null">{{ props.item.query.mid |dateFormat  }}</span></td>
       <td :class="{hasBR:props.item.hasBR}" class="" ><span v-if="props.item.query!=null">{{ props.item.query.mod  |dateFormat}}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.price }}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.name  }}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.orderBy  }}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.propType }}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.nor  }}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.aptType  }}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.bedType  }}</span></td>
       <td class="" ><span v-if="props.item.query!=null">{{ props.item.query.cLength  }}</span></td>
       <td>{{props.item.hasBR}}: {{props.item.numberBR}}</td>
       <td> {{props.item.hasCR}} </td>
       <td><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/users/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>

     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
   </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Users',
  data: () => ({
    headers: [
      { text: 'Username', value: 'userName' },
      { text: 'User Info', value: 'communication', width:"500px" },
      { text: 'Last Login', value: 'lastLogin' },
      { text: 'Date Created', value: 'dateAdded' },
      { text: 'MID', value: 'query.mid'},
      { text: 'MOD', value: 'query.mod' },
      { text: 'Price', value: 'query.price' },
      { text: 'name', value: 'query.name' },
      { text: 'OrderBy', value: 'query.orderBy' },
      { text: 'propType ', value:'query.propType'},
      { text: '# Rooms ', value:'query.nor'},
      { text: 'aptType ', value:'query.aptType'},
      { text: 'Bed Type ', value:'query.bedType'},
      { text: 'Contract Length ', value:'query.cLength'},
      {text: 'Has Booking Request', value:'numberBR'},
      {text: 'Has Confirmed Request', value:'hasCR'},
      { text: 'DB', value:'.key'}

    ],
    affId:"",
    affiliateDialog:false,
    coupon:"",
    couponErr:[],
    dateFilterOpts:['Last login','Date Requested','Date Added','Query MID','Confirm request date','Contract Start Date', 'Real Start Date'],
    dateFilterVal:"",
    filterOpts:['hasQuery','hasBR','hasCR', 'hasName','hasID'],
    filterVal:"",
    filterValNot:"",
    search: null,
    searched: [],
    start:"",
    end:"",
    tUsers:[],
    menu1:false,
    menu2:false,
    counter:0,
    one:0,
    two:0,
    three:0,
    four:0,
    five:0,
    six:0,
    seven:0,
    more:0,
  }),
  firebase() {
    return {
      brs: {
        source:firebase.database().ref('bookingRequests'),
      },
      users: {
        source:firebase.database().ref('users').orderByChild('roleValue'),
        readyCallback:function(){
          // console.log(this.users,'readyCallback')
          let remove=[]
          for (var i = 0; i < this.users.length; i++) {
            this.users[i].id=this.users[i]['.key']
            if (this.users[i].hasOwnProperty('query') && this.users[i].query.mid!=""&& moment(this.users[i].query.mid).isValid()) {
              this.users[i].query.mid=moment(this.users[i].query.mid).toISOString()
            }
            if (this.users[i].hasOwnProperty('query') && this.users[i].query.mod!=""&& moment(this.users[i].query.mod).isValid()) {
              this.users[i].query.mod=moment(this.users[i].query.mod).toISOString()
            }
            if (this.users[i].hasOwnProperty('bookingRequestOut')){
              this.users[i].hasBR=true
              this.countBR(this.users[i])
              this.checkIfhasCR(this.users[i])
            } else {
              this.users[i].hasBR=false
              this.users[i].hasCR=false
            }


          }
          this.tUsers=JSON.parse(JSON.stringify(this.users))
        }
      }
    }
  },
  methods: {
    count(){
      this.tUsers=JSON.parse(JSON.stringify(this.users))
      this.tUsers=this.doFilter(this.tUsers)
      this.tUsers=this.doNotFilter(this.tUsers)
      console.log(this.tUsers.length,'afterDoFilter');
      this.tUsers=this.checkDates(this.tUsers)
      console.log(this.tUsers.length,'after Check Dates');
      this.counter=this.tUsers.length
      this.setBRnumbers()
    },
    dateCheck(user,item){
      if(item=="query.mid"){
        if (moment(user['query']['mid']).isBetween(moment(this.start),moment(this.end))){
          return true
        } else {
          return false
        }
      } else {
        // console.log(user[item], item, user);
        if (moment(user[item]).isBetween(moment(this.start),moment(this.end))){
          return true
        } else {
          return false
        }
      }
    },
    checkDates(users){
      // console.log('the dates:',this.start,this.end);
      switch (this.dateFilterVal) {

        case 'Last login':
          return users.filter(item=>this.dateCheck(item, 'lastLogin'))
          break;
        case 'Date Added':
          return users.filter(item=>this.dateCheck(item, 'dateAdded'))
          break;
        case 'Query MID':
          users=users.filter(item=>this.hasQuery(item))
          return users.filter(item=>this.dateCheck(item, 'query.mid'))
          break;
        case 'Date Requested':
          users=users.filter(item=>this.hasBR(item))
          console.log(users.length);
          return users.filter(item=>this.dateCheck(item,'lBRDate'))
          break;

        case 'Confirm request date':
          users=users.filter(item=>this.hasCR(item))
          console.log(users.length, 'After Has CR');
          return users.filter(item=>this.dateCheck(item,'crDate'))
          break;
        case 'Contract Start Date':
          users=users.filter(item=>this.hasCR(item))
          console.log(users.length, 'After Has CR');
          return users.filter(item=>this.dateCheck(item,'cMid'))
          break;

        case 'Real Start Date':
          users=users.filter(item=>this.hasCR(item))
          console.log(users.length, 'After Has CR');
          return users.filter(item=>this.dateCheck(item,'rMid'))
          break
        default:
          return users
      }
    },
    doFilter(users){
      switch(this.filterVal){
        case 'hasBR':
          return users.filter(item=>this.hasBR(item))
          break;
        case 'hasCR':
          return users.filter(item=>this.hasCR(item))
          break;
        case 'hasQuery':
          return users.filter(item=>this.hasQuery(item))
          break;
        case 'hasName':
          return users.filter(item=>this.hasName(item))
          break;
        case 'hasID':
          return users.filter(item=>this.hasID(item))
          break;
        default:
          return users
      }
    },
    doNotFilter(users){
      switch(this.filterValNot){
        case 'hasBR':
          return users.filter(item=>!this.hasBR(item))
          break;
        case 'hasCR':
          return users.filter(item=>!this.hasCR(item))
          break;
        case 'hasQuery':
          return users.filter(item=>!this.hasQuery(item))
          break;
        case 'hasName':
          return users.filter(item=>!this.hasName(item))
          break;
        case 'hasID':
          return users.filter(item=>!this.hasID(item))
          break;
        default:
          return users

      }
    },
    hasBR(item){
      if (item.hasBR==true){
        return true
      } else {
        return false
      }
    },
    hasCR(item){
      if (item.hasCR==true){
        return true
      } else {
        return false
      }
    },
    hasQuery(item){
      if (item.hasOwnProperty('query')){
        return true
      } else {
        return false
      }
    },
    hasName(item){
      if (item.hasOwnProperty('name') && item.name!=""){
        return true
      } else {
        return false
      }
    },
    hasID(item){
      if (item.hasOwnProperty('passport') && item.passport!=""){
        return true
      } else {
        return false
      }
    },

    countUsersSinceDate(start, end){
      i=0
      for (var i = 0; i < this.users.length; i++) {

          if (moment(this.users[el]).isBetween(moment(start), moment(end))){
            i++
          }
      }
      this.counter=i
    },
    countBR(user){
      let t=this.brs.filter(item=>user['.key']==item.tenantId)
      // console.log(t);
      for (var i = 0; i < t.length; i++) {
        if (user.hasOwnProperty('lBRDate') && moment(t[i].dateRequested).isBefore(moment(user.lBRDate))){
          continue
        } else {
          user.lBRDate=t[i].dateRequested
        }
      }
      user.numberBR=t.length
    },
    setBRnumbers(){
      this.one=0
      this.two=0
      this.three=0
      this.four=0
      this.five=0
      this.six=0
      this.seven=0
      this.more=0

      for (var i = 0; i < this.tUsers.length; i++) {
        if (this.tUsers[i].hasBR==true){
          switch(this.tUsers[i].numberBR) {
            case 1:
              this.one=this.one+1
              break;
            case 2:
              this.two=this.two+1
              break;
            case 3:
              this.three=this.three+1
              break;
            case 4:
              this.four=this.four+1
              break;
            case 5:
              this.five=this.five+1
              break;
            case 6:
              this.six=this.six+1
              break;
            case 7:
              this.seven=this.seven+1
              break;
            default:
              this.more=this.more+1
              break;
          }
        }
      }
    },
    checkIfhasCR(user){
      user.hasCR=false
      axios.get('/completedOrders.json?orderBy="tenantId"&equalTo="'+user['.key']+'"')
      .then(res=>{
        let crs=res.data
        if (Object.keys(crs).length>0){
          // console.log(crs);
          for (var id in crs) {
            if (crs.hasOwnProperty(id)) {
              // if (crs[id].status.toLowerCase()!='canceled'){
                let temp=this.users.find(item=>item['.key']==user['.key'])
                user.hasCR=true
                temp.crDate=crs[id].confirmTimestamp
                if( crs[id].hasOwnProperty('dates')){
                  temp.cMid=crs[id].dates.mid
                } if (crs[id].hasOwnProperty('realDates')){
                  temp.rMid=crs[id].realDates.mid
                }

                console.log('HAS CR', user)
                // this.tUsers=JSON.parse(JSON.stringify(this.users))

              // }
            }
          }
        }

      })
      .catch(err=>{
        console.log(err);
      })
    }
  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "No Date Set"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    averageBR(){
      let u=0
      let t=0
      for (var i = 0; i < this.tUsers.length; i++) {
        if (this.tUsers[i].hasBR==true && this.tUsers[i].numberBR>1 && this.tUsers[i].hasCR==false){
          u=u+1
          t=t+this.tUsers[i].numberBR
        }
      }
      if (u>0){
        return t/u
      } else {
        return 'N/A'
      }
    }
  },
  created() {
  },
  metaInfo() {
      return {
        title:'Analysis - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.hasBR{
    background-color: #00bfff;
    color: white!important;
    a {
      color:white;
    }
}
</style>
