<template>
<div>
  <v-card>
   <v-card-title>
     Apartments
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="allFlats"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td class="" >
         <img
           width='100'
           v-if="props.item.hasOwnProperty('visual') && props.item.visual.hasOwnProperty('photos')"
           :src="baseUrl+props.item.visual.photos[0].id"
           >
       </td>
       <td class="" :class="{draft : props.item.draft!==false}">{{ props.item.type }}</td>
       <td class="" :class="{draft : props.item.draft!==false}"><v-layout align-center row wrap>

         <v-flex xs6>
           <a :href="props.item.roomLink">{{ props.item.name }}</a><span v-if ="props.item.draft!==false"> (draft)</span>
           <br>
            <b v-if="props.item.billing!=undefined">{{props.item.billing.price}} NTD</b>

         </v-flex>
         <v-flex xs6>
           <v-btn color="rgb(255,192,0)"  :href="props.item.editLink" dark>Edit</v-btn>

         </v-flex>
       </v-layout>
     </td>
       <td class="" v-if="props.item.hasOwnProperty('general')" :class="{draft : props.item.draft!==false}"><a :href="'/landlordDashboard/'+props.item.general.landlordId">{{ props.item.llName }}</a></td>
       <td class="" :class="{draft : props.item.draft!==false}"> {{ props.item.llTrustVal }} </td>
       <td class="" :class="{draft : props.item.draft!==false}"><v-text-field
         type="number"
         v-model='props.item.score'
       ></v-text-field></td>
       <td> <v-btn color="primary" :disabled="props.item.sending" @click="setSingleScore(props.item)">Save ME</v-btn></td>
       <td> <v-btn color="primary" :disabled="sending" @click="setAllScores">CLICK ME</v-btn></td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
 <v-snackbar
   v-model="showSnackbar"
   :color="snackbarColor"
   :timeout="3000"
 >
   {{snackbarText}}
   <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
 </v-snackbar>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import config from '../../config'


export default {
  name: 'Apartments',
  data: () => ({
    sending:false,
    pagination:{
      sortBy:'score',
    },
    headers: [
      { text: 'photo', sortable:false},
      { text:'type', value:'type'},
      { text: 'Apartment Name', value:'name' },
      { text: 'Landlord Name', value:'llName'},
      { text: 'Trust Value', value:'llTrustVal' },
      { text: 'Score', value:'score' },
      { text: 'Set All Scores',sortable:false}
      ],

    search: null,
    json:"",
    searched: [],
    users: [],
    visitedVals:[
      {text:'Visited', value:2},
      {text:'Not visited', value:0}
    ],
    studiosList:[],
    aptsList:[],
    roomsList:[],
    completeRoomsList:[],
    wholeAptsList:[],
    allFlats:[],
    snackbarText:"Success",
    showSnackbar:false,
    snackbarColor:"success"
  }),
  filters: {
    dateFormat: function(t) {
      return moment(t).format('l')
    }
  },

  firebase() {
    return {
      rooms:{
        source:firebase.database().ref('rooms'),
        readyCallback:function(){
          // console.log('got the apts, try the sync');
          for (var i = 0; i < this.rooms.length; i++) {
            if (this.rooms[i].draft==false){
              this.rooms[i].id=this.rooms[i]['.key']
              this.rooms[i].dbLink='rooms/'+this.rooms[i]['.key']+'/general.json'
              this.rooms[i].type='Room'
              this.rooms[i].name=this.rooms[i].general.fullRoomNumber
              if (this.rooms[i].general.score!=undefined){
                this.rooms[i].score=Number(this.rooms[i].general.score)
              } else {
                this.rooms[i].score=null
              }
              if (this.checkForBath(this.rooms[i].featureList.features)){
                this.rooms[i].type="Private Bathroom Room"
              }
              this.setLink(this.rooms[i])
              this.roomsList.push(this.rooms[i])
            }

          }

          this.matchRoomsApts()
        }
      },
      landlords: {
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(20),
        asObject:true,
        readyCallback:function(){
          // console.log('got the LL, try the match');
          this.matchListUser()
        }

      },
      apts: {
        source:firebase.database().ref('apartments'),
        asObject:true,
        readyCallback:function(){
          // console.log('got the LL, try the match');
          this.matchRoomsApts()
        }

      },

      studios:{
        source:firebase.database().ref('studios'),
        readyCallback:function(){
          // console.log('got the studios, try the sync');
          for (var i = 0; i < this.studios.length; i++) {
            // console.log( this.studios[i].draft)
            if (this.studios[i].draft==false){
              this.studios[i].id=this.studios[i]['.key']
              this.studios[i].type="Studio"
              this.setLink(this.studios[i])
              this.studios[i].dbLink='studios/'+this.studios[i]['.key']+'/general.json'
              this.studios[i].name=this.studios[i].general.name
              if (this.studios[i].general.score) {
                this.studios[i].score=this.studios[i].general.score
              } else {
                this.studios[i].score=null
              }
              this.studios[i].lastModified=this.studios[i].general.lastModified
              this.studiosList.push(this.studios[i])
              if (!this.studios[i].draft && this.studios[i].draft!=undefined){
                this.studios[i].visit=0
                if (this.studios[i].visit==undefined){
                  this.studios[i].visit=0
                }
              }
            }
          }
          this.syncLists()

        }

      },
      wholeApts:{
        source:firebase.database().ref('wholeApts'),
        readyCallback:function(){
          // console.log('got the studios, try the sync');
          for (var i = 0; i < this.wholeApts.length; i++) {
            // console.log( this.studios[i].draft)
            if (this.wholeApts[i].draft==false){

              this.wholeApts[i].dbLink='wholeApts/'+this.wholeApts[i]['.key']+'/general.json'
              this.wholeApts[i].id=this.wholeApts[i]['.key']
              this.wholeApts[i].name=this.wholeApts[i].general.name
              this.wholeApts[i].lastModified=this.wholeApts[i].general.lastModified
              this.wholeApts[i].score=this.wholeApts[i].general.score
              if (this.wholeApts[i].general.score) {
                this.wholeApts[i].score=this.wholeApts[i].general.score
              } else {
                this.wholeApts[i].score=null
              }
              this.wholeApts[i].type="Whole apartment"
              this.setLink(this.wholeApts[i])
              this.wholeAptsList.push(this.wholeApts[i])
              if (!this.wholeApts[i].draft && this.wholeApts[i].draft!=undefined){
                if (this.wholeApts[i].visit==undefined){
                  this.wholeApts[i].visit=0
                }

              }
            }
          }
          this.syncLists()

        }

      },

    }

  },
  methods: {
    matchRoomsApts(){
      // console.log('do this.');
      if (this.roomsList.length>0 && this.apts!=null ){
        // console.log('we got roooms and apts');
        for (var i = 0; i < this.roomsList.length; i++) {
          // console.log(this.roomsList[i].apartmentId, 'aptID');
          if (this.roomsList[i].hasOwnProperty('general') && this.apts.hasOwnProperty(this.roomsList[i].apartmentId)){
            // console.log('setLLId')
            this.roomsList[i].general.landlordId=this.apts[this.roomsList[i].apartmentId].general.landlordId
            this.completeRoomsList.push(this.roomsList[i])
          } else if ( this.roomsList[i].hasOwnProperty('general') && !this.apts.hasOwnProperty(this.roomsList[i].apartmentId) ){
            // console.log('ERRORRR!!!!,', this.roomsList[i].apartmentId);
          }
        }
        this.syncLists()
      }
    },
    setLink(item){
      if (item.type.toLowerCase()=='studio'){
        item.roomLink='/studioDetails/'+item['.key']
        item.editLink="/studio?studioId="+item['.key']

      } else if(item.type.toLowerCase()=='apartment'){
        item.roomLink='/apartmentDetails/'+item['.key']+'/rooms'
        item.editLink="/newApartment?apartmentId="+item['.key']
      } else if (item.type.toLowerCase()=='whole apartment'){
        item.roomLink='/apartmentDetails/'+item['.key']+'/apartment'
        item.editLink="/Apartment?apartmentId="+item['.key']
        // this.pagination.descending = !this.pagination.descending
      } else {
        item.roomLink='/details/'+item['.key']
        item.editLink="/room?roomId="+item['.key']
      }
    },
    checkForBath(list){
      if (list!=undefined){

        for (var i = 0; i < list.length; i++) {
          if (list[i]==='privateBathroom'){
            return true
          }
        }
        return false
      } else {
        return false
      }
    },
    matchListUser(){
      // console.log('the landlordlist:',this.landlords);
      // console.log('the fats: ', this.allFlats);
      if (this.landlords!=null && this.allFlats.length>0){
        // console.log('Do the match');
        // console.log(this.allFlats.length, "length of allflats");
        for (var i = 0; i < this.allFlats.length; i++) {
          if (this.landlords[this.allFlats[i].general.landlordId]!=undefined){
            // console.log('the concerned LL:',this.landlords[this.allFlats[i].general.landlordId].userName)
            this.allFlats[i].llName=this.landlords[this.allFlats[i].general.landlordId].userName
            this.allFlats[i].llTrustVal=this.landlords[this.allFlats[i].general.landlordId].trustVal
          }

        }
      } else {
        // console.log('no can do because: landlords=',this.landlords, this.allFlats,'=allflats');
      }
      // console.log('finished the match');
    },
    setSingleScore(item){
      item.sending=true
      axios.patch(item.dbLink+"?auth="+this.$store.state.auth.idToken,{score:item.score})
      .then(()=>{
        console.log('success');
        item.sending=false
        this.snackbarText="Saved successfully"
        this.snackbarColor="success"
        this.showSnackbar=true
      })
      .catch(e=>{
        console.error(e);
        item.sending=false
        this.snackbarText="Save ERROR"
        this.snackbarColor="error"
        this.showSnackbar=true
      })
    },
    setAllScores(){
      // console.log('setScores');
      this.sending=true
      let count=0
      for (var i = 0; i < this.allFlats.length; i++) {
        if (this.allFlats[i].score!=undefined){
          count++
          // console.log('do this');
          axios.patch(this.allFlats[i].dbLink+"?auth="+this.$store.state.auth.idToken,{score:this.allFlats[i].score})
          .then(()=>{
            count--
            if( count==0){
              alert('added all the scores. YAAAAY')
              this.sending=false
            }
            // console.log(res);
          })
          .catch(err=>{
            count--
            console.log(err);

            // alert('something failed, please cry now')
            this.sending=false
          })
        }
      }
    },
    syncLists(){
      this.allFlats=[]
      if (this.studiosList.length>0){
        this.allFlats=this.allFlats.concat(this.studiosList)
        // console.log('finished the sync, try the match');
      }
      if (this.aptsList.length>0){
        this.allFlats=this.allFlats.concat(this.aptsList)
      }
      if (this.completeRoomsList.length>0){
        this.allFlats=this.allFlats.concat(this.completeRoomsList)
      }
      if (this.wholeAptsList.length>0){
        this.allFlats=this.allFlats.concat(this.wholeAptsList)
      }
      this.matchListUser()
    },
  },
  computed:{
    baseUrl(){
      return config.PHOTOURL
    },

  },
  created() {
  },
  metaInfo() {
      return {
        title:'Rankings - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.draft{
  color:grey;
}
</style>
