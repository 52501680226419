<template>
<div>
  <v-card>
   <v-card-title>
     Users
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[50,100, 25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="users"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td><a :href="'/profile?id='+props.item['.key']">{{ props.item.userName }}</a></td>
       <td class="">{{ props.item.email }}</td>
       <td class="">{{ props.item.name }}</td>
       <td>
         <v-select
           :items="admins"
           placeholder="No admin selected"
           v-model="props.item.admin"
           @change="setAdmin(props.item)"
           item-text="userName"
           item-value="id"
         ></v-select>
       </td>
       <td><div class="">
         <v-btn flat color="primary" v-if="!props.item.hasOwnProperty('evCode') && props.item.addCoupon!=true" @click="updateEditCoupon(props.item, true)">Add an Event Coupon</v-btn>
         <v-btn flat color="primary" v-if="props.item.hasOwnProperty('evCode') && props.item.addCoupon!=true" @click="updateEditCoupon(props.item, true)">Edit Coupon ({{props.item.evCode}})</v-btn>
           <v-text-field
           v-if="props.item.addCoupon==true"
           v-model="props.item.evCode"
           label="Add or edit Event Coupon Code"
           ></v-text-field>
           <v-btn color="primary" v-if="props.item.addCoupon==true" :disabled="props.item.evCode==''||props.item.evCode==undefined" @click="saveEvCode(props.item)">Save Coupon</v-btn>
       </div>
       </td>
       <td class="">{{ props.item.roleValue }} <span v-if="props.item.roleValue==15">({{props.item.code}})</span> </td>
       <td class="">{{ props.item.dateAdded | dateFormat}}</td>
       <td class="">{{ props.item.lastLogin | dateFormat }}</td>
       <td class=""><v-select
         :items="roles"
         v-model="props.item.roleValue"
         @change="setRoleValue(props.item['.key'],$event)"
       ></v-select></td>
       <td>{{props.item.school}}</td>
       <td><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/users/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
  <v-layout row justify-center>
    <v-dialog v-model="affiliateDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Coupon Code</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="coupon" label="Coupon" :error-messages="couponErr"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click.native="affiliateDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" flat @click.native="saveAffiliate">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Users',
  data: () => ({
    headers: [
      { text: 'Username', value: 'userName' },
      { text: 'Email', value: 'email' },
      { text: 'Real Name', value: 'name'},
      { text: 'Admin owner', value: 'admin'},
      { text: 'Event Coupon', value: 'evCoupon'},
      { text: 'Role Value', value: 'roleValue' },
      { text: 'Date joined', value: 'dateAdded' },
      { text: 'Last login', value: 'lastLogin' },
      { text: 'Set Role: ', value:'roleValue'},
      { text: 'School ', value:'school'},
      { text:'DB ID', value:'id'}


    ],
    affId:"",
    affiliateDialog:false,
    coupon:"",
    couponErr:[],
    search: null,
    searched: [],
    users: [],
    pagination:{
      descending: true
    },

  }),
  firebase() {
    return {
      admins:{
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(80),
      },
      users: {
        source:firebase.database().ref('users'),
        readyCallback:function(){
          console.log(this.users,'readyCallback')
          for (var i = 0; i < this.users.length; i++) {
            this.users[i].id=this.users[i]['.key']
          }
        }
      }
    }
  },
  methods: {
    setAdmin(item){
      console.log(item.admin,' the new admin');
      axios.patch('users/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{admin:item.admin})

    },
    updateEditCoupon(item, val){
      item.addCoupon=val
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    setRoleValue(id,val){
      console.log(id,val)
      if (val==15){
        this.affId=id
        this.affiliateDialog=true
      } else {
        axios.patch('users/'+id+'.json?auth='+this.$store.state.auth.idToken,{roleValue:val})
        .catch(err=>{
          console.log('error: ',err);
        })
      }
    },
    saveEvCode(item){
      console.log(item['.key'],item.evCode);
      axios.patch('users/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{evCode:item.evCode})
      .then(res=>{
        console.log(res);
        console.log(res.data);
      })
      .catch(err=>{
        console.log('error: ',err);
      })
      this.updateEditCoupon(item,false)
    },
    saveAffiliate(){
      this.couponErr=[]
      if (this.coupon!=""){
        this.affiliateDialog=false
        axios.patch('users/'+this.affId+'.json?auth='+this.$store.state.auth.idToken,{roleValue:15,code:this.coupon})
        .catch(err=>{
          console.log('error: ',err);
        })

      } else {
        this.couponErr.push("Add a coupon!!!")
      }
    }
  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    roles(){
      let t=[
        {text: 'Tenant', value:10},
        {text: 'Affiliate', value:15},
        {text: 'Landlord', value:20},
      ]
      if (this.user.roleValue>90){
        t.push({text: 'CPA', value:40},
          {text: 'Admin', value:80},
          {text: 'SuperAdmin', value:99}
        )
      }
      return t
    },

  },
  created() {
  },

  metaInfo() {
      return {
        title:'Users - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
