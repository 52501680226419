<template>
<div>
  <v-card>
    <v-card-title>
      Added Tenants
      <v-btn color="primary" :to="'/admin/addedTenants'">Confirmed Tenants</v-btn>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]' :headers="headers" :items="bookings" item-key=".key" :search="search" :pagination.sync="pagination">
      <template slot="items" slot-scope="props">
        <td>
          <span v-if="!props.item.confirmTimestamp">{{props.item.dateRequested | ts}}</span>
          <span v-else>{{props.item.confirmTimestamp | ts}}</span>
        </td>
        <td>
          <v-autocomplete :items="users" :loading="loadingUsers" v-model="props.item.tenantId" hide-details clearable item-text="email" item-value="id" label="Select tenant and validate it"></v-autocomplete>
          <v-btn color="primary" :disabled="props.item.disableSave" @click="saveTenant(props.item)">save & create rent record</v-btn>
        </td>
        <td>{{props.item.fullRoomNumber}}</td>
        <td>{{props.item.tenant.name}}<br><a :href="'mailto:'+props.item.tenant.email"></a>{{props.item.tenant.email}}</td>
        <td>
          {{props.item.dates.mid | ts}} -> {{props.item.dates.mod | ts}}
          <v-btn color="primary" @click="editChangeDate(props.item)">change Date</v-btn>
        </td>
        <td><span v-if="props.item.landlord">{{props.item.landlord.userName}}</span></td>
        <td>{{props.item.price}}</td>
        <td>{{props.item.status}}</td>
        <td><a :href="'https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/bookings/'+props.item['.key']"> {{props.item['.key']}}</a></td>
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </v-card>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="6000">
    {{snackbarText}}
    <v-btn flat icon @click.native="showSnackbar = false">close</v-btn>
  </v-snackbar>
  <v-dialog
    v-model="dateDialog"
    persistent :overlay="true"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title>
        Change Dates
      </v-card-title>
      <v-card-text>

      <v-menu
        ref="dateMenu"
        :close-on-content-click="false"
        v-model="dateMenu"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        v-if="currentItem.hasOwnProperty('dates')"
        full-width
        min-width="290px"
              >
          <v-text-field
            slot="activator"
            label="Move In Date"
            v-model="currentItem.dates.mid"
            prepend-icon="event"
            readonly
          ></v-text-field>
            <v-date-picker :locale="$vuetify.lang.current" v-model="currentItem.dates.mid" no-title  @input="$refs.dateMenu.save(currentItem.dates.mid)">
            </v-date-picker>
        </v-menu>
        <v-menu
          ref="dateMenu2"
          :close-on-content-click="false"
          v-model="dateMenu2"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          v-if="currentItem.hasOwnProperty('dates')"
          full-width
          min-width="290px"
                >
            <v-text-field
              slot="activator"
              label="Move Out Date"
              v-model="currentItem.dates.mod"
              prepend-icon="event"
              readonly
            ></v-text-field>
              <v-date-picker :locale="$vuetify.lang.current" v-model="currentItem.dates.mod" no-title  @input="$refs.dateMenu2.save(currentItem.dates.mod)">
              </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" flat @click="editChangeDate(currentItem)">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="saveDates(currentItem)">save</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import AWSSES from '../../aws-ses'


export default {
  name: 'Apartments',
  data: () => ({
    currentItem:{},
    dateDialog:false,
    dateMenu:false,
    dateMenu2:false,
    pagination: {
      descending: true
    },
    headers: [{
        text: 'Date',
        value: 'dateRequested'
      },
      { text:'Select Tenant', value:'tenantId'},
      {
        text: 'Room Number',
        value: 'fullRoomNumber'
      },
      {
        text: 'Tenant info',
        value: 'tenant.email'
      },
      {
        text: 'Contract Dates',
        value: 'dates.mid'
      },
      {
        text: 'Landlord',
        value: 'landlord.userName'
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Status',
        value: 'refund'
      },
      {
        text: 'DB Link',
        sortable: false
      },
    ],
    snackbarColor:null,
    snackbar:false,
    snackbarText:null,
    search: null,
    loadingUsers:true
  }),
  filters: {
    dateFormat: function(t) {
      return moment(t).format('l')
    },
    ts: function(x) {
      return moment(x).format('YYYY/MM/DD')
    },


  },

  firebase() {
    return {
      bookings: {
        source: firebase.database().ref('bookings').limitToLast(250),
        readyCallback: function() {
          this.bookings.map(x=>{
            x.changeDates=false
            return x
          })
        }
      },
      users: {
        source: firebase.database().ref('users'),
        readyCallback: function() {
          this.loadingUsers=false
          this.users.map(x=>{
            x.id=x['.key']
            return x
          })
        }

      },

    }

  },
  methods: {
    adminNotif(before,after,ftn,order){
      let emailToSend={
        admin:this.$store.state.auth.user.userName,
        function:ftn,
        date: moment().format("LLLL"),
        crId:order['.key'],
        before:before,
        after:after,
        room:order.fullRoomNumber,
        tenant:order.tenant.name,
      }
      console.log(emailToSend);
      let admin={
        name:'Sandrine',
        email:"sandrine@myroomabroad.com"
      }
      AWSSES.adminNotif(admin,emailToSend, 'ACREdited')
    },
    editChangeDate(item){
      this.dateDialog=!this.dateDialog
      if (item.changeDate){
        item.changeDates=!item.changeDates;
      } else {
        item.changeDates=false
      }
      console.log(item.changeDates)
      this.currentItem=item
    },
    saveDates(item){
      // console.log(item.dates);
      this.editChangeDate(item)
      axios.get('bookings/'+item['.key']+'.json')
      .then(res=>{
        let oldCR=res.data
        try {
          let before="MOVE IN: "+moment(oldCR.dates.mid).format("YYYY-MM-DD")+" ---  MOVE OUT: "+moment(oldCR.dates.mod).format('LL')
          let after="MOVE IN: "+moment(item.dates.mid).format("YYYY-MM-DD")+"  ---  MOVE OUT: "+moment(item.dates.mod).format('LL')
          let ftn="Change Dates"
          this.adminNotif( before, after, ftn, item)
        } catch (e) {
          console.log(e);
          alert('something happend with notifications, alert Vincent')
        }
        axios.patch('bookings/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{dates:item.dates})
      })
      axios.get('rents.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('rent',res.data);
        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            axios.patch('rents/'+Object.keys(res.data)[i]+'.json?auth='+this.$store.state.auth.idToken,{dates:item.dates})
            // console.log('rents/'+Object.keys(res.data)[i]+'.json?auth=',{dates:item.dates});
          }
        }
      })
      axios.get('transfTBM.json?orderBy="crId"&equalTo="'+item['.key']+'"')
      .then(res=>{
        console.log('TRansf',res.data);
        if (Object.keys(res.data).length>0){
          // console.log();
          for (var j = 0; j < Object.keys(res.data).length; j++) {
            axios.patch('transfTBM/'+Object.keys(res.data)[j]+'.json?auth='+this.$store.state.auth.idToken,{dates:item.dates})
            // console.log('transfTBM/'+Object.keys(res.data)[j]+'.json?auth=',{dates:item.dates});
          }
        }
      })
    },
    saveTenant(booking){
      console.log(booking);
      booking.disableSave=true
      if (booking.tenantId){
        axios.patch('users/' + booking.tenantId + '.json' + '?auth=' + this.$store.state.auth.idToken, {bookingRequestOut:booking['.key'], orderPaid:true, unpaidInvoice:true})
        .then(res=>{
          console.log("SUCCESS user patch");
        })
        .catch(err=>{
          console.error(err);
        })
        axios.patch('bookings/' + booking['.key'] + '.json' + '?auth=' + this.$store.state.auth.idToken, {tenantId:booking.tenantId, status:"Confirmed"})
        this.makeRent(booking)
      }
    },
    makeRent(order){
      order.disableMakeRent=true
      let t={
        crId:order['.key'],
        comment:"First rent payment",
        type:order.type,
        tenantId:order.tenantId,
        realDates:order.realDates,
        llPrice:order.llPrice,
        price:order.price,
        perc:1,
        listingId:order.listingId,
        landlordId:order.landlordId,
        reccRent: order.reccRent,
        invoiceSent:true,
        dates:order.dates,
        roomNumber:order.fullRoomNumber,
        listUtil:order.listUtil,
        utilities:order.utilities,
        lastRent:moment().date(moment(order.dates.mod).date()).subtract(1, 'Month').format('YYYYMMDD'),
      }
      console.log(t);
      axios.post('rents.json?auth='+this.$store.state.auth.idToken,t)
      .then(()=>{
        // this.sendRentEmail(order)
        alert('Added Rent')
      })
      .catch(e=>{
        console.error(e);
        alert("Couldn't add the rent")
      })

    },
    sendRentEmail(order){
      let mid=order.dates.mid
      let mod=order.dates.mod
      console.log(order.tenant);
      //check if full months
      if (moment(mid).format('D')!= moment(mod).add(1,"d").format('D')){
        // This means it's not complete months.
        if (parseInt(moment(mid).format('D'))<=10){
          console.log('send email 3 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo3')
        } else if (parseInt(moment(mid).format('D'))<=25){
          console.log('send email 5 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo5')
        } else {
          console.log('send email 2 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo2')
        }
      } else {
        if (10 < parseInt(moment(mid).format('D')) && parseInt(moment(mid).format('D')) <= 25) {
          console.log('send email 4 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo4')
        } else {
          console.log('send email 1 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo1')
        }
      }

    },


  },
  created() {},
  metaInfo() {
    return {
      title: 'Bookings - My Room Abroad Admin' // set a title
    }
  },


}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.draft {
    color: grey;
}
</style>
