<template>
<div>
  <v-card>
   <v-card-title>
     Tickets
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="tickets"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td>{{props.item.time | dateFormat}}</td>
       <td class=""> <span v-if="users.hasOwnProperty(props.item.owner)"> <a :href="'/profile?id='+props.item.owner">{{users[props.item.owner].userName }}</a> ({{users[props.item.owner].email}})</span> <span v-else>{{props.item.owner}}</span> </td>
       <td class="">{{ props.item.price }}TWD</td>
       <td class="" v-if="events.hasOwnProperty(props.item.eventId)"> <a :href="'/event/'+props.item.eventId">{{events[props.item.eventId].title}}</a> </td>
       <td class="" v-if="events.hasOwnProperty(props.item.eventId)"> {{events[props.item.eventId].start | dateTime}}</a> </td>
       <td class="" v-if="events.hasOwnProperty(props.item.eventId) && events[props.item.eventId].tickets.hasOwnProperty(props.item.type)">{{events[props.item.eventId].tickets[props.item.type].name}} </td>
       <td><a :href="'https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/tickets/'+props.item['.key']">{{props.item['.key']}}</a> </td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
  <v-layout row justify-center>
    <v-dialog v-model="affiliateDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Coupon Code</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="coupon" label="Coupon" :error-messages="couponErr"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click.native="affiliateDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" flat @click.native="saveAffiliate">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Users',
  data: () => ({
    headers: [
      { text: 'Date bought', value: 'time' },
      { text: 'Name', value: 'username' },
      { text: 'Price', value: 'Price'},
      { text: 'Event', value: 'eventTitle' },
      { text: 'Event Start', value: 'start' },
      { text: 'Ticket type', value: 'typeName' },

    ],
    pagination: {
      descending: true
    },
    affId:"",
    affiliateDialog:false,
    coupon:"",
    couponErr:[],
    search: null,
    searched: [],
    users: [],
    roles:[
      {text: 'Tenant', value:10},
      {text: 'Affiliate', value:15},
      {text: 'Landlord', value:20},
      {text: 'CPA', value:40},
      {text: 'Admin', value:80},
      {text: 'SuperAdmin', value:99},
    ],
  }),
  firebase() {
    return {
      tickets: {
        source:firebase.database().ref('tickets').orderByChild('time').limitToLast(300),
        readyCallback:function(){
          this.matchTicketsUsers()
          this.matchTicketsEvents()
        }
      },
      users: {
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){
          this.matchTicketsUsers()
        }
      },
      events: {
        source:firebase.database().ref('events').orderByChild('start').limitToLast(30),
        asObject:true,
        readyCallback:function(){
          this.matchTicketsEvents()
        }
      },    }
  },
  methods: {
    matchTicketsUsers(){
      if (this.users!=null && this.tickets!=undefined){
        for (var i = 0; i < this.tickets.length; i++) {
          if (this.users.hasOwnProperty(this.tickets[i].owner)){
            this.tickets[i].username=this.users[this.tickets[i].owner].userName
          }
        }
      }
    },
    matchTicketsEvents(){
      if (this.events!=null && this.tickets!=undefined){
        for (var i = 0; i < this.tickets.length; i++) {
          if (this.events.hasOwnProperty(this.tickets[i].eventId)){
            this.tickets[i].eventTitle=this.events[this.tickets[i].eventId].title
            this.tickets[i].start=this.events[this.tickets[i].eventId].start
            this.tickets[i].typeName=this.events[this.tickets[i].eventId].tickets[this.tickets[i].type].name

          }

        }
      }
    }
  },
  filters: {
    dateFormat: function(t) {
      if (t!=undefined){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    },
    dateTime: function(t) {
      if (t!=undefined){
        return moment.tz(t,"Asia/Taipei").format('lll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
  },
  metaInfo() {
      return {
        title:'Tickets - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
