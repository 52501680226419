<template>
<div>
  <v-card>
   <v-card-title>
     Invoices
     <v-btn color="primary" @click="addInvoice=true">Add New Invoice</v-btn>
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="invoices"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td>{{props.item.ts | dateFormat}}</td>
       <td>{{props.item | status}}</td>
       <td>{{props.item.ref}}</td>
       <td class="" >
         <div class="" v-if="users.hasOwnProperty(props.item.user)">

           <a :href="'/profile?id='+props.item.user"><span v-if="users[props.item.user].hasOwnProperty('name')">{{users[props.item.user].name }}</span><span v-else>{{users[props.item.user].userName }}</span></a> ({{users[props.item.user].email}})
         </div>
       </td>
       <td class="">{{ props.item.val }}TWD</td>
       <td class="">{{ props.item.comment}}</td>
       <td class="">
         <v-btn color="primary" :disabled="props.item.disabled" v-if="props.item.paid!=true" @click="setPaid(props.item)">Set Paid</v-btn><span v-else>Paid</span>
         <br><v-btn color="error" v-if="props.item.paid!=true" :disabled="props.item.disabled" @click="cancelInvoice(props.item)">Cancel invoice</v-btn><v-btn color="#ff4000" dark v-else @click="undo(props.item)">undo</v-btn>
       </td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
 <v-dialog
   v-model="addInvoice"
   max-width="500px"
 >
 <AddInvoice @close="addInvoice =false"></AddInvoice>
 </v-dialog>

</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import AddInvoice from './AddInvoice.vue'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Users',
  data: () => ({
    headers: [
      { text: 'Date', value: 'ts' },
      { text: 'status', value: 'ts' },
      { text: 'Ref', value: 'ref'},
      { text: 'User', value: 'username' },
      { text: 'Amount', value: 'val' },
      { text: 'Comment', value: 'comment' },
      { text: 'Set Paid', value:'paid' },
    ],
    pagination: {
      descending: true
    },
    search: null,
    searched: [],
    addInvoice:false
  }),
  firebase() {
    return {
      invoices: {
        source:firebase.database().ref('invoices').orderByChild('ts').limitToLast(100),
        readyCallback:function(){
          this.matchInvUsers()
        }
      },
      users: {
        source:firebase.database().ref('users').orderByChild('hasInvoice').equalTo(true),
        asObject:true,
        readyCallback:function(){
          this.matchInvUsers()
        }
      },
    }
  },
  methods: {
    setPaid(inv){
      inv.disabled=true
      inv.paid=true
      axios.patch('invoices/'+inv['.key']+'.json?auth='+this.$store.state.auth.idToken,{paid:true})
      .then(()=>{
        inv.disabled=false
      })
      .catch(err=>{
        inv.disabled=false
        console.log('error: ', err);
        inv.paid=false
      })
    },
    undo(inv){
      inv.paid=false
      axios.patch('invoices/'+inv['.key']+'.json?auth='+this.$store.state.auth.idToken,{paid:false})
      .catch(err=>{
        console.log('error: ', err);
        inv.paid=true
      })
    },
    cancelInvoice(inv){
      inv.disabled=true
      let index=this.invoices.findIndex(item=>{item['.key']==inv['.key']})
      axios.delete('invoices/'+inv['.key']+'.json?auth='+this.$store.state.auth.idToken)
      .then(()=>{
        console.log('Success')
      })
      .catch(err=>{
        console.log('error ', err);
      })
    },
    matchInvUsers(){
      if (this.users!=null && this.invoices!=undefined){
        for (var i = 0; i < this.invoices.length; i++) {
          if (this.users.hasOwnProperty(this.invoices[i].user)){
            this.invoices[i].username=this.users[this.invoices[i].user].userName
          }
        }
      }
    },
  },
  filters: {
    dateFormat: function(t) {
      if (t!=undefined){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    },
    status: function(t){
      if (t.paid==true){
        return 'Paid'
      } else {
        return 'Unpaid: due date: '+moment(t.ts).add(1,'week').format("LL")
      }
    },
    dateTime: function(t) {
      if (t!=undefined){
        return moment.tz(t,"Asia/Taipei").format('lll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
  },
  components: {
    AddInvoice,
  },
  metaInfo() {
      return {
        title:'Invoices - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
