<template>
<div style="max-width:100vw">

  <Navbar></Navbar>
  <v-toolbar dense dark color="primary">
      <v-toolbar-side-icon dark @click="drawer=!drawer"></v-toolbar-side-icon>

      <v-toolbar-title >
        <v-list-tile href='/admin'>
          <v-list-tile-title class="title">
            Admin Menu
          </v-list-tile-title>
        </v-list-tile>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items  class="hidden-sm-and-down">
          <v-list-tile dark class="hover" v-for="(item,index) in linkList" :key="index" :href="item.path">
            <v-list-tile-title>{{item.text}}</v-list-tile-title>
          </v-list-tile>

      </v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer class="scrollable" absolute temporary v-model="drawer">
      <v-toolbar color="primary" dark flat >
        <v-list>
          <v-list-tile>
            <v-list-tile-title class="title">
              Take your pick
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense class="pt-0">
        <v-list-tile v-for="(item,index) in drawerLinkList" :key="index" :href="item.path">
          <v-list-tile-avatar>
            <v-icon >{{ item.icon }}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>{{item.text}}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-container grid-list-xs>
      <router-view class=""></router-view>
    </v-container>
</div>
</template>

<script>
export default {
  name: 'PersistentFull',
  data: () => ({
    drawer:false,
    linkList:[
      {
        path:"/admin/issues",
        text:'Issues'
      },
      {
        path:"/admin/tickets",
        text:'Tickets'
      },
      {
        path:"/admin/rent",
        text:'Rent'
      },{
        path:"/admin/deposit",
        text:'Deposits'
      },
      {
        path:"/admin/transfersTBM",
        text:'Transfers TBM'
      },
      {
        path:"/admin/bookingRequests",
        text:'BR'
      },
      {
        path:"/admin/completedRequests",
        text:'CR'
      },
      {
        path:"/admin/bookings",
        text:'Added Tenants'
      },
      {
        path:"/admin/landlords",
        text:'LL'
      },
    ],

    drawerLinkList: [
      {
        icon:"bug_report",
        path:"/admin/issues",
        text:'Issues'
      },
      {
        icon:"euro_symbol",
        path:"/admin/rent",
        text:'Rent'
      },
      {
        icon:"folder",
        path:"/admin/deposit",
        text:'Deposits'
      },{
        icon:"assignment_late",
        path:"/admin/bookings",
        text:'Added Tenants Requests'
      },
      {
        icon:"assignment_turned_in",
        path:"/admin/addedTenants",
        text:'Added Tenants Conf'
      },
      {
        path:"/admin/tickets",
        icon:"print",
        text:'Tickets'
      },
      {
        icon:"search",
        path:"/admin/visits",
        text:'Visits'
      },
      {
        icon:"account_balance",
        path:"/admin/transfersTBM",
        text:'Transfers To Be Made'
      },
      {
        icon:"house",
        path:"/admin/listings",
        text:'Listings'
      },{
        icon:"add_shopping_cart",
        path:"/admin/visitPayments",
        text:'Visit Payments'
      },
      {
        icon:"alarm_on",
        path:"/admin/bookingRequests",
        text:'Booking Requests'
      },
      {
        icon:"check",
        path:"/admin/completedRequests",
        text:'Completed bookings'
      },
      {
        icon:"people",
        path:"/admin/users",
        text:'Users'
      },
      {
        icon:"person",
        path:"/admin/landlords",
        text:'Landlords'
      },
      {
        icon:"domain",
        path:"/admin/apartments",
        text:'Apartments'
      },
      {
        path:"/admin/payments",
        icon:"receipt",
        text:'Payments'
      },
      {
        path:"/admin/payin",
        icon:"input",
        text:'Pay In'
      },
      {
        path:"/admin/rankings",
        icon:"grade",
        text:'Rankings'
      },
      {
        path:"/admin/FuckYouSeb",
        icon:"history",
        text:'Retargetting'
      },
      {
        icon:"money_off",
        path:"/admin/coupons",
        text:'Coupons'
      },{
        icon:"folder",
        path:"/admin/invoices",
        text:'Invoices'
      },
      {
        icon:"message",
        path:"/admin/lineFollows",
        text:'Line Follows'
      },
      {
        icon:"outdoor_grill",
        path:"/admin/employees",
        text:'Employees'
      },

      {
        icon:"timeline",
        path:"/admin/analysis",
        text:'Analysis'
      },
      {
        icon:"cancel_presentation",
        path:"/admin/cancel",
        text:'Cancellations'
      },


      {
        icon:"cloud_download",
        path:"/admin/seo",
        text:'SEO JSON Files'
      },

    ]


  }),
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible
    }
  },
  computed: {
    smallScreen: function() {

      if ( screen.width <= 600) {
        return true
      } else {
        return false
      }
    }
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.scrollable{
  max-height: 100vh;
  // overflow-y: scroll;
}
.toolbar-text{
  color:#FFFFFF !important;
}
.hover:hover{
  background-color:rgba(255,255,255,.2);

}
// Demo purposes only
.md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
    float:left;
}
</style>
