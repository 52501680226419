<template lang="html">
  <v-layout row justify-center>
    <v-dialog v-model="show" persistent >
      <v-card>
        <v-card-title >
          <span class="headline ml-2">Past Rents</span>
        </v-card-title>
        <v-card-text>
            <v-data-table :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]' :headers="headers" :items="rents" :search="search" :pagination.sync="pagination">
              <template slot="items" slot-scope="props">
                <td>{{props.item.roomNumber}}</td>
                <td>
                  <span v-if="users[props.item.landlordId]==undefined" > Waiting for users to load</span>
                  <span v-else-if="users[props.item.landlordId].name!=undefined">{{users[props.item.landlordId].name}}</span>
                  <span v-else-if="users!=undefined">{{users[props.item.landlordId].userName}}</span>
                  <span v-else>No user</span>
                </td>
                <td>
                  <span v-if="users[props.item.tenantId]==undefined" > Waiting for users to load</span>
                  <span v-else-if="users[props.item.tenantId].name!=undefined">{{users[props.item.tenantId].name}}</span>
                  <span v-else-if="users!=undefined">{{users[props.item.tenantId].userName}}</span>
                  <span v-else>No user</span>
                </td>
                <td> {{props.item.dates.mid |dateFormat}} => {{props.item.dates.mod |dateFormat}}</td>
                <td> {{props.item.lastRent | lastRent}}</td>
                <td>
                  <v-btn color="primary" :disabled="props.item.deleting" @click="remove(props.item)">Remove</v-btn>
                </td>
                <td>
                  <v-menu
                    :close-on-content-click="false"
                    v-model="props.item.editingMOD"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="props.item.newDate"
                      label="New MOD"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker v-model="props.item.newDate" @input="props.item.editingMOD = false"></v-date-picker>
                  </v-menu>

                  <v-btn color="#FF4000" dark v-if="props.item.newDate!=undefined" @click="saveDate(props.item)">Save Dates</v-btn>
                </td>
              </template>
            </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="changeShow">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from 'moment'
import {
  required,
  maxLength,
  minLength
} from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  props:['show','rents','users'],
  data: () => ({
    search:null,
    cRent:{},
    pagination: {
      descending: true
    },
    headers: [
      { text: 'Room Number', value: 'roomNumber' },
      { text: 'Landlord', value: 'landlord' },
      { text: 'name', value: 'tenant.name' },
      { text: 'Dates', value: 'dates.mod'},
      { text: 'Last Paid month', value:'lastMonth'},
      { text: 'Delete Rent', sortable:false },
      { text: 'Change Move out date',sortable:false },
    ],
  }),
  methods: {
    changeShow(){
      this.$emit('changeShow')
    },
    remove(rent){
      // console.log(rent);
      axios.delete('rents/' + rent['.key'] + '.json?auth=' + this.$store.state.auth.idToken)
      this.$emit('deleteRent',rent)
    },
    saveDate(rent){
      rent.dates.mod=rent.newDate
      axios.patch('rents/' + rent['.key'] + '/dates.json?auth=' + this.$store.state.auth.idToken,{mod:rent.newDate})
      rent.newDate=undefined
      let index = this.rents.findIndex(item => item['.key'] == rent['.key']);
      this.rents.splice(index,1)
    }
  },
  computed:{
    valueSuffix(){
      if (this.absVal==true){
        return 'TW$'
      } else {
        return '%'
      }
    },
  },
  filters:{
    dateFormat: function(t) {
      if (t) {
        return moment(t).tz('UTC').format('ll')
      } else {
        return "Nothing to show"
      }

    },
    lastRent: function(t){
      if (moment(t,'MMYYYY').isValid()){
        return moment(t,'MMYYYY').format('MMMM')
      } else if (moment(t,'YYYYMMDD')){
        return  moment(t,'YYYYMMDD').format('MMMM')
      }
    }

  },
  created() {
    //do something after creating vue instance

  }



}
</script>

<style lang="scss" scoped>
</style>
