<template>
<div>
  <v-card>
   <v-card-title>
      <span>Retargetting <span class="hasBR">This means it has a BR</span></span>
      <v-btn color="primary" :disabled="noMove" @click="deleteFromUsers()">delete from users</v-btn>
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
     <v-layout wrap>
       <v-flex xs4 sm3 md2>
         <v-select
         label="Date Filter Item"
         :items="dateFilterOpts"
         v-model="dateFilterVal"
         ></v-select>
       </v-flex>
       <v-flex xs6 sm3 md2>
         <v-menu
         v-model="menu1"
         :close-on-content-click="false"
         :nudge-right="40"
         transition="scale-transition"
         offset-y
         full-width
         min-width="290px"
         >
            <v-text-field
            slot="activator"
             v-model="start"
             label="Start"
             prepend-icon="event"
             readonly
           ></v-text-field>
           <v-date-picker v-model="start" @input="menu1 = false"></v-date-picker>
         </v-menu>

       </v-flex>
       <v-flex xs6 sm3 md2>
         <v-menu
         v-model="menu2"
         :close-on-content-click="false"
         :nudge-right="40"
         transition="scale-transition"
         offset-y
         full-width
         min-width="290px"
         >
        <v-text-field
         slot="activator"
         v-model="end"
         label="End"
         prepend-icon="event"
         readonly
         ></v-text-field>
         <v-date-picker v-model="end" @input="menu2 = false"></v-date-picker>
       </v-menu>
      </v-flex>
      <v-flex xs4 sm3 md2>
        <v-select
          label="Filter"
          :items="filterOpts"
          v-model="filterVal"
          @change="count"
        ></v-select>
      </v-flex>
      <v-flex xs4 sm3 md2>
        <v-select
          label="Not This filter"
          clearable
          :items="filterOpts"
          v-model="filterValNot"
          @change="count"
        ></v-select>
      </v-flex>

      <v-btn color="primary" @click="count">count</v-btn>
      <v-flex xs12>
        number of users: {{counter}}
      </v-flex>
     </v-layout>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="tUsers"
     :search="search"
     :loading="!loaded"
     item-key="id"
   >
     <template slot="items" v-if="loaded" slot-scope="props">
       <tr @click="props.expanded = !props.expanded">
         <!-- <td >
           <v-select
           v-if="props.item.retarget!=undefined"
           :items="items"
           v-model="props.item.retarget.value"
           @input="setRT(props.item)"
           ></v-select>
         </td> -->
         <td :class="{hasBR:props.item.hasBR, hasCR:props.item.hasCR}"><a :href="'/profile?id='+props.item.id">{{ props.item.userName }} </a>   <span v-if="props.item.currentBR==true">(CURRENT BR)</span>  </td>
         <td> {{ props.item.email }}
           <span v-if="props.item.communication"><br>communication: <span class="bold">{{props.item.communication}}</span></span>
           <span v-if="props.item.appId"><br> App: {{props.item.appId}}</span>
           <a v-if="props.item.communication=='WhatsApp'" :href="'https://api.whatsapp.com/send?phone='+props.item.appId" target="blank"  > <br>open whatsapp</a>
           <span v-if="props.item.lineId"><br>line: {{props.item.lineId}}</span>
           <span v-if="props.item.facebook"><br><a :href="props.item.facebook" target="blank">fb link</a></span>
           <span v-if="props.item.phone"><br>Phone: {{props.item.phone}}</span>
         </td>

         <td><span v-if="props.item.rts!=undefined">Last email sent: RTS series {{props.item.rts}} - {{props.item.rtsTS | humanDate}} ago</span> <span v-else>Send your first email</span>
            <br> <v-btn v-if="props.item.rts!='found'" :disabled="props.item.rts>29"color="primary" @click="sendNextEmail(props.item)">SEND</v-btn>
            <br><v-btn v-if="props.item.rts!='found'" color="primary" @click="setFound(props.item)">FOUND A ROOM!</v-btn>
            <v-btn v-else disabled color="primary" >Already Found!</v-btn>

         </td>
         <td class="">{{ props.item.name }}
           <br> <v-btn color="#ff4000" dark @click="sendOhNo(props.item)">Room Sold</v-btn>
         </td>
         <td>
           <span v-if="props.item.hasCR==true"> Has CR ({{props.item.numberBR}})</span>
           <span v-else-if="props.item.hasBR==true"> Has No CR, {{props.item.numberBR}} BR made </span>
           <span v-else> No CR no BR</span>
         </td>
         <td class="">
          <br>{{ props.item.numberBook }}</td>
         <td class="">{{ props.item.numberSeen }}</td>
         <td class="">{{ props.item.dateAdded | dateFormat}}</td>
         <td class="">{{ props.item.lastLogin | dateFormat}}</td>
         <td class=""><div class="" v-if="props.item.query!=undefined">
           <span v-if="props.item.query.mid!=''">{{ props.item.query.mid | mid}},</span>
           <span v-if="props.item.query.name!=''"><br>@ {{props.item.query.name}}</span>
           <span v-if="props.item.query.price!=''"><br>TW${{props.item.query.price}}</span>
         </div>
         </td>

         <td>{{props.item.hasBR}}</td>
       </tr>
     </template>

   <template slot="expand" slot-scope="props">
     <v-card flat>
       <v-card-text>{{props.item.tAbout}}

         <v-layout row wrap>
           <v-flex xs12 v-if="props.item.query!=undefined">
             <v-layout row wrap>
               <!-- Search Type: {{props.item.query.view}} -->
               <!-- <v-flex class="" v-for="(el,id) in props.item.query" :key="props.item['.key']+ id">
                 {{id}}: {{el}}
               </v-flex> -->
             </v-layout>
           </v-flex>
           <v-flex xs6>
             <h3 class="heading">Booking Pages</h3>
             <v-data-table
               :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
               :headers="headers2"
               :items="props.item.br"
               header-key="id"
               item-key="id"
             >
               <template slot="items" slot-scope="props">
                 <tr @click="props.expanded = !props.expanded" >
                   <td><a :href="props.item.link">
                     <div class="" v-if="props.item.type=='rooms'">{{roomApts[props.item.id].general.name}} (apt with rooms) </div>
                     <div class="" v-else-if="props.item.type=='apartment'">{{apts[props.item.id].general.name}}  (apt) - {{apts[props.item.id].billing.price}}TWD -  <span class="bold">({{apts[props.item.id].general.nextAvailability | dateFormat}})</span> </div>
                     <div class="" v-else-if="props.item.type=='room'">{{rooms[props.item.id].general.fullRoomNumber}} (Room)  - {{rooms[props.item.id].billing.price}}TWD - <span class="bold">({{rooms[props.item.id].general.nextAvailability | dateFormat}})</span> </div>
                     <div class="" v-else-if="props.item.type=='studio'">{{studios[props.item.id].general.fullRoomNumber}} (studio)  - {{studios[props.item.id].billing.price}}TWD - <span class="bold"> ({{studios[props.item.id].general.nextAvailability | dateFormat}}) </span></div>
                     <div class="" v-else>{{props.item.type}}</div>

                   <!-- {{props.item.type}} -->
                   </a></td>
                   <td class="">{{ props.item.opens }}</td>
                   <td class="">{{ props.item.ts | dateFormat}}</td>
                 </tr>
               </template>

             </v-data-table>

           </v-flex>
           <v-flex xs6>

             <h3 class="heading">Room Pages</h3>
             <v-data-table
               :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
               :headers="headers2"
               :items="props.item.rs"
             >
               <template slot="items" slot-scope="props">
                 <tr @click="props.expanded = !props.expanded">
                   <td><a :href="props.item.link">
                     <div class="" v-if="props.item.type=='rooms'">{{roomApts[props.item.id].general.name}} (apt with rooms) </div>
                     <div class="" v-else-if="props.item.type=='apartment'">{{apts[props.item.id].general.name}}  (apt) - {{apts[props.item.id].billing.price}}TWD -  <span class="bold">({{apts[props.item.id].general.nextAvailability | dateFormat}})</span> </div>
                     <div class="" v-else-if="props.item.type=='room'">{{rooms[props.item.id].general.fullRoomNumber}} (Room)  - {{rooms[props.item.id].billing.price}}TWD - <span class="bold">({{rooms[props.item.id].general.nextAvailability | dateFormat}})</span> </div>
                     <div class="" v-else-if="props.item.type=='studio'">{{studios[props.item.id].general.fullRoomNumber}} (studio)  - {{studios[props.item.id].billing.price}}TWD - <span class="bold"> ({{studios[props.item.id].general.nextAvailability | dateFormat}}) </span></div>
                     <div class="" v-else>{{props.item.type}}</div>
                      <!-- {{props.item.type}} -->
                   </a></td>
                   <td class="">{{ props.item.opens }}</td>
                   <td class="">{{ props.item.ts | dateFormat}}</td>
                 </tr>
               </template>

             </v-data-table>
           </v-flex>
         </v-layout>
       </v-card-text>
     </v-card>
   </template>

     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import AWSSES from '../../aws-ses'


export default {
  name: 'Retargetting',
  data: () => ({
    noMove:false,
    tUsers:[],
    headers: [
      // { text: 'Retargetting Stage', value: 'retarget.value'},
      { text: 'Name', value: 'userName'},
      { text: 'User Info', value: 'communication' },
      { text: 'Retarget 2', value: 'rts' },
      { text: 'Real Name', value: 'name' },
      { text: 'Request info', value:'numberBR'},
      { text: '# booking pages', value: 'numberBook' },
      { text: '# room pages', value: 'numberSeen' },
      { text: 'Date joined', value: 'dateAdded' },
      { text: 'Last login', value: 'lastLogin' },
      {text:' query', value: 'hasBR'},
      {text:' Has BR', value: 'hasBR'}
    ],
    headers2:[
      { text: 'Room Name', sortable:false},
      { text: '# opens', value: 'opens'},
      { text:'last visit', sortable:false}
    ],
    items:[
      {text:"Seb, Do Something (0)",value:0},
      {text:"Seb, Do More (1)",value:1},
      {text:"Seb, You da bitch (3)",value:2},
      {text:"ok Seb, You can give up (4)",value:3},
    ],
    loaded:false,
    usersLoaded:false,
    search: null,
    emailsOpensLoaded:false,
    rooms:[],
    searched: [],
    allUsers:[],
    eOpens:[],
    counter:0,
    start:"",
    end:"",
    filterVal:"",
    filterValNot:"",
    dateFilterVal:"",
    dateFilterOpts:['Last login','Date Added','Query MID','Confirm request date','Contract Start Date', 'Real Start Date'],
    filterOpts:['hasQuery','hasBR','hasCR', 'hasName','hasID'],
    menu1:false,
    menu2:false,
  }),

  firebase() {
    return {
      brs: {
        source:firebase.database().ref('bookingRequests'),
      },
      users: {
        source:firebase.database().ref('users').orderByChild('roleValue'),
        asObject:true,
        readyCallback:function(){
          console.log(this.users,'readyCallback')
          // this.allUsers=JSON.parse(JSON.stringify(this.users))
          this.usersLoaded=true
          if (this.emailsOpensLoaded){
            this.processEmailOpens()
          }
          this.matchSeenUsers()
          this.matchBookUsers()
          let remove=[]
          for (var i in this.users) {
            if (this.users.hasOwnProperty(i) && this.users[i].hasOwnProperty('email')) {
              this.users[i].rs=[]
              this.users[i].br=[]
              this.users[i].id=i
              if (this.users[i].hasOwnProperty('bookingRequestOut')){
                this.users[i].hasBR=true
              } else {
                this.users[i].hasBR=false
              }
              // console.log('got here');
              if (this.users[i].retarget==undefined || this.users[i].retarget.value==undefined){
                this.users[i].retarget={
                  value:0,
                  ts:null
                }
                this.users[i].rValue
              }
              if (this.users[i].hasOwnProperty('bookingRequestOut')){
                this.users[i].hasBR=true
                this.countBR(this.users[i])
                this.checkIfhasCR(this.users[i])
              } else {
                this.users[i].hasBR=false
                this.users[i].hasCR=false
              }
            } else {
              // console.log('Delete: ',this.users[i]);
              delete this.users[i]
            }
          }
          // this.loaded=true
          // this.tUsers=JSON.parse(JSON.stringify(this.users))

            // this.users[i].rs=this.setUniqueLinks(this.users[i].seen)
            // this.users[i].br=this.setUniqueLinks(this.users[i].book)
            // // console.log(this.users[i].rs);
            // if (this.users[i].book!=undefined){
            //   this.users[i].numberBook=Object.keys(this.users[i].book).length
            // } else {
            //   this.users[i].numberBook=0
            // }

          // }
          // remove.reverse()
          // // console.log(this.users.length);
          // for (var j = 0; j < remove.length; j++) {
          //   // console.log(remove.length, remove[j]
          //
          //   this.users.splice(remove[j],1)
          //
          }
          // console.log(this.users);


      },
      seen: {
        source:firebase.database().ref('rSeen').orderByChild('ts').startAt(moment().subtract(4,'days').format()),
        // asObject:true,
        readyCallback:function(){
          // console.log('Seen - ' + this.seen.length);
          this.matchSeenUsers()
        }
      },
      //.orderByChild('ts').startAt(moment().subtract(7,'days').format())
      book: {
        source:firebase.database().ref('rBook'),
        // asObject:true,
        readyCallback:function(){
          // console.log('Books - ' + this.book.length);
          this.matchBookUsers()
        }
      },
      roomApts:{
        source:firebase.database().ref('apartments'),
        asObject:true
      },
      rooms:{
        source:firebase.database().ref('rooms'),
        asObject:true
      },
      studios:{
        source:firebase.database().ref('studios'),
        asObject:true,
      },
      apts:{
        source:firebase.database().ref('wholeApts'),
        asObject:true,
      },

      emailOpens:{
        source:firebase.database().ref('eOpens'),
        readyCallback:function(){

          this.eOpens=JSON.parse(JSON.stringify(this.emailOpens))
          for (var i = 0; i < this.emailOpens.length; i++) {
            axios.delete('eOpens/'+this.emailOpens[i]['.key']+'.json')
          }
          this.emailsOpensLoaded=true
          if (this.usersLoaded){
            this.processEmailOpens()
          }
        }
      }


    }

  },
  methods: {
    getAllIndexes(arr, val) {
      let indexes = []
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].user==val){
          indexes.push(i);
          // console.log(i);
        }
      }
      return indexes;
    },
    dateCheck(user,item){
      if(item=="query.mid"){
        if (moment(user['query']['mid']).isBetween(moment(this.start),moment(this.end))){
          return true
        } else {
          return false
        }
      } else {
        // console.log(user[item], item, user);
        if (moment(user[item]).isBetween(moment(this.start),moment(this.end))){
          return true
        } else {
          return false
        }
      }
    },
    checkDates(users){
      // console.log('the dates:',this.start,this.end);
      switch (this.dateFilterVal) {

        case 'Last login':
          return users.filter(item=>this.dateCheck(item, 'lastLogin'))
          break;
        case 'Date Added':
          return users.filter(item=>this.dateCheck(item, 'dateAdded'))
          break;
        case 'Query MID':
          users=users.filter(item=>this.hasQuery(item))
          return users.filter(item=>this.dateCheck(item, 'query.mid'))
          break;
        case 'Confirm request date':
          users=users.filter(item=>this.hasCR(item))
          // console.log(users.length, 'After Has CR');
          return users.filter(item=>this.dateCheck(item,'crDate'))
          break;
        case 'Contract Start Date':
          users=users.filter(item=>this.hasCR(item))
          // console.log(users.length, 'After Has CR');
          return users.filter(item=>this.dateCheck(item,'cMid'))
          break;

        case 'Real Start Date':
          users=users.filter(item=>this.hasCR(item))
          // console.log(users.length, 'After Has CR');
          return users.filter(item=>this.dateCheck(item,'rMid'))
          break
        default:
          return users
      }
    },

    doFilter(users){
      switch(this.filterVal){
        case 'hasBR':
          return users.filter(item=>this.hasBR(item))
          break;
        case 'hasCR':
          return users.filter(item=>this.hasCR(item))
          break;
        case 'hasQuery':
          return users.filter(item=>this.hasQuery(item))
          break;
        case 'hasName':
          return users.filter(item=>this.hasName(item))
          break;
        case 'hasID':
          return users.filter(item=>this.hasID(item))
          break;
        default:
          return users
      }
    },
    doNotFilter(users){
      switch(this.filterValNot){
        case 'hasBR':
          return users.filter(item=>!this.hasBR(item))
          break;
        case 'hasCR':
          return users.filter(item=>!this.hasCR(item))
          break;
        case 'hasQuery':
          return users.filter(item=>!this.hasQuery(item))
          break;
        case 'hasName':
          return users.filter(item=>!this.hasName(item))
          break;
        case 'hasID':
          return users.filter(item=>!this.hasID(item))
          break;
        default:
          return users

      }
    },
    hasBR(item){
      if (item.hasBR==true){
        return true
      } else {
        return false
      }
    },
    hasCR(item){
      if (item.hasCR==true){
        return true
      } else {
        return false
      }
    },
    hasQuery(item){
      if (item.hasOwnProperty('query')){
        return true
      } else {
        return false
      }
    },
    hasName(item){
      if (item.hasOwnProperty('name') && item.name!=""){
        return true
      } else {
        return false
      }
    },
    hasID(item){
      if (item.hasOwnProperty('passport') && item.passport!=""){
        return true
      } else {
        return false
      }
    },

    count(){
      this.tUsers=JSON.parse(JSON.stringify(this.allUsers))
      this.tUsers=this.doFilter(this.tUsers)
      this.tUsers=this.doNotFilter(this.tUsers)
      // console.log(this.tUsers.length,'afterDoFilter');
      this.tUsers=this.checkDates(this.tUsers)
      // console.log(this.tUsers.length,'after Check Dates');
      this.counter=this.tUsers.length
      // this.setBRnumbers()
    },

    countBR(user){
      let t=this.brs.filter(item=>user['id']==item.tenantId)

      // console.log(t, 'br', user['id']);
      for (var i = 0; i < t.length; i++) {
        if (t[i].status=="Requested"){
          user.currentBR=true
          console.log("currentBR", t[i]);
        }
      }
      user.numberBR=t.length
    },
    checkIfhasCR(user){
      user.hasCR=false
      axios.get('/completedOrders.json?orderBy="tenantId"&equalTo="'+user.id+'"')
      .then(res=>{
        // console.log(user.id);
        let crs=res.data
        // console.log(user.id, crs, Object.keys(crs).length);
        if (Object.keys(crs).length>0){
          for (var id in crs) {
            if (crs.hasOwnProperty(id)) {

              if (crs[id].status.toLowerCase()!='canceled'){
                // let temp=this.users[user.id]
                // let temp=this.allUsers.find(x=>x.id==user.id)
                user.hasCR=true
                // temp.hasCR=true
                user.crDate=crs[id].confirmTimestamp
                // temp.crDate=crs[id].confirmTimestamp
                if( crs[id].hasOwnProperty('dates')){
                  user.cMid=crs[id].dates.mid
                  // temp.cMid=crs[id].dates.mid
                } if (crs[id].hasOwnProperty('realDates')){
                  user.rMid=crs[id].realDates.mid
                  // temp.rMid=crs[id].realDates.mid
                }
                // console.log('HAS CR', user)
                let index=this.allUsers.findIndex(x=>x['.key']==user.id)
                this.allUsers[index]=user
                this.tUsers=JSON.parse(JSON.stringify(this.allUsers))
                // this.tUsers=JSON.parse(JSON.stringify(this.users))

              }
            }
          }
        }

      })
      .catch(err=>{
        console.log(err);
      })
    },

    processEmailOpens(){
      console.log('process emails');
      let j=0
      for (var i = 0; i < this.eOpens.length; i++) {
        let vm=this
        console.log(vm.eOpens[i]);

        // let userIndex = this.users.findIndex(x => x['.key'] == vm.eOpens[i].user);

        if (vm.eOpens[i].user!=undefined && vm.eOpens[i].rts!=undefined && vm.users[vm.eOpens[i].user]!=undefined){
          console.log('do the pattch');
          axios.patch('users/'+vm.eOpens[i].user+'.json?auth='+this.$store.state.auth.idToken,{
            rts:vm.eOpens[i].rts
          })
          .then(()=>{
            j++
            console.log('patch Succes');
            // console.log(vm.eOpens[i]['.key']);
            console.log('add to j',j);
            if (j==this.eOpens.length){
              console.log('all patches finished');
            }
          })
        } else {
          console.log('failed for this one:',this.eOpens[i]);
          j++
          if (j==this.eOpens.length){
            console.log('all patches finished');
          }
        }
      }

    },
    setFound(user){
      user.rts='found'
      axios.patch('users/'+user['.key']+'.json?auth='+this.$store.state.auth.idToken,{rts:'found'})
    },
    sendOhNo(user){
      let rts=20
      let link=this.getQueryLink(user)
      let link2="https://myroomabroad.com?id="+user['.key']+"&rts=found"
      link = this.customUrl(link, 'OhNo')
      link2=this.customUrl(link2, 'found')
      AWSSES.emailWithButtons(user,link, link2, 'RTSeriesOhNo')
      axios.patch('users/'+user['.key']+'.json?auth='+this.$store.state.auth.idToken,{rts:20, rtsTS:moment()})

    },
    getRoomLink(user){
      // console.log(user);
      if (user.numberBook>0){
        let list = user.br.reduce((prev, current) => (prev.opens > current.opens) ? prev : current)
        // console.log(list)
        return 'https://myroomabroad.com'+list.link+'?'
      } else if (user.numberSeen>0){
        let list = user.rs.reduce((prev, current) => (prev.opens > current.opens) ? prev : current)
        console.log(list)
        return 'https://myroomabroad.com'+list.link+'?'
      } else {
        return this.getQueryLink(user)
      }
    },
    getQueryLink(user){
      if (user.query!=undefined){
        if (user.query.nor!=undefined && user.query.nor!=''){
          return 'https://myroomabroad.com/aptSearch?nor='+user.query.nor+'&aptType='+user.query.aptType+'&mid='+user.query.mid+'&price='+user.query.price+'&name='+user.query.name+'&cLength='+user.query.cLength+'&'
        } else {
          return 'https://myroomabroad.com/search?mid='+user.query.mid+'&price='+user.query.price+'&name='+user.query.name+'&cLength='+user.query.cLength+'&bedType='+user.query.bedType+'&propType='+user.query.propType+'&'
        }
      } else {
        return 'https://myroomabroad.com/search?'
      }
    },
    customUrl(link,rts){
      let query="utm_source=rts"+rts+"&utm_medium=email&utm_campaign=RT"
      if (link.includes('?')){
        return link+'&'+query
      } else {
        return link +"?"+query
      }
    },
    sendNextEmail(user){
      let link=this.getRoomLink(user)+'id='+user['.key']
      let link2="https://myroomabroad.com?id="+user['.key']+"&rts=found"
      let nextRTS=11
      console.log(link);
      if (user.rts!=undefined){
        if (isNaN(user.rts) && user.rts.toLowerCase()=='found'){
          return
        } else {
          nextRTS=parseInt(user.rts)+1
        }
        if (nextRTS==14 || nextRTS==24){
          nextRTS=Math.floor(nextRTS/10)*10+11
        }
        if (nextRTS>20){
          link=this.getQueryLink(user)
        }
      }
      // console.log('nextRTS: ',nextRTS);
      let clickRTS=this.setClickRTS(nextRTS)
      link=link+'&rts='+clickRTS
      console.log('to be send: ',user,link, link2, 'RTSeries'+nextRTS)
      link = this.customUrl(link, nextRTS)
      link2=this.customUrl(link2, 'found')
      AWSSES.emailWithButtons(user,link, link2, 'RTSeries'+nextRTS)
      axios.patch('users/'+user['.key']+'.json?auth='+this.$store.state.auth.idToken,{rts:nextRTS, rtsTS:moment()})
    },
    setClickRTS(rts){ //sets the click RTS to 20/30/40/50 => Next series!
      if (rts!=undefined && isNaN(rts)==false){
        console.log('the math', Math.floor(rts/10)*10+10);
        return Math.floor(rts/10)*10+10

      } else {
        console.log('error:  rts is not a number:', isNaN(rts), rts);
      }
    },
    matchSeenUsers(){

      if (this.users!=undefined && this.seen!=undefined && Object.keys(this.users).length>0 && this.seen.length>0){
        console.log('new Seen');
        for (var i = 0; i < this.seen.length; i++) {
          if (this.users.hasOwnProperty(this.seen[i].user)){
            this.users[this.seen[i].user].rs.push(this.seen[i])
          }
        }
        this.tUsers=[]
        this.allUsers=[]
        for (var id in this.users) {
          if (this.users.hasOwnProperty(id)) {
            this.users[id].rs=this.mergeLinks(this.users[id].rs)
            this.users[id].numberSeen=this.users[id].rs.length
            this.users[id].rsChecked=true
            // this.users[id].numberBook=1
            if (this.users[id].numberBook!=undefined){
              this.loaded=true
              if (this.users[id].numberSeen!=0 || this.users[id].numberBook!=0 || this.users[id].rts!=undefined){
                this.allUsers.push(this.users[id])
              }
            }
          }
        }
        this.tUsers=JSON.parse(JSON.stringify(this.allUsers))

        // console.log('do match Seen');
        //   for (var i = 0; i < this.users.length; i++) {
        //     let indexes=this.getAllIndexes(this.seen, this.users[i].id) // Find all the occurences where user==users[i].id
        //     // Add the seen to rs and remove them from the original array. Start at the end
        //     indexes.reverse()
        //     this.users[i].rs=[]
        //     for (var j = 0; j < indexes.length; j++) {
        //       this.users[i].rs.push(this.seen[indexes[j]])
        //       this.seen.splice(indexes[j],1)
        //     }
        //   // this.users[i].rs=this.seen.filter(item=>item.user==this.users[i].id)
        //   this.users[i].numberSeen=this.users[i].rs.length
        //   this.users[i].rsChecked=true
        //   if (this.users[i].numberBook!=undefined){
        //     this.loaded=true
        //     if (this.users[i].numberSeen==0&&this.users[i].numberBook==0){
        //       remove.push(i)
        //     }
        //   }
        // }
        // // console.log(remove);
        // remove.reverse()
        // for (var j = 0; j <remove.length ; j++) {
        //   this.users.splice(remove[j],1)
        // }
        console.log('finish seen');
      } else {
        console.log("Don't check seen");
      }
    },
    matchBookUsers(){
      if (this.users!=undefined && this.book!=undefined && Object.keys(this.users).length>0 && this.book.length>0){
        for (var i = 0; i < this.book.length; i++) {
          if (this.users.hasOwnProperty(this.book[i].user)){
            this.users[this.book[i].user].br.push(this.book[i])
          }
        }
        this.tUsers=[]
        this.allUsers=[]
        for (var id in this.users) {
          if (this.users.hasOwnProperty(id)) {
            this.users[id].br=this.mergeLinks(this.users[id].br)
            this.users[id].numberBook=this.users[id].br.length
            this.users[id].rsChecked=true
            // this.users[id].numberB/ook=1
            if (this.users[id].numberSeen!=undefined){
              this.loaded=true
              if (this.users[id].numberSeen!=0 || this.users[id].numberBook!=0 || this.users[id].rts!=undefined){
                this.users[id]['.key']=id
                this.allUsers.push(JSON.parse(JSON.stringify(this.users[id])))

              }
            }
          }
        }
        this.tUsers=JSON.parse(JSON.stringify(this.allUsers))
        //
        // let remove=[]
        // // console.log('do match book');
        // for (var i = 0; i < this.users.length; i++) {
        //   let indexes=this.getAllIndexes(this.book, this.users[i].id) // Find all the occurences where user==users[i].id
        //   // Add the seen to rs and remove them from the original array. Start at the end
        //   indexes.reverse()
        //   this.users[i].br=[]
        //   for (var j = 0; j < indexes.length; j++) {
        //     this.users[i].br.push(this.book[indexes[j]])
        //     this.book.splice(indexes[j],1)
        //   }
        //   // this.users[i].br=this.book.filter(item=>item.user==this.users[i].id)
        //   this.users[i].numberBook=this.users[i].br.length
        //   this.users[i].br=this.mergeLinks(this.users[i].br)
        //   this.users[i].brChecked=true
        //   if (this.users[i].numberSeen!=undefined){
        //     this.loaded=true
        //     if (this.users[i].numberSeen==0&&this.users[i].numberBook==0){
        //       if (this.checkUser(this.users[i])){
        //         // console.log(';remove');
        //         remove.push(i)
        //       } else {
        //         // console.log(this.users[i].userName, this.users[i].query);
        //       }
        //     }
        //   }
        // }
        // // console.log(remove);
        // remove.reverse()
        // for (var j = 0; j <remove.length ; j++) {
        //   this.users.splice(remove[j],1)
        // }

        console.log('finish book');
      } else {
        console.log("Don't check book");
      }

    },
    checkUser(user){

      if (user.retarget!=undefined && user.retarget.value!=0 ){
        // console.log('value!=0', user.retarget.value);
        return false
      } else if (user.query!=undefined && user.query.mid!=undefined && user.query.mid!='') {
        // console.log(user.query, user.userName)
        if (moment(user.query.mid).isValid()){

          if (moment(user.query.mid).isBetween(moment().subtract(2,'weeks'),moment().add(1,"month"))) {
            // console.log('During next month', moment(user.query.mid).format('LL'));
            return false
          } else {
            // console.log('not next month');
            return true
          }
        } else if (moment(parseInt(user.query.mid)).isValid()) {
          if (moment(parseInt(user.query.mid)).isBetween(moment().subtract(2,'weeks'),moment().add(1,"month"))) {
            // console.log('During next month with parse',moment(parseInt(user.query.mid)).format('LL'));
            return false
          } else {
            // console.log('not next month with parse');
            return true
          }

        }
      } else if (moment(user.dateAdded).isAfter(moment().subtract(1,'weeks'))) {
          // console.log('recently joined,', user.dateAdded);
          return false
      } else {
        // console.log('Send true');
        user.retarget.value=0
        return true
      }
    },
    mergeLinks(links){
      let temp={}
      for (var i = 0; i < links.length; i++) {
        if (temp[links[i].id]!=undefined){
          temp[links[i].id].opens++
          if (moment(temp[links[i].id].ts).isBefore(moment(links[i].ts))) {
            temp[links[i].id].ts=links[i].ts
          }
        } else {
          temp[links[i].id]=links[i]
          temp[links[i].id].opens=1
          switch (links[i].type) {
            case 'rooms':
              temp[links[i].id].link="/apartmentDetails/"+links[i].id+'/rooms'
              break;
            case 'studio':
              temp[links[i].id].link="/studioDetails/"+links[i].id
              break
            case 'room':
              temp[links[i].id].link="/details/"+links[i].id
              break
            case 'apartment':
              temp[links[i].id].link="/apartmentDetails/"+links[i].id+'/apartment'
              break
            default:
          }
        }
      }
      let temp2=[]
      for (var link in temp) {
        if (temp.hasOwnProperty(link)) {
          temp2.push(temp[link])
        }
      }
      return temp2
    },
    setRT(item){
      // console.log('RTSet', item, item['.key'],{value:item.retarget.value, ts:moment()});
      axios.patch('users/'+item['.key']+'/retarget.json?auth='+this.$store.state.auth.idToken,{value:item.retarget.value, ts:moment()})
      .then(()=>{
        // console.log('success');
      })
      .catch(err=>{
        console.log('error: ',err);
      })
    },
    // setUniqueLinks(links){
    //   let pages={}
    //   // this.users[i].uniqueRoomVisits={}
    //   for (var i = 0; i < links.length; i++) {
    //     links[i]
    //   }
    //
    //   for (var link in links) {
    //     if (links.hasOwnProperty(link)) {
    //
    //         if (pages.hasOwnProperty(links[link].id)){
    //           pages[links[link].id].opens++
    //           if (moment(pages[links[link].id].ts).isBefore(moment(links[link].ts))){
    //             pages[links[link].id].ts=links[link].ts
    //           }
    //         } else {
    //           // console.log(link);
    //           pages[links[link].id]={
    //             opens:1,
    //             ts:links[link].ts,
    //             type:links[link].type
    //           }
    //           switch (links[link].type) {
    //             case 'rooms':
    //               pages[links[link].id].link="/apartmentDetails/"+links[link].id+'/rooms'
    //               break;
    //             case 'studio':
    //               pages[links[link].id].link="/studioDetails/"+links[link].id
    //               break
    //             case 'room':
    //               pages[links[link].id].link="/details/"+links[link].id
    //               break
    //             case 'apartment':
    //               pages[links[link].id].link="/apartmentDetails/"+links[link].id+'/apartment'
    //               break
    //             default:
    //
    //           }
    //         }
    //
    //     }
    //   }
    //   return (uniqueLinksAr)
    // },
    deleteFromUsers(){
      for (var i = 0; i < this.users.length; i++) {
        // console.log(this.allUsers[i].userName);
        if (this.users[i]['.key']!=undefined){

          axios.patch('users/'+this.users[i]['.key']+'.json?auth='+this.$store.state.auth.idToken,{seen:null, book:null})
          .then(()=>{
            // console.log('success');
          })
        }
      }
    },
    moveToOtherDB(){
      this.noMove=true
      // console.log('start copying');
      for (var i = 0; i < this.users.length; i++) {
        // console.log(this.users[i].userName);
        if (this.users[i].seen!=undefined){
          // console.log('# seen:',this.users[i].seen.length);
          for (var id in this.users[i].seen) {
            if (this.users[i].seen.hasOwnProperty(id)) {
              this.users[i].seen[id].user=this.users[i]['.key']
              // console.log('post', this.users[i].seen[id]);
            }
          }

          axios.patch('/rSeen.json?auth='+this.$store.state.auth.idToken,this.users[i].seen)
          .then(()=>{
            // console.log('success');
          })
          .catch(err=>{
            console.log(err, ', error');
          })

          // for (var j = 0; j < this.users[i].seen.length; j++) {
          //   console.log('seen:',j);
          // }
        } else {
          console.log('seen undefined');
        }
        if (this.users[i].book!=undefined){
          for (var idj in this.users[i].book) {
            if (this.users[i].book.hasOwnProperty(idj)) {
              this.users[i].book[idj].user=this.users[i]['.key']
            }
          }
        }
        axios.patch('/rBook.json?auth='+this.$store.state.auth.idToken,this.users[i].book)
        .then(()=>{
          // console.log('success');
        })
        .catch(err=>{
          console.log(err, ', error');
        })
        //   console.log('# book:',this.users[i].book.length);
        //     this.users[i].book[k].user=this.users[i]['.key']
        //   for (var k = 0; k < this.users[i].book.length; k++) {
        //     console.log('book:',k);
        //   }
        // } else {
        //   console.log('book undefined');
        // }
      }
      // console.log(this.users);
    }
  },
  created() {
    // this.processEmailOpens()
  },
  filters:{
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "No Date"
      }

    },
    mid: function(t) {
      if (t){
        return moment(t).format('l')
      } else {
        return "No Date"
      }

    },
    humanDate: function(t){
      if(t){
        let diff=moment().diff(moment(t))
        return moment.duration(diff).humanize()
      }
    }
  },
  metaInfo() {
      return {
        title:'Retargetting - My Room Abroad Admin' // set a title
    }
  }


}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.bold{
  font-weight: bold !important;
}

.hasBR{
  background-color: rgba(0, 191, 255,	.30);

}
.hasCR{
  background-color: rgba(119, 221, 119,1);
}
</style>
