<template>
<div>
  <v-card>
   <v-card-title>
     Line Follows (Add My Room Abroad (Official channel))
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>

   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="groups"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td>{{props.item.ts | date}}</td>
       <td>{{props.item.userId}}</td>
       <td>
         <v-autocomplete
           :items="users"
           placeholder="No admin selected"
           v-model="props.item.ll"
           @change="testChange(props.item)"
           item-text="userName"
           item-value="id"
         ></v-autocomplete>
         <v-btn flat color="primary" @click="sendReminder(props.item)"  :disabled="props.item.disable">SEND REMINDER!</v-btn>
         <v-btn color="primary" @click="setLLGroup(props.item, props.item.ll)">Save</v-btn>
       </td>

       <td class=""><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/lineFollow/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a></td>

     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'Line_Follows',
  data: () => ({
    headers:[
      {text:"Date Created" ,value:"ts"},
      {text:'User ID', value:"userId"},
      { text:"Landlord", value:"ll"},
      {text: "Database Id", value:".key"}
    ],
    search:"",
    pagination:{
      descending:true,
    },
  }),
  firebase() {
    return {
      users: {
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(20),
        readyCallback:function(){
          for (var i = 0; i < this.users.length; i++) {
            this.users[i].id=this.users[i]['.key']
          }
        }
      },
      groups:{
        source:firebase.database().ref('lineFollow'),
      }


    }

  },
  methods: {
    testChange(item){
      console.log(item);
    },
    sendReminder(conv){
      conv.disable=true
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
      let message="嗨! 你可以把這個代碼寄給為你服務的My Room Abroad夥伴嗎? 你的代碼是"+conv.userId+ "  只要我們有收到你的專屬代碼 未來你有訂房時我們就可以提醒你，確保你不會漏掉任何一個房客! \n\n Hi! \n Could you please send this code: "+conv.userId+ " to your My Room Abroad coworker? This way we can remind you of any incoming booking requests through this channel."
      let info={to: conv.userId,messages:[{"type": "text","text": message}]}
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', info, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })

    },
    setLLGroup(conv, ll){
      console.log(ll);
      axios.patch('lineFollow/'+conv['.key']+".json",{ll:ll, messageSent:true})
      .then(res=>{
        console.log('patched the line group');
      })
      let landlord=this.users.find(item=>{
        return item['.key']==ll
      })
      console.log(landlord);
      let message="Hi "+landlord.userName+"! \nWe have added this channel to your account on My Room Abroad. You will receive a message on this conversation every time you receive a new booking request. Cheers!"
      if (landlord.sendChinese==true){
        message="Hi "+ landlord.userName + "！恭喜您，我們已成功連結您的My Room Abroad帳號，一旦您有新的訂房請求，我們將在第一時間告知。"
      }
      let info={to: conv.userId,messages:[{"type": "text","text": message}]}
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', info, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })

    },
  },
  created() {
  },
  filters:{
    date(t){
      if (t!=undefined){
        return moment(t).format("LL")
      }
    }
  },
  metaInfo() {
      return {
        title:'LINE - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.doToday{
  background-color: rgb(255, 64, 0);
color: white!important;
}
.table-menu{
  width:130px;
}
.md-field {
    max-width: 300px;
}
</style>
