<template lang="html">
  <div class="">

    <v-card-title>
      Coupons
      <v-btn color="primary" @click="show=true">Add Coupons</v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

    </v-card-title>
    <v-data-table
      :rows-per-page-items='[50,100, 25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
      :headers="headers"
      :items="coupons"
      :search="search"
      :pagination.sync="pagination"
    >
      <template slot="items" slot-scope="props">
        <td>{{props.item.name}}</td>
        <td>{{props.item.val}} {{props.item.absVal | showUnit}}</td>
        <td>{{props.item.sUse | showType}} <span v-if="props.item.used" > - USED </span><span v-if="props.item.user!=undefined">{{props.item.user}}</span> </td>
        <td>{{props.item | status}} - {{props.item.expDate | showDate}}</td>
        <td>
            <!-- <v-tooltip top>
              <v-btn color="info" slot="activator" fab small dark @click="edit">
                <v-icon>stop</v-icon>
              </v-btn>
              <span>Edit the coupon</span>
            </v-tooltip> -->
            <v-tooltip top v-if="props.item.used==true">
              <v-btn slot="activator" :disabled="disabled" color="green" @click="setUnused(props.item, false)" fab small dark>
                <v-icon>play_arrow</v-icon>
              </v-btn>
              <span>Reactivate - Set unused</span>
            </v-tooltip>
            <v-tooltip top v-if="props.item.sUse==true && props.item.used!=true">
              <v-btn slot="activator" color="red" :disabled="disabled" @click="setUnused(props.item, true)" fab small dark>
                <v-icon>stop</v-icon>
              </v-btn>
              <span>Deactivate - SET USED</span>
            </v-tooltip>
            <v-tooltip top v-if="props.item.sent==true">

              <v-btn slot="activator" color="primary" :disabled="disabled" @click="send(props.item,false)" fab small dark>
                <v-icon>undo</v-icon>
              </v-btn>
              <span>Unsend, the coupon has not been given.</span>
            </v-tooltip>
            <v-tooltip top v-else>

              <v-btn slot="activator" color="primary" :disabled="disabled" @click="send(props.item, true)" fab small dark>
                <v-icon>send</v-icon>
              </v-btn>
              <span>Give the coupon</span>
            </v-tooltip>

            <v-tooltip top v-if="expired(props.item.expDate)">
              <v-btn color="error" slot="activator"  :disabled="disabled" fab small dark @click="addDays(props.item)">
                <v-icon>replay_10</v-icon>
              </v-btn>
              <span>Add 10 days to the coupon</span>
            </v-tooltip>
            <v-tooltip top v-else>

              <v-btn color="error" slot="activator" :disabled="disabled" fab small dark @click="stop(props.item)">
                <v-icon>stop</v-icon>
              </v-btn>
              <span>Stop the coupon</span>
            </v-tooltip>
            <v-tooltip top>

              <v-btn color="orange" slot="activator" :disabled="disabled" fab small dark @click="edit(props.item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <span>Edit the coupon</span>
            </v-tooltip>
            <v-tooltip top>
              <v-btn color="red" slot="activator" :disabled="disabled" fab small dark @click="remove(props.item)">
                <v-icon>delete_forever</v-icon>
              </v-btn>
              <span>DELETE</span>

            </v-tooltip>
          </td>
          <td><a :href="'https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/rCoupons/'+props.item['.key']">{{props.item['.key']}}</a> </td>
        <!-- <td>{{props.item.affVal}} {{props.item.affAbsVal | showUnit}}</td> -->
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </v-card>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Create a Coupon</span>
      </v-card-title>
      <v-card-text>
          <v-layout wrap>
            <v-select
              :items="[{text:'Single Use', value:true},{text:'Multiple use', value:false}]"
              v-model="sUse"
              label="Single or multiple use?"
              @change="setUse"
              hint="Can this coupon be used just once or multiple times?"
            ></v-select>
            <v-autocomplete :items="users" v-model="user" hide-details clearable item-text="userName" item-value="id" label="Who can use it?" v-if="sUse">
              <template slot="no-data">
                <v-list-tile>
                  <v-list-tile-title>
                    No user found!
                  </v-list-tile-title>
                </v-list-tile>
              </template>

              <template slot="selection" slot-scope="{item, selected}">
                <v-chip
                  :selected="selected"
                  color="primary"
                  class="white--text"
                >
                  <span v-text="item.userName +' ('+item.email+')'"></span>
                </v-chip>

              </template>
              <template
                slot="item"
                slot-scope="{ item, tile }"
              >
                <v-list-tile-avatar
                  color="indigo"
                  class="headline font-weight-light white--text"
                >
                  <!-- {{ item.userName.charAt(0) }} -->
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title v-text="item.userName"></v-list-tile-title>
                  <v-list-tile-sub-title v-text="item.email"></v-list-tile-sub-title>
                </v-list-tile-content>
              </template>
            </v-autocomplete>            <v-flex xs12>

              <v-checkbox label="Auto generate Coupon" v-if="sUse==true" v-model="autoGen"></v-checkbox>
            </v-flex>

            <v-flex xs12>

            <v-text-field
              v-model="name"
              label="Discount Coupon Name:"
              id="id"
              v-if="autoGen!=true"
            ></v-text-field>
          </v-flex>
            <v-flex xs12 >
              <v-text-field label="Quantity" v-if="autoGen==true" type="number" hint="How many coupons do you want to generate?" v-model="q"></v-text-field>
            </v-flex>
            <v-select
              :items="[{text:'Absolute', value:true},{text:'Percentage', value:false}]"
              v-model="absVal"
              label="Absolute or % value of coupon"
            ></v-select>
            <v-flex xs12>
              <v-text-field
                v-model="value"
                label="Coupon value"
                type="number"
                :suffix="valueSuffix"
                :error-messages="valueErrors"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
                <v-menu
                v-model="eDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    label="Expiration date - Leave empty if no expiration date."
                    v-model="endDate"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                    <v-date-picker v-model="endDate"   @input="eDateMenu = false">
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-checkbox label="Affiliate Coupon?" v-model="affiliate" ></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="affiliate==true">
            <v-flex xs12>
              <v-autocomplete :items="users" v-model="owner" hide-details clearable item-text="userName" item-value="id" label="Who is the affiliate?">
                <template slot="no-data">
                  <v-list-tile>
                    <v-list-tile-title>
                      No landlord found!
                    </v-list-tile-title>
                  </v-list-tile>
                </template>

                <template slot="selection" slot-scope="{item, selected}">
                  <v-chip
                    :selected="selected"
                    color="primary"
                    class="white--text"
                  >
                    <span v-text="item.userName +' ('+item.email+')'"></span>
                  </v-chip>

                </template>
                <template
                  slot="item"
                  slot-scope="{ item, tile }"
                >
                  <v-list-tile-avatar
                    color="indigo"
                    class="headline font-weight-light white--text"
                  >
                    <!-- {{ item.userName.charAt(0) }} -->
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.userName"></v-list-tile-title>
                    <v-list-tile-sub-title v-text="item.email"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>

            </v-flex>
            <v-select
              :items="[{text:'Absolute', value:true},{text:'Percentage', value:false}]"
              v-model="affAbsVal"
              hide-details
              label="Absolute or % value of coupon"
            ></v-select>
            <v-flex xs12>
              <v-text-field
                v-model="affValue"
                label="Affiliate value"
                type="number"
                hide-details
                :suffix="affValueSuffix"
              ></v-text-field>
            </v-flex>
          </v-layout>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" flat @click.native="changeShow">Close</v-btn>
        <v-btn color="primary" flat @click="submit" :disabled="sending">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar
    v-model="showMessage"
    color="success"
    :timeout="6000"
  >
    Coupon edited successfully!
    <v-btn flat @click.native="showMessage = false">Close</v-btn>
  </v-snackbar>
  <v-snackbar
    v-model="errorMessage"
    color="error"
    :timeout="6000"

  >
    Coupon failed to edit. Try again or tell Vincent if error persists
    <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
  </v-snackbar>

  </div>
</template>

<script>
import moment from 'moment'
import {
  required,
  maxLength,
  minLength
} from 'vuelidate/lib/validators'
import axios from 'axios'
import firebase from '../../FirebaseApp'
import CouponType from '../events/eventMgmt/CouponType.vue'
import AWSSES from '../../aws-ses'

export default {

  data: () => ({
    search:"",
    pagination: {
      descending: true
    },
    headers: [
      {
        text: 'Code',
        value: 'name'
      },
      {
        text: 'Value',
        value: 'val'
      },
      {
        text: 'Type',
        value: 'sUse'
      },{
        text: 'Expiration',
        value: 'expDate'
      },
      {
        text: 'Actions',
        sortable: false,
      },
      {
        text: 'db',
        sortable: false,
      }
    ],
    disabled:false,
    errorMessage:false,
    showMessage:false,
    show:false,
    sUse:false,
    autoGen:false,
    eDateMenu:false,
    couponId:null,
    endDate:"",
    user:"",
    absVal:true,
    name:"",
    value:"",
    q:1,
    sending:false,
    affiliate:false,
    owner:false,
    affValue:0,
    affAbsVal:false,
    editing:false,
  }),
  firebase() {
    return {
      coupons: {
        source:firebase.database().ref('rCoupons'),
        readyCallback:function(){
          for (var i = 0; i < this.coupons.length; i++) {
            if (this.coupons[i].sent==undefined){
              this.coupons[i].sent=false
            }
          }
        }
      },
      users:{
        source:firebase.database().ref('users'),
      }
    }
  },

  methods: {
    sendEmail(){
      let admin={
        email:'sandrine@myroomabroad.com',
        name:'Sandrine'
      }
      let info={
        listing:"hello",
        dateAvailable:"2/7/2019",
        listingName:"MRA",
        price:"3000",
        stopEmails:"aerg",
        NumberNewListings:"5"
      }
      AWSSES.emailingInfo(admin, info, 'testMail2-20191219094523')
    },
    expired(expDate){
      if(moment(expDate).isValid() && moment(expDate).isBefore(moment())){
        return true
      } else {
        return false
      }
    },
    edit(coupon){
      this.show= true
      this.editing=true
      this.name=coupon.name
      this.value=coupon.val
      this.endDate=coupon.expDate
      this.user=coupon.user
      this.eventId=coupon.eventId
      this.absval=coupon.absval
      this.sUse=coupon.sUse
      this.affiliate=coupon.sUse,
      this.affAbsVal=coupon.affAbsVal
      this.affVal=coupon.affValue
      this.owner=coupon.owner
      this.couponId=coupon['.key']
    },
    changeShow(){
      this.show = !this.show
    },
    setUse(){
      if (this.sUse==false){
        this.autoGen=false
        this.q=1
      }
    },
    setUnused(item, val){
      this.disabled=true
      console.log(item['.key'], val);
      axios.patch('rCoupons/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken, {used:val})
      .then(res=>{
        this.disabled=false
        console.log( 'success');
        this.showMessage=true
      })
      .catch(err=>{
        this.disabled=false
        this.errorMessage=true
        console.log(err);
      })
    },
    send(item, val){
      this.disabled=true
      axios.patch('rCoupons/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken, {sent:val})
      .then(res=>{
        console.log( 'success');
        this.disabled=false
        this.showMessage=true
      })
      .catch(err=>{
        this.disabled=false
        this.errorMessage=true
        console.log(err);
      })
    },
    generateCoupons(){
      for (var i = 0; i < this.q; i++) {
        let rand1=this.getRandomInt(1000)
        let rand2=this.getRandomInt(1000)
        this.postCoupon(rand1+this.value+rand2)
      }
    },
    getRandomInt(max) {

      return Math.floor(Math.random() * Math.floor(max));
    },
    postCoupon(name){
      let t={
        name:name,
        user:this.user,
        val:this.value,
        expDate:this.endDate,
        eventId:this.eventId,
        absVal:this.absVal,
        sUse:this.sUse,
        aff:this.affiliate,
        affAbsVal:this.affAbsVal,
        affVal:this.affValue,
        owner:this.owner,
      }
      if (this.editing==true){
        axios.patch('rCoupons/'+this.couponId+'.json?auth='+this.$store.state.auth.idToken, t)
        .then(()=>{
          console.log('patch succes');
          this.changeShow()
          this.sending=false
        })
        .catch(err=>{
          console.log('error: ',err);
          this.changeShow()
          this.sending=false
        })
      } else {
        axios.post('rCoupons.json?auth='+this.$store.state.auth.idToken, t)
        .then(()=>{
          console.log('post succes');
          this.changeShow()
          this.sending=false
        })
        .catch(err=>{
          console.log('error: ',err);
          this.changeShow()
          this.sending=false
        })

      }
    },
    remove(coupon){
      this.disabled=true
      axios.delete('rCoupons/'+coupon['.key']+'.json?auth='+this.$store.state.auth.idToken)
      .then(()=>{
        this.disabled=false
        this.showMessage=true
        console.log('success');
      })
      .catch(err=>{
        this.disabled=false
        this.errorMessage=true
        console.log('err', err);
      })
    },
    editSent(coupon) {
        this.disabled=true

        let t=coupon.sent
        if (t==true){
          t=false
        } else {
          t=true
        }
        axios.patch('rCoupons/'+coupon['.key']+'.json?auth='+this.$store.state.auth.idToken,{sent:t})
        .then(()=>{
          console.log('success');
          this.disabled=false
          this.showMessage=true

        })
        .catch(err=>{
          console.log('err', err);
          this.disabled=false
          this.errorMessage=true
        })
    },
    stop(coupon){
      let t=coupon.expDate
      this.disabled=true
      t=moment().subtract(1,'days')
      axios.patch('rCoupons/'+coupon['.key']+'.json?auth='+this.$store.state.auth.idToken,{expDate:t})
      .then(()=>{
        console.log('success');
        this.disabled=false
        this.showMessage=true

      })
      .catch(err=>{
        console.log('err', err);
        this.disabled=false
        this.errorMessage=true

      })
    },
    addDays(coupon){
      this.disabled=true
      let t=coupon.expDate
      console.log();
      t=moment().add(10,'days')
      console.log(t.format('LL'));
      axios.patch('rCoupons/'+coupon['.key']+'.json?auth='+this.$store.state.auth.idToken,{expDate:t})
      .then(()=>{
        console.log('success');
        this.disabled=false
        this.showMessage=true
      })
      .catch(err=>{
        console.log('err', err);
        this.disabled=false
        this.errorMessage=true
      })
    },
    submit() {
      this.sending=true
      // console.log('submit');
      if (this.validate()==true){
        if (this.autoGen==true && this.sUse==true){
          this.generateCoupons()
        } else {
          if (this.name!=""){
            this.postCoupon(this.name)
          } else {
            console.log("NO NAME");
            this.sending=false
          }
        }
        // this.$emit('changeShow')

      }
    },
    validate() {
      // console.log('DateL:  ', sSubmit)
      this.$v.$touch()
      // console.log(this.$v.$invalid);
      if (!this.$v.$invalid && !this.$v.$anyError) {
        // console.log('validate true');
        return true
      } else {
        this.sending=false
        // console.log('validate false');
        return false
      }
    },

  },
  validations: {
    value:{
      required
    },


  },
  computed:{
    valueSuffix(){
      if (this.absVal==true){
        return 'TW$'
      } else {
        return '%'
      }
    },
    affValueSuffix(){
      if (this.affAbsVal==true){
        return 'TW$'
      } else {
        return '%'
      }
    },
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !this.$v.value.required && errors.push('Add a coupon value')
      return errors
    },
  },
  created() {

  },
  filters:{
    showUnit(x){
      if (x){
        return "TW$"
      } else {
        return "%"
      }
    },
    showType(x){
      if (x){
        return 'Single Use'
      } else {
        return 'Multiple Use'
      }
    },
    showDate(x){
      if (moment(x).isValid()){
        return moment(x).format('YYYY-MM-DD')
      } else {
        return " ∞ "
      }
    },
    status(x){
      if (moment(x.expDate).isValid()){
        if (moment(x.expDate).isBefore(moment())) {
          return 'Expired'
        }
      }
      if (x.sUse==true){
        if (x.used==true){
          return 'Used'
        } else if (x.sent==true) {
          return 'Shared but unused'
        } else {
          return 'Valid'
        }
      } else {
        return 'Valid'
      }
    }

  },
  components: {
    CouponType
  },
  metaInfo() {
      return {
        title:'Coupons - My Room Abroad Admin' // set a title
    }
  }




}
</script>

<style lang="scss" scoped>
</style>
