<template>
<div>
  <v-card>
   <v-card-title>
     Visits
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="visits"
     :pagination.sync="pagination"
     :search="search"
   >
     <template slot="items" slot-scope="props">
       <tr @click="props.expanded = !props.expanded">
         <td class="">{{props.item.dateRequested | dateFormat}}</td>
         <td class="">{{ props.item.status }}</td>
         <td class=""><a :href="'/profile?id='+props.item.tenantId" v-if="users.hasOwnProperty(props.item.tenantId)">{{ users[props.item.tenantId].userName }}</a></td>
         <td class="">{{ props.item.tEmail }}</td>
         <td class=""><a :href="props.item.roomLink">{{ props.item.roomNumber }}</a></td>
         <td class=""><a :href="'/profile?id='+props.item.landlordId">{{ props.item.llName }}</a></td>
         <td class="">{{ props.item.llEmail }}</td>
         <td class=""><v-btn color="primary" @click="setDate(props.item)" :disabled="props.item.time==undefined || props.item.date==undefined || props.item.status!='Requested'">Set Date</v-btn></td>
       </tr>

     </template>

     <template slot="expand" slot-scope="props">
       <v-card flat>
         <v-card-text>
           <v-layout row wrap>
             <v-flex xs6>
               <b>Timeslots</b>: {{props.item.timeslot}}
               <br><br>
               <b>About</b>: <span v-if="users.hasOwnProperty(props.item.tenantId)" >{{users[props.item.tenantId].about}}</span>

             </v-flex>
             <v-flex xs6>
               <b>Contract Dates</b>: {{props.item.dates.mid | dateFormat}} => {{props.item.dates.mod | dateFormat}}
               <br><br>
               <b>Real Dates</b>: {{props.item.realDates.mid | dateFormat}} => {{props.item.realDates.mod | dateFormat}}

             </v-flex>
           </v-layout>
           <br><br>
           <v-layout row wrap>
             <v-menu
             :close-on-content-click="false"
             v-model="menu1"
             :nudge-right="40"
             lazy
             transition="scale-transition"
             offset-y
             full-width
             >
             <v-text-field
             slot="activator"
             v-model="props.item.date"
             label="Select the date"
             prepend-icon="event"
             readonly
             ></v-text-field>
             <v-date-picker landscape v-model="props.item.date" @input="menu1 = false"></v-date-picker>
           </v-menu>
             <v-menu
               ref="menu"
               :close-on-content-click="false"
               v-model="menu2"
               :nudge-right="40"
               :return-value.sync="props.item.time"
               lazy
               transition="scale-transition"
               offset-y
             >
               <v-text-field
                 slot="activator"
                 v-model="props.item.time"
                 label="Select the time"
                 prepend-icon="access_time"
                 readonly
               ></v-text-field>
               <v-time-picker
                 v-if="menu2"
                 v-model="props.item.time"
                 format="24hr"
                 landscape
                 @change="$refs.menu.save(props.item.time)"
               ></v-time-picker>
             </v-menu>
           </v-layout>

         </v-card-text>
       </v-card>
     </template>


     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import Countdown from 'vuejs-countdown'
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'


export default {
  name: 'Visits',
  data: () => ({
    menu1:false,

    menu2:false,
    dateSetter:true,
    menuValue:false,
    pagination:{
      descending: true
    },
    headers: [
      { text: 'Date Requested', value: 'dateRequested' },
      { text: 'Status', value: 'status' },
      { text: 'Tenant', value:'tName'},
      { text: 'Tenant Email', value:'tEmail'},
      { text: 'Room name', sortable:false },
      { text: 'Landlord name', value:'llName'},
      { text: 'Landlord Email', value: 'llEmail'},
      { text: 'Confirm a date', sortable:false}
    ],

    search: null,
    searched: [],

  }),
  firebase() {
    return {
      visits: {
        source:firebase.database().ref('visits'),
        readyCallback:function(){
          console.log(this.visits,'readyCallback')
          for (var i = 0; i < this.visits.length; i++) {
            this.visits[i].dateRequested=new Date(moment(this.visits[i].dateRequested)).getTime()
            this.visits[i].id=this.visits[i]['.key']
            this.visits[i].roomLink=this.setLink(this.visits[i])
          }
          this.matchVisitsUsers()

        }
      },
      users:{
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){
          this.matchVisitsUsers()
        }
      },
    }

  },
  methods: {
    setLink(visit){
      switch (visit.type) {
        case 'room':
          return '/details/'+visit.listingId
        case 'studio':
          return '/studioDetails/'+visit.listingId
        case 'apartment':
          return '/apartmentDetails/'+visit.listingId+'/apartment'
        default:
          return ''
      }
    },
    setDate(item){
      item.dateConf=true
      item.status='Confirmed'
      axios.patch('visits/'+item['.key']+'.json?auth='+this.$store.state.auth.idToken,{date:item.date, time:item.time, status:item.status})
    },
    matchVisitsUsers(){
      if (this.visits.length>0 && this.users){
        for (var i = 0; i < this.visits.length; i++) {
          this.visits[i].tenant=this.users[this.visits[i].tenantId]
          if (this.visits[i].tenant){
            this.visits[i].tName=this.visits[i].tenant.name
            this.visits[i].tEmail=this.visits[i].tenant.email
            this.visits[i].tAbout=this.visits[i].tenant.about
          }
          this.visits[i].landlord=this.users[this.visits[i].landlordId]
          if (this.visits[i].landlord){
            this.visits[i].llName=this.visits[i].landlord.userName
            this.visits[i].llEmail=this.visits[i].landlord.email
          }
          this.pagination.descending=!this.pagination.descending
          this.pagination.descending=!this.pagination.descending

        }

      }
    },
  },
  filters: {
    dateFormat: function(t) {
      return moment(t).format('ll')
    }
  },
  created() {
  },
  components:{
    Countdown
  },
  metaInfo() {
      return {
        title:'Visits - My Room Abroad Admin' // set a title
    }
  }


}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
