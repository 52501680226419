<template>
<div>
  <v-card>
    <v-card-title primary-title>
      Employees (Weekly) Stats :D
    </v-card-title>
    <v-card-text>
      <v-select
        :items="admins"
        v-model="employee"
        item-text="userName"
        return-object
        @change="setEmployee"
        label="How are you grilling today?"
      ></v-select>
      <div class="">
        checked:{{llsCrChecked}}, completed: {{llsCrCompleted}}
        Commission: <span class="bold">{{commission}}</span> ({{aCR.length}})
      </div>
      <div class="" v-for="cr in aCR" :key="cr.key">
        {{cr.fullRoomNumber}} (<span class="bold">{{cr.price}} NTD </span>{{cr.dates.mid | dateFilter}} - {{cr.dates.mod | dateFilter}}) {{cr['id']}}
      </div>
      <h2>Totals:</h2>
      Landlords: {{landlords.length}}
      Rooms: {{rooms.length}}
      apartments: {{apts.length}}
      studios: {{studios.length}}
      wholeApts: {{wholeApts.length}}
      llBR: {{llBR.length}}
      llCR: {{llCR.length}}
      tenants: {{tenants.length}}
      br: {{br.length}}
      cr: {{cr.length}}
      visits: {{visits.length}}
      uniqueBR: {{uniqueBR.length}}
      uniqueVisits: {{uniqueVisits.length}}
      <h2>Current</h2>
      <v-layout wrap>
        <v-flex xs4>
          <v-menu
            :close-on-content-click="false"
            v-model="menu1"
            :nudge-right="40"
            lazy
            full-width
            class="px-1 "
            transition="scale-transition"
          >
            <v-text-field
              slot="activator"
              v-model="startDate"
              label="start"
              prepend-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="startDate" @input="menu1 = false"></v-date-picker>
          </v-menu>
      </v-flex>
      <v-flex xs1>
      </v-flex>
      <v-flex xs4>
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"

                v-model="endDate"
                label="End"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            <v-date-picker v-model="endDate" @input="menu2 = false"></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
      Landlords: {{cLL.length}}
      Rooms: {{llCRooms.length}}
      apartments: {{cApts.length}}
      studios: {{cStudios.length}}
      wholeApts: {{cWholeApts.length}}
      llBR: {{cllBR.length}}
      llCR: {{cllCR.length}}
      br: {{cBR.length}}
      cr: {{cCR.length}}
      visits: {{cVisits.length}}
      uniqueBR: {{cUniqueBR.length}}
      uniqueVisits{{cUniqueVisits.length}}

      <v-data-table
        :headers="headersLL"
        :items="cLL"
        hide-actions
        class="elevation-1"
        :search="searchLL"
      >
        <template slot="items" slot-scope="props">
          <td><a :href="'/profile?id='+props.item['.key']">{{ props.item.userName }}</a></td>
          <td class="">{{ props.item.email }}{{props.item.id}}</td>
          <td class="">{{ props.item.dateAdded }}</td>

        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ searchLL }}" found no results.
        </v-alert>

      </v-data-table>
      <v-data-table
        :headers="headersRooms"
        :items="cListings"
        hide-actions
        class="elevation-1"
        :search="searchRoom"
      >

        <template slot="items" slot-scope="props">
          <td>{{props.item.general.fullRoomNumber}}</td>
          <td>{{props.item.area}}</td>
          <td class="" v-if="props.item.landlord!=undefined">{{ props.item.landlord.userName }}</td>
          <td v-else>{{props.item.general.landlordId}}</td>
          <td class="">{{ props.item.general.dateAdded | dateFilter }}</td>
          <td class="">{{ props.item.general.nextAvailability | dateFilter }}</td>
          <td class="">{{ props.item.type }} {{props.item['.key']}}</td>

        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ searchRoom }}" found no results.
        </v-alert>
      </v-data-table>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import inside from 'point-in-polygon'


export default {
  name: 'Employees',
  data: () => ({
    employee:{
    },
    tenants:[],
    landlords:[],
    otherUsers:[],
    searchLL:"",
    llsCrChecked:0,
    llsCrCompleted:0,
    headersLL:[
      {text:'Name',value:'userName'},
      {text:'email',value:'email'},
      {text:'DateAdded',value:'dateAdded'},
    ],
    searchRoom:"",
    headersRooms:[
      {text:'roomNumber',value:'fullRoomNumber'},
      {text: "Area", value:'area'},
      {text:'ll',value:'general/landlordId'},
      {text:'date Added',value:'general/dateAdded'},
      {text:'next Availability',value:'general/nextAvailability'},
      {text:'type',value:'type'}
    ],
    rooms:[],
    apts:[],
    studios:[],
    wholeApts:[],
    llBR:[],
    llCR:[],
    br:[],
    cr:[],
    visits:[],
    uniqueBR:[],
    uniqueCR:[],
    uniqueVisits:[],
    startDate:"",
    endDate:"",
    menu1:false,
    menu2:false,
    on:'',
  }),

  firebase() {
    return {
      admins:{
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(80),
      },
    }

  },
  computed:{
    cLL(){
      //console.log('doCLL');
      let t=[]
      if (this.startDate!="" && moment(this.startDate).isValid() && this.endDate!="" && moment(this.endDate).isValid() ){
        //console.log('doBetween');
        for (var i = 0; i < this.landlords.length; i++) {
          if (moment(this.landlords[i].dateAdded).isBetween(moment(this.startDate),moment(this.endDate))){
            t.push(this.landlords[i])
          }
        }
      } else if (this.startDate!="" && moment(this.startDate).isValid()) {
        //console.log('do start');
        for (var i = 0; i < this.landlords.length; i++) {
          //console.log(moment(this.startDate).format("LLL") , moment(this.landlords[i].dateAdded).format("LLL"));
          if (moment(this.landlords[i].dateAdded).isAfter(moment(this.startDate))) {
            t.push(this.landlords[i])
          }
        }
      } else if(this.endDate!="" && moment(this.endDate).isValid()){
        //console.log('doEnd');
        for (var i = 0; i < this.landlords.length; i++) {
          if (moment(this.landlords[i].dateAdded).isBefore(moment(this.endDate))) {
            t.push(this.landlords[i])
          }
        }
      } else {
        //console.log('doElse');
        t=this.landlords
      }
      return t
    },
    cApts(){
      //console.log('doCLL');
      let t=[]
      if (this.startDate!="" && moment(this.startDate).isValid() && this.endDate!="" && moment(this.endDate).isValid() ){
        //console.log('doBetween');
        for (var i = 0; i < this.apts.length; i++) {

          // //console.log('VERIFY NOMENCLATURE', this.apts[i]['general.dateAdded'], ':1, 2:  ', this.apts[i]['general[dateAdded]'], ' , 3: ', this.apts[i]['general'['dateAdded']])
          if (moment(this.apts[i].general.dateAdded).isBetween(moment(this.startDate),moment(this.endDate))){
            t.push(this.apts[i])
          }
        }
      } else if (this.startDate!="" && moment(this.startDate).isValid()) {
        //console.log('do start');
        for (var i = 0; i < this.apts.length; i++) {
          //console.log(moment(this.startDate).format("LLL") , moment(this.apts[i].general.dateAdded).format("LLL"));
          if (moment(this.apts[i].general.dateAdded).isAfter(moment(this.startDate))) {
            t.push(this.apts[i])
          }
        }
      } else if(this.endDate!="" && moment(this.endDate).isValid()){
        //console.log('doEnd');
        for (var i = 0; i < this.apts.length; i++) {
          if (moment(this.apts[i].general.dateAdded).isBefore(moment(this.endDate))) {
            t.push(this.apts[i])
          }
        }
      } else {
        //console.log('doElse');
        t=this.apts
      }
      return t
    },
    llCRooms(){
      let t= this.returnCurrent(this.rooms,'dateAdded')
      return t
    },
    cStudios(){
      //console.log('doCLL');
      let t=[]
      if (this.startDate!="" && moment(this.startDate).isValid() && this.endDate!="" && moment(this.endDate).isValid() ){
        //console.log('doBetween');

        for (var i = 0; i < this.studios.length; i++) {
          if (moment(this.studios[i].general.dateAdded).isBetween(moment(this.startDate),moment(this.endDate))){
            t.push(this.studios[i])
          }
        }
      } else if (this.startDate!="" && moment(this.startDate).isValid()) {
        //console.log('do start');
        for (var i = 0; i < this.studios.length; i++) {
          //console.log(moment(this.startDate).format("LLL") , moment(this.studios[i].general.dateAdded).format("LLL"));
          if (moment(this.studios[i].general.dateAdded).isAfter(moment(this.startDate))) {
            t.push(this.studios[i])
          }
        }
      } else if(this.endDate!="" && moment(this.endDate).isValid()){
        //console.log('doEnd');
        for (var i = 0; i < this.studios.length; i++) {
          if (moment(this.studios[i].general.dateAdded).isBefore(moment(this.endDate))) {
            t.push(this.studios[i])
          }
        }
      } else {
        //console.log('doElse');
        t=this.studios
      }
      return t

    },
    cWholeApts(){
      //console.log('doCLL');
      let t=[]
      if (this.startDate!="" && moment(this.startDate).isValid() && this.endDate!="" && moment(this.endDate).isValid() ){
        //console.log('doBetween');
        for (var i = 0; i < this.wholeApts.length; i++) {

          if (moment(this.wholeApts[i].general.dateAdded).isBetween(moment(this.startDate),moment(this.endDate))){
            t.push(this.wholeApts[i])
          }
        }
      } else if (this.startDate!="" && moment(this.startDate).isValid()) {
        //console.log('do start');
        for (var i = 0; i < this.wholeApts.length; i++) {
          //console.log(moment(this.startDate).format("LLL") , moment(this.wholeApts[i].general.dateAdded).format("LLL"));
          if (moment(this.wholeApts[i].general.dateAdded).isAfter(moment(this.startDate))) {
            t.push(this.wholeApts[i])
          }
        }
      } else if(this.endDate!="" && moment(this.endDate).isValid()){
        //console.log('doEnd');
        for (var i = 0; i < this.wholeApts.length; i++) {
          if (moment(this.wholeApts[i].general.dateAdded).isBefore(moment(this.endDate))) {
            t.push(this.wholeApts[i])
          }
        }
      } else {
        //console.log('doElse');
        t=this.wholeApts
      }
      return t
    },
    cllBR(){
      let t= this.returnCurrent(this.llBR,'dateRequested')
      return t
    },
    cllCR(){
      let t= this.returnCurrent(this.llCR,'confirmTimestamp')
      return t
    },
    cBR(){
      let t= this.returnCurrent(this.br,'dateRequested')
      return t
    },
    cCR(){
      let t = this.returnCurrent(this.cr,'confirmTimestamp')
      return t

    },
    aCR(){
      let t=[]
      let now=moment()
      for (var i = 0; i < this.llCR.length; i++) {

        let item=this.llCR[i]
        if (item.status=='Canceled'){
          continue
        }
        let start=moment(item.dates.mid)
        let end=moment(item.dates.mod)
        if (now.isBetween(start, end)){
          // console.log(item.fullRoomNumber);
          t.push(item)
        }
      }
      t.sort(this.sorting)
      return t
    },
    commission(){
      let t=0
      for (var i = 0; i < this.aCR.length; i++) {
        t=t+Math.round(parseInt(this.aCR[i].price)*.03*.05)
      }
      for (var j=0; j< this.cCR.length;j++){
        t=t+Math.round((parseInt(this.cCR[j].price)-parseInt(this.cCR[j].discount))*.2*.05)
        // console.log('add cCR,', this.cCR[j].roomNumber);
      }
      return t
    },
    cVisits(){
      let t= this.returnCurrent(this.visits,'dateRequested')
      return t
    },
    cUniqueBR(){
      let t=this.returnCurrent(this.uniqueBR,'dateRequested')
      return t
    },
    cUniqueVisits(){
      let t=this.returnCurrent(this.uniqueVisits,'dateRequested')
      return t
    },
    cListings(){
      let t=this.llCRooms.concat(this.cWholeApts,this.cStudios)
      return t
    }
  },
  methods: {
    sorting(a,b){
      let t=0
      try {
        if (a.fullRoomNumber>b.fullRoomNumber){
          t=1
        } else if (a.fullRoomNumber<b.fullRoomNumber){
          t=-1
        }
      } catch (e) {
        console.log(e);
      } finally {
        return t
      }
    },
    setArea(location){
      let area="other"
      if (inside([location.lat,location.lng],[[25.009265, 121.493519],[25.022660, 121.513878],[25.007928, 121.532117],[24.995454, 121.530985]])){
        area="yonghe"
      } else if ( inside([location.lat,location.lng],[[25.022660, 121.513878],[25.007928, 121.532117],[25.011120, 121.537355],[25.034589, 121.516956],[25.037567, 121.507363],[25.030265, 121.504245]])){
        area='zhongzheng'
      } else if ( inside([location.lat,location.lng],[[25.011120, 121.537355],[25.034589, 121.516956],[25.033037, 121.553444],[25.011379, 121.563730]])){
        area="daanStudent"
      } else if ( inside([location.lat, location.lng],[[24.995454, 121.530985],[25.011120, 121.537355],[25.011379, 121.563730],[25.016621, 121.598156],[24.979995, 121.600270],[24.969820, 121.567915]])){
        area="wenshan"
      } else if ( inside([location.lat, location.lng],[[25.037619, 121.595279],[25.011379, 121.563730],[25.033037, 121.553444],[25.045204, 121.561669],[25.044696, 121.578818]])){
        area="xinyi"
      } else if ( inside([location.lat, location.lng],[[25.045043, 121.532872],[25.045204, 121.561669],[25.044696, 121.578818],[25.052327, 121.578807],[25.074372, 121.569722],[25.075832, 121.543495]])){
        area="songshan"
      } else if ( inside([location.lat, location.lng],[[25.045204, 121.561669],[25.033037, 121.553444],[25.034589, 121.516956],[25.045043, 121.532872]])){
        area="daanExpat"
      } else if ( inside([location.lat, location.lng],[[25.034589, 121.516956],[25.045043, 121.532872],[25.075832, 121.543495],[25.076026, 121.506507],[25.052189, 121.504726]])){
        area="zhongshan"
      } else if ( inside([location.lat, location.lng],[[25.075832, 121.543495],[25.074372, 121.569722],[25.065426, 121.616447],[25.101222, 121.611935],[25.091674, 121.542136]])){
        area="neihu"
      } else if ( inside([location.lat, location.lng],[[25.091674, 121.542136],[25.166770, 121.572119],[25.101222, 121.611935],[25.075832, 121.543495],[25.076026, 121.506507],[25.095602, 121.509428]])){
        area="shilin"
      } else if ( inside([location.lat, location.lng],[[25.095602, 121.509428],[25.166770, 121.572119],[25.209079, 121.561712],[25.125054, 121.458978]])){
        area="beitou"
      } else if ( inside([location.lat, location.lng],[[25.125054, 121.458978],[25.209079, 121.561712],[25.227988, 121.443948],[25.183033, 121.404265]])){
        area="tamsui"
      }
      return area
    },
    returnCurrent(item,location){
      let t=[]
      if (this.startDate!="" && moment(this.startDate).isValid() && this.endDate!="" && moment(this.endDate).isValid() ){
        //console.log('doBetween');
        for (var i = 0; i < item.length; i++) {
          if (moment(item[i][location]).isBetween(moment(this.startDate),moment(this.endDate))){
            t.push(item[i])
          }
        }
      } else if (this.startDate!="" && moment(this.startDate).isValid()) {
        //console.log('do start');
        for (var i = 0; i < item.length; i++) {
          //console.log(moment(this.startDate).format("LLL") , moment(item[i][location]).format("LLL"));
          if (moment(item[i][location]).isAfter(moment(this.startDate))) {
            t.push(item[i])
          }
        }
      } else if(this.endDate!="" && moment(this.endDate).isValid()){
        //console.log('doEnd');
        for (var i = 0; i < item.length; i++) {
          if (moment(item[i][location]).isBefore(moment(this.endDate))) {
            t.push(item[i])
          }
        }
      } else {
        //console.log('doElse');
        t=item
      }
      return t
    },
    setEmployee(){
      this.landlords=[]
      this.tenants=[]
      this.otherUsers=[]
      this.rooms=[]
      this.uniqueBR=[]
      this.uniqueCR=[]
      this.uniqueVisits=[]
      this.br=[]
      this.cr=[]
      this.visits=[]
      this.apts=[]
      this.studios=[]
      this.wholeApts=[]
      this.llBR=[]
      this.llCR=[]
      axios.get('users.json?orderBy="admin"&equalTo="'+this.employee['.key']+'"')
      .then(res => {
        // console.log(res)
        console.log(Object.keys(res.data).length , 'LANDLORDS');
        // this.totalUsers=res.data
        for (var key in res.data){
          res.data[key].id=key
          if (res.data.hasOwnProperty(key)) {
            if (res.data[key].hasOwnProperty('admin')){
              if (parseInt(res.data[key].roleValue)==10){
                this.tenants.push(res.data[key])
                this.getTReqs(key)
              } else if (parseInt(res.data[key].roleValue)==20){
                // console.log(res.data.id, key);
                this.landlords.push(res.data[key])

                // this.getApts(key)
                this.getLLReqs(key)
              } else {
                this.otherUsers.push(res.data[key])
              }
              //console.log(key);
            }
          }
        }
      })
    },
    getApts(id){
      axios.get('apartments.json?orderBy="general/landlordId"&equalTo="'+id+'"')
      .then(res => {
        //console.log('apts: ', res.data);
        for (var key in res.data) {
          if (res.data.hasOwnProperty(key) && res.data[key].draft==false) {
            res.data[key].id=key
            let area=this.setArea(res.data[key].general.location)
            res.data[key].type='apt'
            this.apts.push(res.data[key])
            axios.get('rooms.json?orderBy="apartmentId"&equalTo="'+key+'"')
            .then(res=>{
              for (var key in res.data) {
                if (res.data.hasOwnProperty(key) && res.data[key].draft==false) {
                  res.data[key].type='room'
                  res.data[key].id=key
                  res.data[key].area=area
                  res.data[key].general.dateAdded=res.data[key].dateAdded
                  res.data[key].general.landlordId=id
                  res.data[key].landlord=this.getLL(res.data[key].general.landlordId)
                  // this.setArea(res.data[key])
                  this.rooms.push(res.data[key])
                }
              }
            })
          }
        }
      })
      axios.get('studios.json?orderBy="general/landlordId"&equalTo="'+id+'"')
      .then(res=>{
        //console.log(res.data, "studios");
        for (var key in res.data) {
          if (res.data.hasOwnProperty(key) && res.data[key].draft==false) {
            res.data[key].id=key
            res.data[key].type='Studio'
            res.data[key].area=this.setArea(res.data[key].general.location)
            res.data[key].landlord=this.getLL(res.data[key].general.landlordId)
            this.studios.push(res.data[key])
          }
        }
      })
      axios.get('wholeApts.json?orderBy="general/landlordId"&equalTo="'+id+'"')
      .then(res=>{
        //console.log(res.data, "wholeApts");
        for (var key in res.data) {
          if (res.data.hasOwnProperty(key) && res.data[key].draft==false) {
            res.data[key].id=key
            res.data[key].type='whole Apt'
            res.data[key].area=this.setArea(res.data[key].general.location)
            res.data[key].landlord=this.getLL(res.data[key].general.landlordId)
            this.wholeApts.push(res.data[key])
          }
        }
      })
    },
    getLL(id){
      // console.log(id);
      let t=this.landlords.find(function(el) {
        return el.id==id
      })
      return t
      // console.log(t);
    },
    getLLReqs(id){
      // axios.get('bookingRequests.json?orderBy="landlordId"&equalTo="'+id+'"')
      // .then(res=>{
      //   for (var key in res.data) {
      //     if (res.data.hasOwnProperty(key) && res.data[key].hasOwnProperty("status")) {
      //       res.data[key].id=key
      //       this.llBR.push(res.data[key])
      //     }
      //   }
      // })
      this.llsCrChecked++
      axios.get('completedOrders.json?orderBy="landlordId"&equalTo="'+id+'"')
      .then(res=>{
        this.llsCrCompleted++
        for (var key in res.data) {
          if (res.data.hasOwnProperty(key) && res.data[key].hasOwnProperty("status") && res.data[key].status!='Canceled') {
            res.data[key].id=key
            this.llCR.push(res.data[key])
          }
        }
      })
    },
    getTReqs(id){
      // axios.get('bookingRequests.json?orderBy="tenantId"&equalTo="'+id+'"')
      // .then(res=>{
      //   // console.log(res.data)
      //   if( Object.keys(res.data).length>1){
      //       this.uniqueBR.push(res.data[Object.keys(res.data)[0]])
      //   }
      //   for (var key in res.data) {
      //     if (res.data.hasOwnProperty(key) && res.data[key].hasOwnProperty("status")) {
      //       res.data[key].id=key
      //       this.br.push(res.data[key])
      //     }
      //   }
      // })
      this.llsCrChecked++

      axios.get('completedOrders.json?orderBy="tenantId"&equalTo="'+id+'"')
      .then(res=>{
        this.llsCrCompleted++
        // console.log(res.data);
        if( Object.keys(res.data).length>1){
            this.uniqueCR.push(res.data[Object.keys(res.data)[0]])
        }
        for (var key in res.data) {
          if (res.data.hasOwnProperty(key) && res.data[key].hasOwnProperty("status")) {
            res.data[key].id=key
            this.cr.push(res.data[key])
          }
        }
      })
      axios.get('visits.json?orderBy="tenantId"&equalTo="'+id+'"')
      .then(res=>{
        // console.log(res.data)
        if( Object.keys(res.data).length>1){
            this.uniqueVisits.push(res.data[Object.keys(res.data)[0]])
        }
        for (var key in res.data) {
          if (res.data.hasOwnProperty(key) && res.data[key].hasOwnProperty("status")) {
            res.data[key].id=key
            res.data[key].landlord=this.landlords
            this.visits.push(res.data[key])
          }
        }
      })
    }
  },
  created() {
    this.startDate=moment().subtract(1,"week").day(1).format('YYYY-MM-DD')
    this.endDate=moment().add(1,"D").format('YYYY-MM-DD')
  },
  filters:{
    dateFilter(t){
      if (moment(t).isValid()){
        return moment(t).format("LL")
      } else {
        return t
      }
    },
  },
  metaInfo() {
      return {
        title:'Employees - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.bold {
    font-weight: bold;
}
</style>
