<template>
<div>
  <v-card>
    <v-card-title>
      Visit Payment
      <v-btn color="primary" @click="newPaymentDialog=true">Add</v-btn>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]' :headers="headers" :items="otherPayments" :search="search" :pagination.sync="pagination" :loading="loading">
      <template slot="items" slot-scope="props">
        <td>{{props.item.ts | dateFormat}}</td>
        <td>{{props.item.userName}} ({{props.item.email}})</td>
        <td>{{props.item.price}}</td>
        <td>{{props.item.comment}}</td>
        <td><span v-if="props.item.paidTS!=undefined">PAID ON: {{props.item.paidTS|dateFormat}}</span> <span v-else> Not Paid Yet</span> </td>
        <td><a :href="'https://myroomabroad.com/pay/'+props.item['.key']">https://myroomabroad.com/pay/{{props.item['.key']}}</a></td>
        <td><v-text-field
          v-model="props.item.deleteText"
          placeholder="DELETE"
          v-if="props.item.deleteText!='DELETE'"
        ></v-text-field> <v-btn color="error" :disabled="props.item.deleting" v-else @click="deleteItem(props.item)">DELETE</v-btn></td>
        <td><a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/otherPayments/"+props.item[".key"]'>{{props.item['.key']}}</a></td>
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </v-card>
  <v-layout row justify-center>
    <v-dialog v-model="newPaymentDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">New Payment</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="tUser"
            :items="users"
            label="Who should pay?"
            prepend-icon="person"
            return-object
            :filter="tFilter"

            >
            <template
                   slot="selection"
                   slot-scope="{ item, selected }"
            >
            <span>{{item.userName}} ({{item.email}})</span>
           </template>
           <template
             slot="item"
             slot-scope="{ item, tile }"
           >
             <v-list-tile-avatar
               color="primary"
             >
               {{ item.userName.charAt(0) }}
             </v-list-tile-avatar>
             <v-list-tile-content>
               <v-list-tile-title v-text="item.userName"></v-list-tile-title>
               <v-list-tile-sub-title v-text="item.email"></v-list-tile-sub-title>
             </v-list-tile-content>
           </template>
          </v-autocomplete>
          <v-text-field
            type="number"
            label="Amount"
            suffix="NT$"
            v-model="price"
          ></v-text-field>
          <v-text-field
            v-model="comment"
            label="Write what they are paying for"
          ></v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" dark flat :disabled="disable" @click.native="newPaymentDialog = false">Close</v-btn>
          <v-btn color="primary " flat :disabled="disable" @click.native="addPayment">Add Payment</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import AWSSES from '../../aws-ses'

export default {
  name: 'Visit-Payments',
  data: () => ({
    tUser:null,
    price:100,
    comment:"",
    search:"",
    loading:true,
    newPaymentDialog:false,
    disable:false,

    pagination: {
      descending: true
    },
    headers: [
      {text: 'Date Asked',
        value: 'ts'
      },
      {text: 'Tenant Username',
        value: 'userName'
      },
      {text: 'Price',
        value: 'price'
      },
      {text: 'Comment',
        value: 'comment'
      },

      {text: 'Payment Date',
        value: 'payTS'
      },

      {
        text:'Payment Link',
        sortable: false,
      },
      {
        text:'Write: "DELETE"',
        sortable: false,
      },
      {text: 'DB', value:'.key'}
    ],

  }),

  firebase() {
    return {
      users: {
        source: firebase.database().ref('users'),

        readyCallback: function() {
          this.loading=false
        }
      },
      otherPayments:{
        source: firebase.database().ref('otherPayments')
      }
    }
  },
  methods: {
    deleteItem(item){
      axios.delete('otherPayments/'+item['.key']+'.json?auth=' + this.$store.state.auth.idToken)
    },
    tFilter (item, queryText) {
      // console.log(this.users[item.tenantId], item.tenantId, 'the search based on this:', item.roomNumber);
      let textOne=""
      let textTwo=""
      let textThree=""
      if (item.userName!=undefined){
        textOne = item.userName.toLowerCase()
      }
      if (item.email!=undefined){
        textTwo = item.email.toLowerCase()
      }
      if (item.name!=undefined){
        textThree = item.name.toLowerCase()
      }
      console.log(queryText, textOne,textTwo,textThree);
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText)>-1 || textThree.indexOf(searchText)>-1
    },
    addPayment(){
      this.disable=true
      let rec={
        ts:moment().format("X"),
        userName:this.tUser.userName,
        email:this.tUser.email,
        userId:this.tUser['.key'],
        price:this.price,
        comment:this.comment,
      }
      axios.post('otherPayments.json?auth=' + this.$store.state.auth.idToken,rec)
      .then(res=>{
        console.log('added');
        this.disable=false
        this.newPaymentDialog=false
        this.tUser=null
        this.price=null
        this.comment=null
      })
      .catch(e=>{
        console.log('Error');
        alert('error happened. Try again tell Vincent if it persists.')
        this.disable=false
      })
    }
  },
  filters: {
    dateFormat: function(t) {
      if (t) {
        // return t
        return moment(t,'X').format('ll')
      } else {
        return "Nothing to show"
      }
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),

  },
  created() {
    // moment.tz.setGlobal('UTC')
  },
  components: {
  },
  metaInfo() {
      return {
        title:'Visit Payments - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.light-grey {
    color: rgba(0,0,0,.50) !important;
}
.reminder{
    color:red;
}
</style>
