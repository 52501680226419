<template lang="en">
  <div class="">

    <v-card>
      <v-card-title primary-title>
        Change the room of :  {{this.user.userName}}
      </v-card-title>
      <!-- <v-btn color="primary" @click="test()">Go</v-btn> -->
      <v-card-text>
        <v-autocomplete
          v-model="newRoom"
          :items="lists"
          label="Select the new room"
          prepend-icon="domain"
          :filter="tFilter"
          >
          <template
                 slot="selection"
                 slot-scope="{ item, selected }"
          >
               <span v-if="" v-text="item.general.fullRoomNumber"></span>
         </template>
         <template
           slot="item"
           slot-scope="{ item, tile }"
         >
           <v-list-tile-avatar
             color="primary"
           >
             {{ item.general.fullRoomNumber.charAt(0) }}
           </v-list-tile-avatar>
           <v-list-tile-content>
             <v-list-tile-title v-text="item.general.fullRoomNumber"></v-list-tile-title>
             <v-list-tile-sub-title  v-text="item.billing.price+' - '+item.type"></v-list-tile-sub-title>
           </v-list-tile-content>
         </template>
        </v-autocomplete>
        <v-text-field
          label="Set Price"
          v-model="price"
          type="number"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="disable" @click="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog

      v-model="popup"
      scrollable persistent
      :overlay="true"
      max-width="350px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{popupMessage}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="done()">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showSnackbar"
      color="primary"
      :timeout="6000"

    >
      {{snackbarMessage}}
      <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import AWSSES from '../../aws-ses'

export default {
  name: 'ChangeRoom',
  data: () => ({
    user:{},
    newRoom:{},
    lists:[],
    popup:false,
      popupMessage:"",
    price:"",
    disable:false,
    order:{

    },
    snackbarMessage:"",
    showSnackbar:false,
    blockForward:false,
  }),
  firebase() {
    return {

      apts:{
        source:firebase.database().ref('apartments'),
        asObject:true,
        readyCallback:function(){
        }
      },
      rooms:{
        source:firebase.database().ref('rooms').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          for (var i = 0; i < this.rooms.length; i++) {
            this.rooms[i].type='room'
            this.lists.push(this.rooms[i])
          }
        }
      },
      studios:{
        source:firebase.database().ref('studios').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          for (var i = 0; i < this.studios.length; i++) {
            this.studios[i].type='studio'
            this.lists.push(this.studios[i])
          }
        }
      },
      transfTBM:{
        source:firebase.database().ref('transfTBM').orderByChild('crId').equalTo(this.$route.params.orderId),
      },
      rent:{
        source:firebase.database().ref('rents').orderByChild('crId').equalTo(this.$route.params.orderId),
      },
    }
  },
  methods:{
    adminNotif(){

      let emailToSend={
        admin:this.$store.state.auth.user.userName,
        function:'Change Room',
        date: moment().format("LLLL"),
        crId:this.$route.params.orderId,
        before:"Room: "+this.order.fullRoomNumber+"\nPrice: "+this.order.price+"\nRoom ID: "+this.order.listingId,
        after:"Room: "+this.newRoom.general.fullRoomNumber+"\nPrice: "+this.price+"\nRoom ID: "+this.newRoom['.key'],
        room:this.newRoom.general.fullRoomNumber,
        tenant:this.order.tenant.name,
      }
      console.log(emailToSend);
      let admin={
        name:'Sandrine',
        email:"sandrine@myroomabroad.com"
      }
      AWSSES.adminNotif(admin, emailToSend, 'ACREdited')
    },
    done(){
      this.popup=false
      this.$router.push('/admin/completedRequests')
    },
    tFilter (item, queryText) {
      // console.log(this.users[item.tenantId], item.tenantId, 'the search based on this:', item.roomNumber);
      let textOne=""
      let textThree=""
      try{
        textOne=item.general.fullRoomNumber.toLowerCase()
      } catch (error){
        console.error('roomNumberError:', item);
      }
      let textTwo=""
      try{
        textThree=item.type.toLowerCase()
      } catch (error){
        console.error("item type error", item);
      }
      if (item.type=="room"){
        textTwo=this.apts[item.apartmentId].general.name.toLowerCase()
      } else {
        textTwo=item.general.name.toLowerCase()
      }

      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText)>-1 || textThree.indexOf(searchText)>-1
    },
    confirm(){
      this.disable=true
      try {
        this.adminNotif()
      } catch (e) {
        console.log(e);
        alert('something happend with notifications, alert Vincent')
      }
      console.log(this.newRoom, ' YOur new room');
      if (this.newRoom=={}||this.newRoom==undefined || this.newRoom.type==undefined ||!this.newRoom.hasOwnProperty('type') || this.price==""||this.price==undefined){
        alert('you bozo, add a room and price ;)')
        this.disable=false
        return
      } else {
        // this.disable=true
      //change rent, change BR, change CR, change previous to available now, change new room to available date,...
        let db=""
        let llId=""
        let location={}
        let listUtil={}
        if (this.newRoom.type=='room'){
          db='rooms'
          location=this.apts[this.newRoom.apartmentId].general.location
          llId=this.apts[this.newRoom.apartmentId].general.landlordId
          listUtil.name=this.apts[this.newRoom.apartmentId].contractDetails.utilities
          listUtil.amount=this.apts[this.newRoom.apartmentId].contractDetails.utilitiesAmount
          listUtil.comment=this.apts[this.newRoom.apartmentId].contractDetails.utilitiesComment
        } else if (this.newRoom.type=='studio'){
          db='studios'
          llId=this.newRoom.general.landlordId
          location=this.newRoom.general.location
          listUtil.name=this.newRoom.contractDetails.utilities
          listUtil.amount=this.newRoom.contractDetails.utilitiesAmount
          listUtil.comment=this.newRoom.contractDetails.utilitiesComment
        } else {
          console.log('check your types');
          llId=this.newRoom.general.landlordId
        }
        // update new room to make unavailable till end of contract
        console.log('patch new room', {nextAvailability:this.order.dates.mod});
        axios.patch(db+'/'+this.newRoom['.key']+'/general.json?auth='+this.$store.state.auth.idToken,{nextAvailability:this.order.dates.mod})
        .then(()=>{
          console.log('success on new room');
        })
        .catch(err=>{
          console.log('err:', err);
          this.disable=false
        })
        if (this.order.type=='room'){
          db='rooms'
        } else if (this.order.type=='studio'){
          db='studios'
        }
        // update old room, to make available now
        console.log('patch old room:',{nextAvailability:moment().format()});

        axios.patch(db+'/'+this.order.listingId+'general.json?auth='+this.$store.state.auth.idToken,{nextAvailability:moment().format()})
        .then(()=>{

          console.log('success on old room');
        })
        .catch(err=>{
          console.log('err:', err);
          this.disable=false
        })
        let vm=this
        axios.get('users/'+llId+'.json')
        .then(res=>{
          let ll=res.data
          let newBr={
            fullRoomNumber:vm.newRoom.general.fullRoomNumber,
            landlord:{
              email: res.data.email,
              name: res.data.userName
            },
            listUtil:listUtil,
            landlordId:llId,
            location:location,
            oldRoomId:this.order.listingId,
            oldRoomName: this.order.fullRoomNumber,
            oldRoomPrice: this.order.price,
            changeDate:new Date().getTime(),
            listingId:vm.newRoom['.key'],
            price:vm.price,
            type:vm.newRoom.type
          }
          console.log('patch BR:',newBr);

          // axios.patch('bookingRequests/'+this.order.id+'.json?auth='+this.$store.state.auth.idToken,newBr)
          // .then(res=>{
          //
          //   console.log('succes on BR');
          // })
          // .catch(err=>{
          //   console.log('err:', err);
          //   this.disable=false
          // })
          let newCR=newBr
          newCR.listUtil=listUtil
          console.log('patch order:',newCR);
          axios.patch('completedOrders/'+this.$route.params.orderId+'.json?auth='+this.$store.state.auth.idToken,newCR)
          .then(res=>{
            console.log('succes on CR');
          })
          .catch(err=>{
            console.log('err:', err);
            this.disable=false
          })
          let newRent= {
            utilities:listUtil.amount,
            landlordId:llId,
            listUtil:listUtil,
            listingId:vm.newRoom['.key'],
            roomNumber:vm.newRoom.general.fullRoomNumber,
            price:vm.price,
            type:vm.newRoom.type,
          }
          this.compareRents(newRent,this.order)
        })
      }
    },
    updateRents(newRent, changeTransf){
      if (this.transfTBM!=null && this.transfTBM.length>0){
          for (var i = 0; i < this.transfTBM.length; i++) {
            if (this.transfTBM[i].deposit!=true && this.transfTBM[i].roomChange!=true && changeTransf==true){
              newRent.prepaid=this.transfTBM[i].price
              axios.patch('transfTBM/'+this.transfTBM[i]['.key']+'.json?auth='+this.$store.state.auth.idToken,newRent)
              .then(res=>{
                console.log('succes on trnasfTBM');
                if(this.blockForward!=true){
                  this.done()
                }
              })
              .catch(err=>{
                console.log('err:', err);
              })

            }
          }
      }
      if (this.rent!=null && this.rent.length>0){
          for (var i = 0; i < this.rent.length; i++) {
            if (this.rent[i].deposit!=true && this.rent[i].roomChange!=true){
              console.log(this.rent[i]['.key'], newRent);
              axios.patch('rents/'+this.rent[i]['.key']+'.json?auth='+this.$store.state.auth.idToken,newRent)
              .then(res=>{
                console.log('succes on Rent');
                if(this.blockForward!=true){
                  this.done()
                }
              })
              .catch(err=>{
                console.log('err:', err);
              })

            }
          }
      }
    },
    compareRents(newRent, oldRent){
      let rentDiff=parseInt(newRent.price)-parseInt(oldRent.price)
      let utilDiff=parseInt(newRent.utilities)-parseInt(oldRent.utilities)
      let comm=0
      if (rentDiff+utilDiff>0){
        comm=(rentDiff+utilDiff)*.2
      }
      console.log(newRent.utilities,utilDiff,oldRent.utilities)
      let diff=rentDiff+utilDiff+comm
      let newPayment={
        brId:oldRent.id,
        crId:this.$route.params.orderId,
        comment:'Change of room from '+oldRent.fullRoomNumber+ ' to '+newRent.roomNumber ,
        invoiceComment:'Change of room from '+oldRent.fullRoomNumber+ ' to '+newRent.roomNumber ,
        invoiceSent:true,
        utilities:0,
        landlordId:newRent.landlordId,
        listingId:oldRent.listingId,
        price:diff,
        roomNumber:oldRent.fullRoomNumber,
        roomChange:true,
        nextRent:diff,
        type:oldRent.type,
        tenantId:oldRent.tenantId,
      }
      if (newRent.landlordId==oldRent.landlordId){
        console.log('COMPARE:',diff,newRent,oldRent);
        if (diff==0){

          this.snackbarMessage="Refund added to TransferTBM"
          this.showSnackbar=true
          console.log("Same price, Do nothing");
          this.updateRents(newRent, true)
        } else if (diff>0){
          axios.post('rents.json?auth=' +this.$store.state.auth.idToken,newPayment)
          .then(()=>{
            this.snackbarMessage="Payment request added"
            this.showSnackbar=true

            console.log('newRentPosted')
            this.updateRents(newRent, true)
          })
          .catch(err=>{
            console.log(err);
          })
          axios.patch('users/'+oldRent.tenantId+'.json?auth=' + this.$store.state.auth.idToken, {deposit:true})
          .then(()=>{
            console.log('userPatched')
            let tUser={
              name:oldRent.tenant.name,
              email:oldRent.tenant.email
            }
            AWSSES.emailing(tUser, 'TChangeRoom')
          })
          .catch(err=>{
            console.log(err);
          })

        } else if (diff<0){
          this.blockForward=true
          this.popup=true
          newPayment.landlordId=oldRent.landlordId
          this.popupMessage="Please proceed to a refund of "+diff+' to '+oldRent.tenant.email
          axios.post('transfTBM.json?auth='+ this.$store.state.auth.idToken,newPayment)
          .then(()=>{
            this.snackbarMessage="Refund added to TransferTBM"
            this.showSnackbar=true
            this.updateRents(newRent, true)
            console.log('success');
          })
          .catch(err=>{
            console.log(err);
          })
          let details={
            total:diff,
            ref:moment().format('YYMMDD')+oldRent.tenant.name.charAt(0)+oldRent.tenant.name.split(" ").pop().charAt(0),
            listingId:oldRent.listingId,
            tenantId:oldRent.tenantId,
            type:"CC",
            comment:newPayment.comment,
            ts:moment().format('YYMMDD')
          }
          axios.post('payIn.json?auth='+this.$store.state.auth.idToken,details)
          .catch(err=>{
            console.log(err);
          })

        }
      } else {
        if(diff<=0){
          this.blockForward=true
          this.popupMessage="Please proceed to a refund of "+diff+' to '+oldRent.tenant.email
          this.popup=true
          this.changeLandlord(newPayment,oldRent, newRent,diff)
        } else {
          this.changeLandlord(newPayment,oldRent, newRent,diff)
          //post the new rent
          axios.post('rents.json?auth=' +this.$store.state.auth.idToken,newPayment)
          .then(()=>{
            this.snackbarMessage="Payment request added"
            this.showSnackbar=true
            console.log('newRentPosted')
            this.updateRents(newRent, false)
          })
          .catch(err=>{
            console.log(err);
          })
          axios.patch('users/'+oldRent.tenantId+'.json?auth=' + this.$store.state.auth.idToken, {deposit:true})
          .then(()=>{
            console.log('userPatched')
          })
          .catch(err=>{
            console.log(err);
          })
        }
          // if different landlord, Remove the original rent from the old landlord and add the  whole rent to new landlord.
          // remove from previou
      }

    },
    changeLandlord(newPayment, oldRent, newRent, diff){
      let oldToRemove={
          brId:oldRent.id,
          crId:this.$route.params.orderId,
          comment:'Change of room from '+oldRent.fullRoomNumber,
          invoiceComment:'Change of room from '+oldRent.fullRoomNumber,
          listingId:oldRent.listingId,
          price:-parseInt(oldRent.price),
          roomNumber:oldRent.fullRoomNumber,
          roomChange:true,
          type:oldRent.type,
          tenantId:oldRent.tenantId,
          utilities:oldRent.utilities,
          listUtil:oldRent.listUtil,
          landlordId:oldRent.landlordId
      }

      let newToAdd={
          brId:oldRent.id,
          crId:this.$route.params.orderId,
          comment:'Change of room to '+newRent.roomNumber,
          invoiceComment:'Change of room to '+newRent.roomNumber,
          listingId:newRent.listingId,
          roomNumber:newRent.roomNumber,
          roomChange:true,
          type:newRent.type,
          tenantId:newRent.tenantId,
          utilities:newRent.utilities,
          listUtil:newRent.listUtil,
          landlordId:newRent.landlordId
      }
      if (diff<=0){
        newToAdd.price=newRent.price
      } else if (diff>0){
        newToAdd.price=oldRent.price
        newToAdd.comment = newToAdd.comment+' part 1'
        newToAdd.invoiceComment = newToAdd.invoiceComment+' part 1'
      }
      console.log('Change Landlords',newToAdd, oldToRemove);
      axios.post('transfTBM.json?auth='+ this.$store.state.auth.idToken,oldToRemove)
      .then(()=>{
        console.log('success');
        axios.post('transfTBM.json?auth='+ this.$store.state.auth.idToken,newToAdd)
        .then(()=>{
          this.snackbarMessage="Refund and New landlord payment added to TransferTBM"
          this.showSnackbar=true
          this.updateRents(newRent, false)
          console.log('success');
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log(err);
      })
    },
    getUser(){
        axios.get('users/'+this.order.tenantId+'.json')
        .then(res=>{
          this.user=res.data
        })
    }
  },
  created(){
    axios.get('completedOrders/'+this.$route.params.orderId+'.json')
    .then(res=>{
      this.order=res.data
      this.getUser()
    })
  }
}
</script>

<style lang="css" scoped>
</style>
