<template>
<div>
  <v-card>
   <v-card-title>
     Recent Payments Received
     <v-spacer></v-spacer>
     <v-btn color="primary" :disabled="loaded" @click="loadAll">load All</v-btn>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="tPayIn"
     :loading="loading"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td>{{ props.item.ts | date}}</td>
       <td><span v-if="users[props.item.tenantId]!=undefined">{{ users[props.item.tenantId].name}}</span></td>
       <td>{{ props.item.total}} TW$</td>
       <td>
           {{props.item.comment}}
       </td>
       <td>{{props.item.type}}</td>
       <td><v-btn color="primary" @click="setAcc(props.item)">SET Auto CC</v-btn></td>
       <td>
           <a :href='"https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/payIn/"+props.item[".key"]' target="blank">{{ props.item['.key'] }}</a>
       </td>

     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import axios from 'axios'
export default {
  name: 'Payments',
  data: () => ({
    pagination:{},
    headers: [
      { text:'Date', value: 'ts'},
      { text: 'Tenant', value: 'tName' },
      { text: 'Amount', value: 'total' },
      { text: 'Comments', value: 'comment'},
      {text:'type', value:'type'},
      { text: 'id', value:'.key'}
    ],
    search: null,
    searched: [],
    loaded:false,
    loading:true,
    tPayIn:[]
  }),
  firebase() {
    return {
      payIn: {
        source:firebase.database().ref('payIn').orderByKey().limitToLast(500),
        readyCallback:function(){
          this.matchTransfsUsers(this.payIn)
        }
      },
      users:{
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){

          // this.checkTransfers()
          this.matchTransfsUsers(this.payIn)
        }

      }
    }
  },
  methods: {
    setAcc(item){
      // console.log('payIn/'+item['.key']+'.json?');
      axios.patch('payIn/'+item['.key']+'.json?auth='+ this.$store.state.auth.idToken, {type:'ACC'})
      .then(res=>{
        console.log('success', res);
      })
      .catch(err=>{
        console.log(err, 'ERROR');
      })
    },
    loadAll(){
      console.log('get all');
      this.load=true
      this.loaded=true
      let all=[]
      axios.get('payIn.json')
      .then(res=>{
        for (var key in res.data) {
          if (res.data.hasOwnProperty(key)){
            let temp=res.data[key]
            temp['.key']=key
            all.push(temp)
          }
        }
        this.matchTransfsUsers(all)
      })
      .catch(e=>{
        this.loaded=false
        console.error(e)
      })
    },
    matchTransfsUsers(payIn){
      console.log('match the landlords')
      this.tPayIn=payIn
      for (var i in this.tPayIn) {
        // console.log('set ll', i);
        this.tPayIn[i].id=this.tPayIn[i]['.key']
        let date =new Date(moment(this.tPayIn[i].ts,"YYMMDD"))
        this.tPayIn[i].time=date.getTime()
        if (this.users instanceof Object && this.users[this.tPayIn[i].tenantId]!=undefined){
          this.tPayIn[i].tName=this.users[this.tPayIn[i].tenantId].name
        }
      }
      this.loading=false
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },

  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
  },
  filters:{
    date(t){
      return moment(t, "YYMMDD").format('LL')
    }
  },
  metaInfo() {
      return {
        title:'Pay In - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
