<template lang="html">
  <div class="">
    <v-layout  wrap align-center :class="{ invalid : !valid }">
      <v-flex xs3>
        {{coupon.name}}
      </v-flex>
      <v-flex xs2>
        {{coupon.val}} {{valSuffix}}
      </v-flex>
      <v-flex xs2>
        {{useType}}
      </v-flex>
      <v-flex xs2>
        {{status}} <span v-if="status=='Valid' && coupon.expDate!=''"><br>(Expires: {{coupon.expDate | date2}})</span>

      </v-flex>

      <v-flex xs2>
        <v-tooltip top v-if="coupon.sent==true">

          <v-btn slot="activator" color="primary" @click="send" fab small dark>
            <v-icon>undo</v-icon>
          </v-btn>
          <span>Undo, the coupon has not been given.</span>
        </v-tooltip>
        <v-tooltip top v-else>

          <v-btn slot="activator" color="primary" @click="send" fab small dark>
            <v-icon>send</v-icon>
          </v-btn>
          <span>Give the coupon</span>
        </v-tooltip>
        <v-tooltip top v-if="status=='Expired'">

          <v-btn color="error" slot="activator" fab small dark @click="addDays">
            <v-icon>replay_10</v-icon>
          </v-btn>
          <span>Add 10 days to the coupon</span>
        </v-tooltip>
        <v-tooltip top v-else>

          <v-btn color="error" slot="activator" fab small dark @click="stop">
            <v-icon>stop</v-icon>
          </v-btn>
          <span>Disable the coupon</span>
        </v-tooltip>
        <v-tooltip top>

          <v-btn color="orange" slot="activator" fab small dark @click="edit">
            <v-icon>edit</v-icon>
          </v-btn>
          <span>Edit the coupon</span>
        </v-tooltip>

      </v-flex>
    </v-layout>

  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
export default {
  props:['coupon','index','type'],
  data: () => ({
    show:false,
  }),
  computed:{
    db(){
      if (this.type=="rooms"){
        return 'rCoupons/'
      } else {
        return 'evCoupons/'
      }
    },
    status(){
      if (this.coupon.expDate!=""){
        if (moment(this.coupon.expDate).isBefore(moment())) {
          return 'Expired'
        }
      }
      if (this.coupon.sUse==true){
        if (this.coupon.used==true){
          return 'Used'
        } else if (this.coupon.sent==true) {
          return 'Shared but unused'
        } else {
          return 'Valid'
        }
      } else {
        return 'Valid'
      }
    },
    useType(){
      if (this.coupon.sUse==true){
        return 'Single use'
      } else {
        return 'Multiple use'
      }
    },
    valid(){
      if (this.status=='Valid') {
        return true
      } else {
        return false
      }
    },
    valSuffix(){
      if (this.coupon.absVal==true){
        return "TW$"
      } else {
        return "%"
      }
    }
  },
  methods: {
    changeSent(coupon, payload) {
      console.log(payload, 'Change event');
      coupon.sent=payload
    },
    edit(){
      this.$emit('edit')
    },
    send() {
        console.log('this coupon is sent');
        let t=this.coupon.sent
        if (t==true){
          t=false
        } else {
          t=true
        }
        this.$emit('editCSent',t)
        axios.patch(this.db+this.coupon['.key']+'.json?auth='+this.$store.state.auth.idToken,{sent:t})
        .then(()=>{
          console.log('success');
        })
        .catch(err=>{
          console.log('err', err);
        })
    },
    stop(){
      let t=this.coupon.expDate
      t=moment().subtract(1,'days')
      axios.patch(this.db+this.coupon['.key']+'.json?auth='+this.$store.state.auth.idToken,{expDate:t})
      .then(()=>{
        console.log('success');
      })
      .catch(err=>{
        console.log('err', err);
      })
    },
    addDays(){
      let t=this.coupon.expDate
      console.log();
      t=moment().add(10,'days')
      console.log(t.format('LL'));
      axios.patch(this.db+this.coupon['.key']+'.json?auth='+this.$store.state.auth.idToken,{expDate:t})
      .then(()=>{
        console.log('success');
      })
      .catch(err=>{
        console.log('err', err);
      })
    }
  },

  filters:{
    date(t){
      return moment(t).format('LLL')
    },
    date2(t){
      return moment(t).format('D MMM, YYYY')
    }
  },
  components: {

  }

}
</script>

<style lang="css" scoped>
.invalid{
      background-color:"rgba(0,0,0,.05)"
}
</style>
