<template>
<div>
  <v-card>
   <v-card-title>
     Cancellations
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="cancellations"
     item-key=".key"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td>
         <div class="" v-if="props.item.cancelDate!=undefined">
           Cancel date: {{props.item.cancelDate | ts}}
         </div>
         <div class="" v-else-if="props.item.cancelTS!=undefined">
           CancelTS: {{props.item.cancelTS|ts }}
         </div>
         <div class="" v-else>
           BookingTS: {{props.item.confirmTimestamp|ts}}
         </div>
       </td>
       <td>{{props.item.fullRoomNumber}}</td>
       <td>{{props.item.tenantId}} {{props.item.tenant.name}}<br>{{props.item.tenant.email}}</td>
       <td>{{props.item.dates.mid | ts}} -> {{props.item.dates.mod | ts}} </td>
       <td>{{props.item.refund | refundFilter}}</td>
       <td>{{props.item.status}}</td>
       <td>{{props.item.cancelReason}}</td>
       <td><a :href="'https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/completedOrders/'+props.item['.key']"> {{props.item['.key']}}</a></td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'


export default {
  name: 'Apartments',
  data: () => ({
    pagination:{descending:true},
    headers: [
      { text: 'Cancel TS', value: 'cancelTS' },
      // { text:'Confirm TS', value:'confirmTimestamp'},
      { text: 'Room Number', value:'fullRoomNumber' },
      { text: 'Tenant info', value:'tenant.email' },
      { text: 'Contract Dates', value:'dates.mid' },
      { text:'Refund status', value:'refund'},
      { text: 'Booking Status', value:'status'},
      { text: 'Cancellation reason', value:'status'},
      { text: 'DB Link', sortable:false},
    ],

    search: null,
    rooms:[],
    toBeDeleted:[],
    json:"",
    searched: [],
    users: [],
    visitedVals:[
      {text:'Visited', value:2},
      {text:'Not visited', value:0}
    ],
    studiosList:[],
    aptsList:[],
    wholeAptsList:[],
    allFlats:[],
  }),
  filters: {
    dateFormat: function(t) {
      return moment(t).format('l')
    },
    ts: function(x) {
      return moment(x).format('YYYY/MM/DD')
    },
    refundFilter: function(x){
      if (x==true){
        return "Refund accepted"
      } else {
        return "Not entitled to a refund"
      }
    }

  },

  firebase() {
    return {
      cancellations:{
        source:firebase.database().ref('completedOrders').orderByChild('status').equalTo('Canceled'),
        readyCallback:function(){
          // console.log('got the apts, try the sync');

        }
      },
      users: {
        source:firebase.database().ref('users'),
        asObject:true,
        readyCallback:function(){
        }

      },
    }

  },
  methods: {
  },
  created() {
  },
  metaInfo() {
      return {
        title:'Cancellations - My Room Abroad Admin' // set a title
    }
  },


}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.draft{
  color:grey;
}
</style>
