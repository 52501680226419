<template>
<div>
  <v-card>
   <v-card-title>
     Apartments
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="allFlats"
     item-key=".key"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td class="" :class="{draft : props.item.draft!==false}">{{ props.item.type }}</td>
       <td class="" :class="{draft : props.item.draft!==false}" ><a v-if="props.item.general!=undefined" :href="props.item.roomLink">{{ props.item.general.name }}</a><span v-if ="props.item.draft!==false"> (draft)</span><v-btn color="rgb(255,192,0)"  :href="props.item.editLink" dark>Edit</v-btn> </td>
       <td class="" :class="{draft : props.item.draft!==false}">
         <v-select
           :items="visitedVals"
           v-model="props.item.visit"
           label="Visited"
           @change="setVisited(props.item, $event)"
         ></v-select>
       </td>
       <td class="" :class="{draft : props.item.draft!==false}"><a :href="'/landlordDashboard/'+props.item.general.landlordId">{{ props.item.landlordName }}</a></td>
       <td class="" :class="{draft : props.item.draft!==false}"> {{ props.item.llTrustVal }}</td>
       <td class="" :class="{draft : props.item.draft!==false}">{{ props.item.general.lastModified | dateFormat }}</td>
       <td class="" :class="{draft : props.item.draft!==false}"> <v-btn color="error" :disabled="props.item.disableDelete" @click="deleteListing(props.item)">DELETE</v-btn></td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'


export default {
  name: 'Apartments',
  data: () => ({
    pagination:{},
    headers: [
      { text:'type', value:'type'},
      { text: 'Apartment Name', value:'name' },
      { text: 'Visited', value: 'visit' },
      { text: 'Landlord Name', value:'landlordName'},
      { text: 'Trust Value', value:'llTrustVal' },
      { text: 'Last edited', value: 'lastModified' },
      { text: 'Delete Room', sortable:false},
    ],

    search: null,
    rooms:[],
    toBeDeleted:[],
    json:"",
    searched: [],
    users: [],
    visitedVals:[
      {text:'Visited', value:2},
      {text:'Not visited', value:0}
    ],
    studiosList:[],
    aptsList:[],
    wholeAptsList:[],
    allFlats:[],
  }),
  filters: {
    dateFormat: function(t) {
      return moment(t).format('l')
    }
  },

  firebase() {
    return {
      apts:{
        source:firebase.database().ref('apartments'),
        readyCallback:function(){
          // console.log('got the apts, try the sync');
          for (var i = 0; i < this.apts.length; i++) {
            this.apts[i].type='Apartment'
            if (this.apts[i].hasOwnProperty('general')){
              this.apts[i].name=this.apts[i].general.name
              this.apts[i].lastModified=this.apts[i].general.lastModified
            } else {
              this.apts[i].name='Not set'
              this.apts[i].lastModified= ''
              this.apts[i].noPush=true
            }


            if (this.apts[i].visit==undefined){
              this.apts[i].visit=0

            }

            this.setLink(this.apts[i])
            if (this.apts[i].hasOwnProperty('general')){
              this.aptsList.push(this.apts[i])
            } else {
              console.log(this.apts[i]);
              this.toBeDeleted.push(this.apts[i])
            }

          }

          this.syncLists()
        }
      },
      landlords: {
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(20),
        asObject:true,
        readyCallback:function(){
          // console.log('got the LL, try the match');
          this.matchListUser()
        }

      },
      studios:{
        source:firebase.database().ref('studios'),
        readyCallback:function(){
          // console.log('got the studios, try the sync');
          for (var i = 0; i < this.studios.length; i++) {
            // console.log( this.studios[i].draft)

            this.studios[i].type="Studio"
            this.setLink(this.studios[i])
            if (this.studios[i].hasOwnProperty('general')){
              this.studios[i].name=this.studios[i].general.name
              this.studios[i].lastModified=this.studios[i].general.lastModified
            } else {
              this.studios[i].name="Not set"
              this.studios[i].lastModified=""
            }
            if (!this.studios[i].draft && this.studios[i].draft!=undefined){
              this.studios[i].visit=0
              if (this.studios[i].visit==undefined){
                this.studios[i].visit=0
              }

            }
            if (this.studios[i].hasOwnProperty('general')){
              this.studiosList.push(this.studios[i])
            }
          }
          this.syncLists()

        }

      },
      wholeApts:{
        source:firebase.database().ref('wholeApts'),
        readyCallback:function(){
          // console.log('got the studios, try the sync');
          for (var i = 0; i < this.wholeApts.length; i++) {
            // console.log( this.studios[i].draft)
            if (this.wholeApts[i].hasOwnProperty('general')){
              this.wholeApts[i].name=this.wholeApts[i].general.name
              this.wholeApts[i].lastModified=this.wholeApts[i].general.lastModified
            } else {
              this.wholeApts[i].name="Not set"
              this.wholeApts[i].lastModified=""
            }

            this.wholeApts[i].type="Whole apartment"
            this.setLink(this.wholeApts[i])
            if (this.wholeApts[i].hasOwnProperty('general')){
              this.wholeAptsList.push(this.wholeApts[i])
            }
            if (!this.wholeApts[i].draft && this.wholeApts[i].draft!=undefined){
              if (this.wholeApts[i].visit==undefined){
                this.wholeApts[i].visit=0
              }

            }
          }
          this.syncLists()

        }

      },

    }

  },
  methods: {
    setLink(item){
      if (item.type=='studio'){
        item.roomLink='/studioDetails/'+item['.key']
        item.editLink="/studio?studioId="+item['.key']

      } else if(item.type=='Apartment'){
        item.roomLink='/apartmentDetails/'+item['.key']+'/rooms'
        item.editLink="/newApartment?apartmentId="+item['.key']
      } else {
        item.roomLink='/apartmentDetails/'+item['.key']+'/apartment'
        item.editLink="/Apartment?apartmentId="+item['.key']
        // this.pagination.descending = !this.pagination.descending
      }

    },
    setVisited(item, payload){
      // console.log("setting visited",item, payload)
      if(item.type=='Apartment'){
        axios.patch('apartments/'+item['.key']+".json?auth="+this.$store.state.auth.idToken,{visit:payload})
        .catch(err=>{
          console.log('error: ',err);
        })
        if(item.rooms!=undefined){
          for (var id in item.rooms) {
            if (item.rooms.hasOwnProperty(id)) {
              axios.patch('rooms/'+id+'.json?auth='+this.$store.state.auth.idToken,{visit:payload})
              .catch(err=>{
                console.log('error: ',err);
              })
            }
          }
        }
      } else if(item.type=='Studio'){
          axios.patch('studios/'+item['.key']+".json?auth="+this.$store.state.auth.idToken,{visit:payload})
          .catch(err=>{
            console.log('error: ',err);
          })
      } else if(item.type=='Whole apartment'){
        axios.patch('wholeApts/'+item['.key']+".json?auth="+this.$store.state.auth.idToken,{visit:payload})
        .catch(err=>{
          console.log('error: ',err);
        })
      }
    },
    matchListUser(){
      // console.log('the landlordlist:',this.landlords);
      // console.log('the fats: ', this.allFlats);
      if (this.landlords!=null && this.allFlats.length>0){
        // console.log('Do the match');
        // console.log(this.allFlats.length, "length of allflats");
        for (var i = 0; i < this.allFlats.length; i++) {
          if (this.allFlats[i].hasOwnProperty('general') && this.landlords[this.allFlats[i].general.landlordId]!=undefined){
            // console.log('the concerned LL:',this.landlords[this.allFlats[i].general.landlordId].userName)
            this.allFlats[i].landlordName=this.landlords[this.allFlats[i].general.landlordId].userName
            this.allFlats[i].llTrustVal=this.landlords[this.allFlats[i].general.landlordId].trustVal
            this.allFlats[i].id=this.allFlats[i]['.key']
          }

        }
      } else {
        console.log('no can do because: landlords=',this.landlords, this.allFlats,'=allflats');
      }
      console.log('finished the match');
    },

    syncLists(){
      this.allFlats=[]
      if (this.studiosList.length>0){
        this.allFlats=this.allFlats.concat(this.studiosList)
        console.log('finished the sync, try the match');
      }
      if (this.aptsList.length>0){
        this.allFlats=this.allFlats.concat(this.aptsList)
      }
      if (this.wholeAptsList.length>0){
        this.allFlats=this.allFlats.concat(this.wholeAptsList)
      }
      this.matchListUser()
    },
    writeRooms(){
      console.log("write the rooms")
      let obj={rooms:this.rooms}
      this.json=JSON.stringify(obj)
      // console.log('json sting:', this.json)
    },
    checkBR(listingId){

      axios.get('/bookingRequests.json?orderBy="listingId"&equalTo="' + listingId + '"')
      .then(res=>{
        let requests=res.data
        for (var req in requests) {
          if (requests.hasOwnProperty(req)) {
            axios.get('users/'+requests[req].tenantId+'.json')
            .then(res=>{
              let index = res.request.responseURL.lastIndexOf("/")
              let id = res.request.responseURL.substr(index+1).split('.')[0]
              // console.log('the room', res.data)
              let tenantId=id
              if (res.data.bookingRequestOut==req){
                console.log('delete the booking request out from tenantId:', tenantId);
                axios.delete('users/'+tenantId+'/bookingRequestOut.json?auth='+this.$store.state.auth.idToken)
                .then(()=>{console.log('Deleted the request out from tenant')})
                .catch(err=>{
                  console.log('Error:', err);
                })
              }

            })
            .catch(err=>{
              console.log('error with tenants:', err);
            })
            for (var reqInId in this.landlords[requests[req].landlordId].bookingRequestsIn) {
              if (this.landlords[requests[req].landlordId].bookingRequestsIn.hasOwnProperty(reqInId)) {
                if (this.landlords[requests[req].landlordId].bookingRequestsIn[reqInId].bookingRequestId==req){
                  console.log('delete req from :'+requests[req].landlordId+'request id: '+reqInId)
                   axios.delete('users/'+requests[req].landlordId+"/bookingRequestsIn/"+reqInId+'.json?auth='+this.$store.state.auth.idToken)
                   .then(()=>{console.log('Deleted request in from LL')})
                   .catch(err=>{
                     console.log('Error:', err);
                  })

                }
              }
            }
           console.log('delete reqfrom bookingREQuests:',req)
            axios.delete('bookingRequests/'+req+'.json?auth='+this.$store.state.auth.idToken)
            .then(()=>{console.log('Deleted from bookingrequest DB')})
            .catch(err=>{
              console.log('Error:', err);
           })

          }
        }
      })
    },
    deleteListing(listing){
      listing.disableDelete=true
      let id = ""
      let index = this.allFlats.findIndex(item => item['.key']==listing['.key']);
      this.allFlats.splice(index,1)
      // console.log(index);
    //   console.log('DELETE THIS LISTING:', listing)
      if (listing.type =='Apartment'){
        // console.log('listing==apartments');
        if (listing.rooms!=undefined && Object.keys(listing.rooms).length>0){
          for (var roomId in listing.rooms) {
            // console.log('get for this room:',roomId);
            if (listing.rooms.hasOwnProperty(roomId)) {
              this.checkBR(roomId)
              // console.log('delete room with id: ',roomId)
              axios.delete('rooms/'+roomId+'.json?auth='+this.$store.state.auth.idToken)
               // .then(()=>{console.log('Deleted the room!!')})
               .catch(err=>{
                 // console.log('Error:', err);
              })
            }
          }
        }
        // console.log('before for',this.landlords[listing.general.landlordId]);
        for (id in this.landlords[listing.general.landlordId].apartments) {
          // console.log('trythis');
          if (this.landlords[listing.general.landlordId].apartments.hasOwnProperty(id)) {
            // console.log('compare: ',this.landlords[listing.general.landlordId].apartments[id].apartmentId, "  &&   ",listing['.key']);
            if (this.landlords[listing.general.landlordId].apartments[id].apartmentId==listing['.key']){
              // console.log('delete from landlords: '+listing.general.landlordId+' apartment: '+id);
              axios.delete('users/'+listing.general.landlordId+'/apartments/'+id+'.json?auth='+this.$store.state.auth.idToken)
               // .then(()=>{console.log('Deleted the from the landlord/apt!!')})
               .catch(err=>{
                 console.log('Error:', err);
              })
            }
          }
        }
        // console.log('delete from apts');
        // console.log('delete from /apartments:: '+listing['.key']);
        axios.delete('apartments/'+listing['.key']+'.json?auth='+this.$store.state.auth.idToken)
         .then(()=>{
           console.log('Deleted the apt!!')
           // this.$router.go()
        })
         .catch(err=>{
           console.log('Error:', err);
        })

      } else if (listing.type=='Studio') {
        this.checkBR(listing['.key'])
        for (id in this.landlords[listing.general.landlordId].studios) {
          if (this.landlords[listing.general.landlordId].studios.hasOwnProperty(id)) {
            if (this.landlords[listing.general.landlordId].studios[id].studioId==listing['.key']){
              // console.log('delete from landlords: '+listing.general.landlordId+' apartment: '+id);
              axios.delete('users/'+listing.general.landlordId+'/studios/'+id+'.json?auth='+this.$store.state.auth.idToken)
               .then(()=>{console.log('Deleted the studio from the landlord/studios!!')})
               .catch(err=>{
                 console.log('Error:', err);
              })

            }
          }
        }
        // console.log('delete from /studios:: '+listing['.key']);
        axios.delete('studios/'+listing['.key']+'.json?auth='+this.$store.state.auth.idToken)
         .then(()=>{
           console.log('Deleted the studio!!')

           // this.$router.go()
         })
         .catch(err=>{
           console.log('Error:', err);
        })

      }else if (listing.type=='Whole apartment') {
        this.checkBR(listing['.key'])
        for (id in this.landlords[listing.general.landlordId].wholeApts) {
          if (this.landlords[listing.general.landlordId].wholeApts.hasOwnProperty(id)) {
            // console.log('inside first if');
            // console.log(this.landlords[listing.general.landlordId].wholeApts[id], listing['.key']);
            if (this.landlords[listing.general.landlordId].wholeApts[id].apartmentId==listing['.key']){
              // console.log('delete from landlords: users/'+listing.general.landlordId+'/wholeApts/'+id+'.json?auth='+this.$store.state.auth.idToken);
              axios.delete('users/'+listing.general.landlordId+'/wholeApts/'+id+'.json?auth='+this.$store.state.auth.idToken)
               .then(()=>{
                 console.log('Deleted the studio from the landlord/studios!!')
             })
               .catch(err=>{
                 console.log('Error:', err);
              })

            }
          }
        }
        // console.log('delete from /wholeApts:: '+listing['.key']);
        axios.delete('wholeApts/'+listing['.key']+'.json?auth='+this.$store.state.auth.idToken)
         .then(()=>{
           console.log('Deleted the whole apt!!')
         })
         .catch(err=>{
           console.log('Error:', err);
        })

      }
    }
  },
  created() {
  },
  metaInfo() {
      return {
        title:'Apartments - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.draft{
  color:grey;
}
</style>
