<template lang="html">
  <v-card>
    <v-card-title>
      Add A new Request
    </v-card-title>
    <v-card-text>
<!-- TENANT -->
    <v-autocomplete
      v-model="tenant"
      :items="users"
      label="Tenant"
      prepend-icon="person"
      :filter="tFilter"
      >
      <template
             slot="selection"
             slot-scope="{ item, selected }"
      >
           <span v-text="item.userName"></span>
     </template>
     <template
       slot="item"
       slot-scope="{ item, tile }"
     >
       <v-list-tile-avatar
         color="primary"
       >
         {{ item.userName.charAt(0) }}
       </v-list-tile-avatar>
       <v-list-tile-content>
         <v-list-tile-title v-text="item.userName"></v-list-tile-title>
         <v-list-tile-sub-title v-text="item.email"></v-list-tile-sub-title>
       </v-list-tile-content>
     </template>


    </v-autocomplete>
  <!-- Listing ID -->

   <v-autocomplete
      v-model="listing"
      :items="listings"
      label="Listings"
      prepend-icon="meeting_room"
      :filter="lFilter"
    >
    <template
           slot="selection"
           slot-scope="{ item, selected }"
    >
         <span v-text="item.name"></span>
         <span v-if="landlords[item.general.landlordId]!=undefined"> - {{landlords[item.general.landlordId].userName}}</span>
   </template>
   <template
     slot="item"
     slot-scope="{ item, tile }"
   >
     <v-list-tile-avatar
       color="primary"
     >
       {{ item.name.charAt(0) }}
     </v-list-tile-avatar>
     <v-list-tile-content>
       <v-list-tile-title v-text="item.name"></v-list-tile-title>
       <v-list-tile-sub-title v-if="landlords[item.general.landlordId]!=undefined">{{landlords[item.general.landlordId].userName}}</v-list-tile-sub-title>
     </v-list-tile-content>
   </template>


    </v-autocomplete>
   <!-- PRICE -->
  <v-text-field label="Price" prepend-icon="euro_symbol" type="number" v-model="price"></v-text-field>
  <!-- Real dates -->
  <v-layout row wrap>
    <v-flex xs6>
      <v-menu
        :close-on-content-click="false"
        v-model="rMidMenu"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="rMid"
          label="Real Move In"
          prepend-icon="event"
          readonly
        ></v-text-field>
        <v-date-picker v-model="rMid" @input="rMidMenu = false"></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs6>
      <v-menu
        :close-on-content-click="false"
        v-model="rModMenu"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="rMod"
          label="Real Move Out"
          prepend-icon="event"
          readonly
        ></v-text-field>
        <v-date-picker v-model="rMod" @input="rModMenu = false"></v-date-picker>
      </v-menu>
    </v-flex>
  </v-layout>


  <!-- Contract Dates -->
  <v-layout row wrap>
    <v-flex xs6>
      <v-menu
        :close-on-content-click="false"
        v-model="midMenu"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="mid"
          label="Contract Move In"
          prepend-icon="event"
          readonly
        ></v-text-field>
        <v-date-picker v-model="mid" @input="midMenu = false"></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs6>
      <v-menu
        :close-on-content-click="false"
        v-model="modMenu"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="mod"
          label="Contract Move Out"
          prepend-icon="event"
          readonly
        ></v-text-field>
        <v-date-picker v-model="mod" @input="modMenu = false"></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs3>

      <v-checkbox label="Paid" v-model="paid" ></v-checkbox>
    </v-flex>
    <v-flex xs9>
      <v-select :items="currencyOptions" @change="getCurrency" v-model="curr" label="Select the currency" v-if="paid==false" ></v-select>
    </v-flex>
  </v-layout>
  {{this.error}}
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="secondary" @click="$emit('close')">Cancel</v-btn>
    <v-btn color="primary" :disabled="sending" @click="addBR()">save</v-btn>
  </v-card-actions>


</v-card>

</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'


export default {
  name: 'AddRequest',

  data: () => ({
    rMidMenu:false,
    rMid:null,
    rModMenu:false,
    rMod:null,
    midMenu:false,
    mid:null,
    modMenu:false,
    paid:true,
    mod:null,
    error:"",
    price:"",
    listing:{},
    listings:[],
    landlords:{},
    tenant:{},
    tSearch:null,
    lSearch:null,
    sending:false,
    curr:"TWD",
    fx:1,
    currencyOptions: [{
        text: "New Taiwan Dollar (TWD)",
        value: "TWD"
      },
      {
        text: "Euro (EUR)",
        value: "EUR"
      },
      {
        text: "US Dollar (from any USD denominated bank accounts) (USD)",
        value: "USD"
      },
      {
        text: "GB Pound (from any bank account in the UK) (GBP)",
        value: 'GBP'
      },
      {
        text: "Australian Dollar (from any bank account in Australia) (AUD)",
        value: "AUD"
      },
      {
        text: "Chinese Yuan (CNY)",
        value: "CNY",
      },
      {
        text: "Honk Kong Dollar (HKD)",
        value: "HKD"
      },
      {
        text: "Japanese Yen (JPY)",
        value: "JPY",
      },
      {
        text: "Canadian Dollar (CAD)",
        value: "CAD",
      },
      {
        text: "South African Rand (ZAR)",
        value: "ZAR",
      },
      {
        text: "New Zealand Dollar (NZD)",
        value: "NZD"
      },
      {
        text: "Swiss Franc (CHF)",
        value: "CHF"
      },
      {
        text: "Swedish Kron (SEK)",
        value: "SEK"
      },
      {
        text: "Singapore Dollar (SGD)",
        value: "SGD",
      },
      {
        text: "Mexican Peso (MXN)",
        value: "MXN",
      },
      {
        text: "Thai Baht (THB)",
        value: "THB"
      }
    ],

  }),
  firebase() {
    return {
      users: {
        source:firebase.database().ref('users'),
        readyCallback:function(){
          console.log('dousers');
          for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].roleValue>=20){
              this.landlords[this.users[i]['.key']]=this.users[i]
            }
          }

        }
      },
      rooms:{
        source:firebase.database().ref('rooms'),
        readyCallback:function(){
          console.log('do rooms ');
          for (var i = 0; i < this.rooms.length; i++) {
            if (this.rooms[i].draft==false){
              this.rooms[i].type='room'
              this.rooms[i].name=this.rooms[i].general.fullRoomNumber
              this.listings.push(this.rooms[i])
              if (this.apartments!=undefined && this.apartments!={} && this.apartments.hasOwnProperty(this.rooms[i].apartmentId)){
                this.rooms[i].general.landlordId=this.apartments[this.rooms[i].apartmentId].general.landlordId
                this.rooms[i].general.location=this.apartments[this.rooms[i].apartmentId].general.location
              }
            }
          }
        }
      },

      studios:{
        source:firebase.database().ref('studios'),
        readyCallback:function(){
          console.log('do studios');
          for (var i = 0; i < this.studios.length; i++) {
            if (this.studios[i].draft==false){
              this.studios[i].type='studio'
              this.studios[i].name=this.studios[i].general.fullRoomNumber
              this.listings.push(this.studios[i])
            }
          }

        }
      },
      apts:{
        source:firebase.database().ref('wholeApts'),
        readyCallback:function(){
          console.log('do apts');
          for (var i = 0; i < this.apts.length; i++) {
            if (this.apts[i].draft==false){
              this.apts[i].type='apartment'
              this.apts[i].name=this.apts[i].general.fullRoomNumber
              this.listings.push(this.apts[i])
            }
          }
        }
      },
      apartments:{
        source:firebase.database().ref('apartments'),
        asObject:true,
        readyCallback:function(){
          console.log('do apartments');
          if (this.rooms!=undefined && this.rooms!=null &&this.rooms.length>0){
            for (var i = 0; i < this.rooms.length; i++) {
              if (this.rooms[i].draft==false){
                this.rooms[i].general.landlordId=this.apartments[this.rooms[i].apartmentId].general.landlordId
                this.rooms[i].general.location=this.apartments[this.rooms[i].apartmentId].general.location
              }
            }
          }
        }
      }
    }
  },
  methods: {

    utilities(list){
      let t={}
      if (list.type == "room") {
        axios.get('apartments/'+list.apartmentId+'.json')
        .then(res=>{
          let apt=res.data
          t.name=apt.contractDetails.utilities
          if (apt.contractDetails.utilitiesAmount && apt.contractDetails.utilities=='Fixed monthly amount'){
            t.amount= parseInt(apt.contractDetails.utilitiesAmount)
          } else {
            t.amount=0
            t.comment=apt.contractDetails.utilitiesComment
          }
        })
      } else {
        t.name=list.billing.utilities
        if (list.billing.utilitiesAmount && list.billing.utilities=='Fixed monthly amount'){
          t.amount= parseInt(list.billing.utilitiesAmount)
        } else {
          t.amount=0
          t.comment=list.contractDetails.utilitiesComment

        }
      }
      return t
    },
    checkFields(){
      if (this.rMid==null || this.rMod==null || this.mid==null || this.mod==null || this.listing=={} || this.tenant=={}){
        return false
      } else {
        return true
      }
    },
    addBR(){
      //post completed Br --for tenant to see it.
      if (this.checkFields()){
        this.sending=true
        let status='Accepted'
        let curr=this.curr
        let fx=this.fx
        if (this.paid==true){
          status='AdminMade'
          curr="TWD"
          fx=1
        }
        let br={
          tenantId: this.tenant['.key'],
          landlordId:this.listing.general.landlordId,
          listingId: this.listing['.key'],
          location:this.listing.general.location,
          fullRoomNumber:this.listing.name,
          price: this.price,
          oldNextAv: this.listing.general.nextAvailability,
          discount: null,
          fx:fx,
          currency:curr,
          dates: {
            mid:this.mid,
            mod:this.mod
          },
          realDates:{
            mid:this.rMid,
            mod:this.rMod
          },
          listUtil: this.utilities(this.listing),
          dateRequested: moment(),
          status: status,
          type: this.listing.type,
          landlord:{
            name:this.landlords[this.listing.general.landlordId].name,
            email:this.landlords[this.listing.general.landlordId].email
          },
          tenant:{
            name:this.tenant.name,
            email:this.tenant.email
          }
        }
        console.log(br, 'post this BR');
        // post BR
        axios.post('bookingRequests.json?auth='+this.$store.state.auth.idToken,br)
        .then( res=>{
          console.log('BR Posted');
          let brId=res.data.name
          let cr=br
          cr.confirmTimestamp=moment()
          cr.id=brId
          cr.payIn=this.paid
          console.log(br, 'post this CR');
          //post completed CR

          axios.post('completedOrders.json?auth='+this.$store.state.auth.idToken,cr)
          .then(res=>{
            console.log('orderPosted');
            let crId=res.data.name
            axios.patch('bookingRequests/'+brId+'.json?auth='+this.$store.state.auth.idToken,{orderId:crId})
            let rent={
              brId:brId,
              crId:crId,
              listingId:br.listingId,
              type:br.type,
              price:br.price,
              utilities:null,
              tenantId:br.tenantId,
              roomNumber:this.listing.name,
              listUtil:br.listUtil,
              dates:br.dates,
              landlordId:br.landlordId,
              realDates:br.realDates,
              lastRent:moment().format('MMYYYY'),
            }
            console.log('post this rent:', rent);
            // post the rent
            if (this.paid){
              axios.post('rents.json?auth='+this.$store.state.auth.idToken, rent)
              .then(()=>{
                console.log('Finished');
                alert('BR Added')
                this.$emit('close')
              })
            }
          })
          // patch Tenant: Add BRO with BRID, add OrderPaid:TRUE
          console.log('patch the tenant');

          axios.patch('users/'+br.tenantId+'.json?auth='+this.$store.state.auth.idToken,{bookingRequestOut:brId, orderPaid:true})
          .then(()=>{
            console.log('userPatched');
          })
        })
      } else {
        this.error="Complete all fields"
      }

    },
    tFilter (item, queryText) {
      if (item.userName!=undefined && item.email!=undefined){
        const textOne = item.userName.toLowerCase()
        // const textTwo = item.name.toLowerCase()
        const textThree = item.email.toLowerCase()
        const searchText = queryText.toLowerCase()
        return textOne.indexOf(searchText) > -1 || textThree.indexOf(searchText)>-1
      }
      else {return false}
    },
    lFilter (item, queryText){
      if (item.name!=undefined && this.landlords.hasOwnProperty(item.general.landlordId) && this.landlords[item.general.landlordId].userName!=undefined && this.landlords[item.general.landlordId].email!=undefined && item.general.title!=undefined){

        const textOne = item.name.toLowerCase()
        const textTwo = this.landlords[item.general.landlordId].userName.toLowerCase()
        const textFour = this.landlords[item.general.landlordId].email.toLowerCase()
        const textFive = item.general.title.toLowerCase()
        const searchText = queryText.toLowerCase()
        return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textFour.indexOf(searchText)>-1|| textFive.indexOf(searchText)>-1
      } else {
        return true
      }
    },
    getCurrency(val){
      // console.log(this.user.currency ,'user currencyOptions')
      if (this.curr==undefined){
        this.curr='EUR'
      }

      this.curr=val
      axios.get('https://free.currencyconverterapi.com/api/v6/convert?q=TWD_' + this.curr + '&compact=ultra&apiKey=2d6cf65af2f8557d2378')
      .then(res => {
        let fromToCurrency = "TWD_" + this.curr

        console.log(res.data[fromToCurrency])
        if (this.curr!="TWD"&&this.curr!="NTD"){
          this.fx = res.data[fromToCurrency]*1.04
        }
      })
      .catch(err=>{
        console.log('error: ',err);
        this.fx=1
        this.curr="TWD"
      })
    },

  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created(){
  }


}
</script>

<style lang="scss" scoped>
</style>
