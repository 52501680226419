<template>
<div>
  <v-card>
    <v-card-title>
      Confirmed Added Tenants
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]' :headers="headers" :items="bookings" item-key=".key" :search="search" :pagination.sync="pagination">
      <template slot="items" slot-scope="props">
        <td>
          <span v-if="!props.item.confirmTimestamp">{{props.item.dateRequested | ts}}</span>
          <span v-else>{{props.item.confirmTimestamp | ts}}</span>
        </td>
        <td>{{props.item.fullRoomNumber}}</td>
        <td>
          <div class="" v-if="usersObj && props.item.tenantId && usersObj[props.item.tenantId]">
            <span v-if="usersObj[props.item.tenantId].name">{{usersObj[props.item.tenantId].name}}</span><span v-else>{{usersObj[props.item.tenantId].userName}}</span><br>
            <a :href="'mailto:'+usersObj[props.item.tenantId].email">{{usersObj[props.item.tenantId].email}}</a>
          </div>
          <div class="" v-else>
            Dont got it
          {{props.item.tenant.name}}<br><a :href="'mailto:'+props.item.tenant.email">{{props.item.tenant.email}}</a>
          </div>
        </td>
        <td>{{props.item.dates.mid | ts}} -> {{props.item.dates.mod | ts}} </td>
        <td><span v-if="props.item.landlord">{{props.item.landlord.userName}}</span></td>
        <td>{{props.item.price}}</td>
        <td>{{props.item.status}}</td>
        <td><a :href="'https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/bookings/'+props.item['.key']"> {{props.item['.key']}}</a></td>
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </v-card>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="6000">
    {{snackbarText}}
    <v-btn flat icon @click.native="showSnackbar = false">close</v-btn>
  </v-snackbar>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import AWSSES from '../../aws-ses'


export default {
  name: 'Apartments',
  data: () => ({
    pagination: {
      descending: true
    },
    headers: [{
        text: 'Date',
        value: 'dateRequested'
      },
      {
        text: 'Room Number',
        value: 'fullRoomNumber'
      },
      {
        text: 'Tenant info',
        value: 'tenant.email'
      },
      {
        text: 'Contract Dates',
        value: 'dates.mid'
      },
      {
        text: 'Landlord',
        value: 'landlord.userName'
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Status',
        value: 'refund'
      },
      {
        text: 'DB Link',
        sortable: false
      },
    ],
    snackbarColor:null,
    snackbar:true,
    snackbarText:null,
    search: null,
    loadingUsers:true
  }),
  filters: {
    dateFormat: function(t) {
      return moment(t).format('l')
    },
    ts: function(x) {
      return moment(x).format('YYYY/MM/DD')
    },


  },

  firebase() {
    return {
      bookings: {
        source: firebase.database().ref('bookings').orderByChild('status').equalTo('Confirmed').limitToLast(250),
      },
      usersObj:{
        source: firebase.database().ref('users'),
        asObject:true,
      },
      users: {
        source: firebase.database().ref('users'),
        readyCallback: function() {
          this.loadingUsers=false
          this.users.map(x=>{
            x.id=x['.key']
            return x
          })
        }

      },

    }

  },
  methods: {
    saveTenant(booking){
      console.log(booking);
      booking.disableSave=true
      if (booking.tenantId){
        axios.patch('users/' + booking.tenantId + '.json' + '?auth=' + this.$store.state.auth.idToken, {bookingRequestOut:booking['.key'], orderPaid:true, unpaidInvoice:true})
        .then(res=>{
          console.log("SUCCESS user patch");
        })
        .catch(err=>{
          console.error(err);
        })
        axios.patch('bookings/' + booking['.key'] + '.json' + '?auth=' + this.$store.state.auth.idToken, {tenantId:booking.tenantId, status:"Confirmed"})
        this.makeRent(booking)
      }

    },
    makeRent(order){
      order.disableMakeRent=true
      let t={
        crId:order['.key'],
        comment:"First rent payment",
        type:order.type,
        tenantId:order.tenantId,
        realDates:order.realDates,
        llPrice:order.llPrice,
        price:order.price,
        perc:1,
        listingId:order.listingId,
        landlordId:order.landlordId,
        reccRent: order.reccRent,
        invoiceSent:true,
        dates:order.dates,
        roomNumber:order.fullRoomNumber,
        listUtil:order.listUtil,
        utilities:order.utilities,
        lastRent:moment().date(moment(order.dates.mod).date()).subtract(1, 'Month').format('YYYYMMDD'),
      }
      console.log(t);
      axios.post('rents.json?auth='+this.$store.state.auth.idToken,t)
      .then(()=>{
        // this.sendRentEmail(order)
        alert('Added Rent')
      })
      .catch(e=>{
        console.error(e);
        alert("Couldn't add the rent")
      })

    },
    sendRentEmail(order){
      let mid=order.dates.mid
      let mod=order.dates.mod
      console.log(order.tenant);
      //check if full months
      if (moment(mid).format('D')!= moment(mod).add(1,"d").format('D')){
        // This means it's not complete months.
        if (parseInt(moment(mid).format('D'))<=10){
          console.log('send email 3 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo3')
        } else if (parseInt(moment(mid).format('D'))<=25){
          console.log('send email 5 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo5')
        } else {
          console.log('send email 2 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo2')
        }
      } else {
        if (10 < parseInt(moment(mid).format('D')) && parseInt(moment(mid).format('D')) <= 25) {
          console.log('send email 4 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo4')
        } else {
          console.log('send email 1 ', moment(mid).format('D'));
          AWSSES.emailing(order.tenant, 'TRentInfo1')
        }
      }

    },


  },
  created() {},
  metaInfo() {
    return {
      title: 'Bookings - My Room Abroad Admin' // set a title
    }
  },


}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.draft {
    color: grey;
}
</style>
