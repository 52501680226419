<template>
<div id="">
    <Navbar></Navbar>
    <v-container grid-list-xs>
      <v-layout  justify-center wrap>
        <div class="display-2">
          Apartments of landlord {{landlord.userName}}
        </div>
      </v-layout>
      <v-layout  wrap>
        <v-container grid-list-xl wrap >
          <v-layout  wrap>

          <DashboardApartmentCard v-for="(id,index) in apartments" :key="'apartment:'+index" :apartmentId="id.apartmentId">
          </DashboardApartmentCard>
          <DashboardWholeAptCard v-for="(id,index) in wholeApts" :key="'wholeApt:'+index" :apartmentId="id.apartmentId" :type="'wholeApt'">
          </DashboardWholeAptCard>
        </v-layout>
        </v-container>
        <v-flex xs12 v-if="user.hasOwnProperty('apartments')">
          <v-layout  justify-center wrap v-if="user.apartments.length == 0">

          <v-card>
            <v-layout  justify-center wrap>

            <v-icon large justify-center class="top-padding-only pa-3 ">domain</v-icon>
          </v-layout>
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Let's setup an apartment</h3>
                <div>Click the button below to get started.</div>
              </div>
            </v-card-title>
            <v-card-actions>
              <v-btn  color="primary" href='/addListing'>+ add Apartment</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
        </v-flex>
      </v-layout>


    </v-container>
    <v-divider></v-divider>
    <v-container grid-list-xs>
      <v-layout  justify-center wrap>
        <div class="display-3">
           Studios
        </div>
      </v-layout>
      <v-layout  wrap>
        <DashboardStudioCard v-for="(id,index) in studios" :key="index" :studioId="id.studioId">
        </DashboardStudioCard>

        <v-flex xs12 v-if="user.hasOwnProperty('studios')">
          <v-layout  justify-center wrap v-if="user.studios.length == 0">

          <v-card>
            <v-layout  justify-center wrap>

            <v-icon large justify-center class="top-padding-only pa-3 ">domain</v-icon>
          </v-layout>
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Let's setup a studio</h3>
                <div>Click the button below to get started.</div>
              </div>
            </v-card-title>

            <v-card-actions>
              <v-btn  color="primary" href='/addListing'>+ add Apartment</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
        </v-flex>
      </v-layout>
    </v-container>


</div>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import DashboardApartmentCard from './DashboardApartmentCard.vue'
import DashboardStudioCard from './DashboardStudioCard.vue'
import DashboardWholeAptCard from './DashboardWholeAptCard.vue'
import firebase from '../../FirebaseApp'
import axios from 'axios'
export default {
  name: "",
  data: () => ({
    landlord:{},

  }),
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  firebase() {
    return {
      apartmentIds: firebase.database().ref('users/' + this.$route.params.id+'/apartments'),
      studioIds: firebase.database().ref('users/' + this.$route.params.id+'/studios'),
      apartments: {
        source: firebase.database().ref('users/' + this.$route.params.id + '/apartments'),
        readyCallback: function() {
          this.initialLoad = false
        },
      },
      wholeApts: {
        source: firebase.database().ref('users/' + this.$route.params.id + '/wholeApts'),
        readyCallback: function() {
          this.initialLoad = false
          for (var apt in this.wholeApts) {
            if (this.wholeApts.hasOwnProperty(apt)) {
              this.wholeApts[apt].type="wholeApt"
            }
          }
        },
      },
      studios:{
        source: firebase.database().ref('users/' + this.$route.params.id + '/studios'),
        readyCallback: function() {
          this.initialLoad = false
        },

      }



    }
  },
  components: {
    DashboardApartmentCard,
    DashboardStudioCard,
    DashboardWholeAptCard,
  },
  created(){
    // console.log('loaded');
    axios.get("users/"+this.$route.params.id+".json")
    .then(res=>{
      this.landlord=res.data
    })

  }

}
</script>
<style lang="scss" scoped>
</style>
