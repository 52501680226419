<template>
<div id="">
  <v-layout row wrap>
    <v-flex xs12 class="headline">
      Welcome Sexy beast
    </v-flex>
    <v-flex xs12 class="title">
    What would you like to do?
    </v-flex>
  </v-layout>
  <v-layout row wrap>
    <v-flex xs12 sm6 md3 class="pa-4" v-for="(link, index) in routes" :key="index">
      <v-card hover height="300" :to="link.path">
        <v-layout  align-center justify-center row wrap>
          <v-icon :href="link.path" class="huge" large>{{link.icon}}</v-icon>

        </v-layout>
        <v-card-title class="text-xs-center" justify-center primary-title>
          <v-layout   justify-center row wrap>
            <v-list>

            <h2 class="card-hover headline" :href="link.path">
              {{link.text}}


            </h2>
          </v-list>
          </v-layout>
        </v-card-title>
        <v-card-actions>

          <v-layout row wrap justify-center>
            <v-btn color="primary" flat :href="link.path" >go</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    routes: [
          {
            icon:"bug_report",
            path:"/admin/issues",
            text:'Issues'
          },
          {
            icon:"euro_symbol",
            path:"/admin/rent",
            text:'Rent'
          },{
            icon:"folder",
            path:"/admin/deposit",
            text:'Deposits'
          },{
            icon:"assignment_late",
            path:"/admin/bookings",
            text:'Added Tenants'
          },{
            icon:"assignment_turned_in",
            path:"/admin/addedTenants",
            text:'Added Tenants Conf'
          },
          {
            path:"/admin/tickets",
            icon:"print",
            text:'Tickets'
          },
          {
            icon:"search",
            path:"/admin/visits",
            text:'Visits'
          },
          {
            icon:"timeline",
            path:"/admin/analysis",
            text:'Analysis'
          },
          {
            icon:"account_balance",
            path:"/admin/transfersTBM",
            text:'Transfers To Be Made'
          },
          {
            icon:"house",
            path:"/admin/listings",
            text:'Listings'
          },
          {
            icon:"alarm_on",
            path:"/admin/bookingRequests",
            text:'Booking Requests'
          },
          {
            icon:"check",
            path:"/admin/completedRequests",
            text:'Completed bookings'
          },
          {
            icon:"people",
            path:"/admin/users",
            text:'Users'
          },
          {
            icon:"person",
            path:"/admin/landlords",
            text:'Landlords'
          },
          {
            icon:"domain",
            path:"/admin/apartments",
            text:'Apartments'
          },
          {
            path:"/admin/payments",
            icon:"receipt",
            text:'Payments'
          },{
            icon:"add_shopping_cart",
            path:"/admin/visitPayments",
            text:'Visit Payments'
          },
          {
            path:"/admin/payin",
            icon:"input",
            text:'Pay In'
          },
          {
            path:"/admin/rankings",
            icon:"grade",
            text:'Rankings'
          },
          {
            path:"/admin/FuckYouSeb",
            icon:"history",
            text:'Retargetting'
          },
          {
            icon:"money_off",
            path:"/admin/coupons",
            text:'Coupons'
          },
          {
            icon:"folder",
            path:"/admin/invoices",
            text:'Invoices'
          },
          {
            icon:"message",
            path:"/admin/LineFollows",
            text:'Line Follows'
          },
          {
            icon:"outdoor_grill",
            path:"/admin/employees",
            text:'Employees'
          },
          {
            icon:"cancel_presentation",
            path:"/admin/cancel",
            text:'Cancellations'
          },
          {
            icon:"cloud_download",
            path:"/admin/seo",
            text:'SEO JSON Files'
          },

        ]

  }),
  metaInfo() {
      return {
        title:'Admin - My Room Abroad Admin' // set a title
    }
  }

}
</script>
<style lang="scss" scoped>
.adminHome{
  margin-bottom:5em;
}
.card-hover:hover{
  cursor:pointer;
  text-decoration: underline;
}
.huge{
  font-size:10em !important;
}
</style>
