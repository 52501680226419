<template lang="html">
  <v-card>
    <v-card-title>
      Add an invoice
    </v-card-title>
    <v-card-text>
<!-- TENANT -->
    <v-autocomplete
      v-model="rec"
      :items="users"
      label="Recipient"
      prepend-icon="person"
      :filter="tFilter"
      >
      <template
             slot="selection"
             slot-scope="{ item, selected }"
      >
           <span v-text="item.userName"></span>
     </template>
     <template
       slot="item"
       slot-scope="{ item, tile }"
     >
       <v-list-tile-avatar
         color="primary"
       >
         {{ item.userName.charAt(0) }}
       </v-list-tile-avatar>
       <v-list-tile-content>
         <v-list-tile-title v-text="item.userName"></v-list-tile-title>
         <v-list-tile-sub-title v-text="item.email"></v-list-tile-sub-title>
       </v-list-tile-content>
     </template>


    </v-autocomplete>
   <!-- amount -->
  <v-text-field label="How much" prepend-icon="euro_symbol" type="number" v-model="val"></v-text-field>
  <v-text-field label="Reason" v-model="comment"></v-text-field>
  {{error}}
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="secondary" @click="$emit('close')">Cancel</v-btn>
    <v-btn color="primary" :disabled="sending" @click="addInv()">save</v-btn>
  </v-card-actions>


</v-card>

</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'


export default {
  name: 'AddInvoice',

  data: () => ({
    val:null,
    error:null,
    rec:null,
    comment:null,
    sending:false,

  }),
  firebase() {
    return {
      users: {
        source:firebase.database().ref('users').orderByChild('roleValue').startAt(19),
        readyCallback:function(){
          console.log('dousers');

        }
      },
    }
  },
  methods: {
    checkFields(){
      this.error=null
      if (this.val==null || this.rec==null || this.comment==null) {

        return false
      } else {
        return true
      }
    },
    createRef(){
      if (this.rec.name!=undefined){
        console.log('use Name');
        return moment().format('YYMMDD') + this.rec.name.charAt(0) + this.rec.name.split(" ").pop().charAt(0)
      } else {
        console.log('use userName');
        return moment().format('YYMMDD') + this.rec.userName.charAt(0) + this.rec.userName.split(" ").pop().charAt(0)
      }
    },
    addInv(){
      if (this.checkFields()){
        let invoice={
          val:this.val,
          user:this.rec['.key'],
          comment:this.comment,
          ts:moment().format(),
          ref:this.createRef()
        }
        let vm=this
        console.log('post,',invoice);
        axios.post('invoices.json?auth='+this.$store.state.auth.idToken,invoice)
        .then(()=>{
          console.log('SUCCESS');
          axios.patch('users/'+vm.rec['.key']+'.json?auth='+this.$store.state.auth.idToken,{hasInvoice:true})
        })
        .catch(err=>{
          console.log('error: ',err);
        })
        this.$emit('close')
      } else {
        this.error="Please complete the form"
      }
    },
    tFilter (item, queryText) {
      if (item.userName!=undefined && item.email!=undefined){
        const textOne = item.userName.toLowerCase()
        if (item.name!=undefined){
          const textTwo = item.name.toLowerCase()
          if (textTwo.indexOf(searchText) > -1 ){
            return true
          }
        }
        const textThree = item.email.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 || textThree.indexOf(searchText)>-1
      }
      else {return false}
    },

  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created(){
  }


}
</script>

<style lang="scss" scoped>
</style>
