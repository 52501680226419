<template>
  <div class="">

  <v-layout  justify-center>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-container grid-list-xs,sm,md,lg,xl>
        <v-card-title class="headline">
          {{$t("requestsInbox.declineTitle")}}
        </v-card-title>
        <v-card-text>
          <v-select
            :items="declineOpts"
            item-text="text"
            item-value="val"
            v-model="reason"
            :label="$t('requestsInbox.declineReasonLabel')"
          ></v-select>
          <v-menu
            v-if="reason=='booked'"
            ref="dateMenu"
            :close-on-content-click="false"
            v-model="dateMenu"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
                  >
              <v-text-field
                slot="activator"
                :label="$t('requestsInbox.setNextAv')"
                v-model="nextAv"
                :hint="$t('requestsInbox.setNextAvHint')"
                prepend-icon="event"
                readonly
              ></v-text-field>
                <v-date-picker :locale="$vuetify.lang.current" v-model="nextAv" no-title  @input="$refs.dateMenu.save(nextAv)">
                </v-date-picker>
            </v-menu>
              <v-menu
                v-if="reason=='dates'"
                ref="dateMenu2"
                :close-on-content-click="false"
                v-model="dateMenu2"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                      >
                <v-text-field
                  slot="activator"
                  :label="$t('userRequest.mid')"
                  v-model="mid"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                  <v-date-picker :locale="$vuetify.lang.current" v-model="mid" no-title  @input="$refs.dateMenu2.save(mid)">
                  </v-date-picker>
                </v-menu>
                <v-menu
                  v-if="reason=='dates'"
                  ref="dateMenu3"
                  :close-on-content-click="false"
                  v-model="dateMenu3"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                        >
                  <v-text-field
                    slot="activator"
                    :label="$t('userRequest.mod')"
                    v-model="mod"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                    <v-date-picker :locale="$vuetify.lang.current" v-model="mod" no-title  @input="$refs.dateMenu3.save(mod)">
                    </v-date-picker>
                  </v-menu>

          <v-autocomplete
            :items="allRooms"
            return-object
            v-model="suggest"
            item-text="general.fullRoomNumber"
            :label="$t('requestsInbox.suggest')"
          >
            <template slot="selection" slot-scope="data" >
                <span v-if="data.item.hasOwnProperty('general')">{{data.item.general.fullRoomNumber}} ({{data.item.type}} {{$t("requestsInbox.from")}}: {{data.item.general.nextAvailability | date}})</span>
            </template>
            <template slot="item" slot-scope="data" >
                <span v-if="data.item.hasOwnProperty('general')">{{data.item.general.fullRoomNumber}} ({{data.item.type}} {{$t("requestsInbox.from")}}: {{data.item.general.nextAvailability | date}})</span>
            </template>
          </v-autocomplete>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat @click.native="dialog = false">{{$t("datePicker.cancel")}}</v-btn>
          <v-btn color="primary" flat @click.native="declineRequest">{{$t("requestsInbox.confirm")}}</v-btn>
        </v-card-actions>

      </v-container>
      </v-card>
    </v-dialog>
  </v-layout>
</div>
</template>
<script>
import moment from 'moment'

export default {
  props:['declineDialog','allRooms'],
  data: () => ({
    rooms:{},
    reason:"",
    nextAv:"",
    mid:"",
    mod:"",
    suggest:"",
    dateMenu:false,
    dateMenu2:false,
    dateMenu3:false,
    menuVal:false,
  }),
  computed:{
    declineOpts:function(){
      let t=[
        {
          text:"Someone already booked the room",
          val:"booked"
        },
        {
          text:"The tenant isn't right for this room",
          val:"tenant"
        },{
          text:"The dates are not good",
          val:"dates"
        }
      ]
      if(this!=undefined){
        t=[
          {
            text:this.$t("requestsInbox.reasonBooked"),
            val:"booked"
          },
          {
            text:this.$t("requestsInbox.reasonTenant"),
            val:"tenant"
          },
          {
            text:'The dates are not good.',
            val:"dates"
          }
        ]
      }
      return t
    },
    dialog:{
      get:function(){
        return this.declineDialog
      },
      set:function(val){
        this.$emit('changeDialog',val)
      }
    }

  },
  methods:{
    declineRequest(){
      this.dialog=false
      let suggestLink=""
      if (this.suggest!=""){
        if (this.suggest.type=='room'){
          suggestLink='details/'+this.suggest.id
        } else if(this.suggest.type=='studio'){
          suggestLink='studioDetails/'+this.suggest.id
        } else {
          suggestLink='apartmentDetails/'+this.suggest.id+'/apartment'
        }
      }

      let payload={
        reason:this.reason,
        nextAv:this.nextAv,
        suggest:suggestLink,
        mid:this.mid,
        mod:this.mod
      }
      this.$emit('decline',payload)

    }
  },
  created(){

  },
  filters:{
    date(t){
      return moment(t).format('LL')
    }
  }
}
</script>

<style lang="scss scoped">
</style>
