<template>
<div>
  <v-card>
   <v-card-title>
     Transfers To Be Made
     <v-btn color="primary" @click="addInvoice=true">Add Invoice</v-btn>
     <v-btn color="primary" @click="addRefundDialog=true">Add Refund</v-btn>
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="transfsTBP"
     :search="search"
     :pagination.sync="pagination"
   >
     <template slot="items" slot-scope="props">
       <td><a :href="'/profile?id='+props.item.landlordId">{{ props.item.llName}}</a>
         <br> <span v-if="props.item.editNN==true && props.item.hasOwnProperty('landlord')">
           <v-text-field
             v-model="props.item.landlord.nickname"
             label="Nickname"
           ></v-text-field>
           <v-btn color="primary" @click="saveNN(props.item)">save</v-btn>
         </span> <span v-else> <a  @click="editNN(props.item)">edit Nickname</a></span>

       </td>
       <td><div class="" v-if="props.item.editAmount!=undefined && props.item.editAmount==true">
         <v-text-field
         type="number"
         v-model="props.item.totalAmount"
         ></v-text-field>
         </div>
       <div class="" v-else>
         {{ props.item.totalAmount}} TWD
         <br><a @click="props.item.editAmount=true">Edit</a>

       </div>
       </td>
       <td>
         <div class="" v-for="transf in props.item.transfers" :key="transf['.key']">
           <v-layout wrap>
             <v-flex xs1>
               <v-checkbox hide-details v-model="transf.selector" @change="changeSelector(transf, props.item)" ></v-checkbox>
             </v-flex>
             <v-flex xs11>

               <a :href="roomLink(transf)">{{transf.roomNumber}}</a>
               ( <span v-if="transf.deposit==true || transf.refund">{{transf.price}}</span>
                  <span v-else-if="transf.prepaid!=undefined">{{transf.prepaid}} </span>
                  <span v-else-if="transf.llPrice!=undefined">{{transf.amount}}</span>
                  <span v-else-if="transf.nextRent==undefined">{{transf.price}}</span>
                  <span v-else>{{transf.nextRent}}</span>
               <span v-if="transf.utilities"> + {{transf.utilities}}</span>
               <span v-if="!transf.noComm && transf.deposit!=true && !transf.refund"> - {{transf.margin}}</span>
               <span v-if="transf.comment"> | {{transf.comment}}</span>
               <!-- <span v-if="transf.deposit==true" > - Deposit</span>) -->
               <span v-if="transf.noComm==true">No comm</span>
               <span v-if="users.hasOwnProperty(transf.tenantId)"> - {{users[transf.tenantId].name}}  <span v-if="transf && transf.moneyInTS"> - {{transf.moneyInTS | dateFormat}}</span></span>
               <span> - <a @click="undo(transf)">undo</a></span>
               <span> - <a color="alert" class="alert" @click="deleteTransf(transf)">delete</a></span>
               <span> - <a color="secondary" target="_blank" :href="'https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/transfTBM/'+transf['.key'] ">DB</a></span>
             </v-flex>
           </v-layout>

         </div>
       </td>
       <td>
         <div class="" v-if="props.item.bankInfo!=undefined">

         <div class="">
           {{props.item.bankInfo.bankName}}
         </div>
         <div class="">
           BankCode: {{props.item.bankInfo.bankCode}}
         </div>
         <!-- <div class="">
           {{props.item.bankInfo.bankAddress}},
         </div> -->
         <div class="">
         Account Holder: {{props.item.bankInfo.holderName}}
         </div>
         <div class="">
           Account No: {{props.item.bankInfo.accountNumber | noSpaceAndHyphen}}
         </div>
        </div>

       </td>
       <td>
         {{props.item.ref}}
       </td>
       <td><v-textarea
         v-model="props.item.comment"
       ></v-textarea></td>
       <td>
         <v-checkbox label="No Fapiou" v-model="props.item.noFapiou"></v-checkbox>
         <v-btn color="primary" :disabled="props.item.transfered" @click="processPayment(props.item)">Payment processed</v-btn>
       </td>

     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
 <v-dialog
   v-model="addInvoice"
   max-width="500px"
 >
 <AddInvoice @close="addInvoice =false"></AddInvoice>
 </v-dialog>
 <v-dialog
   v-model="addRefundDialog"
   overlay
   max-width="500px"
   transition="dialog-transition"
 >
  <v-card>
    <v-card-title primary-title>
      Add Refund
    </v-card-title>
    <v-card-text>
      <v-autocomplete :items="cr" v-model="refundCR" hide-details clearable item-text="sTerm" return-object label="Which booking?">
        <template slot="no-data">
          <v-list-tile>
            <v-list-tile-title>
              No match found!
            </v-list-tile-title>
          </v-list-tile>
        </template>
        <template slot="selection" slot-scope="{item, selected}">
          <v-chip
            :selected="selected"
            color="primary"
            class="white--text"
          >
            <span v-text="">{{item.fullRoomNumber}} <span v-if="item.landlord && item.landlord.userName">- {{item.landlord.userName}}</span>  <span v-if="item.tenant && item.tenant.name">- {{item.tenant.name}}</span></span>
          </v-chip>

        </template>
        <template
          slot="item"
          slot-scope="{ item, tile }"
        >
          <v-list-tile-avatar
            color="indigo"
            class="headline font-weight-light white--text"
          >
            <!-- {{ item.userName.charAt(0) }} -->
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title > {{item.fullRoomNumber}} <span v-if="item.landlord && item.landlord.userName"> - {{item.landlord.userName}}</span></v-list-tile-title>
            <v-list-tile-sub-title ><span v-if="item.tenant"> <span v-if="item.tenant.name">{{item.tenant.name}}</span> <span v-else-if="item.tenant.userName">{{item.tenant.userName}}</span> ({{item.tenant.email}}) </span>  </v-list-tile-sub-title>
          </v-list-tile-content>
        </template>
      </v-autocomplete>
      <v-text-field
        v-model="refundAmount"
        label="Amount"
        type="number"
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="refundComment"
        label="Comment"
        hide-details
      ></v-text-field>
      <div class="pt-2">
        <span  v-if="refundAmount && refundCR &&refundCR.tenant">You will refund {{refundAmount}} to {{refundCR.tenant.name}}</span>
      </div>
    </v-card-text>
    <v-card-actions class="px-4 pb-3">
      <v-btn color="secondary" @click="addRefundDialog=false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="addRefund">ADD</v-btn>
    </v-card-actions>
  </v-card>

 </v-dialog>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import AWSSES from '../../aws-ses'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import AddInvoice from './AddInvoice.vue'

export default {
  name: 'TransfersToBeMade',
  data: () => ({
    pagination:{descending:false},
    headers: [
      { text: 'Landlord', value: 'llName' },
      { text: 'Total Amount', value: 'total' },
      { text: 'Rooms (Rent+Util-3%)..........................................................................................................................', value: 'name'},
      { text: 'Bank Info', sortable:false},
      { text: 'Reference', sortable:false},
      { text: 'Human comment', sortable:false},
      { text: 'Transfer completed', sortable:false},
    ],
    addInvoice:false,
    addRefundDialog:false,
    disableRefund:false,
    refundLandlord:null,
    refundCR:null,
    refundAmount:null,
    refundComment:null,
    search: null,
    searched: [],
    transfsTBP:[],
  }),
  firebase() {
    return {
      transfers: {
        source:firebase.database().ref('transfTBM'),
        readyCallback:function(){

          // this.checkTransfers()
          this.groupTransfers()
        }
      },
      landlords:{
        source:firebase.database().ref('users').orderByChild("roleValue").startAt(20),
        asObject:true,
        readyCallback:function(){

          // this.checkTransfers()
          this.matchTransfsLL()
        }

      },
      cr:{
        source:firebase.database().ref('completedOrders'),
        readyCallback:function(){
          this.cr.map(x=>{
            x.sTerm=x.fullRoomNumber
            if (x.tenant){
              x.sTerm=x.sTerm+ " "+x.tenant.userName+' '+x.tenant.name+ " "+x.tenant.email
            }
            if (x.landlord){
              x.sTerm=x.sTerm+" "+x.landlord.userName+' '+x.landlord.name+ " "+x.landlord.email
            }
            return x
          })
          this.cr.reverse()
        }
      },
      users:{
        source:firebase.database().ref('users').orderByChild("roleValue").endAt(19),
        asObject:true,
        readyCallback:function(){

          // this.checkTransfers()
          this.matchTransfsLL()
        }

      }
    }
  },
  methods: {
    addRefund(){
      this.disabledRefund=true
      let t={
        brId:this.refundCR['id'],
        crId:this.refundCR['.key'],
        comment:this.refundComment,
        landlordId:this.refundCR.landlordId,
        listingId:this.refundCR.listingId,
        type:this.refundCR.type,
        price:-this.refundAmount,
        tenantId:this.refundCR.tenantId,
        refund:true,
        roomNumber:this.refundCR.fullRoomNumber,
      }
      console.log(t);
      t=JSON.parse(JSON.stringify(t))
      axios.post('transfTBM.json?auth='+this.$store.state.auth.idToken,t)
      .then(res=>{
        this.disableRefund=false
        this.addRefundDialog=false
        alert('reload to see the refund.')
      })
    },
    editNN(transf){
      transf.editNN=true
      console.log(transf);
      this.pagination.descending=!this.pagination.descending
      this.pagination.descending=!this.pagination.descending
    },
    saveNN(transf){
      console.log(transf.landlord);
      transf.editNN=false
      transf.llName=transf.landlord.userName + " ("+transf.landlord.nickname+")"

      axios.patch('users/'+transf.id+'.json?auth='+this.$store.state.auth.idToken,{nickname:transf.landlord.nickname})
      .then(res=>{
        this.pagination.descending=!this.pagination.descending
        this.pagination.descending=!this.pagination.descending
        console.log('updated Nickname of landlord');
      })
      .catch(err=>{
        console.log('error saving Admin info:',err);
        alert('Something happened, Tell Vincent - DO NO REFRESH')
      })
    },
    deleteTransf(transf){
      console.log('delete', transf);
      axios.delete('transfTBM/'+transf['.key']+'.json?auth='+this.$store.state.auth.idToken)
    },
    undo(transf){
      let t={
        brId:transf.brId,
        crId:transf.crId,
        comment:transf.comment,
        listingId:transf.listingId,
        type:transf.type,
        price:transf.price,
        amount:transf.amount,
        llPrice:transf.llPrice,
        utilities:transf.utilities,
        tenantId:transf.tenantId,
        margin:transf.margin,
        roomNumber:transf.roomNumber,
      }
      let rent=JSON.parse(JSON.stringify(t))
      delete rent.amount
      delete rent.margin
      rent.comment=null
      rent.listUtil=transf.listUtil
      rent.currency=transf.currency
      rent.dates=transf.dates
      rent.landlordId=transf.landlordId
      rent.realDates=transf.realDates
      rent.lastRent=transf.lastRent
      rent.reccRent=transf.reccRent
      rent.askDay=transf.askDay
      rent.noDateChange=transf.noDateChange
      rent.fDueDate=transf.fDueDate
      rent.perc=transf.perc
      if (rent.utilities){
        rent.utilities=null
      }
      if (rent.lastRent!=undefined){
        if (moment(rent.lastRent,"MMYYYY").isValid()){
          rent.lastRent=moment(rent.lastRent,"MMYYYY").subtract(1,"M").format("YYYYMMDD")
        } else {
          rent.lastRent=moment(rent.lastRent,"YYYYMMDD").subtract(1,"M").format("YYYYMMDD")
        }
      }
      // let t = JSON.stringify(transf)
      // let rent=JSON.parse(JSON.stringify(transf));
      console.log(rent)
      axios.post('rents.json?auth='+this.$store.state.auth.idToken,rent)
      .then(res=>{
        console.log(res.data);
        axios.delete('transfTBM/'+transf['.key']+'.json?auth='+this.$store.state.auth.idToken)
        .then(res=>{
          console.log(res.data);
          this.$router.go()
        })
        .catch(err=>{
          console.log(err, 'Error on delete from transf');
        })

      })
      .catch(err=>{
        console.log(err,'Error on post in rent');
      })
    },
    roomLink(transf){
      switch (transf.type) {
        case 'room':
          return '/details/'+transf.listingId
        case 'studio':
          return '/studioDetails/'+transf.listingId
        case 'apartment':
          return '/apartmentDetails/'+transf.listingId
        default:
          return ""
      }
    },
    processPayment(payment){
      payment.transfered=true
      console.log(payment);
      let totalMargin=0
      let transfer={
        landlordId:payment.landlordId,
        total:payment.totalAmount,
        ref:payment.ref,
        dateSent:new Date(),
        items:[]
      }
      console.log(transfer);
      AWSSES.emailing(this.landlords[payment.transfers[0].landlordId], 'LLPaymentTransferred')
      for (var i = 0; i < payment.transfers.length; i++) {
        if (payment.transfers[i].selector==false){
          continue
        }
        if (payment.transfers[i].refund){
          let d={
            brId:payment.transfers[i].brId,
            crId:payment.transfers[i].crId,
            comment:payment.transfers[i].comment,
            listingId:payment.transfers[i].listingId,
            type:payment.transfers[i].type,
            price:payment.transfers[i].price,
            utilities:0,
            tenantId:payment.transfers[i].tenantId,
            margin:0,
            roomNumber:payment.transfers[i].roomNumber,
          }
          transfer.items.push(d)
          axios.post('payIn.json?auth='+this.$store.state.auth.idToken,rent)

        }else if (payment.transfers[i].deposit==true ){
          console.log('this was deposit');
          if (payment.transfers[i].margin==undefined){
            payment.transfers[i].margin=0
          }
          let d={
            brId:payment.transfers[i].brId,
            crId:payment.transfers[i].crId,
            comment:payment.transfers[i].comment,
            listingId:payment.transfers[i].listingId,
            type:payment.transfers[i].type,
            price:payment.transfers[i].price,
            utilities:payment.transfers[i].utilities,
            tenantId:payment.transfers[i].tenantId,
            margin:0,
            roomNumber:payment.transfers[i].roomNumber,
          }
          transfer.items.push(d)
        } else {
          let t={
            brId:payment.transfers[i].brId,
            crId:payment.transfers[i].crId,
            comment:payment.transfers[i].comment,
            listingId:payment.transfers[i].listingId,
            type:payment.transfers[i].type,
            price:payment.transfers[i].price,
            amount:payment.transfers[i].amount,
            llPrice:payment.transfers[i].llPrice,
            utilities:payment.transfers[i].utilities,
            tenantId:payment.transfers[i].tenantId,
            margin:payment.transfers[i].margin,
            roomNumber:payment.transfers[i].roomNumber,
          }
          totalMargin=totalMargin+payment.transfers[i].margin
          transfer.items.push(t)
          let rent=JSON.parse(JSON.stringify(t))
          delete rent.amount
          delete rent.margin
          rent.comment=null
          rent.listUtil=payment.transfers[i].listUtil
          rent.currency=payment.transfers[i].currency
          rent.dates=payment.transfers[i].dates
          rent.landlordId=payment.transfers[i].landlordId
          rent.realDates=payment.transfers[i].realDates
          rent.lastRent=payment.transfers[i].lastRent
          rent.reccRent=payment.transfers[i].reccRent
          rent.askDay=payment.transfers[i].askDay
          rent.noDateChange=payment.transfers[i].noDateChange
          rent.fDueDate=payment.transfers[i].fDueDate
          rent.perc=payment.transfers[i].perc
          if (rent.utilities){
            rent.utilities=null
          }

          // console.log('rents.json?auth='+' ---- POST',rent);
          axios.post('rents.json?auth='+this.$store.state.auth.idToken,rent)
          .then(()=>{
            console.log('rent posted');
          })
          .catch(err=>{
            console.log(err);
          })
        }
        // console.log('transfTBM/'+payment.transfers[i]['.key']+'.json?auth='+ '   ----- DELETE');

        axios.delete('transfTBM/'+payment.transfers[i]['.key']+'.json?auth='+this.$store.state.auth.idToken)
      }
      // console.log('index --- ' + index);
      // console.log('payments.json?auth=' + '   --- POST', transfer);
      let index = this.transfsTBP.findIndex(item => item==payment);
      this.transfsTBP.splice(index, 1)
      if (totalMargin>0){
        let fapiouInfo={
          invoiceNumber:'MRA'+Math.round(moment().format('X')).toString()+this.landlords[payment.transfers[0].landlordId].userName.substring(0,3).toUpperCase(),
          amount: totalMargin,
        }
        try{
          this.landlords[payment.transfers[0].landlordId].id=payment.transfers[0].landlordId
          this.createFapiou(fapiouInfo,this.landlords[payment.transfers[0].landlordId])
        }
        catch(err){
          console.error('err with createFpiou ',err);
        }
      } else {
        console.log('No Margin to account for');
      }
      axios.post('payments.json?auth='+this.$store.state.auth.idToken,transfer)
    },
    createFapiou(invoice,user){
      if (invoice.noFapiou==true){
        alert("No Fapiou Created!")
        return
      }
      let data={
          relateNumber:invoice.invoiceNumber,
          businessId:'',
          name:user.userName,
          userId:user.id,
          email:user.email,
          amount:invoice.amount,
          itemName:"My Room Abroad Consulting Service",
          address:"",
          type:"B2C"
      }
      if (user.hasOwnProperty('companyId') && user.companyId.length==8){
        data.businessId=user.companyId
        data.type="B2B"
      }
      if (user.hasOwnProperty("companyName")  && user.companyName.length!=""){
        data.name=user.companyName
      }
      console.info(data);
      axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/fapiou2', data, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then(res => {
        // console.log('Response data: ', res.data);
        console.log('SUCCESS: ',res.data);

        let fapiouNumber=this.getQueryVariable('InvoiceNumber', res.data)
        if (fapiouNumber!=undefined && fapiouNumber!=""){
          axios.post('fapiou.json?auth='+this.$store.state.auth.idToken,{number:fapiouNumber, ts:Math.round(moment().format('X')).toString(), user:user.id})
        } else {
          console.log('no fapiou created for ');
          this.failedFapiou(data,user)
        }

      })
      .catch(err=>{
        console.log('ERROR', err);
        this.failedFapiou(data, user)
      })
    },
    // failedFapiou(invoice,user){
    //   admin={
    //     email:'vincent@myroomabroad.com',
    //     name:'Admin'
    //   }
    //   let info={
    //     tenantName:user.userName,
    //     amount:invoice.amount
    //   }
    //   AWSSES.emailingInfo(admin, info ,'AFapiouFailed')
    // },
    failedFapiou(data,user){
      axios.post('failedFapiou.json?auth='+this.$store.state.auth.idToken,data)

    },


    getQueryVariable(variable, query) {
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    },

    checkTransfers(){
      for(var i in this.transfers){

        if(this.transfers[i] instanceof Object){

          if (moment().isBetween(moment(this.transfers[i].dates.mid), moment(this.transfers[i].dates.mod))){
            this.transfers[i].contractValue=this.contractValue(this.transfers[i])
          }
        }
      }
    },
    changeSelector(item,transf){
      console.log(item, transf);
      let transferAmount=this.getTransferAmount(item)
      if (item.selector==true){
        transf.totalAmount=transf.totalAmount+transferAmount
      } else{
        transf.totalAmount=transf.totalAmount-transferAmount
      }
      console.log(transf.totalAmount);
    },
    groupTransfers(){
      console.log('group transfs');
      let t=[]
      for (var i = 0; i < this.transfers.length; i++) {
        this.transfers[i].selector=true
        let transferAmount=this.getTransferAmount(this.transfers[i])
        let index = t.findIndex(item => item.landlordId==this.transfers[i].landlordId);
        console.log(index, 'index');
        //if the landlord is not yet in the last, add him
        if (index==-1){
          t.push({
            id:this.transfers[i].landlordId,
            landlordId:this.transfers[i].landlordId,
            transfers:[],
            totalAmount:0,
            transfered:false,
            editAmount:false,
          })
          index=t.length-1
          console.log(t,index);
        }
        t[index].transfers.push(this.transfers[i])
        t[index].id=t[index].transfers[0].landlordId
        t[index].totalAmount=t[index].totalAmount+transferAmount

      }
      for (var i = 0; i < t.length; i++) {
        t[i].transfers.sort(this.compare)
      }
      console.log('done sort');
      this.transfsTBP=t
      this.matchTransfsLL()
    },
    compare( a, b ) {
      if ( a.roomNumber < b.roomNumber ){
        return -1;
      }
      if ( a.roomNumber > b.roomNumber ){
        return 1;
      }
      return 0;
    },

    getTransferAmount(transf){
      // ({{transf.price}}<span v-if="transf.utilities"> + {{transf.utilities}}</span> - {{transf.price*.03}})
      let total=0
      if (transf.refund==true){
        return parseInt(transf.price)
      }
      if (transf.deposit==true){
        console.log('Its a deposit');
        if (transf.prepaid!=undefined){
          return parseInt(transf.prepaid)
        } else {
          return parseInt(transf.price)
        }
      }

      if (transf.llPrice!=undefined && transf.llPrice!=""){
        if (transf.prepaid!=undefined){
          transf.amount=parseInt(transf.prepaid)
          transf.margin=0
          total=parseInt(transf.prepaid)
        } else {
          console.log('llPrice');
          if (transf.nextRent==undefined || parseInt(transf.nextRent)==parseInt(transf.price)){
            transf.amount=parseInt(transf.llPrice)
            transf.margin=0
            total=parseInt(transf.llPrice)
          } else {
            transf.amount=Math.floor(parseInt(transf.nextRent)/parseInt(transf.price)*parseInt(transf.llPrice))
            transf.margin=0
            total=parseInt(transf.amount)
          }
        }
      }
      else if (transf.nextRent!=undefined){
        if (transf.perc!=undefined && isNaN(parseInt(transf.perc))==false){
          let perc=parseInt(transf.perc)/100
          if (transf.prepaid!=undefined){
            total=Math.floor(parseInt(transf.prepaid)*(1-perc))
            transf.amount=parseInt(transf.prepaid)*(1-perc)
            transf.margin=Math.ceil(parseInt(transf.prepaid)*perc)
          } else {
            total=Math.floor(parseInt(transf.nextRent)*(1-perc))
            transf.amount=parseInt(transf.nextRent)*(1-perc)
            transf.margin=Math.ceil(parseInt(transf.nextRent)*perc)

          }
        } else {
          if (transf.prepaid!=undefined){
            total=Math.floor(parseInt(transf.prepaid)*.97)
            transf.amount=parseInt(transf.prepaid)*.97
            transf.margin=Math.ceil(parseInt(transf.prepaid)*.03)
          } else {
            total=Math.floor(parseInt(transf.nextRent)*.97)
            transf.amount=parseInt(transf.nextRent)*.97
            transf.margin=Math.ceil(parseInt(transf.nextRent)*.03)
          }
        }
        if (transf.nextRent!=transf.price && transf.noDateChange!=true){
          transf.askDay=20
        }
      } else {
        if (transf.perc!=undefined && isNaN(parseInt(transf.perc))==false){
          let perc=parseInt(transf.perc)/100
          if (transf.prepaid!=undefined){
            total=Math.floor(parseInt(transf.prepaid)*(1-perc))
            transf.amount=parseInt(transf.prepaid)*(1-perc)
            transf.margin=Math.ceil(parseInt(transf.prepaid)*perc)
          } else {
            total=Math.floor(parseInt(transf.price)*(1-perc))
            transf.amount=parseInt(transf.price)*(1-perc)
            transf.margin=Math.ceil(parseInt(transf.price)*perc)
          }
        } else {
          if (transf.prepaid!=undefined){
            total=Math.floor(parseInt(transf.prepaid)*.97)
            transf.amount=parseInt(transf.prepaid)*.97
            transf.margin=Math.ceil(parseInt(transf.prepaid)*.03)
          } else {
            total=Math.floor(parseInt(transf.price)*.97)
            transf.amount=parseInt(transf.price)*.97
            transf.margin=Math.ceil(parseInt(transf.price)*.03)
          }
        }
      }
      if (transf.utilities){
        total=total+parseInt(transf.utilities)
      } else {
        if (transf.listUtil!=undefined && transf.listUtil.amount != 0){
          total=total+parseInt(transf.listUtil.amount)
        }
      }
      // if (transf.landlordId=='-LSOHT4wFtvKabn9ZQTk'){
      //   console.log('ROBYN TOTAL:', total, transf);
      //
      // }
      return total
    },
    matchTransfsLL(){
      console.log('match the landlords');
      if (this.landlords!=undefined){
        for (var i in this.transfsTBP) {
          console.log('set ll');
          if (this.landlords.hasOwnProperty(this.transfsTBP[i].landlordId)){
            this.transfsTBP[i].landlord=this.landlords[this.transfsTBP[i].landlordId]
            this.transfsTBP[i].llName=this.transfsTBP[i].landlord.userName
            if (this.transfsTBP[i].landlord.hasOwnProperty('nickname')){
              this.transfsTBP[i].llName=this.transfsTBP[i].llName+' ('+this.transfsTBP[i].landlord.nickname+')'
            }
            this.transfsTBP[i].ref= "MRA"+ moment().format("YYMMDD")+this.transfsTBP[i].landlord.userName.substring(0,3).toUpperCase()
            if (this.landlords[this.transfsTBP[i].landlordId].bankInfo instanceof Object){
              this.transfsTBP[i].bankInfo= this.landlords[this.transfsTBP[i].landlordId].bankInfo
            } else {
              this.transfsTBP[i].bankInfo={}
            }
          }
        }
        this.pagination.descending=!this.pagination.descending
        this.pagination.descending=!this.pagination.descending
      }
    },
// ALL FROM RENTS FOR THE CALCULATIONS OF THE TOTAL COMMISSION!
          calcComm(rent){
            let t=0
            if (rent.perc!=undefined){
              t=parseInt(rent.nextRent)*-parseInt(rent.perc)/100
            } else if (rent.llPrice!=undefined) {
              t=parseInt(rent.llPrice)-parseInt(rent.price)
            } else {
              t=parseInt(rent.nextRent)*-0.03
            }
            return t
          },
          getLastRentMoment(date){
            if (moment(date,"MMYYYY").isValid()){
              return moment(date,"MMYYYY")
            } else if (moment(date,"YYYYMMDD").isValid()) {
              return moment(date,"YYYYMMDD")
            } else {
              return moment(date)
            }
          },
          setNextRentMonth(rent) {
            let nextRent = ""
            if (rent.lastRent != undefined ) {
              if (moment(rent.lastRent, 'MMYYYY').isValid()){
                if (rent.dates!=undefined){
                  if (moment(rent.dates.mid).format("D")==1 || this.isOddDates(rent)){
                    nextRent = moment(rent.lastRent, 'MMYYYY').add(1, 'month').format('31 MMM YY')
                  } else {
                    nextRent = moment(rent.lastRent, 'MMYYYY').add(1, 'month').date(moment(rent.dates.mod).date())
                  }

                } else {
                  nextRent="ERROR WITH DATES!"
                }
                console.log('useOldformat');
              } else if (moment(rent.lastRent, 'YYYYMMDD').isValid()) {
                nextRent = moment(rent.lastRent, 'YYYYMMDD').add(1, 'month').format('DD MMM YY')
                console.log('useNewFormat');
              }
            } else {
              if (rent.dates!=undefined){
                if (moment(rent.dates.mid).format("D")==1 || this.isOddDates(rent)){
                  nextRent = moment(rent.dates.mid).add(1, 'month').format('31 MMM YY')
                } else {
                  nextRent = moment(rent.dates.mid).add(1, 'month').date(moment(rent.dates.mod).date())
                }
              } else {
                nextRent="ERROR WITH DATES!"
              }
            }
            rent.temp=nextRent
            return nextRent
          },
          setRent(contract) {
            // console.log('mid:',contract.dates.mid, ', mod: ', moment(contract.dates.mod).add(1,"d").format('D'));
            // console.log('Days difference: ', moment(contract.dates.mid).format('D'), moment(contract.dates.mod).add(1,"d").format('D'));
            // console.log();
            if (this.isOddDates(contract)==true && this.notFirstMonth(contract)==false){
              // console.log('calc first rent');
              let start= moment(contract.dates.mid).add(1,'M')
              let end = moment(contract.dates.mid).add(1,'M').endOf('month')
              // console.log('START:', start.format('LLL'), ' END: ', end.format('LLL'));
              // console.log('DAYS TILL END OF MONTH:',end.diff(start,'days'),' days');
              let days=end.diff(start,'days')+1
              let monthDays=start.daysInMonth()
              // console.log(days/monthDays);
              contract.invoiceComment=contract.invoiceComment + ' ('+days+' Days)';
              contract.setCheckDates=true
              return Math.ceil(contract.price*(days/monthDays))
            } else if (this.isOddDates(contract)==true && this.isLastMonth(contract)==true) {
              // console.log('calc Last Rent');
              // console.log(contract.roomNumber, this.users[contract.tenantId].userName);
              return this.calcLastRentAmount(contract)
            } else {
              // console.log('regular month');
              return contract.price
            }
          },

          calculateTotalComm(rent){
            let tComm=0
            if(!rent.hasOwnProperty('nextRent')){
              rent.nextRent=rent.price
            }
            let mod=moment(rent.dates.mod)
            if (mod.date()<=10 && this.isOddDates(rent)==false){
              mod=mod.subtract(1,"Month")
            }
            mod=mod.subtract(1,'Month')
            console.log('Last Rent: ',rent.lastRent, '  nextRent: ',rent.nextRent, ' MOD: ',rent.dates.mod,);
            tComm=this.calcComm(rent)
            rent.lastRent=moment(this.setNextRentMonth(rent),'DD MMM YY').format("YYYYMMDD")
            if (rent.hasOwnProperty('lastRent')){
              rent.comm1=this.setComment(rent,rent.lastRent)
            }
            // Check if next Rent Month is last Month
            console.log(tComm);
            // console.log(parseInt(moment(rent.lastRent,"YYYYMM").month()) < parseInt(moment(rent.dates.mod).month()));
            console.log(rent.lastRent,moment(rent.dates.mod).format('ll'),mod.format('ll'));
            while (this.getLastRentMoment(rent.lastRent).isBefore(mod,"Month")){
              rent.nextRent=this.setRent(rent)
              tComm=tComm+this.calcComm(rent)
              console.log(tComm);
              // console.log(rent.lastRent)
              rent.lastRent=moment(this.setNextRentMonth(rent),'DD MMM YY').format("YYYYMMDD")
              console.log(rent.lastRent, rent.dates.mod, this.getLastRentMoment(rent.lastRent).isBefore(mod,"Month"));
            }
            if (this.isOddDates(rent)){
              rent.nextRent=this.calcLastRentAmount(rent)
            } else {
              rent.nextRent=this.setRent(rent)
            }
            tComm=tComm+this.calcComm(rent)
            console.log(rent.comm1, " and  until ", moment(rent.dates.mod).format('LL'), ' TOTAL COMM : ', tComm);
            return tComm
          },
          isOddDates(contract){
            // console.log(contract.dates.mod,moment(contract.dates.mod).add(1,"d").format('D'));
            if (moment(contract.dates.mid).format('D')!= moment(contract.dates.mod).add(1,"d").format('D')){
              return true
            } else {
              return false
            }
          },
          notFirstMonth(contract){
            // console.log(contract.roomNumber,moment(contract.dates.mid).add(1,'M').format('M'), moment(this.setNextRentMonth(contract), 'MMM YY').format('M'));
            if (moment(contract.dates.mid).add(1,'M').format('DD MMM YYYY')== moment(this.setNextRentMonth(contract), 'DD MMM YY').format('DD MMM YYYY')) {
              // console.log('Its the first month');
              return false
            } else {
              // console.log("It's not the first month");
              return true
            }
          },
          isLastMonth(contract){
            if (contract.askDay!=5 && moment().add(1,'M').format('M YYYY')== moment(contract.dates.mod).format('M YYYY')) {
              return true
            } else if (contract.askDay==5 && moment().format('M YYYY')== moment(contract.dates.mod).format('M YYYY')) {
              return true
            } else {
              return false
            }
          },

          calcLastRentAmount(contract){
            let totalDays=moment(contract.dates.mod).daysInMonth()
            let days=moment(contract.dates.mod).date()
            let ratio=days/totalDays
            // console.log(ratio, days, totalDays);
            // console.log('last rent:', contract,Math.ceil( contract.price*ratio));
            return Math.ceil(contract.price*ratio)
          },

          payAllToLL(transf){

          },

  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('MMM DD')
      } else {
        return "Nothing to show"
      }

    },
    noSpaceAndHyphen: function(t){
      if (t){
        return t.replace(/-|\s/g,"");
      } else {
        return null
      }
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
  },
  components: {
    AddInvoice,
  },
  metaInfo() {
      return {
        title:'Transf TBM - My Room Abroad Admin' // set a title
    }
  }

}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.alert{
  color: red!important;
}
</style>
